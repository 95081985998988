import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { flatMap, map, pickBy, sortBy } from "lodash";
import moment, { Moment } from 'moment';
import { EuiFormRow, EuiSuperSelect, EuiFieldText, EuiRadioGroup, EuiDatePicker, EuiButton, EuiFlyoutBody, EuiFlyoutFooter, EuiFlexGroup, EuiFlexItem } from "@elastic/eui";
import { DollarTextField } from "src/utils";
import { addTransactionApi } from "src/apiService";
import ConfirmDialog from "src/components/Dialogs/ConfirmDialog";
import {
  Account,
  ACCOUNT_TYPES,
  isIncomeTypeToConfirm,
  NewTransaction,
  STUDENT_TOP_LEVEL_CATEGORIES,
  TOP_LEVEL_CATEGORIES,
  Transaction,
  TRANSACTION_ACCOUNT_TYPES,
  TRANSACTION_CATEGORIES,
  TYPE_LIST_ORDER,
} from "src/interfaces";
import {
  addTransactionSuccess,
  addUnconfirmedTransaction,
} from "src/store/transaction/actions";
import { getAccounts } from "src/store/account/selector";
import { getIsCurrentStudent } from "src/store/system/selector";
import { StyledSpacer, StyledSpan, StyledEuiButton } from "src/components/Global/StyledComponents"
import { Global, css } from "@emotion/react";

const transactionStyles = css`
  .flyout-footer {
    background-color: #fff !important;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-top: 1px solid #D3DAE6;
    button {
      margin: 0;
    }
  }
`;

interface AddManualTransactionDialogProps {
  visible: boolean;
  onClose: VoidFunction;
  openConfirmDialog: (transaction: Transaction) => void;
  prefill?: any;
  onAdd?: VoidFunction;
  defaultAddAnother?: boolean;
}

const AddManualTransaction = ({
  visible,
  onAdd,
  onClose,
  openConfirmDialog,
  prefill,
  defaultAddAnother,
}: AddManualTransactionDialogProps) => {
  const dispatch = useDispatch();
  const accounts = useSelector(getAccounts);
  const isCurrentStudent = useSelector(getIsCurrentStudent);
  const [confirmAddAnother, setConfirmAddAnother] = useState(!!defaultAddAnother);
  const [selectedDate, setSelectedDate] = useState<Moment | null>(moment(new Date()));
  const [formState, setFormState] = useState<any>({ ...(prefill || {}), reverse: "n" });

  useEffect(() => {
    if (!visible) {
      setConfirmAddAnother(false);
      setFormState({ reverse: "n" });
    } else if (prefill) {
      setFormState((current: any) => ({ ...prefill, ...current, reverse: "n" }));
    }
  }, [visible, prefill]);

  const topLevelCategories = isCurrentStudent ? STUDENT_TOP_LEVEL_CATEGORIES : TOP_LEVEL_CATEGORIES;

  const handleDateChange = (date: Moment | null) => {
    setSelectedDate(date);
  };

  const setFormValue = (e: any) => {
    const target = e.target;
    const newFormState = { ...formState, [target.name]: target.value };
    if (target.name === "category") {
      const category = TRANSACTION_CATEGORIES[target.value];
      const entries = Object.entries(category.types);
      newFormState.type = entries.length ? +entries[0][0] : undefined;
    }
    if (newFormState.type !== formState.type) {
      const validAccountTypes =
        TRANSACTION_ACCOUNT_TYPES[
        ("" + newFormState.type) as keyof typeof TRANSACTION_ACCOUNT_TYPES
        ];
      if (validAccountTypes) {
        const firstAccountType = validAccountTypes[0];
        const firstAccount = accounts.find(
          (account) => (account.variable || account.type) === firstAccountType
        );
        newFormState.account = firstAccount?.id || undefined;
      } else {
        newFormState.account = undefined;
      }
    }
    setFormState(newFormState);
  };

  const submit = () => {
    const date = selectedDate || moment(new Date());
    const newTransaction: NewTransaction = {
      account: formState.account,
      date: date.format('YYYY-MM-DD'),
      amount: formState.amount,
      type: formState.type,
      description: formState.description,
      reverse: formState.reverse === "y",
    };
    addTransactionApi(newTransaction)
      .then((result) => {
        const resultTransaction = result?.transactions?.[0];
        resultTransaction.isManual = true;
        if (resultTransaction) {
          if (isIncomeTypeToConfirm(resultTransaction.type)) {
            dispatch(addUnconfirmedTransaction(resultTransaction));
            openConfirmDialog(resultTransaction);
          } else {
            dispatch(addTransactionSuccess(resultTransaction));
            setConfirmAddAnother(true);
          }
        }
        setFormState({ ...(prefill || {}), reverse: "n" });
        if (onAdd) {
          onAdd();
        }
      })
      .catch(console.error);
  };

  const submitEnabled =
    formState.account !== undefined &&
    formState.amount &&
    formState.category !== undefined &&
    formState.type !== undefined;

  let availableAccounts: Account[] = [];
  if (formState.type !== undefined) {
    const validAccountTypes =
      TRANSACTION_ACCOUNT_TYPES[
      (formState.type + "") as keyof typeof TRANSACTION_ACCOUNT_TYPES
      ];
    availableAccounts = accounts.filter(
      (account) =>
        validAccountTypes.indexOf(account.variable || account.type || "") >= 0
    );
  }

  let availableTypes: any = undefined;
  let typeSelection: any[] = [];
  const selectedCategory =
    formState.category === undefined
      ? undefined
      : topLevelCategories[formState.category];
  const subcategories =
    selectedCategory === undefined
      ? []
      : TRANSACTION_CATEGORIES.slice(
        selectedCategory.start,
        selectedCategory.end
      );
  if (subcategories.length) {
    availableTypes = {};
    typeSelection = flatMap(subcategories, (subcategory) => {
      const typeList: any[] = map(
        sortBy(
          Object.entries(
            pickBy(subcategory.types, (label, value) => value !== "6000")
          ),
          (item: [string, string]) =>
            TYPE_LIST_ORDER[item[0] as keyof typeof TYPE_LIST_ORDER]
        ),
        ([value, label]: [string, string]) => {
          availableTypes[value] = label;
          return { value: +value, inputDisplay: label, dropdownDisplay: label };
        }
      );
      return [
        ...typeList,
      ];
    });
  }

  const radioOptions = [
    {
      id: 'n',
      label: 'No',
    },
    {
      id: 'y',
      label: 'Yes',
    },
  ];

  return (
    <>
      {!confirmAddAnother && (
        <>
          <Global styles={transactionStyles} />
          <EuiFlyoutBody>
            <EuiFormRow
              className="input-size"
              label={
                <StyledSpan>
                  Date
                </StyledSpan>
              }
            >
              <EuiDatePicker
                selected={selectedDate}
                onChange={handleDateChange}
              />
            </EuiFormRow>

            <StyledSpacer size="32px" />

            <EuiFormRow label="Category">
              <EuiSuperSelect
                name="category"
                options={topLevelCategories.map((item, index) => ({
                  value: index,
                  inputDisplay: item.label,
                }))}
                valueOfSelected={formState.category === undefined ? "" : formState.category}
                onChange={(value) => setFormState({ ...formState, category: value })}
                hasDividers
              />
            </EuiFormRow>

            <StyledSpacer size="32px" />

            <EuiFormRow label="Type">
              <EuiSuperSelect
                name="type"
                options={typeSelection}
                valueOfSelected={formState.type === undefined ? "" : formState.type}
                onChange={(value) => setFormState({ ...formState, type: value })}
                disabled={formState.category === undefined}
                hasDividers
              />
            </EuiFormRow>

            <StyledSpacer size="32px" />

            <EuiFormRow label="Account">
              <EuiSuperSelect
                name="account"
                options={availableAccounts.map((account) => ({
                  value: account.id,
                  inputDisplay: account.name || `Unnamed ${account.variable ? ACCOUNT_TYPES[account.variable] : ""}`,
                }))}
                valueOfSelected={formState.account === undefined ? "" : formState.account}
                onChange={(value) => setFormState({ ...formState, account: value })}
                disabled={formState.type === undefined}
                hasDividers
              />
            </EuiFormRow>

            <StyledSpacer size="32px" />

            <EuiFormRow label="Amount">
              <DollarTextField
                eui
                name="amount"
                variant="outlined"
                value={formState.amount || ""}
                onChange={setFormValue}
              />
            </EuiFormRow>

            <StyledSpacer size="32px" />

            <EuiFormRow label="Description">
              <EuiFieldText
                name="description"
                value={formState.description || ""}
                onChange={setFormValue}
              />
            </EuiFormRow>

            <StyledSpacer size="32px" />

            <EuiFormRow label="Mark as refund or reversal?">
              <EuiRadioGroup
                options={radioOptions}
                idSelected={formState.reverse}
                onChange={(id) => setFormState({ ...formState, reverse: id })}
                name="reverse"
              />
            </EuiFormRow>

            <StyledSpacer size="32px" />

            <EuiFlyoutFooter className="flyout-footer">
              <EuiFlexGroup>
                <EuiFlexItem>
                  <StyledEuiButton
                    color="text"
                    className="btn-spacing"
                    onClick={onClose}
                  >
                    Cancel
                  </StyledEuiButton>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiButton className="btn-text" fill onClick={submit}>
                    Confirm
                  </EuiButton>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlyoutFooter>
          </EuiFlyoutBody>
        </>
      )}
      {confirmAddAnother && (
        <ConfirmDialog
          visible={visible}
          title="Add Another Transaction"
          message="Would you like to add another transaction?"
          onCancel={onClose}
          onConfirm={() => setConfirmAddAnother(false)}
          cancelButton="No, Thank You"
          confirmButton="Yes, Please"
        />
      )}
    </>
  );
};

export default AddManualTransaction;