import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { cloneDeep } from "lodash";

import Questionnaire from "src/components/Questionnaire";
import {
  spouseQuestions,
  QuestionFormProps,
  singleQuestions,
} from "src/interfaces/optimizedPlanBuild.interface";
import { Question, QuestionForm } from "src/interfaces/questionnaire.interface";
import { getIsMarried } from "src/store/system/selector";
// import CenterContainer from "../PlanBuilder/Components/CenterContainer";

const WorkStatus = ({
  planSetter,
  planData,
  nextClicked,
  goToNext,
  errors,
  setErrors,
  validate,
}: QuestionFormProps) => {
  // note: hold initial next click count
  const [nextClick] = useState(nextClicked);
  const isMarried = useSelector(getIsMarried);
  const [spouseView, setSpouseView] = useState(false);
  const questionData: QuestionForm = spouseView
    ? spouseQuestions.nonProfit
    : singleQuestions.nonProfit;

  const startingValues = cloneDeep(questionData.emptyValue);
  for (const key in questionData.emptyValue) {
    // check the questionnaire for the key
    // if key exists then use that value
    const savedData = planData[key as keyof typeof planData];
    if (savedData && savedData !== "n") {
      // get the field for the question
      const question = questionData.fields.find(
        (question) => question.field === key
      );
      // set all field enablers to "y"
      if (question && question.enabled) {
        const enablers = question.enabled;
        enablers.forEach((enabler) => {
          startingValues[enabler] = "y";
        });
      }
      startingValues[key] = savedData;
    }
  }
  const [values, setValues] = useState<Question>(startingValues);

  const handleChange = (newValues: any) => {
    updatePlanData(newValues);
    setValues(newValues);
  };

  useEffect(() => {
    const planUpdate: any = {};
    if (nextClicked !== nextClick) {
      if (!validate || validate(questionData.fields)) {
        if (!planData.will_nonprofit) {
          planUpdate.will_nonprofit = "n";
        }
        if (isMarried) {
          if (!spouseView) {
            setSpouseView(true);
          } else {
            if (!planData.s_will_nonprofit) {
              planUpdate.s_will_nonprofit = "n";
            }
            goToNext();
          }
        } else {
          goToNext();
        }
        if (Object.keys(planUpdate).length) {
          planSetter({ ...planData, ...planUpdate });
        }
      }
    }
  }, [nextClicked]);

  const handleSinglePlanData = (changedValues: any) => {
    const newPlan = { ...planData };
    if (changedValues.preReq === "y") {
      // if preReq is y then update will_nonprofit accordingly
      newPlan.will_nonprofit = changedValues.will_nonprofit;
    } else if (changedValues.preReq === "n") {
      // if preReq is n then set will_nonprofit to n
      newPlan.will_nonprofit = "n";
    }
    planSetter(newPlan);
  };

  const handleMarriedPlanData = (changedValues: any) => {
    const newPlan = { ...planData };
    if (changedValues.preReq === "y") {
      // if preReq is y then update will_nonprofit accordingly
      newPlan.will_nonprofit = changedValues.will_nonprofit;
      newPlan.s_will_nonprofit = changedValues.s_will_nonprofit;
    } else if (changedValues.preReq === "n") {
      // if preReq is n then set will_nonprofit to n
      newPlan.will_nonprofit = "n";
      newPlan.s_will_nonprofit = "n";
    } else if (changedValues.preReq === "s") {
      // if preReq is n then set will_nonprofit to n
      newPlan.will_nonprofit = "n";
      newPlan.s_will_nonprofit = changedValues.s_will_nonprofit;
    } else if (changedValues.preReq === "a") {
      // if preReq is n then set will_nonprofit to n
      newPlan.will_nonprofit = changedValues.will_nonprofit;
      newPlan.s_will_nonprofit = "n";
    }
    planSetter(newPlan);
  };

  const updatePlanData = isMarried
    ? handleMarriedPlanData
    : handleSinglePlanData;

  return (
    <Questionnaire
      questions={questionData}
      values={values}
      onChange={handleChange}
      errors={errors}
      setErrors={setErrors}
    />
  );
};

export default WorkStatus;
