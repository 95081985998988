import React, { useEffect, useState } from "react";
import { cloneDeep } from "lodash";

import Questionnaire from "src/components/Questionnaire";
import {
  QuestionFormProps,
  singleQuestions,
} from "src/interfaces/optimizedPlanBuild.interface";
import { Question, QuestionForm } from "src/interfaces/questionnaire.interface";

const AutoLoan = ({
  planSetter,
  planData,
  nextClicked,
  goToNext,
  errors,
  setErrors,
  validate,
}: QuestionFormProps) => {
  // note: hold initial next click count
  const [nextClick] = useState(nextClicked);
  const questionData: QuestionForm = singleQuestions.auto;

  const startingValues = cloneDeep(questionData.emptyValue);
  for (const key in questionData.emptyValue) {
    // check the questionnaire for the key
    // if key exists then use that value
    const savedData = planData[key as keyof typeof planData];
    const defaultValue = questionData.emptyValue[key];
    if (savedData && savedData !== defaultValue) {
      // get the field for the question
      const question = questionData.fields.find(
        (question) => question.field === key
      );
      // set all field enablers to "y"
      if (question && question.enabled) {
        const enablers = question.enabled;
        enablers.forEach((enabler) => {
          startingValues[enabler] = "y";
        });
      }
      startingValues[key] = savedData;
    }
  }
  if (!startingValues.id) {
    startingValues.id = Date.now();
  }
  const [values, setValues] = useState<Question>(startingValues);

  const handleChange = (newValues: any) => {
    updatePlanData(newValues);
    setValues(newValues);
  };

  useEffect(() => {
    // note: if next click count changed after mount then run goToNext
    if (nextClicked !== nextClick) {
      if (!validate || validate(questionData.fields)) {
        goToNext();
      }
    }
  }, [nextClicked]);

  const updatePlanData = (changedValues: any) => {
    const newPlan = { ...planData };
    if (changedValues.preReq === "n") {
      newPlan.vehicle_date = "";
      newPlan.vehicle_down = 0;
      newPlan.vehicle_cost = 0;
      newPlan.vehicle_type = "add";
    } else if (changedValues.preReq === "y") {
      newPlan.vehicle_date = changedValues.vehicle_date;
      newPlan.vehicle_down = changedValues.vehicle_down;
      newPlan.vehicle_cost = changedValues.vehicle_cost;
      newPlan.vehicle_type = changedValues.vehicle_type;
    }
    planSetter(newPlan);
  };

  return (
    <Questionnaire
      questions={questionData}
      values={values}
      onChange={handleChange}
      errors={errors}
      setErrors={setErrors}
    />
  );
};

export default AutoLoan;
