export interface MessageDefinition {
  title: string;
  text: string[];
  variables: any;
}

const AI_GOAL_MESSAGES = {
  shortTermGoal: {
    title: "First Short Term Goal",
    text: [
      "At FitBUX, we focus on one goal at a time to help you achieve financial success. Based on your finances, behavior, and current economic trends, the #1 short-term goal you should target is to GOAL_NAME. To get you on track, we allocate your available funds to this short-term goal. Once you achieve this goal, we’ll automatically update and optimize your plan.",
      "If you want to pursue a different plan, please ensure your FitBUX profile is complete and up-to-date, then refresh this optimized plan or use our manual plan builder instead. If you have any questions, don't hesitate to reach out to your FitBUX Expert.",
    ],
    variables: {},
  },
  buyAHouse: {
    title: "Should You Buy or Rent?",
    text: [
      "After analyzing your profile and current conditions, we recommend buying a house. We have incorporated this into your financial plan and have included an estimated amount to save for the down payment. If you want to exclude this from your plan, choose the option to manually edit your plan and remove this goal.",
    ],
    variables: {},
  },
  keepRenting: {
    title: "Should You Buy or Rent?",
    text: [
      "After analyzing your profile and current conditions, we recommend renting instead of buying a home at this time. This approach best aligns with your financial goals and current situation.",
    ],
    variables: {},
  },
  prioritizeInvesting: {
    title: "Should You Prioritize Investing?",
    text: [
      "Based on your behavior, current financial situation, and the current economic conditions, it makes more sense for you to prioritize investing. We've optimized your plan to reflect this strategy.",
      "As your personal and economic situation changes, this recommendation may also change. We'll keep you informed if any adjustments are needed over time once you implement the plan.",
    ],
    variables: {},
  },
  dontPrioritizeInvesting: {
    title: "Should You Prioritize Investing?",
    text: [
      "In your current financial plan, investing is not the top priority. Therefore, we've allocated minimum amounts to key items such as retirement matching, Roth IRAs, and savings for the tax liability if you are pursuing an income-driven repayment (IDR) student loan plan. This approach ensures that essential contributions are still made while focusing on your more pressing financial goals.",
      "If you have any questions or need further assistance, please reach out to your FitBUX Expert.",
    ],
    variables: {},
  },
  fileSeparately: {
    title: "Your Tax Filing Status",
    text: [
      "Our AI recommends you consider filing your taxes separately while using an income-driven repayment (IDR) plan. Therefore, we assume a back door Roth IRA for your investment strategy.",
      "For more details or assistance in setting this up, please schedule a call with a FitBUX Expert or check out this video. We're here to help you with this process at FitBUX.",
      // TODO link to video
    ],
    variables: {},
  },
  payOffDebt: {
    title: "Should You Pay Off Your Debt?",
    text: [
      "Based on your behavior, current financial situation, and the current economic conditions, it makes more sense for you to prioritize paying off your DEBT_TYPE. We've optimized your plan to reflect this strategy.",
      "As your personal and economic situation changes, this recommendation may also change. We'll keep you informed if any adjustments are needed over time once you implement the plan.",
    ],
    variables: {},
  },
  dontPayOffDebt: {
    title: "Should You Pay Off Your Debt?",
    text: [
      "In your current financial plan, paying off debt is not the top priority. Therefore, we've allocated only the minimum payments towards your debt. This approach allows you to focus on your more pressing short-term financial goals while still managing your debt responsibly.",
      "If you have any questions or need further assistance, please reach out to your FitBUX Expert.",
    ],
    variables: {},
  },
  userEnrollInIDR: {
    title: "Your Student Loan Repayment Strategy",
    text: [
      "Based on your current situation, we recommend enrolling in the PLAN_NAME to manage your student loans effectively. We've optimized your plan with this recommendation. If you have any questions or need further assistance, please reach out to your FitBUX Expert.",
    ],
    variables: {},
  },
  spouseEnrollInIDR: {
    title: "Your Student Loan Repayment Strategy",
    text: [
      "Based on SPOUSE_NAME's current situation, we recommend SPOUSE_NAME enrolling in the PLAN_NAME to manage SPOUSE_NAME's student loans effectively. We've optimized your plan with this recommendation. If you have any questions or need further assistance, please reach out to your FitBUX Expert.",
    ],
    variables: {},
  },
  userIDRwithPSLF: {
    title: "Your Student Loan Repayment Strategy",
    text: [
      "Based on your current situation, we recommend you enroll in the PLAN_NAME to manage your student loans effectively. We've optimized your plan with this recommendation. Additionally, our AI indicates that it would be beneficial for you to pursue Public Service Loan Forgiveness (PSLF).",
      "Should you implement this plan, you'll want to certify your employment immediately and every 6-12 months thereafter. If you have any questions or need further assistance, please reach out to your FitBUX Expert.",
    ],
    variables: {},
  },
  spouseIDRwithPSLF: {
    title: "Your Student Loan Repayment Strategy",
    text: [
      "Based on SPOUSE_NAME's current situation, we recommend SPOUSE_NAME enroll in the PLAN_NAME to manage SPOUSE_NAME's student loans effectively. We've optimized your plan with this recommendation. Additionally, our AI indicates that it would be beneficial for SPOUSE_NAME to pursue Public Service Loan Forgiveness (PSLF).",
      "Should you implement this plan, you'll want to certify your employment immediately and every 6-12 months thereafter. If you have any questions or need further assistance, please reach out to your FitBUX Expert.",
    ],
    variables: {},
  },
  userSaveForTaxBomb: {
    title: "Your Student Loan Repayment Strategy",
    text: [
      "Since you are enrolling in an income-driven repayment (IDR) plan, you will need to save for the tax liability of TAX_BOMB_AMOUNT. We have included a monthly amount to save for this within your plan already.",
      "Once you implement this plan, you'll be able to use FitBUX to see how much you'll owe in taxes, as the amount will change over time. If you have any questions or need further assistance, please reach out to your FitBUX Expert.",
    ],
    variables: {},
  },
  spouseSaveForTaxBomb: {
    title: "Your Student Loan Repayment Strategy",
    text: [
      "Since SPOUSE_NAME is enrolling in an income-driven repayment (IDR) plan, SPOUSE_NAME will need to save for the tax liability of TAX_BOMB_AMOUNT. We have included a monthly amount to save for this within your plan already.",
      "Once you implement this plan, you'll be able to use FitBUX to see how much SPOUSE_NAME will owe in taxes, as the amount will change over time. If you have any questions or need further assistance, please reach out to your FitBUX Expert.",
    ],
    variables: {},
  },
  fileSeparatelyOrJointly: {
    title: "Your Student Loan Repayment Strategy",
    text: [
      "Based on your current situation we recommend that you file SEPARATELY_OR_JOINTLY while using an income-driven repayment (IDR) plan. To dive deeper into this decision, we suggest scheduling a call with a FitBUX Expert and/or a CPA.",
    ],
    variables: {},
  },
  userPrepayFedLoans: {
    title: "Your Student Loan Repayment Strategy",
    text: [
      "Based on your current situation, we recommend that you enroll in the PLAN_NAME to manage your student loans effectively and make prepayments to your highest interest rate loan. We've optimized your plan with this recommendation.",
      "In addition, you should look into refinancing your student loans. After implementing your plan, be sure to schedule a call with a FitBUX Expert to discuss if refinancing is right for you.",
    ],
    variables: {},
  },
  spousePrepayFedLoans: {
    title: "Your Student Loan Repayment Strategy",
    text: [
      "Based on SPOUSE_NAME's current situation, we recommend that SPOUSE_NAME enroll in the PLAN_NAME to manage SPOUSE_NAME's student loans effectively and make prepayments to SPOUSE_NAME's highest interest rate loan. We've optimized your plan with this recommendation.",
      "In addition, you should look into refinancing SPOUSE_NAME's student loans. After implementing your plan, be sure to schedule a call with a FitBUX Expert to discuss if refinancing is right for SPOUSE_NAME.",
    ],
    variables: {},
  },
  userDontPrepayFedLoans: {
    title: "Your Student Loan Repayment Strategy",
    text: [
      "Based on your current situation, we recommend that you enroll in the PLAN_NAME to manage your student loans effectively. However, do not make prepayments to your student loans until your top ranked goal is achieved first. We've optimized your plan with this recommendation.",
      "In addition, you should look into refinancing your student loans.  After implementing your plan, be sure to schedule a call with a FitBUX Expert to discuss if refinancing is right for you.",
    ],
    variables: {},
  },
  spouseDontPrepayFedLoans: {
    title: "Your Student Loan Repayment Strategy",
    text: [
      "Based on your current situation, we recommend that SPOUSE_NAME enroll in the PLAN_NAME to manage SPOUSE_NAME's student loans effectively. However, do not make prepayments to SPOUSE_NAME's student loans until your top ranked goal is achieved first. We've optimized your plan with this recommendation.",
      "In addition, you should look into refinancing SPOUSE_NAME's student loans.  After implementing your plan, be sure to schedule a call with a FitBUX Expert to discuss if refinancing is right for SPOUSE_NAME.",
    ],
    variables: {},
  },
};

export default AI_GOAL_MESSAGES;
