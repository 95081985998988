import React, { useEffect, useState } from "react";
import { cloneDeep } from "lodash";

import Questionnaire from "src/components/Questionnaire";
import {
  QuestionFormProps,
  singleQuestions,
} from "src/interfaces/optimizedPlanBuild.interface";
import { Question, QuestionForm } from "src/interfaces/questionnaire.interface";

const InvestOrPayoff = ({
  planSetter,
  planData,
  nextClicked,
  goToNext,
  errors,
  setErrors,
}: QuestionFormProps) => {
  const [nextClick] = useState(nextClicked);
  const questionData: QuestionForm = singleQuestions.investOrPayoff;

  const startingValues = cloneDeep(questionData.emptyValue);
  for (const key in questionData.emptyValue) {
    const savedData = planData[key as keyof typeof planData];
    if (savedData) {
      startingValues[key] = savedData;
    }
  }
  const [values, setValues] = useState<Question>(startingValues);

  const handleChange = (newValues: any) => {
    updatePlanData(newValues);
    setValues(newValues);
  };

  useEffect(() => {
    if (nextClicked !== nextClick) {
      goToNext();
    }
  }, [nextClicked]);

  const updatePlanData = (changedValues: any) => {
    const newPlan = {
      ...planData,
      ...changedValues,
    };
    planSetter(newPlan);
  };

  return (
    <Questionnaire
      questions={questionData}
      values={values}
      onChange={handleChange}
      errors={errors}
      setErrors={setErrors}
    />
  );
};

export default InvestOrPayoff;
