import React, { useMemo } from "react";

import { EuiFlexGroup, EuiFlexItem, EuiText } from "@elastic/eui";

import styled from "@emotion/styled";

import bot from "src/assets/svgs/bot.svg";
import { MessageDefinition } from "src/store/planBuild/aiGoalMessages";
import { colors } from "src/theme";
import { StyledSpacer } from "src/components/Global/StyledComponents";

const StyledBox = styled.div`
  background-color: ${colors.backgroundInfo};
  padding: 12px 16px;
  margin-bottom: 16px;
  p {
    font-size: 16px;
    line-height: 24px;
    font-family: "Inter";
    margin-bottom: 1rem;
  }
`;

const TitleText = styled.h4`
  color: ${colors.info} !important;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

export const MessageBox = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => (
  <StyledBox>
    <EuiFlexGroup className="items-center no-wrap" gutterSize="s">
      <EuiFlexItem grow={0} className="align-l" style={{inlineSize: 'auto', flexBasis: 'auto'}}>
        <img height={33} src={bot} />
      </EuiFlexItem>
      <EuiFlexItem grow={2} className="align-l">
        <TitleText>{title}</TitleText>
      </EuiFlexItem>
    </EuiFlexGroup>
    {children}
  </StyledBox>
);

const ReviewMessages = ({ messages }: { messages: MessageDefinition[] }) => {
  const formattedMessages = useMemo(() => {
    return messages.map((message) => {
      const text = [...message.text];
      for (let i = 0; i < text.length; i++) {
        Object.entries(message.variables).forEach(([placeholder, value]) => {
          text[i] = text[i].replace(placeholder, value as string);
        });
      }
      return { ...message, text };
    });
  }, [messages]);

  return (
    <>
      {formattedMessages.map((message) => (
        <MessageBox key={message.title} title={message.title}>
          <StyledSpacer size="16px" />
          <EuiText>
            {message.text.map((line) => (
              <p>{line}</p>
            ))}
          </EuiText>
        </MessageBox>
      ))}
    </>
  );
};

export default ReviewMessages;
