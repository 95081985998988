import React from 'react';
import {
    EuiCard,
    EuiTitle,
    EuiSpacer,
    EuiText,
    EuiFlexGroup,
    EuiFlexItem,
    EuiBadge,
    EuiLink,
    EuiIcon
} from '@elastic/eui';
import { StyledEuiLink, StyledSpacer } from 'src/components/Global/StyledComponents';
import { Global, css } from '@emotion/react';
import useWindowSize from 'src/hooks/useWindowSize';

const planReviewStyles = css`
    .card-header {
        flex-wrap: nowrap !important;
    }
    .card-title {
        font-size: 20px;
        line-height: 32px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        color: #1A1C21;
        text-align: left;
    }
    .pr-link {
        font-size: 16px;
        align-items: end;
        svg {
            display: none;
        }
        a {
            font-family: "Inter", sans-serif;
            font-weight: 500;
            color: #69707d;
            text-decoration: none;
        }
    }
    .review-group {
        flex-wrap: nowrap !important;
        h3 {
            font-size: 16px;
            line-height: 24px;
            font-family: "Inter", sans-serif;
            font-weight: 600;
            color: #343741;
        }
        p {
            font-size: 16px;
            line-height: 22px;
            font-family: "Inter", sans-serif;
            font-weight: 500;
            color: #343741;
        
        }
        .review-row {
            align-items: flex-start;
            flex: 0 1 auto !important;
            inline-size: auto !important;
        }
        .status-info {
            display: flex;
            align-items: center;
            flex: 0 1 auto;
            inline-size: auto;
            p {
                margin: 0;
            }
        }
        .dot-success {
            margin-right: 5px;
            fill: #00BFB3;
        }
        .dot-over {
            svg {
                margin-right: 5px;
                fill: #C4407C;
            }
            p {
                color: #C4407C;
            }
        }
        .dot-warning {
            margin-right: 5px;
            fill: #FEC514;
        }
    }
`;

const FinancialPlanReview: React.FC = () => {
    const { width } = useWindowSize();
    return (
        <>
            <Global styles={planReviewStyles} />
            <EuiCard
                title={
                    <EuiFlexGroup alignItems="center" justifyContent="spaceBetween" className='card-header'>
                        <EuiFlexItem>
                            <div className='card-title'>
                                <h2>
                                    {width <= 767 || (width >= 992 && width <= 1140) ? 'Financial Plan' : 'Financial Plan for Last 30 Days'}
                                </h2>
                            </div>
                        </EuiFlexItem>
                        <EuiFlexItem grow={false} className='pr-link'>
                            <StyledEuiLink
                                href=""
                                color="text"
                            >View more
                            </StyledEuiLink>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                }
            >
                <StyledSpacer size="24px" />

                <EuiFlexGroup className='review-group' alignItems='center' justifyContent='spaceBetween'>
                    <EuiFlexItem className='review-row'>
                        <h3>Total Income</h3>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false} className='review-row'>
                        <div className='status-info'>
                            <EuiIcon type="dot" className='dot-success' />
                            <p>On track</p>
                        </div>
                    </EuiFlexItem>
                </EuiFlexGroup>

                <StyledSpacer size="24px" />

                <EuiFlexGroup className='review-group' alignItems='center' justifyContent='spaceBetween'>
                    <EuiFlexItem className='review-row'>
                        <h3>Day-to-Day Expenses</h3>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false} className='review-row'>
                        <div className='status-info dot-over'>
                            <EuiIcon type="dot" />
                            <p>{width <= 767 || (width >= 992 && width <= 1140) ? '$482 over' : '$482 over budget'}</p>
                        </div>
                    </EuiFlexItem>
                </EuiFlexGroup>

                <StyledSpacer size="24px" />

                <EuiFlexGroup className='review-group' alignItems='center' justifyContent='spaceBetween'>
                    <EuiFlexItem className='review-row'>
                        <h3>Asset Contributions</h3>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false} className='review-row'>
                        <div className='status-info'>
                            <EuiIcon type="dot" className='dot-warning' />
                            <p>{width <= 767 || (width >= 992 && width <= 1140) ? '$505 more' : 'Contribute $505 more'}</p>
                        </div>
                    </EuiFlexItem>
                </EuiFlexGroup>

                <StyledSpacer size="24px" />

                <EuiFlexGroup className='review-group' alignItems='center' justifyContent='spaceBetween'>
                    <EuiFlexItem className='review-row'>
                        <h3>Debt Payments</h3>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false} className='review-row'>
                        <div className='status-info'>
                            <EuiIcon type="dot" className='dot-warning' />
                            <p>{width <= 767 || (width >= 992 && width <= 1140) ? '$320 more' : 'Contribute $505 more'}</p>
                        </div>
                    </EuiFlexItem>
                </EuiFlexGroup>

            </EuiCard>

        </>
    );
};

export default FinancialPlanReview;