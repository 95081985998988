import React from "react";
import { FlyoutProvider } from "src/components/Global/FlyoutContext";
import { EuiProvider, EuiPageBody } from "@elastic/eui";
import Flyout from "src/components/Global/Flyout";
import "./styles/PlanBuilderLayout.css";

export const PlanBuilderLayout = (props: any) => {

  return (
    <EuiProvider>
      <FlyoutProvider>
        <Flyout />
        <EuiPageBody>
          <div className="ai-main">
            <div className="ai-plan">
              {props.children || ""}
            </div>
          </div>
        </EuiPageBody>
      </FlyoutProvider>
    </EuiProvider>
  );
};

export default PlanBuilderLayout;