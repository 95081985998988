import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InlineWidget } from "react-calendly";

import { Box, IconButton, makeStyles, Typography } from "@material-ui/core";

import Accordion from "src/components/Accordion";
import Button from "src/components/Button";
import Card from "src/components/Card";
import { Modal } from "src/components/Dialogs";
import Icon from "src/components/Icon";
import { getCalendlyLinkApi } from "src/apiService";
import { ActionItem } from "src/interfaces";
import { getActionItems } from "src/store/dashboard/selector";
import { hideActionItem } from "src/store/dashboard/actions";
import { PLAN_NOTIFICATION_MESSAGES } from "src/store/dashboard/planNotifications";
import colors from "src/theme/colors";
import { AdapterLink } from "src/utils";
import { LINK } from "src/store/dashboard/actionItems";
import { ALL_VIDEOS } from "src/store/dashboard/helpTopics";
import { getPlanNotifications } from "src/store/dashboard/selector";
import { getLivePlan } from "src/store/planBuild/selector";
import { getHasPlan, getIsCurrentStudent } from "src/store/system/selector";
import {
  // dismissAllPlanNotifications,
  setVideo,
} from "src/store/dashboard/actions";
import { ACTION_ITEM_IDS } from "src/store/dashboard/actionItems";
import AccountLinkPage from "src/pages/Dashboard/MyProfile/Accounts/components/AccountLinkPage";
import { useCSC } from "../hooks";
import UpdateManualAccountsDialog from "./UpdateManualAccountsDialog";
import { EuiButton, EuiCard, EuiFlexGroup, EuiFlexItem, EuiAccordion, useGeneratedHtmlId, EuiButtonIcon, EuiImage, EuiText } from "@elastic/eui";
import { StyledEuiButton, StyledSpacer, StyledEuiButtonEmpty } from "src/components/Global/StyledComponents";
import zero_state_archive_items from "src/assets/svgs/action_items_es.svg";
import styled from '@emotion/styled'
import { Global, css } from "@emotion/react";
import "src/Cards.css";
import useWindowSize from "src/hooks/useWindowSize";

export const StyledEuiButtonIcon = styled(EuiButtonIcon)`
  background-color: #f1f4fa;
  text-decoration: none;
  border: 1px solid #343741;
  svg {
    width: 24px;
    height: 24px;
  }
`;

const buttonStyles = css`
  .action-btn {
    width: 160px;
  }
  .action_title {
    font-size: 20px;
    line-height: 32px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
  }
`;

const useStyles = makeStyles({
  list: {},
  item: {},
  itemTitle: {
    fontWeight: 600,
  },
  complete: {
    color: colors.blueGray3,
    textDecoration: "line-through",
  },
  cardContent: {
    height: 260,
    overflowY: "auto",
  },
  icon: {
    // marginLeft: 14
  },
  iconComplete: {},
  content: {
    fontWeight: 400,
    fontSize: 12,
  },
  button: {
    padding: 3,
    width: 145,
  },
});

const ActionItems = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const actionItems: ActionItem[] = useSelector(getActionItems);
  const planNotifications = useSelector(getPlanNotifications);
  const hasPlan = useSelector(getHasPlan);
  const livePlan = useSelector(getLivePlan);
  const isCurrentStudent = useSelector(getIsCurrentStudent);
  const { recheckItem } = useCSC();
  const { width } = useWindowSize();
  const [calendlyUrl, setCalendlyUrl] = useState("");
  const [accountUpdateDialogVisible, setAccountUpdateDialogVisible] = useState(
    false
  );
  const [relinkDialogVisible, setRelinkDialogVisible] = useState(false);

  const openCalendlyDialog = () => {
    getCalendlyLinkApi().then((result) => {
      let url = result.url + "?";
      if (result.prefill) {
        url += `name=${result.prefill.name}&email=${result.prefill.email}`;
        if (result.prefill.customAnswers) {
          url += `&a1=${result.prefill.customAnswers.a1}`;
        }
      }
      setCalendlyUrl(url);
    });
  };

  const openAccountUpdateDialog = () => setAccountUpdateDialogVisible(true);
  const openRelinkDialog = () => {
    setRelinkDialogVisible(true);
  };

  const actionData = {
    hasPlan,
    isCurrentStudent,
    livePlan,
    openCalendlyDialog,
    openAccountUpdateDialog,
    openRelinkDialog,
  };

  const renderButton = (item: ActionItem) => {
    const buttonProps: any = {
      className: styles.button,
      fbColor: "primary",
      variant: "outlined",
      onClick: (e: any) => {
        e.stopPropagation();
        if (item.action) {
          (item.action as any)(dispatch, actionData);
        }
      },
    };
    if (item.buttonType === LINK) {
      buttonProps.component = AdapterLink;
      buttonProps.to = item.target;
    }
    return <Button {...buttonProps}>{item.buttonLabel}</Button>;
  };

  const extraActionAccordionId = useGeneratedHtmlId({
    prefix: 'extraActionAccordion',
  });

  const renderedPlanNotifications: any[] = [];
  if (planNotifications.length) {
    const item = planNotifications[0];
    const notification = PLAN_NOTIFICATION_MESSAGES.find(
      (found) => found.id === item.message
    );
    if (notification) {
      renderedPlanNotifications.push({
        disabled: false,
        title: (
          <Typography className={styles.itemTitle}>
            Plan progress update
          </Typography>
        ),
        // titleDetail: (
        //   <div className="flex items-center justify-end">
        //     <IconButton onClick={() => dispatch(dismissAllPlanNotifications())}>
        //       <Icon iconName="fa-circle-x" className={styles.icon} />
        //     </IconButton>
        //   </div>
        // ),
        content: (
          <Box className={styles.content}>
            {notification.message(item.data)}
          </Box>
        ),
      });
    }
  }

  return (
    <>
      <Global styles={buttonStyles} />
      <EuiFlexGroup
        direction="column"
        gutterSize="s"
        className="card-content"
      >
        <EuiFlexItem>
          <EuiCard
            title={
              <div>
                <h2 className="action_title">Action Items</h2>
              </div>
            }
            paddingSize="l"
            description=""
            textAlign="left"
          >
            {actionItems.length === 0 ? (
              <>
                <StyledSpacer size="24px" />
                <div className="no-state-content">
                  <EuiImage
                    className=""
                    src={zero_state_archive_items}
                    alt="checklist"
                    size="original"
                  />
                  <StyledSpacer size="16px" />
                  <EuiText className="zero_state_action">
                    <p>
                      Done for today!
                    </p>
                  </EuiText>
                </div>
              </>
            ) : (
              actionItems.slice(0, 6).map((item) => (
                <>
                  <StyledSpacer size="16px" />
                  <EuiAccordion
                    id={`accordion-${item.id}`}
                    key={item.id}
                    buttonContent={item.title}
                    paddingSize="l"
                    className="accordion-group"
                    extraAction={
                      (width > 767 && width < 992) || width > 1290 ? (
                        <EuiFlexGroup className="extra-action">
                          <EuiFlexItem grow={false}>
                            <StyledEuiButton
                              className="action-btn"
                              title={item.buttonLabel}
                              size="s"
                              color="text"
                              onClick={(e: any) => {
                                e.stopPropagation();
                                if (item.action) {
                                  (item.action as any)(dispatch, actionData);
                                }
                              }}
                            >
                              {item.buttonLabel}
                            </StyledEuiButton>
                          </EuiFlexItem>
                          <EuiFlexItem grow={false}>
                            <StyledEuiButtonIcon
                              size="s"
                              color="text"
                              iconType="cross"
                              onClick={(e: any) => {
                                e.stopPropagation();
                                dispatch(hideActionItem(item.id));
                              }}
                            />
                          </EuiFlexItem>
                        </EuiFlexGroup>
                      ) : null
                    }
                  >
                    <p>
                      {item.message}
                    </p>
                    {(width <= 767 || (width >= 992 && width <= 1290)) && (
                      <>
                        <StyledSpacer size="16px" />
                        <StyledEuiButton
                          className="action-btn"
                          title={item.buttonLabel}
                          color="text"
                          onClick={(e: any) => {
                            e.stopPropagation();
                            if (item.action) {
                              (item.action as any)(dispatch, actionData);
                            }
                          }}
                        >
                          {item.buttonLabel}
                        </StyledEuiButton>
                        <StyledEuiButtonEmpty
                          color="text"
                          onClick={(e: any) => {
                            e.stopPropagation();
                            dispatch(hideActionItem(item.id));
                          }}
                        >
                          Dismiss
                        </StyledEuiButtonEmpty>
                      </>
                    )}
                  </EuiAccordion>
                </>
              ))
            )}
          </EuiCard>
        </EuiFlexItem>
      </EuiFlexGroup>
      <UpdateManualAccountsDialog
        open={accountUpdateDialogVisible}
        onClose={() => {
          setAccountUpdateDialogVisible(false);
          recheckItem(ACTION_ITEM_IDS.ACCOUNT_UPDATE);
        }}
      />
    </>
  );
};

export default ActionItems;
