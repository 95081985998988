import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiPanel } from "@elastic/eui";

import { getV2PlanProjectionApi } from "src/apiService";
import { getDebtObligations } from "src/store/account/selector";
import { getLiveExpenseTotal, getLiveIncomeTotal } from "src/store/cashflow/selector";
import { SUCCESS } from "src/store/common";
import {
  convertOptimizedToManual,
  implementPlan,
  savePlan,
  setBuildStep,
  setCurrentPlan,
  updateCurrentPlan,
  GET_PLAN_PROJECTION,
} from "src/store/planBuild/actions";
import { getCurrentPlan, getPlans } from "src/store/planBuild/selector";
import { getIsMarried } from "src/store/system/selector";
import { fetchUser } from "src/store/system/actions";
import { PLAN_BUILD_STEPS } from "src/store/planBuild/constants";
import TEST_PROJECTIONS from "src/store/planBuild/testPlans";
import { setPopup } from "src/store/dashboard/actions";
import { getRouterState } from "src/store/router/selector";
import { StyledEuiButton, StyledEuiButtonIcon } from "src/components/Global/StyledComponents";
import { emptyPlan } from "src/interfaces/optimizedPlanBuild.interface";

import LeftSidebar, { sections } from "./LeftSidebar";
import { progressSteps, STEP_INDICES } from "./common";
import ConfirmCancelModal from "./ConfirmCancelModal";
import EditOptions from "./EditOptions";
import MainForm from "./MainForm";
import NegativeCashflow from "./Views/NegativeCashflow";
import ReviewSidebar, { reviewSections } from "../PlanBuilder/Components/ReviewSidebar";
import useWindowSize from "src/hooks/useWindowSize";

interface StepInfo {
  target: number;
  label: string;
  mainStepIndex: number;
}

const Content = () => {
  const router = useSelector(getRouterState);
  const query = router.location.query;
  const planId = query.plan;
  const testProjection = (TEST_PROJECTIONS as any)[`plan${planId}`];
  const debugMode = !!testProjection;
  const dispatch = useDispatch();
  const savedPlans = useSelector(getPlans);
  const currentPlan = useSelector(getCurrentPlan);
  const isMarried = useSelector(getIsMarried);
  const currentIncomeTotal = useSelector(getLiveIncomeTotal);
  const currentExpenseTotal = useSelector(getLiveExpenseTotal);
  const debtObligations = useSelector(getDebtObligations);
  const windowSize = useWindowSize();
  const [nextClicked, setNextClicked] = useState(0);
  const [reviewStep, setReviewStep] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isMobileNavVisible, setIsMobileNavVisible] = useState(false);
  const [stepIndex, setStepIndex] = useState(
    debugMode ? STEP_INDICES.Transition : STEP_INDICES.Introduction
  );
  const [errors, setErrors] = useState(new Set<string>());
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [negativeCashflow, setNegativeCashflow] = useState(false);
  const overviewSection = reviewSections.find(section => section.label === 'Overview');
  const overviewSteps = overviewSection ? overviewSection.steps : [];

  const totalReviewSteps = overviewSteps.length;

  useEffect(() => {
    if (debugMode) {
      getV2PlanProjectionApi(+planId).then((planProjection) => {
        dispatch({
          type: GET_PLAN_PROJECTION + SUCCESS,
          payload: { planProjection, minimalProjection: planProjection },
        });
        dispatch(setCurrentPlan({ planId: +planId, keepId: true }));
      });
    }
  }, []);

  const mainSteps = sections.flatMap((section) => section.steps);

  const stepLabelMap: StepInfo[] = sections.flatMap((section) =>
    section.steps.flatMap((step, stepIndex) => [
      { target: step.target, label: step.label, mainStepIndex: stepIndex },
      ...(step.relevantSteps || []).map((target) => ({
        target,
        label: step.label,
        mainStepIndex: stepIndex,
      })),
    ])
  );

  const totalSteps = mainSteps.length;

  const currentStepInfo = stepLabelMap.find(
    (step) => step.target === stepIndex
  ) || {
    target: 0,
    label: "",
    mainStepIndex: 0,
  };

  const currentMainStepIndex = currentStepInfo.mainStepIndex;
  const currentStepLabel = currentStepInfo.label;

  const currentReviewStepInfo = overviewSteps.find(
    (step) => step.target === reviewStep
  ) || {
    target: 0,
    label: "",
  };

  const currentReviewStepLabel = currentReviewStepInfo.label;

  const truncatedStepLabel =
    currentStepLabel.length > 20
      ? `${currentStepLabel.substring(0, 20)}...`
      : currentStepLabel;

  const truncatedReviewStepLabel =
    currentReviewStepLabel.length > 20
      ? `${currentReviewStepLabel.substring(0, 20)}...`
      : currentReviewStepLabel;

  const persistChanges = (index: STEP_INDICES) => {
    if (debugMode) {
      return;
    }
    const newProgress = progressSteps[index];
    if (newProgress > (currentPlan.questionnaire?.progress || 0)) {
      dispatch(
        updateCurrentPlan({
          questionnaire: {
            ...(currentPlan.questionnaire || emptyPlan),
            progress: newProgress,
          },
        })
      );
    }
    dispatch(savePlan(null));
  }

  const navigate = (newIndex: STEP_INDICES) => {
    persistChanges(newIndex);
    setStepIndex(newIndex);
  };

  useEffect(() => {
    if (!debugMode) {
      const aiPlan = savedPlans.find((plan) => plan.questionnaire);
      if (!aiPlan) {
        if (currentIncomeTotal < currentExpenseTotal - debtObligations.total) {
          setNegativeCashflow(true);
        }
      } else if (currentPlan.id !== aiPlan.id) {
        dispatch(setCurrentPlan({ planId: aiPlan.id, keepId: false }));
      }
    }
  }, []);

  const implementOptimizedPlan = () => {
    if (currentPlan && currentPlan.id) {
      dispatch(implementPlan(currentPlan.id));
    }
  };

  const handleClick = () => {
    setNextClicked((current) => current + 1);
    if (stepIndex === STEP_INDICES.Transition) {
      setReviewStep((current) => current + 1);
    }
  };

  const backClicked = () => {
    if (!errors.size) {
      persistChanges(0);
    }
    setErrors(new Set([]));
    setNextClicked(nextClicked - 1);
    if (stepIndex === STEP_INDICES.Transition && reviewStep > 0) {
      setReviewStep((current) => current - 1);
    } else {
      navigate(stepIndex - 1);
    }
  };

  const allSteps = sections.flatMap((section) =>
    section.steps.flatMap((step) => [
      step,
      ...(step.relevantSteps || []).map((target) => ({ target })),
    ])
  );

  const incrementStep = () => {
    let nextStepIndex =
      allSteps.findIndex((step) => step.target === stepIndex) + 1;

    if (
      isMarried &&
      allSteps[nextStepIndex]?.target === STEP_INDICES.Marriage
    ) {
      nextStepIndex++;
    }

    const nextStep = allSteps[nextStepIndex]?.target;
    if (nextStep !== undefined) {
      navigate(nextStep);
    } else {
      navigate(STEP_INDICES.Transition);
    }
  };

  const handleReviewSectionClick = (index: number) => {
    setReviewStep(index);
  };

  const handleReviewSectionEdit = () => {
    setDialogOpen(true);
  };

  const handleEditRoute = (optimized: boolean) => {
    if (optimized) {
      setStepIndex(10);
      handleDialogClose();
    } else {
      dispatch(convertOptimizedToManual());
      dispatch(fetchUser());
      // if we ever change it so graduated users can go back to school this will need to be expanded on
      dispatch(setBuildStep(PLAN_BUILD_STEPS.GRADUATED_LIFE_EVENTS_OVERVIEW));
      dispatch(setPopup("Build"));
      dispatch(push("/plan-builder"));
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const exit = () => {
    dispatch(push("/plan-summary"));
  };

  const saveAndClose = () => {
    persistChanges(stepIndex);
    exit();
  };

  return (
    <>
      <div className="ai-side-nav-mobile">
        <EuiFlexGroup className="ai-mobile-nav-header">
          <EuiFlexItem className="ai-mobile-nav-item">
            <StyledEuiButtonIcon
              iconType="arrowLeft"
              onClick={backClicked}
            ></StyledEuiButtonIcon>
          </EuiFlexItem>
          <EuiFlexItem
            className="section-label"
            onClick={() => setIsMobileNavVisible(!isMobileNavVisible)}
          >
            <span className="section-label-title">
              {stepIndex >= STEP_INDICES.Transition ? "Overview" : "Plan Setup"}
            </span>
            <span className="secondary-label-text">
              {stepIndex >= STEP_INDICES.Transition
                ? `${truncatedReviewStepLabel} (${reviewStep + 1} of ${totalReviewSteps})`
                : `${truncatedStepLabel} (${currentMainStepIndex + 1} of ${totalSteps})`}
              <EuiIcon type={isMobileNavVisible ? "arrowUp" : "arrowDown"} />
            </span>
          </EuiFlexItem>
          <EuiFlexItem
            style={{ alignItems: "flex-end" }}
            className="ai-mobile-nav-item"
          >
            <StyledEuiButtonIcon
              iconType="cross"
              onClick={() => setShowCancelModal(true)}
            ></StyledEuiButtonIcon>
          </EuiFlexItem>
        </EuiFlexGroup>
        {isMobileNavVisible && (
          <EuiPanel className="mobile-nav-container">
            {stepIndex < STEP_INDICES.Transition && (
              <LeftSidebar stepIndex={stepIndex} handleClick={navigate} />
            )}
            {stepIndex >= STEP_INDICES.Transition && (
              <ReviewSidebar
                handleClick={handleReviewSectionClick}
                handleEdit={handleReviewSectionEdit}
                reviewStep={reviewStep}
              />
            )}
          </EuiPanel>
        )}
      </div>
      <div className="ai-content-container">
        <div className="ai-side-nav-desk">
          {stepIndex < STEP_INDICES.Transition && (
            <LeftSidebar stepIndex={stepIndex} handleClick={navigate} />
          )}
          {stepIndex >= STEP_INDICES.Transition && (
            <ReviewSidebar
              handleClick={handleReviewSectionClick}
              handleEdit={handleReviewSectionEdit}
              reviewStep={reviewStep}
            />
          )}
        </div>
        <div className="ai-content">
          {negativeCashflow && <NegativeCashflow />}
          {!negativeCashflow && (
            <MainForm
              debugMode={debugMode}
              stepIndex={stepIndex}
              reviewStepIndex={reviewStep}
              goToNext={incrementStep}
              nextClicked={nextClicked}
              onSummaryClick={navigate}
              errors={errors}
              setErrors={setErrors}
            />
          )}
        </div>
      </div>
      <div className="ai-footer-nav">
        <EuiFlexGroup justifyContent="spaceBetween">
          {windowSize.width >= 768 && (
            <>
              <EuiFlexItem>
                <EuiFlexGroup>
                  <EuiFlexItem>
                    {stepIndex < STEP_INDICES.Transition ? (
                      <StyledEuiButton
                        color="text"
                        className="danger-sec-btn"
                        onClick={() => setShowCancelModal(true)}
                      >
                        Cancel
                      </StyledEuiButton>
                    ) : (
                      <StyledEuiButton
                        color="text"
                        onClick={saveAndClose}
                        disabled={!!errors.size}
                      >
                        Exit
                      </StyledEuiButton>
                    )}
                  </EuiFlexItem>
                  {stepIndex >= 1 && (
                    <EuiFlexItem>
                      <StyledEuiButton color="text" onClick={backClicked}>
                        Back
                      </StyledEuiButton>
                    </EuiFlexItem>
                  )}
                </EuiFlexGroup>
              </EuiFlexItem>
            </>
          )}
          <EuiFlexItem>
            <EuiFlexGroup
              justifyContent={
                windowSize.width < 768 ? "spaceBetween" : "flexEnd"
              }
            >
              {stepIndex < STEP_INDICES.Transition ? (
                <StyledEuiButton
                  color="text"
                  onClick={saveAndClose}
                  disabled={!!errors.size}
                >
                  Save &amp; Exit
                </StyledEuiButton>
              ) : (
                <StyledEuiButton
                  color="text"
                  onClick={implementOptimizedPlan}
                  disabled={!!errors.size}
                >
                  Implement Plan
                </StyledEuiButton>
              )}
              <StyledEuiButton
                fill
                color="primary"
                onClick={handleClick}
                style={{ border: "none" }}
                disabled={!!errors.size || negativeCashflow}
              >
                Next
              </StyledEuiButton>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
        <EditOptions
          open={dialogOpen}
          handleClose={handleDialogClose}
          handleClick={handleEditRoute}
        />
      </div>
      {showCancelModal && (
        <ConfirmCancelModal
          confirm={exit}
          onClose={() => setShowCancelModal(false)}
        />
      )}
    </>
  );
};

export default Content;
