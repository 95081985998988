import React, { useEffect, useMemo, useState } from "react";
import { cashMessages } from "./messages";
import { Transaction } from "src/interfaces";
import {
  fetchFilteredTransactionsApi,
  getBreakoutBaseApi,
} from "src/apiService";
import {
  BREAKOUT_TYPES,
  CASHFLOW_TO_TYPE_AND_CATEGORY,
  TRANSACTION_TYPE_IDS,
} from "src/interfaces";
import edit_icon from 'src/assets/svgs/edit_pencil.svg';
import AddManualTransactionDialog from "./AddManualTransactionDialog";
import ConfirmIncomeDialog from "./ConfirmIncomeDialog";
import { EuiFlexGroup, EuiFlexItem, EuiAvatar, EuiButtonIcon, EuiText, EuiFlyoutFooter, EuiButton } from "@elastic/eui";
import { Global, css } from "@emotion/react";
import { StyledEuiHorizontalRule, StyledSpacer, StyledEuiButtonEmpty } from "src/components/Global/StyledComponents";
import CashDialog from "src/components/Dialogs/CashDialog";

const listStyles = css`
  .transaction-list-group {
    padding: 0 16px;
    justify-content: space-between !important;
    .euiButtonIcon {
      display: block;
    }
    .euiFlexItem {
      flex: 0 1 auto;
    }
    .transaction-label-group {
      color: #343741;
      gap: 16px;
      align-items: center;
      .euiAvatar {
        width: 40px;
        height: 40px;
      }
    }
    .transaction-main-text {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      font-family: "Inter", sans-serif;
    }
    .transaction-secondary-text {
      font-size: 14px;
      line-height: 24px;
      font-family: "Inter", sans-serif;
    }
    .transaction-amount-group {
      align-items: center;
      gap: 8px;
      svg {
        fill: #006BB8;
      }
    }
  }
  .flyout-footer {
    background-color: #fff !important;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-top: 1px solid #D3DAE6;
    button {
      margin: 0;
    }
  }
`;

const emptyTransaction: Transaction = {
  account: -999,
  amount: -999,
  date: "1999-01-01",
  description: "",
  id: -999,
  type: 7000,
};

const ReviewTransactionsDialog = ({
  open,
  onClose,
  query,
  cashMessage,
  onAdd,
  onRemove,
  accounts
}: any) => {
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [confirmingTransaction, setConfirmingTransaction] = useState<Transaction>(emptyTransaction);
  const [editing, setEditing] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [addAnotherFlag, setAddAnotherFlag] = useState(false);
  const [cashMessageVisible, setCashMessageVisible] = useState(!!cashMessage);

  const prefill = useMemo(() => {
    if (!open || !query) {
      return undefined;
    }
    const matchingCategory: any = CASHFLOW_TO_TYPE_AND_CATEGORY[query.type as keyof typeof CASHFLOW_TO_TYPE_AND_CATEGORY];
    return matchingCategory
      ? {
        category: matchingCategory.category,
        type: matchingCategory.type,
      }
      : undefined;
  }, [query, open]);

  const load = () => {
    fetchFilteredTransactionsApi(query)
      .then((results) => {
        if (results && results.transactions) {
          setTransactions(results.transactions);
        } else {
          setTransactions([]);
        }
      })
      .catch((error) => {
        console.error('Failed to fetch transactions:', error);
        setTransactions([]);
      });
  };


  const handleAdd = () => {
    load();
    if (onAdd) {
      onAdd();
    }
  };

  useEffect(() => {
    if (!cashMessage) {
      load();
    }
  }, [open, query]);

  const getTypeLabel = (typeValue: number) => {
    let label: string = TRANSACTION_TYPE_IDS[(typeValue + "") as keyof typeof TRANSACTION_TYPE_IDS];
    if (!label) {
      label = BREAKOUT_TYPES[(typeValue + "") as keyof typeof BREAKOUT_TYPES];
    }
    if (!label) {
      console.warn(`Missing type label for transaction type ${typeValue}`);
    }
    return label || "";
  };

  const getAccountName = (accountId: number) => {
    const account = accounts.find((account: any) => account.id === accountId);
    return account ? account.name.substring(0, 20) : "Unnamed Account";
  };

  const openConfirmDialog = (transaction?: Transaction, toEdit?: boolean) => {
    if (!toEdit) {
      setAddAnotherFlag(true);
    }
    setEditing(!!toEdit);
    let rootTransaction = transaction;
    let promise = Promise.resolve();
    if (transaction) {
      promise = getBreakoutBaseApi(transaction.id)
        .then((result: any) => {
          rootTransaction = result?.transactions?.[0] || transaction;
        })
        .catch(console.error);
    }
    return promise.then(() => {
      if (rootTransaction) {
        setConfirmingTransaction(rootTransaction);
      } else {
        setConfirmingTransaction(emptyTransaction);
      }
      setEditing(true);
    });
  };

  const closeConfirmDialog = () => {
    setConfirmingTransaction(emptyTransaction);
    setEditing(false);
  };

  const handleUpdate = (updatedTransaction: Transaction) => {
    const transactionIndex = transactions.findIndex((item) => item.id === updatedTransaction.id);
    if (transactionIndex >= 0) {
      const newTransactions = [...transactions];
      newTransactions[transactionIndex] = updatedTransaction;
      setTransactions(newTransactions);
    }
  };

  const removeTransaction = (id: any) => {
    const closeNow = transactions.length <= 1;
    setTransactions((current) =>
      current.filter((transaction: Transaction) => transaction.id !== id)
    );
    if (closeNow) {
      onClose();
    }
    if (onRemove) {
      onRemove();
    }
  };

  if (cashMessageVisible) {
    return (
      <CashDialog
        visible={cashMessageVisible}
        title="Review/Edit Cash"
        message={cashMessages.map((message, index) => (
          <p key={index} style={{ marginBottom: index < cashMessages.length - 1 ? '1em' : '0' }}>
            {message}
          </p>
        ))}
        confirmButton="Go to Accounts Page"
        cancelButton="Cancel"
        onConfirm={() => {
          setCashMessageVisible(false);
          onClose();
        }}
        onCancel={() => setCashMessageVisible(false)}
      />
    );
  }

  if (editing && open) {
    return (
      <ConfirmIncomeDialog
        open
        onClose={closeConfirmDialog}
        onRemove={removeTransaction}
        transaction={confirmingTransaction}
        alreadyConfirmed
        onSave={handleUpdate}
      />
    );
  }

  if (addDialogOpen) {
    return (
      <AddManualTransactionDialog
        visible={addDialogOpen}
        onClose={() => {
          setAddDialogOpen(false);
          setAddAnotherFlag(false);
        }}
        openConfirmDialog={openConfirmDialog}
        prefill={prefill}
        onAdd={handleAdd}
        defaultAddAnother={addAnotherFlag}
      />
    );
  }

  return (
    <>
      <Global styles={listStyles} />
      {!cashMessage && (
        <>
          {transactions.map((transaction: any) => (
            <div key={transaction.id}>
              <StyledSpacer size="32px" />
              <EuiFlexGroup className="transaction-list-group">
                <EuiFlexItem>
                  <EuiFlexGroup className="transaction-label-group">
                    <EuiFlexItem>
                      <EuiAvatar
                        className="euiAvatar"
                        name={getTypeLabel(transaction.type).substring(0, 2).toUpperCase()}
                        initialsLength={2}
                        casing="uppercase"
                      />
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <span className="transaction-main-text">{getTypeLabel(transaction.type)}</span>
                      <span className="transaction-secondary-text">{getAccountName(transaction.account)}</span>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFlexGroup className="transaction-amount-group">
                    <EuiFlexItem>
                      <EuiText style={{ textAlign: 'right' }}>
                        <span className="transaction-main-text">{transaction.amount < 0 ? "+" : "-"}${Math.abs(transaction.amount).toFixed(2)}</span><br />
                        <span className="transaction-secondary-text">
                          {new Date(transaction.date).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}
                        </span>
                      </EuiText>
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <EuiButtonIcon
                        className="euiButtonIcon"
                        iconType={edit_icon}
                        onClick={() => openConfirmDialog(transaction, true)}
                      />
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiFlexItem>
              </EuiFlexGroup>
              <StyledSpacer size="32px" />
              <StyledEuiHorizontalRule />
            </div>
          ))}

          <EuiFlyoutFooter className="flyout-footer">
            <EuiFlexGroup>
              <EuiFlexItem>
                <StyledEuiButtonEmpty
                  className="continue-button"
                  type="submit"
                  color="primary"
                  onClick={() => setAddDialogOpen(true)}
                >
                  Add Manual Transaction
                </StyledEuiButtonEmpty>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiButton className="btn-text" fill onClick={onClose}>
                  Done
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlyoutFooter>
        </>
      )}
    </>
  );
};

export default ReviewTransactionsDialog;