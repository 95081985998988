import React from "react";
import { QuestionField, QuestionForm } from "./questionnaire.interface";

export interface QuestionFormProps {
  planData: OptimizedPlan;
  planSetter: (value: OptimizedPlan) => void;
  nextClicked: number;
  goToNext: () => void;
  reviewStepIndex: number;
  onRecapClick: (i: number) => void;
  errors: Set<string>;
  setErrors: React.Dispatch<React.SetStateAction<Set<string>>>;
  subIndex?: number;
  section?: number;
  validate?: (fields: QuestionField[]) => boolean;
  debugMode?: boolean;
}

export interface OptimizedPlanBuilderMarried {
  moving: QuestionForm;
  auto: QuestionForm;
  nonProfit: QuestionForm;
  employerRetirement: QuestionForm;
  hsa: QuestionForm;
  employerStudentLoans: QuestionForm;
  investOrPayoff: QuestionForm;
  vacation: QuestionForm;
}

export interface OptimizedPlanBuilder extends OptimizedPlanBuilderMarried {
  marriage: QuestionForm;
}

export interface OptimizedPlanBuilderSpouse {
  employerRetirement: QuestionForm;
  employerStudentLoans: QuestionForm;
  nonProfit: QuestionForm;
}

export const emptyPlan: OptimizedPlan = {
  back_to_school: "",
  school_start_date: "",
  school_grad_date: "",
  will_nonprofit: "",
  s_back_to_school: "",
  s_school_start_date: "",
  s_school_grad_date: "",
  s_will_nonprofit: "",
  will_marry: "",
  marry_date: "",
  marry_cost: 0,
  marry_spouse_income: 0,
  marry_spouse_fed_loan_balance: 0,
  marry_spouse_priv_loan_balance: 0,
  children_dates: [],
  child_income_drop: [],
  s_child_income_drop: [],
  income_increase: [],
  income_increase_date: [],
  income_decrease: [],
  income_decrease_date: [],
  s_income_increase: [],
  s_income_increase_date: [],
  s_income_decrease: [],
  s_income_decrease_date: [],
  will_move: "",
  move_date: "",
  move_zip_code: "",
  vehicle_date: "",
  vehicle_down: 0,
  vehicle_cost: 0,
  vehicle_type: "add",
  retirement_match: 100,
  retirement_limit: 0,
  retirement_additional_match: 50,
  retirement_additional_limit: 0,
  retirement_nonelective_contribution: 0,
  retirement_student_loan_match: "",
  s_retirement_match: 100,
  s_retirement_limit: 0,
  s_retirement_additional_match: 50,
  s_retirement_additional_limit: 0,
  s_retirement_nonelective_contribution: 0,
  s_retirement_student_loan_match: "",
  has_401k: "",
  has_roth_401k: "",
  has_hsa: "",
  employer_hsa_contribution: 0,
  employer_student_loan_contribution: 0,
  s_employer_student_loan_contribution: 0,
  s_has_401k: "",
  s_has_roth_401k: "",
  debt_comfort_level: 0,
  risk_comfort_level: 0,
  invest_comfort_level: 0,
  vacation_date: "",
  vacation_down: 0,
  vacation_recurring: "",
  progress: 0,
};

export interface OptimizedPlan {
  back_to_school: string;
  school_start_date: string;
  school_grad_date: string;
  will_nonprofit: string;
  s_back_to_school: string;
  s_school_start_date: string;
  s_school_grad_date: string;
  s_will_nonprofit: string;
  will_marry: string;
  marry_date: string;
  marry_cost: number;
  marry_spouse_income: number;
  marry_spouse_fed_loan_balance: number;
  marry_spouse_priv_loan_balance: number;
  children_dates: string[];
  child_income_drop: (number | null)[];
  s_child_income_drop: (number | null)[];
  income_increase: number[];
  income_increase_date: string[];
  income_decrease: number[];
  income_decrease_date: string[];
  s_income_increase: number[];
  s_income_increase_date: string[];
  s_income_decrease: number[];
  s_income_decrease_date: string[];
  will_move: string;
  move_date: string;
  move_zip_code: string;
  vehicle_date: string;
  vehicle_down: number;
  vehicle_cost: number;
  vehicle_type: "add" | "replace";
  retirement_match: number;
  retirement_limit: number;
  retirement_additional_match: number;
  retirement_additional_limit: number;
  retirement_nonelective_contribution: number;
  retirement_student_loan_match: string;
  s_retirement_match: number;
  s_retirement_limit: number;
  s_retirement_additional_match: number;
  s_retirement_additional_limit: number;
  s_retirement_nonelective_contribution: number;
  s_retirement_student_loan_match: string;
  has_401k: string;
  has_roth_401k: string;
  has_hsa: string;
  employer_hsa_contribution: number;
  employer_student_loan_contribution: number;
  s_employer_student_loan_contribution: number;
  s_has_401k: string;
  s_has_roth_401k: string;
  debt_comfort_level: number;
  risk_comfort_level: number;
  invest_comfort_level: number;
  vacation_date: string;
  vacation_down: number;
  vacation_recurring: string;
  progress?: number;
}

export interface recapEntryOptions {
  nonprofit?: OptimizedRecapItem;
  incomeIncrease?: OptimizedRecapItem;
  incomeDecrease?: OptimizedRecapItem;
  employerRetirement?: OptimizedRecapItem;
  married?: OptimizedRecapItem;
  child?: OptimizedRecapItem;
  moving?: OptimizedRecapItem;
  auto?: OptimizedRecapItem;
}

export interface OptimizedRecapItem {
  description: string;
  details?: string;
  date?: string;
  stepIndex: number;
  key?: string;
  who?: "applicant" | "spouse";
  subIndex?: number;
  resetKeys?: string[];
  resetKeysAtIndex?: string[];
  recurring?: boolean;
}

// for dynamic text based on marriage status and spouse name
export const OR_YOUR_SPOUSES = "OR_YOUR_SPOUSES";
export const SPOUSE_NAME = "SPOUSE_NAME";

export const singleQuestions: OptimizedPlanBuilder = {
  moving: {
    emptyValue: {
      will_move: undefined,
      move_date: "",
      move_zip_code: "",
    },
    typeLabel: "Moving To A Different Area",
    fields: [
      {
        field: "will_move",
        type: "radio",
        label:
          "Do you see yourself moving to a new city or state within the next 5 years?",
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
      },
      {
        field: "move_date",
        label: "When do you plan on moving?",
        type: "monthYear",
        enabled: ["will_move"],
        required: true,
      },
      {
        field: "move_zip_code",
        label: "What ZIP code do you plan on moving to?",
        type: "zip",
        enabled: ["will_move"],
        required: true,
      },
    ],
  },
  auto: {
    emptyValue: {
      preReq: undefined,
      vehicle_date: "",
      vehicle_down: "",
      vehicle_cost: 0,
      vehicle_type: "add",
    },
    icon: "fb-car",
    buttonLabel: "Purchase a Vehicle",
    typeLabel: "Purchase a Vehicle",
    fields: [
      {
        field: "preReq",
        type: "radio",
        label: "Do you expect to buy a car within the next 3 years?",
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
      },
      {
        field: "vehicle_type",
        label: "Are you adding a new vehicle or replacing an old one?",
        type: "radio",
        enabled: ["preReq"],
        select: [
          {
            label: "Adding",
            value: "add",
          },
          {
            label: "Replacing",
            value: "replace",
          },
        ],
        required: true,
      },
      {
        field: "vehicle_date",
        label: "When do you plan on buying a vehicle?",
        type: "monthYear",
        enabled: ["preReq"],
        required: true,
      },
      {
        field: "vehicle_cost",
        label:
          "What is the estimated cost of the vehicle you are thinking of buying?",
        type: "dollar",
        enabled: ["preReq"],
        required: true,
      },
      {
        field: "vehicle_down",
        label: "What's the estimated down payment'?",
        type: "dollar",
        enabled: ["preReq"],
        required: true,
      },
    ],
  },
  nonProfit: {
    emptyValue: {
      will_nonprofit: undefined,
    },
    typeLabel: "Work Status & Income",
    section: 1,
    fields: [
      {
        field: "will_nonprofit",
        type: "radio",
        label:
          "Are you planning to work for a non-profit for more than 5 years? (This includes being employed by the government, a 501(c)(3) not-for-profit, or other not-for-profit organization.)",
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
      },
    ],
  },
  employerRetirement: {
    emptyValue: {
      has_401k: undefined,
      retirement_limit: 0,
      retirement_match: 100,
      preReq1: undefined,
      retirement_additional_limit: 0,
      retirement_additional_match: 100,
      retirement_student_loan_match: undefined,
      has_roth_401k: undefined,
      retirement_nonelective_contribution: 0,
      preReq3: undefined,
    },
    typeLabel: "Employer Retirement Plans",
    section: 1,
    fields: [
      {
        field: "has_401k",
        type: "radio",
        label: "Does your employer offer a 401k/403b/457b/TSP?",
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
        required: true,
      },
      {
        field: "retirement_match",
        type: "percent",
        enabled: ["has_401k"],
        label:
          "What percentage of your contributions does your employer match?",
        subLabel: "Most employers match 100%. You can edit if needed.",
        required: true,
      },
      {
        field: "retirement_limit",
        type: "percent",
        label:
          "What is the maximum percentage of your salary that your employer will match?",
        enabled: ["has_401k"],
        info:
          "Includes being employed by the government, a 501(c)(3) not-for-profit, or other not-for-profit organization that provides a qualifying service.",
        required: true,
      },
      {
        field: "preReq1",
        type: "radio",
        label: "Does your employer offer an additional match?",
        enabled: ["has_401k"],
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
      },
      {
        field: "retirement_additional_match",
        type: "percent",
        enabled: ["preReq1", "has_401k"],
        label:
          "What additional percentage of your contributions does your employer match?",
        subLabel: "Most employers match 50%. You can edit if needed.",
        required: true,
      },
      {
        field: "retirement_additional_limit",
        type: "percent",
        className: "long-row",
        label:
          "What is the maximum additional percentage of your salary that your employer will match?",
        enabled: ["preReq1", "has_401k"],
        info:
          "Includes being employed by the government, a 501(c)(3) not-for-profit, or other not-for-profit organization that provides a qualifying service.",
        required: true,
      },
      {
        field: "has_roth_401k",
        type: "radio",
        label: "Does your employer offer a Roth 401k?",
        enabled: ["has_401k"],
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
      },
      {
        field: "preReq3",
        type: "radio",
        label:
          "Does your employer offer contributions to your retirement without you having to contribute?",
        enabled: ["has_401k"],
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
      },
      {
        field: "retirement_nonelective_contribution",
        type: "percent",
        enabled: ["has_401k", "preReq3"],
        label: "What percentage of your income does your employer contribute?",
        info:
          "Includes being employed by the government, a 501(c)(3) not-for-profit, or other not-for-profit organization that provides a qualifying service.",
        required: true,
      },
    ],
  },
  hsa: {
    emptyValue: {
      has_hsa: undefined,
      employer_hsa_contribution: 0,
    },
    typeLabel: "Health Savings Account",
    section: 2,
    help: [
      {
        header: "Recommendation For HSA",
        body: [
          "If you choose a high deductible health insurance plan through your employer and they offer an HSA, we highly recommend contributing at least $50 per month to the HSA.  Doing so will help with retirement income diversification.",
          "Retirement income diversification is when you hold your assets in as many different types of accounts as you can.  Therefore, at retirement, if the government changes how they tax one type of an account, you have others that won't be affected.",
        ],
      },
      {
        header: "What is a Health Savings Account?",
        body: [
          "Health Savings Accounts (HSA for short) are tax advantaged accounts with special features for withdraws related to health care costs. Below is a summary of features:",
          "1) Contributions you make each year reduce how much you pay in taxes in the year the contribution was made.",
          "2) You invest the money and you don't pay taxes each year on investment gains.",
          "3) If you have a medical expense, you can take the money out anytime you want and not pay penalties or taxes on it.",
          "4) After age 65, you can withdraw the money for any reason without penalty. However, if you take the money out for non-medical reasons, you'll owe income tax on it.",
        ],
      },
    ],
    fields: [
      {
        field: "has_hsa",
        type: "radio",
        label: `Does your employer offer a Health Savings Account (HSA)?`,
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
        required: true,
      },
      {
        field: "preReq",
        type: "radio",
        label: `Does your employer contribute to your HSA?`,
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
        enabled: ["has_hsa"],
      },
      {
        field: "employer_hsa_contribution",
        label: `What is the total household HSA contribution from your employer?`,
        type: "annualMonthly",
        enabled: ["has_hsa", "preReq"],
        required: true,
      },
    ],
  },
  employerStudentLoans: {
    emptyValue: {
      employer_contribute_to_student_loans: undefined,
      employer_student_loan_contribution: 0,
    },
    typeLabel: "Employer Student Loan Repayment",
    section: 3,
    fields: [
      {
        field: "employer_contribute_to_student_loans",
        type: "radio",
        label: "Does your employer contribute to your student loans?",
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
      },
      {
        field: "employer_student_loan_contribution",
        label:
          "What amount does your employer contribute to your student loans?",
        type: "annualMonthly",
        enabled: ["employer_contribute_to_student_loans"],
        required: true,
      },
    ],
  },
  marriage: {
    emptyValue: {
      will_marry: undefined,
      eventtype: "marriage",
      marry_date: "",
      marry_spouse_income: 0,
      marry_cost: 0,
      enable_fedloan: false,
      enable_privloan: false,
      marry_spouse_fed_loan_balance: 0,
      marry_spouse_priv_loan_balance: 0,
    },
    icon: "fb-favorite",
    buttonLabel: "Get Married",
    typeLabel: "Getting Married",
    fields: [
      {
        field: "will_marry",
        type: "radio",
        label: "Are you planning on getting married in the next 3 years?",
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
      },
      {
        field: "marry_date",
        label: "When do you plan on getting married?",
        width: "50%",
        type: "monthYear",
        enabled: ["will_marry"],
        required: true,
      },
      {
        field: "marry_spouse_income",
        label: "What is your future spouse’s total annual income?",
        type: "dollar",
        enabled: ["will_marry"],
      },
      {
        field: "marry_cost",
        label: "What are your anticipated wedding costs?",
        type: "dollar",
        enabled: ["will_marry"],
        subLabel:
          "Only include the cost that you have to pay for your wedding.",
      },
      {
        field: "enable_fedloan",
        label: "Does your spouse have federal student loans?",
        type: "radio",
        enabled: ["will_marry"],
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
      },
      {
        field: "enable_privloan",
        label: "Does your spouse have private student loans?",
        type: "radio",
        enabled: ["will_marry"],
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
      },
      {
        field: "marry_spouse_fed_loan_balance",
        label: "What is their federal student loan balance?",
        type: "dollar",
        enabled: ["enable_fedloan", "will_marry"],
        required: true,
      },
      {
        field: "marry_spouse_priv_loan_balance",
        label: "What is their private student loan balance?",
        type: "dollar",
        enabled: ["enable_privloan", "will_marry"],
        required: true,
      },
    ],
  },
  investOrPayoff: {
    emptyValue: {
      debt_comfort_level: 0,
      risk_comfort_level: 0,
      invest_comfort_level: 0,
    },
    typeLabel: "Invest or Pay Off Debt",
    fields: [
      {
        field: "debt_comfort_level",
        type: "radio",
        label: "How do you feel about your current debt level?",
        required: true,
        select: [
          {
            label: "Very uncomfortable",
            value: "1",
          },
          {
            label: "Somewhat uncomfortable",
            value: "2",
          },
          {
            label: "Somewhat comfortable",
            value: "3",
          },
          {
            label: "Comfortable",
            value: "4",
          },
          {
            label: "Very comfortable",
            value: "5",
          },
        ],
      },
      {
        field: "risk_comfort_level",
        type: "radio",
        label:
          "Let’s assume you have $10,000 to invest. Given the best- and worst-case returns in 1 year of the four investment choices below, which would you prefer?",
        required: true,
        select: [
          {
            label: "$200 gain best case, $0 gain/loss worst case",
            value: "1",
          },
          {
            label: "$800 gain best case, $200 loss worst case",
            value: "2",
          },
          {
            label: "$2,600 gain best case, $800 loss worst case",
            value: "3",
          },
          {
            label: "$4,800 gain best case, $2,400 loss worst case",
            value: "4",
          },
        ],
      },
      {
        field: "invest_comfort_level",
        type: "radio",
        label: "How comfortable are you with choosing your own investments?",
        required: true,
        select: [
          {
            label: "Very uncomfortable",
            value: "1",
          },
          {
            label: "Somewhat uncomfortable",
            value: "2",
          },
          {
            label: "Somewhat comfortable",
            value: "3",
          },
          {
            label: "Comfortable",
            value: "4",
          },
          {
            label: "Very comfortable",
            value: "5",
          },
        ],
      },
    ],
  },
  vacation: {
    emptyValue: {
      preReq: undefined,
      vacation_date: "",
      vacation_down: 0,
      vacation_recurring: undefined,
    },
    typeLabel: "Vacation",
    fields: [
      {
        field: "preReq",
        type: "radio",
        label: "Are you planning on going on vacation this year?",
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
      },
      {
        field: "vacation_down",
        type: "dollar",
        label: "What is the estimated cost of the vacation?",
        enabled: ["preReq"],
        required: true,
      },
      {
        field: "vacation_date",
        type: "monthYear",
        label: "When would you like to save this amount by?",
        enabled: ["preReq"],
        required: true,
      },
      {
        field: "vacation_recurring",
        type: "radio",
        label: "Do you want to save this amount for a vacation each year?",
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
        enabled: ["preReq"],
        required: true,
      },
    ],
  },
};

export const marriedQuestions: OptimizedPlanBuilderMarried = {
  moving: singleQuestions.moving,
  auto: singleQuestions.auto,
  nonProfit: singleQuestions.nonProfit,
  employerRetirement: singleQuestions.employerRetirement,
  hsa: {
    emptyValue: {
      has_hsa: undefined,
      employer_hsa_contribution: 0,
    },
    typeLabel: "Health Savings Account",
    section: 2,
    help: [
      {
        header: "Recommendation For HSA",
        body: [
          "If you choose a high deductible health insurance plan through your employer and they offer an HSA, we highly recommend contributing at least $50 per month to the HSA.  Doing so will help with retirement income diversification.",
          "Retirement income diversification is when you hold your assets in as many different types of accounts as you can.  Therefore, at retirement, if the government changes how they tax one type of an account, you have others that won't be affected.",
        ],
      },
      {
        header: "What is a Health Savings Account?",
        body: [
          "Health Savings Accounts (HSA for short) are tax advantaged accounts with special features for withdraws related to health care costs. Below is a summary of features:",
          "1) Contributions you make each year reduce how much you pay in taxes in the year the contribution was made.",
          "2) You invest the money and you don't pay taxes each year on investment gains.",
          "3) If you have a medical expense, you can take the money out anytime you want and not pay penalties or taxes on it.",
          "4) After age 65, you can withdraw the money for any reason without penalty. However, if you take the money out for non-medical reasons, you'll owe income tax on it.",
        ],
      },
    ],
    fields: [
      {
        field: "has_hsa",
        type: "radio",
        label:
          "Does your or your spouse's employer offer a Health Savings Account (HSA)?",
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
      },
      {
        field: "employer_hsa_contribution",
        label: `What is the total household HSA contribution from your employer?`,
        type: "annualMonthly",
        enabled: ["has_hsa"],
      },
    ],
  },
  employerStudentLoans: singleQuestions.employerStudentLoans,
  investOrPayoff: singleQuestions.investOrPayoff,
  vacation: singleQuestions.vacation,
};

export const spouseQuestions: OptimizedPlanBuilderSpouse = {
  employerRetirement: {
    emptyValue: {
      s_has_401k: undefined,
      s_retirement_match: 100,
      s_retirement_limit: 0,
      preReq1: undefined,
      s_retirement_additional_match: 100,
      s_retirement_additional_limit: 0,
      s_retirement_student_loan_match: undefined,
      s_has_roth_401k: undefined,
      s_retirement_nonelective_contribution: 0,
      preReq3: undefined,
    },
    typeLabel: "Employer Retirement Plans",
    fields: [
      {
        field: "s_has_401k",
        type: "radio",
        label: "Does SPOUSE_NAME's employer offer a 401k/403b/457b/TSP?",
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
      },
      {
        field: "s_retirement_match",
        type: "percent",
        enabled: ["s_has_401k"],
        label:
          "What percentage of your contributions does SPOUSE_NAME's employer match?",
        subLabel: "Most employers match 100%. You can edit if needed.",
        required: true,
      },
      {
        field: "s_retirement_limit",
        type: "percent",
        label:
          "What is the maximum percentage of SPOUSE_NAME's salary that their employer will match?",
        enabled: ["s_has_401k"],
        info:
          "Includes being employed by the government, a 501(c)(3) not-for-profit, or other not-for-profit organization that provides a qualifying service.",
        required: true,
      },
      {
        field: "preReq1",
        type: "radio",
        label: "Does SPOUSE_NAME's employer offer additional match?",
        enabled: ["s_has_401k"],
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
      },
      {
        field: "s_retirement_additional_match",
        type: "percent",
        label:
          "What additional percentage of SPOUSE_NAME's contributions does their employer match?",
        enabled: ["preReq1", "s_has_401k"],
        subLabel: "Most employers match 50%. You can edit if needed.",
        required: true,
      },
      {
        field: "s_retirement_additional_limit",
        type: "percent",
        enabled: ["preReq1", "s_has_401k"],
        label:
          "What is the maximum additional percentage of SPOUSE_NAME's salary that your employer will match?",
        info:
          "Includes being employed by the government, a 501(c)(3) not-for-profit, or other not-for-profit organization that provides a qualifying service.",
        required: true,
      },
      {
        field: "s_retirement_student_loan_match",
        type: "radio",
        label:
          "Does SPOUSE_NAME's employer make matching contributions based on their student loan payments?",
        enabled: ["s_has_401k"],
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
      },
      {
        field: "s_has_roth_401k",
        type: "radio",
        label: "Does SPOUSE_NAME's employer offer a Roth 401k?",
        enabled: ["s_has_401k"],
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
      },
      {
        field: "preReq3",
        type: "radio",
        label:
          "Does SPOUSE_NAME's employer offer contributions to your retirement without you having to contribute?",
        enabled: ["s_has_401k"],
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
      },
      {
        field: "s_retirement_nonelective_contribution",
        type: "percent",
        enabled: ["s_has_401k", "preReq3"],
        label:
          "What percentage of SPOUSE_NAME's income does their employer contribute?",
        info:
          "Includes being employed by the government, a 501(c)(3) not-for-profit, or other not-for-profit organization that provides a qualifying service.",
        required: true,
      },
    ],
  },
  employerStudentLoans: {
    emptyValue: {
      s_employer_contribute_to_student_loans: undefined,
      s_employer_student_loan_contribution: 0,
    },
    typeLabel: "Employer Student Loan Repayment",
    section: 3,
    fields: [
      {
        field: "s_employer_contribute_to_student_loans",
        type: "radio",
        label: "Does SPOUSE_NAME's employer contribute to their student loans?",
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
      },
      {
        field: "s_employer_student_loan_contribution",
        label:
          "What amount does SPOUSE_NAME's employer contribute to their student loans?",
        type: "annualMonthly",
        enabled: ["s_employer_contribute_to_student_loans"],
        required: true,
      },
    ],
  },
  nonProfit: {
    emptyValue: {
      s_will_nonprofit: undefined,
    },
    typeLabel: "Work Status & Income",
    section: 1,
    fields: [
      {
        field: "s_will_nonprofit",
        type: "radio",
        label:
          "Is SPOUSE_NAME planning to work for a non-profit for more than 5 years? (This includes being employed by the government, a 501(c)(3) not-for-profit, or other not-for-profit organization.)",
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
      },
    ],
  },
};
