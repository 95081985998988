import { forOwn, pickBy, range } from "lodash";
import { createSelector } from "reselect";
import { AppState } from "..";
import {
  MAX_401K_ANNUAL_CONTRIBUTION,
  MAX_IRA_ANNUAL_CONTRIBUTION,
} from "src/constants";
import {
  ACCOUNT_TYPES,
  ALLOCATIONS_TYPES,
  Allocations,
  APPLICANT_WHO_TYPES,
  AssetAllocations,
  DebtAllocations,
  EducationFunding,
  EDUCATION_EXPENSE_TYPES,
  EDUCATION_FUNDING_AND_EXPENSES,
  FUNDING_INCOME_TYPES,
  GRADUATED_LIFE_EVENTS,
  Marriage,
  PLAN_EXPENSE_TYPES,
  PLAN_INCOME_TYPES,
  PlanBuildState,
  Plan,
  PlanListRecord,
  RISK_MANAGEMENT_TYPES,
  MappedCashflow,
  PlanLiabilities,
  PlanProjection,
  MessageCode,
  REPAYMENT_PLANS,
  SPECIAL_REPAYMENTS,
} from "src/interfaces";
import { PLAN_BUILD_STEPS, ReviewSections } from "./constants";
import AI_GOAL_MESSAGES, { MessageDefinition } from "./aiGoalMessages";
import {
  getAccounts,
  getAccountTypesWithBalances,
  getDebtObligations,
  getStudentLoanRates,
} from "../account/selector";
import {
  getEstimatedTaxes,
  getLiveExpenseTotal,
  getLiveIncomeTotal,
  getLiveRiskTotal,
  getMyLive401kEligibleIncome,
  getSpouseLive401kEligibleIncome,
} from "../cashflow/selector";
import {
  getLastGraduationYearMonth,
  getProfile,
  getSpouseInSchool,
  getSpouseProfile,
  getUserInSchool,
} from "../profileBuild/selector";
import { getIsMarried, spouseSelector, userSelector } from "../system/selector";

// import {
//   PERSONAL_INFO_KEYS,
//   EDUCATION_KEYS,
//   CAREER_KEYS,
//   OTHER_HC_KEYS,
//   DEBTS_KEYS,
// } from './selectorKeys';

interface AllocationTotals {
  assetsTotal: number;
  debtsTotal: number;
  riskManagementTotal: number;
}

const renderWho = (who: string) => {
  if (who === "applicant") {
    return "Mine";
  }
  if (who === "spouse") {
    return "Spouse";
  }
  return "Household";
};

const now = new Date();
const nowMonth = now.getMonth() + 1;
const nowYear = now.getFullYear();

export const getPlanBuildState = (state: AppState) => state.planBuild;
export const getComparePlanIndex = createSelector(
  getPlanBuildState,
  (state) => state.compareWithPlanIndex
);
export const getLoadingState = createSelector(
  getPlanBuildState,
  (state) => state.loading
);
export const getLoadedState = createSelector(
  getPlanBuildState,
  (state) => state.loaded
);
export const getErrorState = createSelector(
  getPlanBuildState,
  (state) => state.error
);
export const getPlanBuilderReady = createSelector(
  getPlanBuildState,
  (state) => state.planBuildReady
);

export const getLoadedPlans = createSelector(
  getLoadedState,
  (loaded) => loaded.plans
);
export const getLoadingPlans = createSelector(
  getLoadingState,
  (loading) => loading.plans
);
export const getLoadingProjection = createSelector(
  getLoadingState,
  (loading) => loading.projection
);
export const getLoadedSavedProjections = createSelector(
  getLoadedState,
  (loaded) => loaded.savedProjections
);
export const getLoadingSavedProjections = createSelector(
  getLoadingState,
  (loading) => loading.savedProjections
);

export const getSavedProjectionsError = createSelector(
  getErrorState,
  (error) => error.savedProjections
);

export const getSelectedPlan = (state: any) => state.planBuild.selectedPlan;

export const getCurrentStep = createSelector<
  AppState,
  PlanBuildState,
  PLAN_BUILD_STEPS
>([getPlanBuildState], (state: PlanBuildState) => state.currentStep);
export const getFurthestStep = createSelector<
  AppState,
  PlanBuildState,
  PLAN_BUILD_STEPS
>([getPlanBuildState], (state: PlanBuildState) => state.furthestStep);
export const getReviewSection = createSelector<
  AppState,
  PlanBuildState,
  ReviewSections
>([getPlanBuildState], (state: PlanBuildState) => state.reviewSection || 0);

export const getPlans = createSelector<
  AppState,
  PlanBuildState,
  PlanListRecord[]
>([getPlanBuildState], (state: PlanBuildState) => state.savedPlans);
export const getCurrentPlan = createSelector(
  [getPlanBuildState],
  (state: PlanBuildState) => state.currentPlan
);
export const getComparePlan = createSelector(
  [getPlanBuildState],
  (state: PlanBuildState) => state.compareWithPlanDetail
);
export const getLivePlan = createSelector(
  [getPlanBuildState],
  (state: PlanBuildState) => state.livePlan
);
export const getIsMonthly = createSelector(
  [getPlanBuildState],
  (state) => state.monthlyOverview
);
export const getLiveProjection = createSelector(
  [getPlanBuildState],
  (state) => {
    const projection = state.liveProjection;
    return projection;
  }
);
export const getOriginalProjection = getLiveProjection;
export const getMinimalProjection = createSelector(
  [getPlanBuildState],
  (state) => {
    const projection = state.minimalProjection;
    return projection;
  }
);
export const getSavedPlanProjections = createSelector(
  [getPlanBuildState],
  (state) => state.savedPlanProjections
);
export const isCurrentPlanImplemented = createSelector(
  [getPlans, getCurrentPlan],
  (plans, currentPlan) => {
    const currentPlanDetail = plans.find((plan) => plan.id === currentPlan.id);
    return currentPlanDetail?.implemented;
  }
);
export const getPlanProjection = createSelector(
  [getPlanBuildState, isCurrentPlanImplemented],
  (state, isImplemented) => {
    const projection = isImplemented ? state.liveProjection : state.projection;
    return projection;
  }
);

export const getLiveShortTermSavings = createSelector(
  [getLiveProjection],
  (projection: any) => {
    if (!projection) {
      return 0;
    }
    return projection.targets.savings.reduce(
      (result: number, item: any) => result + item.amount,
      0
    );
  }
);

export const getLiabilities = createSelector(
  getPlanBuildState,
  (state) => state.liabilities
);

export const getStudentLoanData = createSelector(
  getCurrentPlan,
  (plan) => plan.studentloan
);
export const getRawIncomes = createSelector(
  getCurrentPlan,
  (plan) => plan.incomes
);
export const getRawExpenses = createSelector(
  getCurrentPlan,
  (plan) => plan.expenses
);
export const getRawRisks = createSelector(getCurrentPlan, (plan) => plan.risks);
export const getRawEducation = createSelector(
  getCurrentPlan,
  (plan) => plan.education
);
export const getMonthlyTax = createSelector(
  [getPlanBuildState, isCurrentPlanImplemented, getEstimatedTaxes],
  (state, isImplemented, liveTaxes) => {
    if (isImplemented) {
      return liveTaxes;
    }
    return state.estimatedMonthlyTax;
  }
);
export const getStudentTax = createSelector(
  getPlanBuildState,
  (state) => state.estimatedStudentTax
);
export const getEmergencyMonths = createSelector(
  getCurrentPlan,
  (plan) => plan.emergencymonths
);
export const getCurrentPlanMessages = createSelector(getCurrentPlan, (plan) => {
  const data = plan.messages || [];
  const messages: MessageCode[] = [];
  data.forEach((messageData) => {
    const id = messageData.id;
    const existingMessage = messages.find((m) => m.id === id);
    if (existingMessage && existingMessage.who) {
      existingMessage.who = "both";
    } else {
      messages.push({ ...messageData });
    }
  });
  return messages;
});
export const getAIPlanReviewMessages = createSelector(
  [
    getCurrentPlan,
    getPlanProjection,
    getIsMarried,
    getAccounts,
    userSelector,
    spouseSelector,
    getProfile,
    getSpouseProfile,
  ],
  (
    plan,
    projection,
    isMarried,
    accounts,
    user,
    spouse,
    myProfile,
    spouseProfile
  ) => {
    if (!plan.goals) {
      return {
        goalSummary: [],
        assets: [],
        debt: [],
      };
    }
    const spouseFirstName = spouse?.first_name || "";
    const output: any = {
      goalSummary: [AI_GOAL_MESSAGES.shortTermGoal],
      assets: [],
      debt: [],
    };
    if (plan.lifeevents.find((event) => event.eventtype === "house")) {
      output.goalSummary.push(AI_GOAL_MESSAGES.buyAHouse);
    } else {
      output.goalSummary.push(AI_GOAL_MESSAGES.keepRenting);
    }
    if (plan.goals[0].goaltype === "invest") {
      output.assets.push(AI_GOAL_MESSAGES.prioritizeInvesting);
    } else {
      output.assets.push(AI_GOAL_MESSAGES.dontPrioritizeInvesting);
    }
    const myRepayPlan: any = plan.studentloan[0].repayplan || "";
    const spouseRepayPlan: any = plan.studentloan[1]?.repayplan || "";
    const userOnIDR = SPECIAL_REPAYMENTS.includes(myRepayPlan);
    const spouseOnIDR = SPECIAL_REPAYMENTS.includes(spouseRepayPlan);
    if (
      isMarried &&
      plan.profile.filing_jointly === false &&
      (userOnIDR || spouseOnIDR)
    ) {
      output.assets.push(AI_GOAL_MESSAGES.fileSeparately);
    }
    const account = accounts.find((a) => a.id === plan.goals[0]?.account_id);
    if (
      [
        "payoff",
        "privloanpayoff",
        "fedloanpayoff",
        "perkinsloanpayoff",
      ].includes(plan.goals[0].goaltype)
    ) {
      const message: MessageDefinition = {
        ...AI_GOAL_MESSAGES.payOffDebt,
        variables: {},
      };
      switch (plan.goals[0].goaltype) {
        case "privloanpayoff":
          message.variables.DEBT_TYPE = "private student loans";
          break;
        case "fedloanpayoff":
          message.variables.DEBT_TYPE = "Federal student loans";
          break;
        case "perkinsloanpayoff":
          message.variables.DEBT_TYPE = "Perkins student loans";
          break;
        case "payoff":
        default:
          if (account) {
            message.variables.DEBT_TYPE = ACCOUNT_TYPES[
              account.variable as any
            ].toLowerCase();
          } else {
            message.variables.DEBT_TYPE = "debt";
          }
          break;
      }
      output.debt.push(message);
    } else {
      output.debt.push(AI_GOAL_MESSAGES.dontPayOffDebt);
    }
    if (
      userOnIDR &&
      plan.studentloan[0].repayplan !== myProfile.fed_repayment_plan
    ) {
      const message: MessageDefinition = {
        ...(plan.studentloan[0].idroption === "standard"
          ? AI_GOAL_MESSAGES.userEnrollInIDR
          : AI_GOAL_MESSAGES.userIDRwithPSLF),
        variables: {},
      };
      message.variables.PLAN_NAME =
        REPAYMENT_PLANS[plan.studentloan[0].repayplan];
      output.debt.push(message);
    }
    if (
      isMarried &&
      spouseOnIDR &&
      plan.studentloan[1]?.repayplan !== spouseProfile.fed_repayment_plan
    ) {
      const message: MessageDefinition = {
        ...(plan.studentloan[1]?.idroption === "standard"
          ? AI_GOAL_MESSAGES.spouseEnrollInIDR
          : AI_GOAL_MESSAGES.spouseIDRwithPSLF),
        variables: {},
      };
      message.variables.PLAN_NAME =
        REPAYMENT_PLANS[plan.studentloan[0].repayplan];
      message.variables.SPOUSE_NAME = spouseFirstName;
      output.debt.push(message);
    }
    if (userOnIDR) {
      const message: MessageDefinition = {
        ...AI_GOAL_MESSAGES.userSaveForTaxBomb,
        variables: {},
      };
      const taxBombRecord = (projection as any)?.taxbomb?.find(
        (item: any) => item.who !== "spouse"
      );
      message.variables.TAX_BOMB_AMOUNT = taxBombRecord?.amount || 0;
      output.debt.push(message);
    }
    if (isMarried && spouseOnIDR) {
      const message: MessageDefinition = {
        ...AI_GOAL_MESSAGES.spouseSaveForTaxBomb,
        variables: {},
      };
      message.variables.SPOUSE_NAME = spouseFirstName;
      const taxBombRecord = (projection as any)?.taxbomb?.find(
        (item: any) => item.who === "spouse"
      );
      message.variables.TAX_BOMB_AMOUNT = taxBombRecord?.amount || 0;
      output.debt.push(message);
    }
    if (isMarried && (userOnIDR || spouseOnIDR)) {
      const message: MessageDefinition = {
        ...AI_GOAL_MESSAGES.fileSeparatelyOrJointly,
        variables: {},
      };
      message.variables.SEPARATELY_OR_JOINTLY = plan.profile.filing_jointly
        ? "jointly"
        : "separately";
      output.debt.push(message);
    }
    if (!userOnIDR || plan.studentloan[0].idroption === "payoff") {
      const message: MessageDefinition = {
        ...AI_GOAL_MESSAGES[
          plan.goals[0].goaltype === "fedloanpayoff"
            ? "userPrepayFedLoans"
            : "userDontPrepayFedLoans"
        ],
        variables: {},
      };
      message.variables.PLAN_NAME = REPAYMENT_PLANS[myRepayPlan];
      output.debt.push(message);
    }
    if (
      isMarried &&
      (!spouseOnIDR || plan.studentloan[1]?.idroption === "payoff")
    ) {
      const message: MessageDefinition = {
        ...AI_GOAL_MESSAGES[
          plan.goals[0].goaltype === "fedloanpayoff"
            ? "userPrepayFedLoans"
            : "userDontPrepayFedLoans"
        ],
        variables: {},
      };
      message.variables.PLAN_NAME = REPAYMENT_PLANS[myRepayPlan];
      message.variables.SPOUSE_NAME = spouseFirstName;
      output.debt.push(message);
    }
    return output;
  }
);
export const getEmergencyMonthlyExpenses = createSelector(
  getPlanBuildState,
  (state) => state.estimatedMonthlyEmergencyFund
);

export const getCompareMonthlyTax = createSelector(
  getPlanBuildState,
  (state) => state.compareMonthlyTax
);
export const getCompareStudentTax = createSelector(
  getPlanBuildState,
  (state) => state.compareStudentTax
);
export const getCompareMonthlyExpenses = createSelector(
  getPlanBuildState,
  (state) => state.compareMonthlyEmergencyFund
);
export const getCompareEmergencyMonths = createSelector(
  getComparePlan,
  (plan) => plan.emergencymonths
);

export const getLiveMonthlyTax = createSelector(
  getPlanBuildState,
  (state) => state.liveMonthlyTax
);
export const getLiveStudentTax = createSelector(
  getPlanBuildState,
  (state) => state.liveStudentTax
);
export const getLiveEmergencyMonths = createSelector(
  getLivePlan,
  (plan) => plan.emergencymonths
);
export const getLiveMonthlyExpenses = createSelector(
  getPlanBuildState,
  (state) => state.liveMonthlyEmergencyFund
);
export const getLiveEmergencySavings = createSelector(
  [getLiveEmergencyMonths, getLiveMonthlyExpenses],
  (months, dollars) => months * dollars
);

export const getPlanCashflows = (plan: Plan, monthlyTax: number) => {
  let incomeTotal = 0;
  const income: MappedCashflow[] = (plan.incomes || []).map((income, index) => {
    incomeTotal += income.earning;
    return {
      ...income,
      id: index,
      monthly: Math.round(income.earning / 12),
      annual: income.earning,
      typeLabel: PLAN_INCOME_TYPES[income.type],
      whoLabel: APPLICANT_WHO_TYPES[income.who],
    };
  });
  const expenses: MappedCashflow[] = plan.expenses.map((expense, index) => ({
    ...expense,
    id: index,
    monthly: Math.round(expense.payment / 12),
    annual: expense.payment,
    percent: (expense.payment / incomeTotal) * 100,
    typeLabel: PLAN_EXPENSE_TYPES[expense.type],
  }));
  const risks: MappedCashflow[] = (plan.risks || []).map((expense, index) => ({
    ...expense,
    id: index,
    monthly: Math.round(expense.payment / 12),
    percent: (expense.payment / incomeTotal) * 100,
    annual: expense.payment,
    typeLabel: RISK_MANAGEMENT_TYPES[expense.type],
  }));
  const tax: MappedCashflow = {
    id: 0,
    type: "tax",
    typeLabel: "Taxes",
    annual: monthlyTax * 12,
    monthly: monthlyTax,
    percent: ((monthlyTax * 12) / incomeTotal) * 100,
  };
  return { income, expenses, risks, tax };
};

const getIncomeTotal = ({ income }: { income: MappedCashflow[] }) =>
  income.reduce(
    (result: number, item: MappedCashflow) => result + (item.annual || 0),
    0
  );
const getExpenseTotal = (
  { expenses }: { expenses: MappedCashflow[] },
  tax: number
) =>
  expenses.reduce(
    (result: number, item: MappedCashflow) => result + (item.annual || 0),
    tax * 12
  );
const getRiskManagementTotal = ({ risks }: { risks: MappedCashflow[] }) =>
  risks.reduce(
    (result: number, item: MappedCashflow) => result + (item.annual || 0),
    0
  );

const getPlanEducationDetails = (plan: Plan) => {
  const output: {
    funding: any[];
    expenses: any[];
    fundingTotal: number;
    expenseTotal: number;
  } = {
    funding: [],
    expenses: [],
    fundingTotal: 0,
    expenseTotal: 0,
  };
  (plan.education || []).forEach(
    (item: EducationFunding, editIndex: number) => {
      const typeLabel = EDUCATION_FUNDING_AND_EXPENSES[item.type];
      const date: string = item.date || "";
      const year: string = date ? date.slice(0, 4) : "N/A";
      const month: string = date && date.length > 4 ? date.slice(5, 7) : "";
      if (item.type in EDUCATION_EXPENSE_TYPES) {
        output.expenseTotal += item.payment || 0;
        output.expenses.push({
          ...item,
          editIndex,
          amount: item.payment,
          month,
          year,
          typeLabel,
          whoLabel: item.who === "spouse" ? "Spouse" : "Mine",
        });
      } else {
        output.fundingTotal += item.earning || item.payment || 0;
        const mappedItem = {
          ...item,
          editIndex,
          amount: item.earning || item.payment,
          displayDate: year,
          year,
          typeLabel,
          whoLabel: item.who === "spouse" ? "Spouse" : "Mine",
        };
        if (date.length > 4) {
          mappedItem.displayDate = `${month}/${year}`;
        }
        output.funding.push(mappedItem);
      }
    }
  );
  output.funding.sort((a, b) => a.year - b.year);
  output.expenses.sort((a, b) => a.year - b.year);
  return output;
};

export const getCurrentPlanEducationDetails = createSelector(
  [getCurrentPlan],
  getPlanEducationDetails
);
export const getLivePlanEducationDetails = createSelector(
  [getLivePlan],
  getPlanEducationDetails
);
export const getCurrentPlanCashflows = createSelector(
  [getCurrentPlan, getMonthlyTax],
  getPlanCashflows
);
export const currentPlanIncomeTotal = createSelector(
  [getCurrentPlanCashflows, isCurrentPlanImplemented, getLiveIncomeTotal],
  (planCashflows, isImplemented, liveTotal) => {
    if (isImplemented) {
      return liveTotal;
    }
    return getIncomeTotal(planCashflows);
  }
);
export const currentPlanExpenseTotal = createSelector(
  [
    getCurrentPlanCashflows,
    getMonthlyTax,
    isCurrentPlanImplemented,
    getLiveExpenseTotal,
  ],
  (planCashflows, planTax, isImplemented, liveTotal) => {
    if (isImplemented) {
      return liveTotal;
    }
    return getExpenseTotal(planCashflows, planTax);
  }
);
export const currentPlanRiskManagementTotal = createSelector(
  [getCurrentPlanCashflows, isCurrentPlanImplemented, getLiveRiskTotal],
  (planCashflows, isImplemented, liveTotal) => {
    if (isImplemented) {
      return liveTotal;
    }
    return getRiskManagementTotal(planCashflows);
  }
);

export const getComparePlanCashflows = createSelector(
  [getComparePlan, getCompareMonthlyTax],
  getPlanCashflows
);
export const comparePlanIncomeTotal = createSelector(
  getComparePlanCashflows,
  getIncomeTotal
);
export const comparePlanExpenseTotal = createSelector(
  [getComparePlanCashflows, getCompareMonthlyTax],
  getExpenseTotal
);
export const comparePlanRiskManagementTotal = createSelector(
  getComparePlanCashflows,
  getRiskManagementTotal
);

export const getLivePlanCashflows = createSelector(
  [getLivePlan, getLiveMonthlyTax],
  getPlanCashflows
);
export const livePlanIncomeTotal = getLiveIncomeTotal;
export const livePlanExpenseTotal = getLiveExpenseTotal;
export const livePlanRiskManagementTotal = getLiveRiskTotal;

const extractMy401kIncome = (result: number, item: MappedCashflow) => {
  if (
    item.who === "applicant" &&
    ["salary", "commission", "hourly"].indexOf(item.type as string) >= 0
  ) {
    return result + (item.annual || 0);
  }
  return result;
};

const extractSpouse401kIncome = (result: number, item: MappedCashflow) => {
  if (
    item.who === "spouse" &&
    ["salary", "commission", "hourly"].indexOf(item.type as string) >= 0
  ) {
    return result + (item.annual || 0);
  }
  return result;
};

export const getMy401kEligibleIncome = createSelector(
  [
    getCurrentPlanCashflows,
    isCurrentPlanImplemented,
    getMyLive401kEligibleIncome,
  ],
  ({ income }, isImplemented, liveTotal) => {
    if (isImplemented) {
      return liveTotal;
    }
    return income.reduce(extractMy401kIncome, 0);
  }
);
export const getSpouse401kEligibleIncome = createSelector(
  [
    getCurrentPlanCashflows,
    isCurrentPlanImplemented,
    getSpouseLive401kEligibleIncome,
  ],
  ({ income }, isImplemented, liveTotal) => {
    if (isImplemented) {
      return liveTotal;
    }
    return income.reduce(extractSpouse401kIncome, 0);
  }
);

export const getMyCompare401kEligibleIncome = createSelector(
  getComparePlanCashflows,
  ({ income }) => income.reduce(extractMy401kIncome, 0)
);

export const getSpouseCompare401kEligibleIncome = createSelector(
  getComparePlanCashflows,
  ({ income }) => income.reduce(extractSpouse401kIncome, 0)
);

export const getSpecificDollarLimit = (index: number) => (contributions: {
  maxDollars: number;
  values: number[];
}) =>
  Math.max(
    0,
    Math.floor(
      contributions.values.reduce((output, value, i) => {
        if (i === index) {
          return output;
        }
        return output - value;
      }, contributions.maxDollars)
    )
  );

export const getMyIRAContributions = createSelector(
  [getCurrentPlan],
  (plan) => {
    const maxDollars = MAX_IRA_ANNUAL_CONTRIBUTION;
    const mySoloAllocation = plan.allocations[0].solo[0];
    const myTradIraContribution = mySoloAllocation.ira_value || 0;
    const myRothIraContribution = mySoloAllocation.roth_ira_value || 0;
    return {
      maxDollars,
      values: [myTradIraContribution, myRothIraContribution],
    };
  }
);
export const getSpouseIRAContributions = createSelector(
  [getCurrentPlan],
  (plan) => {
    const maxDollars = MAX_IRA_ANNUAL_CONTRIBUTION;
    const spouseSoloAllocation = plan.allocations[0].solo[1];
    const spouseTradIraContribution = spouseSoloAllocation.ira_value || 0;
    const spouseRothIraContribution = spouseSoloAllocation.roth_ira_value || 0;
    return {
      maxDollars,
      values: [spouseTradIraContribution, spouseRothIraContribution],
    };
  }
);

export const getMyMaxTradIRADollarContribution = createSelector(
  getMyIRAContributions,
  getSpecificDollarLimit(0)
);
export const getMyMaxRothIRADollarContribution = createSelector(
  getMyIRAContributions,
  getSpecificDollarLimit(1)
);
export const getSpouseMaxTradIRADollarContribution = createSelector(
  getSpouseIRAContributions,
  getSpecificDollarLimit(0)
);
export const getSpouseMaxRothIRADollarContribution = createSelector(
  getSpouseIRAContributions,
  getSpecificDollarLimit(1)
);

export const getMy401kContributions = createSelector(
  [getCurrentPlan, getMy401kEligibleIncome],
  (plan, total) => {
    const maxDollars = MAX_401K_ANNUAL_CONTRIBUTION;
    const mySoloAllocation = plan.allocations[0].solo[0];
    const my401kContribution = mySoloAllocation["401k_value"] || 0;
    const myRoth401kContribution = mySoloAllocation.roth_401k_value || 0;
    return {
      maxDollars,
      values: [
        (my401kContribution / 100) * total,
        (myRoth401kContribution / 100) * total,
      ],
    };
  }
);
export const getSpouse401kContributions = createSelector(
  [getCurrentPlan, getSpouse401kEligibleIncome],
  (plan, total) => {
    const maxDollars = MAX_401K_ANNUAL_CONTRIBUTION;
    const spouseSoloAllocation = plan.allocations[0].solo[1];
    const spouse401kContribution = spouseSoloAllocation["401k_value"] || 0;
    const spouseRoth401kContribution =
      spouseSoloAllocation.roth_401k_value || 0;
    return {
      maxDollars,
      values: [
        (spouse401kContribution / 100) * total,
        (spouseRoth401kContribution / 100) * total,
      ],
    };
  }
);

export const getMyMax401kDollarContribution = createSelector(
  getMy401kContributions,
  getSpecificDollarLimit(0)
);
export const getMyMaxRoth401kDollarContribution = createSelector(
  getMy401kContributions,
  getSpecificDollarLimit(1)
);
export const getSpouseMax401kDollarContribution = createSelector(
  getSpouse401kContributions,
  getSpecificDollarLimit(0)
);
export const getSpouseMaxRoth401kDollarContribution = createSelector(
  getSpouse401kContributions,
  getSpecificDollarLimit(1)
);

export const getAllocations = (
  plan: Plan,
  liabilities: PlanLiabilities,
  debtObligations: any,
  isMarried: boolean,
  totalIncome: number,
  myEligibleIncome: number,
  spouseEligibleIncome: number
) => {
  const assets: any = [];
  const debts: any = [];
  const minLiabilities: any = liabilities.min;
  forOwn(plan.allocations[0], (allocation: any, key: string) => {
    if (key === "solo") {
      return true;
    }
    const allocationType = ALLOCATIONS_TYPES[key as keyof Allocations];
    if (!allocationType) {
      return true;
    }
    let solo = false;
    const annual = plan.allocations[0][key as keyof Allocations] || 0;
    let percent = (annual / totalIncome) * 100;
    if (allocationType.category === "asset") {
      if (
        plan.allocations[0].solo &&
        plan.allocations[0].solo[0] &&
        key in plan.allocations[0].solo[0]
      ) {
        const soloAllocation = plan.allocations[0].solo[0] as any;
        let myAnnual = soloAllocation[key];
        solo = true;
        if (["401k_value", "roth_401k_value"].indexOf(key) >= 0) {
          myAnnual = ((soloAllocation[key] || 0) / 100) * myEligibleIncome;
        }
        percent = (myAnnual / totalIncome || 0) * 100;
        if (percent > 0) {
          assets.push({
            type: key,
            annual: myAnnual,
            percent,
            who: "applicant",
          });
        }
      }
      if (
        isMarried &&
        plan.allocations[0].solo &&
        plan.allocations[0].solo.length > 1 &&
        key in plan.allocations[0].solo[1]
      ) {
        const soloAllocation = plan.allocations[0].solo[1] as any;
        let spouseAnnual = soloAllocation[key];
        solo = true;
        if (["401k_value", "roth_401k_value"].indexOf(key) >= 0) {
          spouseAnnual =
            ((soloAllocation[key] || 0) / 100) * spouseEligibleIncome;
        }
        percent = (spouseAnnual / totalIncome || 0) * 100;
        if (percent > 0) {
          assets.push({
            type: key,
            annual: spouseAnnual,
            percent,
            who: "spouse",
          });
        }
      }
      if (!solo) {
        assets.push({
          type: key,
          annual,
          percent,
          whoLabel: "Household",
          who: "Household",
        });
      }
    } else {
      if (
        plan.allocations[0].solo &&
        plan.allocations[0].solo[0] &&
        key in plan.allocations[0].solo[0]
      ) {
        const soloAllocation = plan.allocations[0].solo[0] as any;
        solo = true;
        if (soloAllocation[key] !== undefined) {
          const debt: any = {
            type: key,
            annual: soloAllocation[key],
            percent: (soloAllocation[key] / totalIncome) * 100,
            who: soloAllocation.who,
          };
          debts.push(debt);
          if (minLiabilities.solo && minLiabilities.solo[0][key]) {
            debt.min = (minLiabilities.solo[0][key] / totalIncome) * 100;
          }
        }
      }
      if (
        isMarried &&
        plan.allocations[0].solo &&
        plan.allocations[0].solo.length > 1 &&
        key in plan.allocations[0]?.solo[1]
      ) {
        const soloAllocation = plan.allocations[0].solo[1] as any;
        solo = true;
        if (soloAllocation[key] !== undefined) {
          const debt: any = {
            type: key,
            annual: soloAllocation[key],
            percent: (soloAllocation[key] / totalIncome) * 100,
            who: soloAllocation.who,
          };
          debts.push(debt);
          if (minLiabilities.solo && minLiabilities.solo[1][key]) {
            debt.min = (minLiabilities.solo[1][key] / totalIncome) * 100;
          }
        }
      }
      if (!solo) {
        const debt: any = {
          type: key,
          annual,
          percent,
          who: "Household",
        };
        if (typeof minLiabilities[key] === "number") {
          const liability = Math.max(minLiabilities[key], debtObligations[key]);
          debt.min = (liability / totalIncome) * 100;
          debt.min_annual = liability;
          debt.min_monthly = liability / 12;
        }
        debts.push(debt);
      }
    }
  });
  return { assets, debts } as {
    assets: Partial<AssetAllocations>;
    debts: Partial<DebtAllocations>;
  };
};

export const getDerived401kAllocations = createSelector(
  [
    getLivePlan,
    getIsMarried,
    getMyLive401kEligibleIncome,
    getSpouseLive401kEligibleIncome,
    getLiveIncomeTotal,
  ],
  (
    plan: Plan,
    married: boolean,
    my401kIncome: number,
    spouse401kIncome: number,
    totalIncome: number
  ) => {
    const result: any = {
      spouse401kDollars: 0,
      spouseRoth401kDollars: 0,
    };
    const soloAllocations = plan.allocations[0].solo;
    result.my401kDollars =
      (soloAllocations[0]["401k_value"] || 0) * my401kIncome;
    result.myRoth401kDollars =
      (soloAllocations[0]["roth_401k_value"] || 0) * my401kIncome;
    if (married && soloAllocations[1]) {
      result.spouse401kDollars =
        (soloAllocations[1]["401k_value"] || 0) * spouse401kIncome;
      result.spouseRoth401kDollars =
        (soloAllocations[1]["roth_401k_value"] || 0) * spouse401kIncome;
    }
    result.total401kDollars = result.my401kDollars + result.spouse401kDollars;
    result.totalRoth401kDollars =
      result.myRoth401kDollars + result.spouseRoth401kDollars;
    result.total401kPercent = result.total401kDollars / totalIncome;
    result.totalRoth401kPercent = result.totalRoth401kDollars / totalIncome;
    return result;
  }
);

export const getStudentAllocations = (
  plan: Plan,
  liabilities: PlanLiabilities,
  isMarried: boolean
) => {
  const assets: any = [];
  const debts: any = [];
  const minLiabilities: any = liabilities.min;
  forOwn(plan.allocations[0], (allocation: any, key: string) => {
    if (key === "solo") {
      return true;
    }
    const allocationType = ALLOCATIONS_TYPES[key as keyof Allocations];
    if (!allocationType) {
      return true;
    }
    let solo = false;
    if (allocationType.category === "asset") {
      if (
        plan.allocations[0].solo &&
        plan.allocations[0].solo[0] &&
        key in plan.allocations[0].solo[0]
      ) {
        const soloAllocation = plan.allocations[0].solo[0] as any;
        solo = true;
        assets.push({
          type: key,
          amount: soloAllocation[key],
          who: soloAllocation.who,
        });
      }
      if (
        isMarried &&
        plan.allocations[0].solo &&
        plan.allocations[0].solo.length > 1 &&
        key in plan.allocations[0].solo[1]
      ) {
        const soloAllocation = plan.allocations[0].solo[1] as any;
        solo = true;
        assets.push({
          type: key,
          amount: soloAllocation[key],
          who: soloAllocation.who,
        });
      }
      if (!solo) {
        assets.push({
          type: key,
          amount: allocation,
          whoLabel: "Household",
          who: "Household",
        });
      }
    } else {
      if (
        plan.allocations[0].solo &&
        plan.allocations[0].solo[0] &&
        key in plan.allocations[0].solo[0]
      ) {
        const soloAllocation = plan.allocations[0].solo[0] as any;
        solo = true;
        const debt: any = {
          type: key,
          amount: soloAllocation[key],
          who: soloAllocation.who,
        };
        if (minLiabilities.solo && minLiabilities.solo[0][key]) {
          debt.min = minLiabilities.solo[0][key];
        }
        debts.push(debt);
      }
      if (
        isMarried &&
        plan.allocations[0].solo &&
        plan.allocations[0].solo.length > 1 &&
        key in plan.allocations[0].solo[1]
      ) {
        const soloAllocation = plan.allocations[0].solo[1] as any;
        solo = true;
        const debt: any = {
          type: key,
          amount: soloAllocation[key],
          who: soloAllocation.who,
        };
        if (minLiabilities.solo && minLiabilities.solo[1][key]) {
          debt.min = minLiabilities.solo[1][key];
        }
        debts.push(debt);
      }
      if (!solo) {
        const debt: any = {
          type: key,
          amount: allocation,
          whoLabel: "Household",
          who: "Household",
        };
        if (minLiabilities[key]) {
          debt.min = minLiabilities[key];
        }
        debts.push(debt);
      }
    }
  });
  return { assets, debts } as {
    assets: Partial<AssetAllocations>;
    debts: Partial<DebtAllocations>;
  };
};

const checkImplementedAndGetAllocations = (
  plan: Plan,
  liabilities: PlanLiabilities,
  debtObligations: any,
  isMarried: boolean,
  planIncome: number,
  my401kIncome: number,
  spouse401kIncome: number,
  isImplemented: boolean,
  liveIncome: number
) => {
  return getAllocations(
    plan,
    liabilities,
    debtObligations,
    isMarried,
    isImplemented ? liveIncome : planIncome,
    my401kIncome,
    spouse401kIncome
  );
};

export const getCurrentPlanAllocations = createSelector<any, any, any>(
  [
    getCurrentPlan,
    getLiabilities,
    getDebtObligations,
    getIsMarried,
    currentPlanIncomeTotal,
    getMy401kEligibleIncome,
    getSpouse401kEligibleIncome,
    isCurrentPlanImplemented,
    getLiveIncomeTotal,
  ],
  checkImplementedAndGetAllocations
);

export const getComparePlanAllocations = createSelector<any, any, any>(
  [
    getComparePlan,
    getLiabilities,
    getDebtObligations,
    getIsMarried,
    comparePlanIncomeTotal,
    getMyCompare401kEligibleIncome,
    getSpouseCompare401kEligibleIncome,
  ],
  getAllocations
);
export const getLivePlanAllocations = createSelector<any, any, any>(
  [
    getLivePlan,
    getLiabilities,
    getDebtObligations,
    getIsMarried,
    livePlanIncomeTotal,
    getMyLive401kEligibleIncome,
    getSpouseLive401kEligibleIncome,
    isCurrentPlanImplemented,
    getLiveIncomeTotal,
  ],
  checkImplementedAndGetAllocations
);

export const getDashboardAllocations = createSelector(
  getLivePlanAllocations,
  ({ assets, debts }) => {
    const result: any = {
      assets: {},
      debts: {},
    };
    assets.forEach((item: any) => {
      if (!result.assets[item.type]) {
        result.assets[item.type] = item.annual;
      } else {
        result.assets[item.type] += item.annual;
      }
    });
    debts.forEach((item: any) => {
      if (!result.debts[item.type]) {
        result.debts[item.type] = item.annual;
      } else {
        result.debts[item.type] += item.annual;
      }
    });
    return result;
  }
);

export const getCurrentPlanStudentAllocations = createSelector(
  [getCurrentPlan, getLiabilities, getIsMarried],
  getStudentAllocations
);

export const getComparePlanStudentAllocations = createSelector(
  [getComparePlan, getLiabilities, getIsMarried],
  getStudentAllocations
);

export const getLivePlanStudentAllocations = createSelector(
  [getLivePlan, getLiabilities, getIsMarried],
  getStudentAllocations
);

const getPlanStartYear = (projection: PlanProjection | null) => {
  if (!projection || !projection.start) {
    return new Date().getFullYear();
  }
  return +projection.start.slice(0, 4);
};

const getPlanStartMonth = (projection: PlanProjection | null) => {
  if (!projection || !projection.start) {
    return new Date().getMonth() + 1;
  }
  return +projection.start.slice(5, 7);
};

const getCurrentPlanStartYear = createSelector(
  getPlanProjection,
  getPlanStartYear
);
export const getLivePlanStartYear = createSelector(
  getLiveProjection,
  getPlanStartYear
);
export const getLivePlanStartMonth = createSelector(
  getLiveProjection,
  getPlanStartMonth
);

const getStudentYearlySummary = (
  { assets, debts }: any,
  { funding, expenses: educationExpenses }: any,
  { expenses, risks }: any,
  taxes: any,
  startYear: number,
  graduationDate: [number, number]
) => {
  const [graduationYear, graduationMonth] = graduationDate;
  let monthsRemainingInYear = 12 - now.getMonth();
  if (nowYear === graduationYear) {
    monthsRemainingInYear = graduationMonth - now.getMonth();
  }
  const years: number[] = range(startYear, graduationYear + 1);
  const output: any = {
    funding: { keys: new Set<string>([]) },
    educationExpenses: { keys: new Set<string>([]) },
    expenses: {},
    assets: {},
    debts: {},
    risks: {},
    remaining: {},
  };

  for (let i = 0; i < years.length; i++) {
    const year = "" + years[i];
    output.expenses[year] = { total: 0 };
    output.assets[year] = { total: 0 };
    output.debts[year] = { total: 0 };
    output.risks[year] = { total: 0 };
    output.funding[year] = { total: 0 };
    output.educationExpenses[year] = { total: 0 };
  }
  funding.forEach((item: any) => {
    output.funding.keys.add(item.type);
    let year = "" + item.year;
    if (year === "N/A") {
      year = "" + nowYear;
    }
    let amount = item.amount;
    if (FUNDING_INCOME_TYPES[item.type as keyof typeof FUNDING_INCOME_TYPES]) {
      if (+item.year === nowYear) {
        amount = Math.round((amount * monthsRemainingInYear) / 12);
      } else if (+item.year === graduationYear) {
        amount = Math.round((amount * Math.min(12, graduationMonth)) / 12);
      }
    }
    if (item.type === "401k_value" || item.type === "roth_401k_value") {
      amount = Math.round(amount * 0.9);
    }
    if (!output.funding[year]) {
      output.funding[year] = { total: 0 };
    }
    if (!output.funding[year][item.type]) {
      output.funding[year][item.type] = 0;
    }
    output.funding[year].total += amount;
    output.funding[year][item.type] += amount;
  });
  educationExpenses.forEach((item: any) => {
    output.educationExpenses.keys.add(item.type);
    const year = item.year;
    if (!output.educationExpenses[year]) {
      output.educationExpenses[year] = { total: 0 };
    }
    if (!output.educationExpenses[year][item.type]) {
      output.educationExpenses[year][item.type] = 0;
    }
    output.educationExpenses[year].total += item.amount;
    output.educationExpenses[year][item.type] += item.amount;
  });
  for (let i = 0; i < years.length; i++) {
    const year = "" + years[i];
    expenses.forEach((expense: any) => {
      if (!output.expenses[year][expense.type]) {
        output.expenses[year][expense.type] = 0;
      }
      let amount = expense.annual;
      if (+year === nowYear) {
        amount = Math.round((amount * monthsRemainingInYear) / 12);
      } else if (+year === graduationYear) {
        amount = Math.round((amount * Math.min(12, graduationMonth)) / 12);
      }
      output.expenses[year][expense.type] += amount;
      output.expenses[year].total += amount;
    });
    const monthlyTaxes = taxes?.[year] || 0;
    output.expenses[year].tax = monthlyTaxes * 12;
    if (+year === nowYear) {
      output.expenses[year].tax = monthlyTaxes * monthsRemainingInYear;
    } else if (+year === graduationYear) {
      output.expenses[year].tax = monthlyTaxes * Math.min(12, graduationMonth);
    }
    output.expenses[year].total += output.expenses[year].tax;
    risks.forEach((risk: any) => {
      if (!output.risks[year][risk.type]) {
        output.risks[year][risk.type] = 0;
      }
      let amount = risk.annual;
      if (+year === nowYear) {
        amount = Math.round((amount * monthsRemainingInYear) / 12);
      } else if (+year === graduationYear) {
        amount = Math.round((amount * Math.min(12, graduationMonth)) / 12);
      }
      output.risks[year][risk.type] += amount;
      output.risks[year].total += amount;
    });
    assets.forEach((asset: any) => {
      if (!output.assets[year][asset.type]) {
        output.assets[year][asset.type] = 0;
      }
      let amount = asset.amount;
      if (+year === nowYear) {
        amount = Math.round((amount * monthsRemainingInYear) / 12);
      } else if (+year === graduationYear) {
        amount = Math.round((amount * Math.min(12, graduationMonth)) / 12);
      }
      output.assets[year][asset.type] += amount;
      output.assets[year].total += amount;
    });
    debts.forEach((debt: any) => {
      if (!output.debts[year][debt.type]) {
        output.debts[year][debt.type] = 0;
      }
      let amount = debt.amount;
      if (+year === nowYear) {
        amount = Math.round((amount * monthsRemainingInYear) / 12);
      } else if (+year === graduationYear) {
        amount = Math.round((amount * Math.min(12, graduationMonth)) / 12);
      }
      output.debts[year][debt.type] += amount;
      output.debts[year].total += amount;
    });
    const carryover = i ? output.remaining["" + years[i - 1]] : 0;
    output.remaining[year] =
      carryover +
      output.funding[year].total -
      output.educationExpenses[year].total -
      output.expenses[year].total -
      output.risks[year].total -
      output.assets[year].total -
      output.debts[year].total;
  }
  return output;
};

export const getCurrentPlanYearlySummary = createSelector<any, any, any>(
  [
    getCurrentPlanStudentAllocations,
    getCurrentPlanEducationDetails,
    getCurrentPlanCashflows,
    getStudentTax,
    getCurrentPlanStartYear,
    getLastGraduationYearMonth,
  ],
  getStudentYearlySummary
);

export const getActivePlanYearlySummary = createSelector<any, any, any>(
  [
    getLivePlanStudentAllocations,
    getLivePlanEducationDetails,
    getLivePlanCashflows,
    getLiveStudentTax,
    getLivePlanStartYear,
    getLastGraduationYearMonth,
  ],
  getStudentYearlySummary
);

export const getFormattedAllocations = createSelector<any, any, any>(
  [getCurrentPlanAllocations],
  ({ assets, debts }) => {
    const formattedAssets: any[] = [];
    forOwn(assets, (item: any) => {
      const monthly = Math.round(item.annual / 12);
      formattedAssets.push({
        annual: item.annual,
        monthly,
        percent: item.percent,
        type: item.type,
        who: item.who,
        whoLabel: renderWho(item.who),
        typeLabel:
          ALLOCATIONS_TYPES[item.type as keyof typeof ALLOCATIONS_TYPES]
            ?.label || item.type,
      });
    });

    const formattedDebts: any[] = [];
    forOwn(debts, (item: any) => {
      const monthly = Math.round(item.annual / 12);
      formattedDebts.push({
        ...item,
        monthly,
        whoLabel: renderWho(item.who),
        typeLabel:
          ALLOCATIONS_TYPES[item.type as keyof typeof ALLOCATIONS_TYPES]
            ?.label || item.type,
      });
    });
    return { formattedAssets, formattedDebts };
  }
);

export const getFormattedStudentAllocations = createSelector(
  [getCurrentPlanStudentAllocations, getSpouseInSchool, getUserInSchool],
  ({ assets, debts }, spouseInSchool, userInSchool) => {
    const formattedAssets: any[] = [];
    forOwn(assets, (item: any) => {
      const annual = item.amount;
      const monthly = Math.round(annual / 12);
      formattedAssets.push({
        annual,
        monthly,
        type: item.type,
        who: item.who,
        whoLabel: renderWho(item.who),
        typeLabel:
          ALLOCATIONS_TYPES[item.type as keyof typeof ALLOCATIONS_TYPES]
            ?.label || item.type,
      });
    });

    const formattedDebts: any[] = [];
    forOwn(debts, (item: any) => {
      const annual = item.amount;
      const monthly = Math.round(annual / 12);
      let min = item.min;
      if (item.type === "fed_loan") {
        if (
          (spouseInSchool && item.who === "spouse") ||
          (userInSchool && item.who !== "spouse")
        ) {
          min = 0;
        }
      }
      formattedDebts.push({
        annual,
        monthly,
        min,
        min_annual: min,
        min_monthly: Math.round(min / 12),
        type: item.type,
        who: item.who,
        whoLabel: renderWho(item.who),
        typeLabel:
          ALLOCATIONS_TYPES[item.type as keyof typeof ALLOCATIONS_TYPES]
            ?.label || item.type,
      });
    });
    return { formattedAssets, formattedDebts };
  }
);

const getAllocationTotals = ({ assets, debts }: any) => {
  const reduceFn = (result: number, value: any) =>
    value ? result + value.annual : result;
  const assetsTotal = Object.values(assets).reduce(reduceFn, 0);
  const debtsTotal = Object.values(debts).reduce(reduceFn, 0);
  return { assetsTotal, debtsTotal } as AllocationTotals;
};

const getLiveAllocationTotals = (
  { assets, debts }: any,
  debtObligations: any,
  accountTypes: any
) => {
  const reduceFn = (result: number, value: any) =>
    value ? result + value.annual : result;
  const assetsTotal = Object.values(assets).reduce(reduceFn, 0);
  const debtsByCategory: any = {};
  debts.forEach((debt: any) => {
    if (accountTypes.has(debt.type)) {
      if (!debtsByCategory[debt.type]) {
        debtsByCategory[debt.type] = debt.annual;
      } else {
        debtsByCategory[debt.type] += debt.annual;
      }
    }
  });
  const debtsTotal = Object.keys(debtsByCategory).reduce(
    (result, key) =>
      result + Math.max(debtsByCategory[key], (debtObligations[key] || 0) * 12),
    0
  );
  return { assetsTotal, debtsTotal } as AllocationTotals;
};

const getStudentAllocationTotals = ({ assets, debts }: any) => {
  const reduceFn = (result: number, value: any) =>
    value ? result + value.amount : result;
  const assetsTotal = Object.values(assets).reduce(reduceFn, 0);
  const debtsTotal = Object.values(debts).reduce(reduceFn, 0);
  return { assetsTotal, debtsTotal } as AllocationTotals;
};

export const currentPlanAllocationTotals = createSelector(
  [
    getCurrentPlanAllocations,
    isCurrentPlanImplemented,
    getDebtObligations,
    getAccountTypesWithBalances,
  ],
  (allocations, isImplemented, obligations, accountTypes) => {
    if (isImplemented) {
      return getLiveAllocationTotals(allocations, obligations, accountTypes);
    }
    return getAllocationTotals(allocations);
  }
);
export const comparePlanAllocationTotals = createSelector(
  [getComparePlanAllocations],
  getAllocationTotals
);
export const livePlanAllocationTotals = createSelector(
  [getLivePlanAllocations, getDebtObligations, getAccountTypesWithBalances],
  getLiveAllocationTotals
);
export const currentPlanStudentAllocationTotals = createSelector(
  [getCurrentPlanStudentAllocations],
  getStudentAllocationTotals
);
export const comparePlanStudentAllocationTotals = createSelector(
  [getComparePlanStudentAllocations],
  getStudentAllocationTotals
);
export const livePlanStudentAllocationTotals = createSelector(
  [getLivePlanStudentAllocations],
  getStudentAllocationTotals
);

export const currentPlanCashAfterExpenses = createSelector(
  [currentPlanIncomeTotal, currentPlanExpenseTotal],
  (income, expenses) => income - expenses
);

export const livePlanCashAfterExpenses = createSelector(
  [livePlanIncomeTotal, livePlanExpenseTotal],
  (income, expenses) => income - expenses
);
export const getCurrentPlanSurplus = createSelector(
  [
    currentPlanCashAfterExpenses,
    currentPlanRiskManagementTotal,
    currentPlanAllocationTotals,
  ],
  (cash, risks, { assetsTotal, debtsTotal }) =>
    cash - (assetsTotal + debtsTotal + risks)
);
export const getLivePlanSurplus = createSelector(
  [
    livePlanCashAfterExpenses,
    livePlanRiskManagementTotal,
    livePlanAllocationTotals,
  ],
  (cash, risks, { assetsTotal, debtsTotal }) =>
    cash - (assetsTotal + debtsTotal + risks)
);

export const planProjectionSeries = (
  key: "score" | "asset" | "debt" | "net" | "hc",
  includeHc?: boolean
) => (projection: PlanProjection | null, minimal: PlanProjection | null) => {
  if (!projection) {
    return [];
  }
  let startIndex = 0;
  const startMonth = +projection.start.slice(5);
  const startYear = +projection.start.slice(0, 4);
  const yearDiff = startYear - nowYear;
  startIndex -= yearDiff * 12;
  const monthDiff = startMonth - nowMonth;
  startIndex -= monthDiff;
  let minStartIndex = 0;
  if (minimal?.start) {
    const minStartMonth = +minimal.start.slice(5);
    const minStartYear = +minimal.start.slice(0, 4);
    const minYearDiff = minStartYear - nowYear;
    minStartIndex -= minYearDiff * 12;
    const minMonthDiff = minStartMonth - nowMonth;
    minStartIndex -= minMonthDiff;
  }
  const result = projection.score
    .slice(startIndex)
    .map((point: number, rawIndex: number) => {
      const index = rawIndex + startIndex;
      const minIndex = rawIndex + minStartIndex;
      const output: any = { month: rawIndex };
      if (key === "score") {
        output.score = projection.score[index];
        if (minimal?.score) {
          output.min = minimal.score[minIndex] || 0;
        }
      } else if (key === "net") {
        output[key] =
          projection.series.asset[index] - projection.series.debt[index];
        if (minimal?.series) {
          output.min =
            (minimal.series.asset[minIndex] || 0) -
            (minimal.series.debt[minIndex] || 0);
        }
      } else {
        output[key] = projection.series[key][index];
        if (minimal?.series) {
          output.min = minimal.series[key][minIndex] || 0;
        }
      }
      if (includeHc) {
        output.hc = projection.series.hc[index];
      }
      return output;
    });
  return result;
};

export const getCompareProjection = createSelector(
  [getPlanBuildState, getComparePlanIndex],
  (state, index) => {
    if (index < 0) {
      return state.minimalProjection;
    }
    return state.savedPlanProjections[index] || null;
  }
);
export const getDashboardScoreProjection = createSelector(
  [getLiveProjection, getOriginalProjection],
  planProjectionSeries("score")
);
export const getDashboardAssetProjection = createSelector(
  [getLiveProjection, getOriginalProjection],
  planProjectionSeries("asset")
);
export const getDashboardDebtProjection = createSelector(
  [getLiveProjection, getOriginalProjection],
  planProjectionSeries("debt")
);
export const getDashboardHcProjection = createSelector(
  [getLiveProjection, getOriginalProjection],
  planProjectionSeries("hc")
);
export const getDashboardNetProjection = createSelector(
  [getLiveProjection, getOriginalProjection],
  planProjectionSeries("net", true)
);
export const getScoreProjection = createSelector(
  [getPlanProjection, getCompareProjection],
  planProjectionSeries("score")
);
export const getAssetProjection = createSelector(
  [getPlanProjection, getCompareProjection],
  planProjectionSeries("asset")
);
export const getDebtProjection = createSelector(
  [getPlanProjection, getCompareProjection],
  planProjectionSeries("debt")
);
export const getNetProjection = createSelector(
  [getPlanProjection, getCompareProjection],
  planProjectionSeries("net", true)
);
export const savedPlanProjectionData = createSelector(
  [getSavedPlanProjections],
  (projections) =>
    projections
      ? projections.map((projection) => {
          if (!projection || !projection.series || !projection.series.asset) {
            return [];
          }
          return projection.series.asset.map((assetValue, index) => ({
            net: assetValue - projection.series.debt[index],
            month: index,
          }));
        })
      : []
);

export const getPlanFutureProjections = (projection: PlanProjection | null) => {
  const score: any = [];
  const assets: any = [];
  const debts: any = [];
  const net: any = [];
  const hc: any = [];
  const assetKeys: any = new Set<string>([]);
  const debtKeys: any = new Set<string>([]);
  const output: any = {
    score,
    assets,
    debts,
    net,
    hc,
    assetKeys,
    debtKeys,
  };
  if (!projection) {
    return output;
  }
  const timeframes: any = ["now", "soon", "later", "retirement"];
  timeframes.forEach(
    (timeframe: "soon" | "later" | "retirement", index: number) => {
      const planTimeframe = projection.summary[timeframe] || {};
      const planAssets = planTimeframe.assets || {};
      const planDebts = planTimeframe.debts || {};
      forOwn(planAssets, (value, key) => {
        if (value) {
          assetKeys.add(key);
        }
      });
      forOwn(planDebts, (value, key) => {
        if (value) {
          debtKeys.add(key);
        }
      });
      hc[index] = planTimeframe.hc;
      score[index] = planTimeframe.score;
      assets[index] = pickBy(planAssets);
      debts[index] = pickBy(planDebts);
      net[index] = planAssets.total + planTimeframe.hc - planDebts.total;
    }
  );
  return output;
};

export const getFutureProjections = (
  projection: PlanProjection | null,
  minimal: PlanProjection | null
) => {
  interface MinPlan {
    min: number;
    plan: number;
  }
  const score: MinPlan[] = [];
  const assets: Array<{
    min: { [assetType: string]: number };
    plan: { [assetType: string]: number };
  }> = [];
  const debts: Array<{
    min: { [debtType: string]: number };
    plan: { [debtType: string]: number };
  }> = [];
  const net: MinPlan[] = [];
  const hc: MinPlan[] = [];
  const assetKeys = new Set<string>([]);
  const debtKeys = new Set<string>([]);
  if (!projection || !minimal) {
    return { score, assets, debts, hc, net, assetKeys, debtKeys };
  }

  const timeframes: any = ["now", "soon", "later", "retirement"];
  let retirementYear = "";
  timeframes.forEach(
    (timeframe: "soon" | "later" | "retirement", index: number) => {
      const planTimeframe = projection.summary[timeframe] || {};
      const minTimeframe = minimal?.summary?.[timeframe] || {};
      const planAssets = planTimeframe.assets || {};
      const minAssets = minTimeframe.assets || {};
      const planDebts = planTimeframe.debts || {};
      const minDebts = minTimeframe.debts || {};
      forOwn(planAssets, (value, key) => {
        if (value) {
          assetKeys.add(key);
        }
      });
      forOwn(minAssets, (value, key) => {
        if (value) {
          assetKeys.add(key);
        }
      });
      forOwn(planDebts, (value, key) => {
        if (value) {
          debtKeys.add(key);
        }
      });
      forOwn(minDebts, (value, key) => {
        if (value) {
          debtKeys.add(key);
        }
      });
      if (timeframe === "retirement") {
        retirementYear = planTimeframe?.date?.slice(0, 4) || "1999";
      }
      hc[index] = { min: minTimeframe.hc, plan: planTimeframe.hc };
      score[index] = { min: minTimeframe.score, plan: planTimeframe.score };
      assets[index] = { min: pickBy(minAssets), plan: pickBy(planAssets) };
      debts[index] = { min: pickBy(minDebts), plan: pickBy(planDebts) };
      net[index] = {
        min: minAssets.total + minTimeframe.hc - minDebts.total,
        plan: planAssets.total + planTimeframe.hc - planDebts.total,
      };
    }
  );
  return { score, assets, debts, net, hc, assetKeys, debtKeys, retirementYear };
};

export const getCompareFutureProjections = createSelector(
  [getPlanProjection, getCompareProjection],
  getFutureProjections
);

export const getMinFutureProjections = createSelector(
  [getPlanProjection, getMinimalProjection],
  getFutureProjections
);

export const currentPlanFutureProjections = createSelector(
  [getPlanProjection],
  getPlanFutureProjections
);

export const getLifeEventProjections = createSelector(
  [getPlanProjection, getMinimalProjection],
  (projection: PlanProjection | null, minimal: PlanProjection | null) => {
    if (!projection || !minimal) {
      return [];
    }
    return projection.goal.map((item, index) => ({
      type: item.type,
      min: minimal.goal?.[index]?.date || "",
      plan: item.date,
      who: item.who || "",
    }));
  }
);

export const getCompareLifeEventProjections = createSelector(
  [getPlanProjection, getCompareProjection],
  (projection: PlanProjection | null, compare: PlanProjection | null) => {
    if (!projection || !compare) {
      return { plan1Events: [], plan2Events: [] };
    }
    return {
      plan1Events: projection.goal.map((item) => ({
        type: item.type,
        plan: item.date,
        who: item.who || "",
      })),
      plan2Events: compare.goal.map((item) => ({
        type: item.type,
        plan: item.date,
        who: item.who || "",
      })),
    };
  }
);

export const getLiveLifeEventProjections = createSelector(
  [getLiveProjection],
  (projection: PlanProjection | null) => {
    if (!projection) {
      return [];
    }
    return projection.goal.map((item) => ({
      type: item.type,
      plan: item.date,
      who: item.who || "",
    }));
  }
);

export const getPlanIsDirty = createSelector(
  getPlanBuildState,
  (state) => state.dirty
);

const GOAL_TYPE_TO_ACCOUNT_TYPE_MAPPING: any = {
  fedloanpayoff: "fed_loan",
  privloanpayoff: "priv_loan",
  perkinsloanpayoff: "perkins_loan",
};

export const getFormattedGoalData = createSelector(
  [
    getCurrentPlan,
    getPlanProjection,
    getAccounts,
    userSelector,
    spouseSelector,
    getStudentLoanRates,
    getIsMarried,
    isCurrentPlanImplemented,
  ],
  (
    plan,
    projection,
    accounts,
    user,
    spouse,
    studentLoanRates,
    isMarried,
    isImplemented
  ) => {
    if (!plan.goals) {
      return [];
    }
    let willBeMarried = false;
    return plan.goals.map((goal) => {
      const spouseFirstName = spouse?.first_name || "";
      const userFirstName = user?.first_name || "";
      const lifeEvent: any = goal.lifeevent_id
        ? plan.lifeevents.find((event) => event.id === goal.lifeevent_id)
        : null;
      const eventDefinition = lifeEvent
        ? GRADUATED_LIFE_EVENTS[lifeEvent.eventtype]
        : null;
      const account = goal.account_id
        ? accounts.find((found) => found.id === goal.account_id)
        : null;
      const accountType = GOAL_TYPE_TO_ACCOUNT_TYPE_MAPPING[goal.goaltype];
      const loanDetail = accountType
        ? studentLoanRates?.[goal.who || ""]?.[accountType]
        : null;
      const name = goal.who === "spouse" ? spouseFirstName : userFirstName;
      let displayName = isMarried || willBeMarried ? `${name}'s ` : "";
      if (!isMarried && goal.who === "spouse") {
        displayName = "fiance's ";
      }
      const output: any = {
        ...goal,
        balance: 0,
        rate: 0,
        title: "",
        savingsGoal: 0,
        date: lifeEvent?.date || "",
        eventType: lifeEvent?.type || "",
      };
      if (
        !isMarried &&
        goal.who === "spouse" &&
        lifeEvent?.eventtype === "marriage"
      ) {
        willBeMarried = true;
        if (goal.goaltype === "fedloanpayoff") {
          output.balance = (lifeEvent as Marriage).spouseFedLoanBalance;
        } else if (goal.goaltype === "privloanpayoff") {
          output.balance = (lifeEvent as Marriage).spousePrivLoanBalance;
        }
      }
      if (lifeEvent?.eventtype === "vacation") {
        output.recurring = lifeEvent?.recurring;
      }
      const taxBombRecord = (projection as any)?.taxbomb?.find(
        (item: any) => item.who === goal.who
      );
      switch (goal.goaltype) {
        case "payoff":
          if (account) {
            output.title = `Pay off ${account.name}`;
            output.balance =
              (isImplemented
                ? account.origination_principal_amount
                : account.balance) || 0;
            output.rate = account.rate;
          } else if (lifeEvent) {
            output.title = eventDefinition?.payoffGoalLabel || "";
            output.balance = lifeEvent.cost - lifeEvent.down;
          }
          break;
        case "down":
          if (lifeEvent) {
            output.title =
              eventDefinition?.goalLabel || eventDefinition?.typeLabel;
            output.savingsGoal = lifeEvent.down || 0;
          }
          break;
        case "fedloanpayoff":
          output.title = `Pay off ${displayName}Federal student loans`;
          if (!output.balance) {
            output.balance = loanDetail?.balance || 0;
          }
          output.rate = loanDetail?.rate || 0;
          break;
        case "privloanpayoff":
          output.title = `Pay off ${displayName}private student loans`;
          if (!output.balance) {
            output.balance = loanDetail?.balance || 0;
          }
          output.rate = loanDetail?.rate || 0;
          break;
        case "perkinsloanpayoff":
          output.title = `Pay off ${displayName}Perkins student loans`;
          output.balance = loanDetail?.balance || 0;
          output.rate = loanDetail?.rate || 0;
          break;
        case "taxbomb":
          output.title = `Save for ${displayName}IDR tax`;
          output.balance = taxBombRecord?.amount || 0;
          break;
        case "emergencyfund":
          output.title = "Save for emergency fund";
          break;
        case "invest":
          output.title = "Prioritize investing";
          break;
        default:
          break;
      }
      return output;
    });
  }
);
