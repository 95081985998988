import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiBasicTable,
  EuiIcon,
  EuiButtonIcon,
} from "@elastic/eui";
import { Global, css } from "@emotion/react";
import styled from "@emotion/styled";

import {
  getNetProjection,
  currentPlanFutureProjections,
} from "src/store/planBuild/selector";
import { formatWholeDollars } from "src/utils";
import Chart from "src/components/ChartV2";
import { StyledSpacer } from "src/components/Global/StyledComponents";
import { useFlyout } from "src/components/Global/FlyoutContext";
import useWindowSize from "src/hooks/useWindowSize";
import bot from "src/assets/svgs/bot.svg";
import { colors } from "src/theme";

import { TableWrapper } from "./PayCheckAllocation";
import { reviewStyles } from "./GoalSummary";
import { chartLayoutStyle } from "./AssetsInvestments";

const StyledBox = styled.div`
  background-color: ${colors.backgroundInfo};
  padding: 12px 16px;
  margin-bottom: 16px;
  span {
    text-decoration: underline;
  }
  p {
    font-size: 16px;
    line-height: 24px;
    font-family: "Inter";
    margin-bottom: 1rem;
  }
`;

const TitleText = styled.h4`
  color: ${colors.info} !important;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

const NetWealth = () => {
  const netProjection = useSelector(getNetProjection);
  const future = useSelector(currentPlanFutureProjections);
  const [showBreakdown, setShowBreakdown] = useState(false);
  const { toggleFlyout } = useFlyout();
  const toggleBreakdown = () => setShowBreakdown((current) => !current);
  const windowSize = useWindowSize();
  const isStacked = windowSize.width <= 989;
  const isMobile = windowSize.width <= 567;
  const isSticky = windowSize.width >= 989;
  const now = new Date();
  const nowYear = now.getFullYear();
  const years = ["Now", "" + (nowYear + 5), "" + (nowYear + 10), "" + future.retirementYear].filter((year) => year);
  console.log('retirementYear', future.retirementYear);

  const formattedData = useMemo(() => {
    const totalRow = {
      toggle: true,
      [years[0]]: formatWholeDollars(future.net[0]),
      [years[1]]: formatWholeDollars(future.net[1]),
      [years[2]]: formatWholeDollars(future.net[2]),
      [years[3]]: formatWholeDollars(future.net[3]),
      type: "",
    };
    const output: any[] = [totalRow];
    if (showBreakdown) {
      output.push(
        {
          type: "Human Capital",
          [years[0]]: formatWholeDollars(future.hc[0]),
          [years[1]]: formatWholeDollars(future.hc[1]),
          [years[2]]: formatWholeDollars(future.hc[2]),
          [years[3]]: formatWholeDollars(future.hc[3]),
        },
        {
          type: "Financial Assets",
          [years[0]]: formatWholeDollars(future.assets[0].total),
          [years[1]]: formatWholeDollars(future.assets[1].total),
          [years[2]]: formatWholeDollars(future.assets[2].total),
          [years[3]]: formatWholeDollars(future.assets[3].total),
        },
        {
          type: "(Less) Debt",
          [years[0]]: formatWholeDollars(future.debts[0].total),
          [years[1]]: formatWholeDollars(future.debts[1].total),
          [years[2]]: formatWholeDollars(future.debts[2].total),
          [years[3]]: formatWholeDollars(future.debts[3].total),
        }
      );
    }
    return output;
  }, [showBreakdown, future]);

  const columns = [
    {
      field: "type",
      name: "",
      render: (value: any) => {
        if (!value) {
          return (
            <div onClick={toggleBreakdown} className="cursor-pointer">
              <EuiIcon
                type={showBreakdown ? "arrowUp" : "arrowDown"}
                className="mr-3"
                style={{ cursor: "pointer", fill: "#0077CC" }}
              />
              <span className="custom-header">Total if plan followed</span>
            </div>
          );
        }
        if (["Human Capital", "Financial Assets", "(Less) Debt"].includes(value)) {
          return (
            value === "Human Capital" ? (
              <div className="custom-header ai-sub-header">
                {value}
                <EuiButtonIcon
                  onClick={() => toggleFlyout("human-capital")}
                  iconType="questionInCircle"
                  aria-label="Help"
                  className="help-icon"
                  style={{ marginBottom: '8px', height: '16px' }}
                />
              </div>
            ) : (
              <div className="custom-header ai-sub-header">{value}</div>
            )
          );
        }
        return value;
      },
    },
    ...years.map((year) => ({
      field: year,
      name: year,
      width: isMobile ? "110px" : "",
      render: (value: any, item: any) => {
        if (item.type === "") {
          return <span className="ai-header-content">{value}</span>;
        }
        if (
          ["Human Capital", "Financial Assets", "(Less) Debt"].includes(
            item.type
          )
        ) {
          return <span className="ai-sub-content">{value}</span>;
        }
        return value;
      },
    })),
  ];

  return (
    <>
      <Global
        styles={css`
          ${reviewStyles}
          ${chartLayoutStyle}
        `}
      />
      <div className="ai-content-title">
        <h1>Net Wealth Summary</h1>
      </div>
      <StyledSpacer size="24px" />
      <EuiFlexGroup direction={isStacked ? "column" : "row"} alignItems="flexStart">
        <EuiFlexItem style={isStacked ? { width: "100%" } : { flex: 'auto', width: '60%' }}>
          <EuiCard title="" className="ai-card" style={{ width: '100%', height: 'auto' }}>
            <div className="ai-card-title">
              <h1>Projected Total Net Wealth</h1>
            </div>
            <Chart
              dollars
              noTitlePadding
              centerTitle
              colors={[colors.brandingBlue1, colors.warn]}
              data={netProjection}
              height={320}
              keys={["net", "hc"]}
              xKey="month"
              keyLabels={{
                net: "Plan Followed",
                hc: "Human Capital",
              }}
              width="100%"
            />
          </EuiCard>
        </EuiFlexItem>
        <EuiFlexItem grow={2} style={{ minWidth: '35%' }}>
          <StyledBox>
            <EuiFlexGroup className="items-center no-wrap" gutterSize="s">
              <EuiFlexItem grow={0} className="align-l" style={{inlineSize: 'auto', flexBasis: 'auto'}}>
                <img height={33} src={bot} />
              </EuiFlexItem>
              <EuiFlexItem grow={2} className="align-l">
                <TitleText>Maximize Your Tangible Net Wealth</TitleText>
              </EuiFlexItem>
            </EuiFlexGroup>
            <StyledSpacer size="16px" />
            <EuiText>
              <p>
                When we are young, most of our net wealth is intangible and made
                up of our human capital. Over time, your human capital is
                converted into a tangible asset in the form of income. Income is
                then used to pay off debt or invested to grow assets.
              </p>
            </EuiText>
            <StyledSpacer size="16px" />
            <EuiText>
              <p>
                One of your top goals is to maximize your tangible net wealth
                (i.e., the sum of your assets less your debts).
              </p>
            </EuiText>
            <StyledSpacer size="16px" />
            <EuiText>
              <p>
                This chart shows how likely you are to convert your human
                capital to tangible net wealth.
              </p>
            </EuiText>
          </StyledBox>
        </EuiFlexItem>
      </EuiFlexGroup>
      <StyledSpacer size="24px" />
      <EuiCard title="" className="ai-card">
        <div className="ai-card-title">
          <h1>Net Wealth</h1>
        </div>
        <TableWrapper>
          <EuiBasicTable
            responsive={false}
            tableLayout="fixed"
            className={`${isSticky ? 'table-border' : ''} ai-table eg-table`}
            items={formattedData}
            columns={columns}
            rowProps={(item: any) => {
              if (["Human Capital", "Financial Assets", "(Less) Debt"].includes(item.type)) {
                return { style: { backgroundColor: '#FAFBFD' } };
              }
              return {};
            }}
          />
        </TableWrapper>
      </EuiCard>
      <StyledSpacer size="16px" />
    </>
  );
};

export default NetWealth;
