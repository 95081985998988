import React from "react";
import { useSelector } from "react-redux";

import { EuiFlexGroup, EuiFlexItem, EuiText } from "@elastic/eui";
import { getMinFutureProjections } from "src/store/planBuild/selector";
import { getProfile } from "src/store/profileBuild/selector";
import styled from "@emotion/styled";
import bot from "src/assets/svgs/bot.svg";
import { colors } from "src/theme";

import { StyledSpacer } from "src/components/Global/StyledComponents";

const StyledBox = styled.div`
  background-color: ${colors.backgroundInfo};
  padding: 12px 16px;
  margin-bottom: 16px;
  span {
    text-decoration: underline;
  }
  p {
    font-size: 16px;
    line-height: 24px;
    font-family: "Inter";
    margin-bottom: 1rem;
  }
`;

const TitleText = styled.h4`
  color: ${colors.info} !important;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

const ScoreFactors = () => {
  const future = useSelector(getMinFutureProjections);
  // [ now, 5 years, 10 years, retirement ]
  const RETIREMENT_INDEX = 3;
  const retirementScore =
    future.score.length > RETIREMENT_INDEX
      ? future.score[RETIREMENT_INDEX].plan
      : 0;

  const profile = useSelector(getProfile);
  const dobYear = profile.dob_year;
  const retirementYear = Number(future.retirementYear);
  const retirementAge = retirementYear - dobYear;

  return (
    <>
      <StyledBox>
        <EuiFlexGroup className="items-center no-wrap" gutterSize="s" justifyContent="flexStart">
          <EuiFlexItem grow={0} className="align-l" style={{inlineSize: 'auto', flexBasis: 'auto'}}>
            <img height={33} src={bot} />
          </EuiFlexItem>
          <EuiFlexItem grow={2} className="align-l">
            <TitleText>Understanding Your Score</TitleText>
          </EuiFlexItem>
        </EuiFlexGroup>
        <StyledSpacer size="16px" />
        <EuiText>
          <span>Short-Term</span>
          <p>
            Your current FitBUX Score means that you have significant risk in
            your financial plan. By following this optimized plan, you can
            significantly reduce that risk over the next 10 years.
          </p>
        </EuiText>
        <StyledSpacer size="16px" />
        <EuiText>
          <span>Long-Term</span>
          <p>
            If you implement and follow this plan, you'll have, approximately,
            a(n) {retirementScore / 10}% probability of retiring at age{" "}
            {retirementAge} with enough money to last you through retirement.
          </p>
        </EuiText>
      </StyledBox>
    </>
  );
};

export default ScoreFactors;
