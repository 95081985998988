import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  EuiDragDropContext,
  EuiDraggable,
  EuiDroppable,
  EuiText,
  EuiSpacer,
} from "@elastic/eui";

import { PlanViewComponent } from "src/interfaces";
import { getAssetBalanceTotals } from "src/store/account/selector";
import { savePlan, updateCurrentPlan } from "src/store/planBuild/actions";
import { PLAN_BUILD_STEPS } from "src/store/planBuild/constants";
import {
  currentPlanAllocationTotals,
  currentPlanExpenseTotal,
  currentPlanRiskManagementTotal,
  getCurrentPlan,
  getFormattedGoalData,
} from "src/store/planBuild/selector";
import { usePlanUtils } from "src/store/planBuild/util";
import CenterContainer from "../../Components/CenterContainer";
import GoalCard from "./GoalCard";
import GoalMessage from "./GoalMessage";

const Goals: PlanViewComponent = ({ render }) => {
  const dispatch = useDispatch();
  const plan: any = useSelector(getCurrentPlan);
  const expenseTotal = useSelector(currentPlanExpenseTotal);
  const riskTotal = useSelector(currentPlanRiskManagementTotal);
  const { debtsTotal } = useSelector(currentPlanAllocationTotals);
  const assetBalanceTotals = useSelector(getAssetBalanceTotals);
  const goals = useSelector(getFormattedGoalData);
  let runningCashTotal = assetBalanceTotals.cash_value || 0;
  const totalPerMonth = expenseTotal + riskTotal + debtsTotal;
  const emergencyFundTarget = totalPerMonth * plan.emergencymonths;
  const emergencyFundRequired = emergencyFundTarget > runningCashTotal;
  runningCashTotal -= emergencyFundTarget;
  const noCashAllocation = !plan.allocations[0].cash_value;
  const { syncGoals } = usePlanUtils();

  useEffect(() => {
    const newGoals = syncGoals();
    dispatch(updateCurrentPlan({ goals: newGoals }));
  }, []);

  const onNext = () => {
    dispatch(savePlan(PLAN_BUILD_STEPS.RECOMMENDATIONS));
  };

  const reorderGoals = (sourceIndex: number, destinationIndex: number) => {
    const newGoals = plan.goals.slice(1);
    const sourceItem = newGoals.splice(sourceIndex, 1);
    newGoals.splice(destinationIndex, 0, sourceItem[0]);
    dispatch(updateCurrentPlan({ goals: [plan.goals[0], ...newGoals] }));
  };

  const onDragEnd = ({ source, destination }: any) =>
    reorderGoals(source.index, destination.index);

  const moveGoalUp = (index: number) => reorderGoals(index, index - 1);
  const moveGoalDown = (index: number) => reorderGoals(index, index + 1);

  let firstSavingGoalNotMet = noCashAllocation && !emergencyFundRequired;

  return render({
    component: (
      <CenterContainer
        title="Goal Ranking"
        iconName="fb-event"
        scrollable={true}
      >
        <EuiSpacer />
        <EuiText>
          The key to achieving your financial goals is to focus on one
          short-term goal at a time. Drag and drop your goals below to rank them
          accordingly.
        </EuiText>
        <EuiSpacer />
        {emergencyFundRequired && (
          <GoalCard
            goal={{
              title: "Save for emergency fund",
              id: 1,
              savingsGoal: emergencyFundTarget,
            }}
            index={0}
            message={
              <>
                <GoalMessage
                  level="info"
                  contentKey="emergencyFund"
                  goal={{}}
                />
                {noCashAllocation && (
                  <GoalMessage
                    level="warning"
                    contentKey="noCashAllocation"
                    goal={{}}
                  />
                )}
              </>
            }
          />
        )}
        <EuiDragDropContext onDragEnd={onDragEnd}>
          <EuiDroppable droppableId="goals-list" spacing="m">
            {goals.slice(1).map((goal: any, index: number) => {
              let message: any = undefined;
              switch (goal.goaltype) {
                case "down":
                  if (firstSavingGoalNotMet) {
                    firstSavingGoalNotMet = false;
                    message = (
                      <GoalMessage
                        level="warning"
                        contentKey="noCashAllocation"
                        goal={{}}
                      />
                    );
                  } else {
                    runningCashTotal -= goal.savingsGoal || 0;
                    if (runningCashTotal > 0) {
                      message = (
                        <GoalMessage
                          level="success"
                          contentKey="savingsAchieved"
                          goal={{}}
                        />
                      );
                    }
                  }
                  break;
                case "payoff":
                  firstSavingGoalNotMet = false;
                  runningCashTotal -= goal.balance;
                  if (goal.lifeevent_id) {
                    message = (
                      <GoalMessage
                        level="info"
                        contentKey="futureDebt"
                        goal={goal}
                      />
                    );
                  }
                  break;
                case "fedloanpayoff":
                case "privloanpayoff":
                case "perkinsloanpayoff":
                  firstSavingGoalNotMet = false;
                  runningCashTotal -= goal.balance;
                  break;
                default:
                  firstSavingGoalNotMet = false;
                  break;
              }
              return (
                <EuiDraggable
                  spacing="m"
                  key={"" + goal.id}
                  index={index}
                  draggableId={"" + goal.id}
                  customDragHandle
                  hasInteractiveChildren
                  className="w-full"
                >
                  {(providedProps) => (
                    <GoalCard
                      goal={goal}
                      index={index + 1}
                      providedProps={providedProps}
                      moveUp={index > 0 ? () => moveGoalUp(index) : undefined}
                      moveDown={
                        index < plan.goals.length - 1
                          ? () => moveGoalDown(index)
                          : undefined
                      }
                      message={message}
                    />
                  )}
                </EuiDraggable>
              );
            })}
          </EuiDroppable>
        </EuiDragDropContext>
      </CenterContainer>
    ),
    onNext,
  });
};

export default Goals;
