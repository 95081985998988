import React from "react";
import { useSelector } from "react-redux";

import { range } from "lodash";

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiFieldText,
  EuiButtonIcon,
  EuiFormRow,
  EuiRadioGroup,
  EuiSelect,
  EuiSuperSelect,
} from "@elastic/eui";

import { MONTHS, YEARS_FROM_NOW } from "src/constants";

import { StyledSpacer } from "src/components/Global/StyledComponents";
import useWindowSize from "src/hooks/useWindowSize";
import { OR_YOUR_SPOUSES, QuestionForm, SPOUSE_NAME } from "src/interfaces";
import { getProfile, getSpouseProfile } from "src/store/profileBuild/selector";
import { spouseSelector } from "src/store/system/selector";
import { DollarTextField, PercentTextField } from "src/utils";
import { useFlyout } from "src/components/Global/FlyoutContext";

interface QuestionInterface {
  questions: QuestionForm;
  values: any;
  onChange: (newValues: any) => void;
  errors: Set<string>;
  setErrors: (arg: (current: Set<string>) => Set<string>) => void;
  spouseView?: boolean;
}

const Questionnaire = ({
  questions,
  onChange,
  values,
  spouseView,
  errors,
  setErrors,
}: QuestionInterface) => {
  const myProfile = useSelector(getProfile);
  const spouseProfile = useSelector(getSpouseProfile);
  const spouse = useSelector(spouseSelector);
  const updateFields = values;
  const windowSize = useWindowSize();
  const spouseFirstName = spouse?.first_name || "";
  const { toggleFlyout } = useFlyout();

  const updateErrors = (fieldName: string, hasError: boolean) => {
    setErrors((current) => {
      const newErrors = new Set(current);
      if (hasError) {
        newErrors.add(fieldName);
      } else {
        newErrors.delete(fieldName);
      }
      return newErrors;
    });
  };

  const validateField = (e: any) => {
    updateErrors(e.target.name, !e.target.value);
    questions.fields.forEach((item) => {
      if (item.enabled && item.enabled.includes(e.target.name)) {
        updateErrors(item.field, false);
      }
    });
  };

  const setFormValue = (e: React.ChangeEvent<any>) => {
    const field = e.target.name;
    let value = e.target.value;
    if (e.target.type === "number") {
      value = +value;
    }
    const update = { ...values, [field]: value };
    if (field === "enable_reduce" && value === "y") {
      update.reduce = "applicant";
      update.salary = 0;
    }
    if (value) {
      updateErrors(field, false);
    }
    onChange(update);
  };

  const setMonthlyValue = (e: React.ChangeEvent<any>) => {
    const field = e.target.name;
    const value = 12 * +e.target.value;
    const update = { ...updateFields, [field]: value };
    if (value) {
      updateErrors(field, false);
    }
    onChange(update);
  };

  const renderRetirementAgeItems = () => {
    const profile = updateFields.who === "spouse" ? spouseProfile : myProfile;
    const dobYear = (profile as any).dob_year;
    const currentAge = new Date().getFullYear() - dobYear;
    const ages = range(currentAge, 121);
    return ages.map((age: number) => ({ value: age, text: "" + age }));
  };

  return (
    <>
      <EuiFlexGroup justifyContent="spaceBetween" className="ai-flex-content">
        <EuiFlexItem>
          <div className="ai-content-title">
            <EuiFlexGroup style={{ gap: 0 }}>
              <EuiFlexItem grow={false}>
                <h1>
                  {spouseView ? `${spouseFirstName}'s ` : ""}
                  {questions.typeLabel}
                </h1>
              </EuiFlexItem>
              {questions.help ? (
                <EuiFlexItem grow={false}>
                  <EuiButtonIcon
                    onClick={() => toggleFlyout("hsa-ai")}
                    iconType="questionInCircle"
                    aria-label="Help"
                    className="help-icon"
                  />
                </EuiFlexItem>
              ) : null}
            </EuiFlexGroup>
          </div>
        </EuiFlexItem>
        {questions.section && (
          <EuiFlexItem className="flex-align">
            <h2 className="ai-secondary-text">
              {windowSize.width > 991
                ? `Section ${questions.section} of 3`
                : `(${questions.section}/3)`}
            </h2>
          </EuiFlexItem>
        )}
      </EuiFlexGroup>
      <StyledSpacer size="32px" />
      <>
        {questions.fields.map((item) => {
          const value = updateFields[item.field] || "";

          if (item.enabled) {
            const hideQuestion = item.enabled.some((enabler, i) => {
              const enablingValues = item.enabling_values
                ? item.enabling_values[i]
                : null;
              const enablersValue = updateFields[enabler];
              const isDisabled =
                enablingValues && enablingValues.indexOf(enablersValue) < 0;
              if (isDisabled || (!enablingValues && enablersValue !== "y")) {
                return true;
              } else {
                return false;
              }
            });
            if (hideQuestion) {
              return null;
            }
          }
          let label = item.label
            .replace(OR_YOUR_SPOUSES, `or ${spouseFirstName}'s`)
            .replace(SPOUSE_NAME, `${spouseFirstName}`);
          if (item.whoText) {
            if (updateFields[item.whoText] !== "spouse") {
              label = label
                .replace("their", "your")
                .replace("they", "you")
                .replace("does your spouse", "do you");
            }
          }

          const labelClass = item.label.length >= 50 ? "full-width-ai" : "";

          let yearString = "";
          let monthString = "";

          function renderContent() {
            switch (item.type) {
              case "monthYear":
                yearString = value.slice(0, 4);
                monthString = value.slice(5);
                if (monthString[0] === "0") {
                  monthString = monthString.slice(1);
                }
                return (
                  <>
                    <EuiFlexGroup>
                      <EuiFlexItem>
                        <EuiSuperSelect
                          options={MONTHS.map((month, index) => ({
                            value: (index + 1).toString(),
                            inputDisplay: month,
                          }))}
                          valueOfSelected={monthString}
                          onChange={(value: string) => {
                            const newMonthString =
                              value.length < 2 ? "0" + value : value;
                            const newDate = `${yearString}-${newMonthString}`;
                            setFormValue({
                              target: { name: item.field, value: newDate },
                            } as any);
                          }}
                          isInvalid={item.required && errors.has(item.field)}
                        />
                      </EuiFlexItem>
                      <EuiFlexItem>
                        <EuiSuperSelect
                          options={YEARS_FROM_NOW.slice(0, 9).map((year) => ({
                            value: year.toString(),
                            inputDisplay: year.toString(),
                          }))}
                          valueOfSelected={yearString}
                          onChange={(value: string) => {
                            const newDate = `${value}-${monthString}`;
                            setFormValue({
                              target: { name: item.field, value: newDate },
                            } as any);
                          }}
                          isInvalid={item.required && errors.has(item.field)}
                        />
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </>
                );
              case "age":
                return (
                  <>
                    <EuiSelect
                      options={/* TODO */ renderRetirementAgeItems()}
                      value={value}
                      name={item.field}
                      placeholder="Select"
                      onChange={setFormValue}
                      isInvalid={item.required && errors.has(item.field)}
                    />
                    <StyledSpacer size="32px" />
                  </>
                );
              case "select":
                return (
                  <>
                    <EuiSelect
                      value={value}
                      name={item.field}
                      placeholder="Select"
                      onChange={setFormValue}
                      options={
                        item.select
                          ? item.select.map(
                            (selectItem: { label: string; value: any }) => ({
                              value: selectItem.value,
                              text: selectItem.label,
                            })
                          )
                          : []
                      }
                      isInvalid={item.required && errors.has(item.field)}
                    />
                    <StyledSpacer size="32px" />
                  </>
                );
              case "radio":
                return (
                  <>
                    <EuiRadioGroup
                      idSelected={`${item.field}_${value}`}
                      name={item.field}
                      options={
                        item.select
                          ? item.select.map(
                            (selectItem: { label: string; value: any }) => ({
                              id: `${item.field}_${selectItem.value}`,
                              label: selectItem.label,
                            })
                          )
                          : []
                      }
                      onChange={(id: any) => {
                        const value = id.replace(`${item.field}_`, "");
                        const event: any = {
                          target: {
                            name: item.field,
                            value,
                          },
                        };
                        setFormValue(event);
                      }}
                    />
                  </>
                );
              case "annualMonthly":
                return (
                  <>
                    <EuiFlexGroup>
                      <EuiFlexItem>
                        <EuiFormRow
                          label="Monthly"
                          helpText="Max: $321"
                        >
                          <DollarTextField
                            fullWidth
                            eui
                            name={item.field}
                            value={Math.round(value / 12)}
                            placeholder="0"
                            onChange={setMonthlyValue}
                          />
                        </EuiFormRow>
                      </EuiFlexItem>
                      <EuiFlexItem>
                        <EuiFormRow
                          label="Annual"
                          helpText="Max: $3,850">
                          <DollarTextField
                            fullWidth
                            eui
                            name={item.field}
                            value={value}
                            placeholder="0"
                            onChange={setFormValue}
                          />
                        </EuiFormRow>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                    <StyledSpacer size="32px" />
                  </>
                );
              case "dollar":
                return (
                  <>
                    <DollarTextField
                      fullWidth
                      eui
                      name={item.field}
                      value={value}
                      placeholder="0"
                      onChange={setFormValue}
                      onBlur={item.required ? validateField : undefined}
                      isInvalid={item.required && errors.has(item.field)}
                    />
                  </>
                );
              case "percent":
                return (
                  <>
                    <PercentTextField
                      eui
                      name={item.field}
                      value={value}
                      placeholder="0"
                      onChange={setFormValue}
                      onBlur={item.required ? validateField : undefined}
                      isInvalid={item.required && errors.has(item.field)}
                    />
                  </>
                );
              case "string":
                return (
                  <>
                    <EuiFieldText
                      name={item.field}
                      value={value}
                      placeholder=""
                      onChange={setFormValue}
                      type="text"
                      onBlur={item.required ? validateField : undefined}
                      isInvalid={item.required && errors.has(item.field)}
                    />
                    <StyledSpacer size="32px" />
                  </>
                );
              case "zip":
                return (
                  <>
                    <EuiFieldText
                      name={item.field}
                      value={value}
                      placeholder=""
                      onChange={setFormValue}
                      type="text"
                      onBlur={item.required ? validateField : undefined}
                      isInvalid={item.required && errors.has(item.field)}
                    />
                    <StyledSpacer size="32px" />
                  </>
                );
              default:
                return <div />;
            }
          }
          return (
            <React.Fragment key={item.field}>
              <EuiFormRow
                label={label}
                isInvalid={item.required && errors.has(item.field)}
                error={
                  item.type === "zip"
                    ? "Please enter a valid ZIP code."
                    : "This field is required."
                }
                className={labelClass}
                helpText={
                  item.type === "percent" || item.type === "string" || item.type === "dollar"
                    ? item.subLabel
                    : undefined
                }
                style={{ maxWidth: "460px" }}
              >
                {renderContent()}
              </EuiFormRow>
              <StyledSpacer size="32px" />
            </React.Fragment>
          );
        })}
      </>
    </>
  );
};

export default Questionnaire;