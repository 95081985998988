import * as React from "react";

import PlanBuilderLayout from "src/layouts/PlanBuilderLayout";
import { Content } from "./Content";

const PlanBuild = () => {
  return (
    <PlanBuilderLayout
      steps={["Build Plan", "Review & Compare", "Implement"]}
      pageTitle="Build a plan"
    >
      <Content />
    </PlanBuilderLayout>
  );
};

export default PlanBuild;
