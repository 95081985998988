import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EuiFlyout, EuiFlyoutHeader, EuiFlyoutBody, EuiFlyoutFooter, EuiButton, EuiText, EuiIcon, EuiTitle } from "@elastic/eui";
import { getBreakoutBaseApi, refreshActionItemsApi } from "src/apiService";
import { AppLoader } from "src/components/Spinners";
import IncomeTransactionList from "src/components/IncomeTransactionList";
import ConfirmIncomeDialog from "src/pages/Dashboard/MyProfile/Transactions/Dialogs/ConfirmIncomeDialog";
import { Transaction, CONFIRMATION_MODAL_STATUS } from "src/interfaces";
import { fetchActionItems } from "src/store/dashboard/actions";
import {
  closeConfirmationModal,
  confirmTransactions,
  getUnconfirmedTransactions,
} from "src/store/transaction/actions";
import {
  getConfirmationModalStatus,
  getLoadingUnconfirmedTransactions,
  getUnconfirmedIncomeAndExpenses,
} from "src/store/transaction/selector";
import { getHasPlan, getIsSubscribed } from "src/store/system/selector";
import { StyledEuiButton, StyledSpacer } from "src/components/Global/StyledComponents";
import { Global, css } from "@emotion/react";
import styled from "@emotion/styled";

export const StyledFlyoutHeader = styled(EuiFlyoutHeader)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const StyledFlyout = styled(EuiFlyout)`
  top: 0;
  position: fixed;
  z-index: 1000;
  .euiFlyout__closeButton {
    display: none;
  }

  @media (max-width: 576px) {
    width: 100vw !important;
    max-inline-size: 100vw !important;
  }

  @media (min-width: 576px) and (max-width: 992px) {
    width: 70vw !important;
    max-inline-size: 70vw !important;
  }
`;
const StyledEuiIcon = styled(EuiIcon)`
  width: 24px;
  height: 24px;
`;
export const StyledEuiFlyoutFooter = styled(EuiFlyoutFooter)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: transparent;
  border-top: 1px solid #e9edf3;
  button {
    font-size: 16px;
  }
`;

const confirmlistStyles = css`
  .transaction-list-group {
    padding: 0 16px;
    justify-content: space-between !important;
    .euiButtonIcon {
      display: block;
    }
    .euiFlexItem {
      flex: 0 1 auto;
    }
    .transaction-label-group {
      color: #343741;
      gap: 16px;
      align-items: center;
      .euiAvatar {
        width: 40px;
        height: 40px;
      }
    }
    .transaction-main-text {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      font-family: "Inter", sans-serif;
    }
    .transaction-secondary-text {
      font-size: 14px;
      line-height: 24px;
      font-family: "Inter", sans-serif;
    }
    .transaction-amount-group {
      align-items: center;
      gap: 8px;
      svg {
        fill: #006BB8;
      }
    }
  }
  .flyout-footer {
    background-color: #fff !important;
    text-align: right;
    border-top: 1px solid #D3DAE6;
    button {
      margin: 0;
    }
  }
  .flyout-footer-transfers {
    padding: 16px 0px 0px 0px !important;
    margin-top: 16px;
    position: absolute;
    bottom: 16px;
    left: 0;
    right: 0;
    button {
      margin: 0 16px 0 0;
    }
  }
  .confirm-flyout {
    .flyout-description {
      font-size: 16px;
      font-family: "Inter", sans-serif;
      line-height: 24px;
      color: #343741;
    }
  }
`;

export const emptyTransaction: Transaction = {
  account: -999,
  amount: -999,
  date: "1999-01-01",
  description: "",
  id: -999,
  type: 7000,
};

const RootConfirmationDialog = () => {
  const dispatch = useDispatch();
  const subscribed = useSelector(getIsSubscribed);
  const hasPlan = useSelector(getHasPlan);
  const activeStatus = useSelector(getConfirmationModalStatus);
  const loading = useSelector(getLoadingUnconfirmedTransactions);
  const { income, transfers } = useSelector(getUnconfirmedIncomeAndExpenses);
  const [confirmedOne, setConfirmedOne] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showFlyout, setShowFlyout] = useState(true);
  const [confirmingTransaction, setConfirmingTransaction] = useState<Transaction>(emptyTransaction);
  const [selectedTransfers, setSelectedTransfers] = useState<Set<number>>(new Set());
  const [title, setTitle] = useState("Welcome Back!");
  const [description, setDescription] = useState<string>("");

  const showLoading = showConfirmDialog || loading;

  if (!subscribed || !hasPlan || activeStatus === CONFIRMATION_MODAL_STATUS.NONE) {
    return null;
  }

  const confirmSelectedTransfers = () => {
    dispatch(confirmTransactions(Array.from(selectedTransfers)));
  };

  const openConfirmDialog = (transaction: Transaction, description: string) => {
    let rootTransaction = transaction;
    let promise = Promise.resolve();
    if (transaction) {
      promise = getBreakoutBaseApi(transaction.id)
        .then((result: any) => {
          rootTransaction = result?.transactions?.[0] || transaction;
        })
        .catch(console.error);
    }
    return promise.then(() => {
      if (rootTransaction) {
        setConfirmingTransaction(rootTransaction);
      } else {
        setConfirmingTransaction(emptyTransaction);
      }
      setShowConfirmDialog(true);
    });
  };

  const refresh = () => {
    dispatch(getUnconfirmedTransactions());
  };

  const sync = () => {
    setConfirmedOne(true);
  };

  const handleSelect = (transaction: Transaction) => {
    setSelectedTransfers((current) => {
      const newSet = new Set(current);
      if (newSet.has(transaction.id)) {
        newSet.delete(transaction.id);
      } else {
        newSet.add(transaction.id);
      }
      return newSet;
    });
  };

  const closeAndConfirmTransfers = () => {
    confirmSelectedTransfers();
    setShowConfirmDialog(false);
    setConfirmedOne(false);
    dispatch(closeConfirmationModal());
  };

  const handleClose = () => {
    confirmSelectedTransfers();
    setShowConfirmDialog(false);
    setConfirmedOne(false);
    dispatch(closeConfirmationModal());
    setShowFlyout(false);
  };

  const IncomeTransactionsContent = () => (
    <>
      <Global styles={confirmlistStyles} />
      <StyledFlyoutHeader hasBorder>
        <EuiTitle size="m">
          <h2>{title}</h2>
        </EuiTitle>
        <StyledEuiIcon
          type="cross"
          onClick={handleClose}
          style={{ cursor: "pointer" }}
        />
      </StyledFlyoutHeader>
      <EuiFlyoutBody>
        {showLoading && (
          <AppLoader />
        )}
        {!showLoading && !!income.length && (
          <>
            <EuiText className="flyout-description">
              <p>Please review and confirm the income transactions below to ensure the accuracy of your plan.</p>
            </EuiText>
            <StyledSpacer size="32px" />
            <IncomeTransactionList
              items={income}
              openConfirmDialog={openConfirmDialog}
            />
          </>
        )}
      </EuiFlyoutBody>
      <EuiFlyoutFooter className="flyout-footer">
        {!!income.length && (
          <StyledEuiButton color="text" onClick={handleClose}>
            I'll do this later
          </StyledEuiButton>
        )}
        {!income.length && (
          <EuiButton
            onClick={() => {
              refreshActionItemsApi().then(() => {
                dispatch(fetchActionItems());
              });
              handleClose();
            }}
          >
            Done
          </EuiButton>
        )}
      </EuiFlyoutFooter>
    </>
  );

  const TransfersContent = () => (
    <>
      <Global styles={confirmlistStyles} />
      <StyledFlyoutHeader hasBorder>
        <EuiTitle size="m">
          <h2>{transfers.length ? "Welcome Back!" : "You're All Done!"}</h2>
        </EuiTitle>
        <StyledEuiIcon
          type="cross"
          onClick={handleClose}
          style={{ cursor: "pointer" }}
        />
      </StyledFlyoutHeader>
      <EuiFlyoutBody>
        {showLoading && <AppLoader />}
        {!showLoading && (
          <>
            {!!transfers.length && (
              <>
                <EuiText className="flyout-description">
                  <p>Please review and confirm the transactions below. If any are retirement/investment contributions or debt payments, please edit and assign to the correct category and transaction type.</p>
                </EuiText>
                <StyledSpacer size="32px" />
                <IncomeTransactionList
                  variableHeight
                  onSelect={handleSelect}
                  selectedIds={selectedTransfers}
                  items={transfers}
                  openConfirmDialog={openConfirmDialog}
                />
              </>
            )}
            <EuiFlyoutFooter className="flyout-footer flyout-footer-transfers">
              {!!transfers.length && (
                <StyledEuiButton color="text" onClick={handleClose} className="btn-spacing">
                  I'll do this later
                </StyledEuiButton>
              )}
              <EuiButton fill color="primary" onClick={handleClose}>
                Done
              </EuiButton>
            </EuiFlyoutFooter>
          </>
        )
        }
      </EuiFlyoutBody >
    </>
  );

  return (
    showFlyout ? (
      <StyledFlyout onClose={handleClose} size="s" className="confirm-flyout" isOpen={showFlyout}>
        {showConfirmDialog ? (
          <>
            <StyledFlyoutHeader hasBorder>
              <EuiTitle size="m">
                <h2>{title}</h2>
              </EuiTitle>
              <StyledEuiIcon
                type="cross"
                onClick={handleClose}
                style={{ cursor: "pointer" }}
              />
            </StyledFlyoutHeader>
            <ConfirmIncomeDialog
              open={showConfirmDialog}
              onClose={handleClose}
              transaction={confirmingTransaction}
              alreadyConfirmed={false}
              onFinish={sync}
              setTitle={setTitle}
            />
          </>
        ) : activeStatus === CONFIRMATION_MODAL_STATUS.INCOME ? (
          <IncomeTransactionsContent />
        ) : (
          <TransfersContent />
        )}
      </StyledFlyout>
    ) : null
  );
};

export default RootConfirmationDialog;