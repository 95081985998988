import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { cloneDeep } from "lodash";

import Questionnaire from "src/components/Questionnaire";
import {
  QuestionFormProps,
  singleQuestions,
  spouseQuestions,
} from "src/interfaces/optimizedPlanBuild.interface";
import { Question, QuestionForm } from "src/interfaces/questionnaire.interface";
import { getIsMarried } from "src/store/system/selector";
// import CenterContainer from "../PlanBuilder/Components/CenterContainer";

const EmployerRetirement = ({
  planSetter,
  planData,
  goToNext,
  nextClicked,
  errors,
  setErrors,
  validate,
}: QuestionFormProps) => {
  // note: hold initial next click count
  const [nextClick] = useState(nextClicked);
  const isMarried = useSelector(getIsMarried);
  const [spouseView, setSpouseView] = useState(false);
  const questionData: QuestionForm = spouseView
    ? spouseQuestions.employerRetirement
    : singleQuestions.employerRetirement;

  const startingValues = cloneDeep({
    ...questionData.emptyValue,
    has_401k: planData.has_401k,
  });
  if (planData.has_401k === "y") {
    for (const key in questionData.emptyValue) {
      // check the questionnaire for the key
      // if key exists then use that value
      const savedData = planData[key as keyof typeof planData];
      const defaultValue = questionData.emptyValue[key];
      if (savedData && savedData !== defaultValue) {
        // get the field for the question
        const question = questionData.fields.find(
          (question) => question.field === key
        );
        // set all field enablers to "y"
        if (question && question.enabled) {
          const enablers = question.enabled;
          enablers.forEach((enabler) => {
            startingValues[enabler] = "y";
          });
        }
        startingValues[key] = savedData;
      }
    }
  }
  if (!startingValues.id) {
    startingValues.id = Date.now();
  }
  const [values, setValues] = useState<Question>(startingValues);

  const handleChange = (newValues: any) => {
    updatePlanData(newValues);
    setValues(newValues);
  };

  useEffect(() => {
    if (nextClicked !== nextClick) {
      const isValid = validate ? validate(questionData.fields) : true;
      if (isValid) {
        const planUpdate: any = {};
        if (planData.has_401k === "n" && !planData.has_roth_401k) {
          planUpdate.has_roth_401k = "n";
        }
        if (isMarried) {
          if (!spouseView) {
            // if set to empty data like the others then we have to deal with a race case
            setValues(spouseQuestions.employerRetirement.emptyValue);
            setSpouseView(true);
          } else {
            if (planData.s_has_401k === "n" && !planData.s_has_roth_401k) {
              planUpdate.s_has_roth_401k = "n";
            }
            goToNext();
          }
        } else {
          goToNext();
        }
        if (Object.keys(planUpdate).length) {
          planSetter({ ...planData, ...planUpdate });
        }
      }
    }
  }, [nextClicked]);

  const updateSingleData = (changedValues: any) => {
    const newPlan = { ...planData };

    if (changedValues.has_401k === "n") {
      // if preReq is y then update will_nonprofit accordingly
      newPlan.has_401k = "n";
      newPlan.retirement_limit = 0;
      newPlan.retirement_match = 100;
      newPlan.has_roth_401k = "n";
      newPlan.retirement_nonelective_contribution = 0;
    } else if (changedValues.has_401k === "y") {
      newPlan.has_401k = changedValues.has_401k;
      newPlan.retirement_limit = changedValues.retirement_limit;
      newPlan.retirement_match = changedValues.retirement_match;
      newPlan.retirement_nonelective_contribution =
        changedValues.retirement_nonelective_contribution;
    }
    if (changedValues.preReq1 === "n" || changedValues.has_401k === "n") {
      newPlan.retirement_additional_limit = 0;
      newPlan.retirement_additional_match = 50;
    } else if (changedValues.preReq1 === "y") {
      newPlan.retirement_additional_limit =
        changedValues.retirement_additional_limit;
      newPlan.retirement_additional_match =
        changedValues.retirement_additional_match;
    }

    if (changedValues.preReq3 === "n") {
      newPlan.retirement_nonelective_contribution = 0;
    }

    planSetter(newPlan);
  };

  const updateSpouseData = (changedValues: any) => {
    const newPlan = { ...planData };
    if (changedValues.s_has_401k === "n") {
      // if preReq is y then update will_nonprofit accordingly
      newPlan.s_has_401k = "n";
      newPlan.s_retirement_limit = 0;
      newPlan.s_retirement_match = 100;
      newPlan.s_has_roth_401k = "n";
      newPlan.s_retirement_nonelective_contribution = 0;
    } else if (changedValues.preReq1 === "y") {
      newPlan.s_has_401k = changedValues.s_has_401k;
      newPlan.s_retirement_limit = changedValues.s_retirement_limit;
      newPlan.s_retirement_match = changedValues.s_retirement_match;
      newPlan.s_retirement_nonelective_contribution =
        changedValues.s_retirement_nonelective_contribution;
    }

    if (changedValues.preReq1 === "n" || changedValues.has_401k === "n") {
      newPlan.s_retirement_additional_limit = 0;
      newPlan.s_retirement_additional_match = 50;
    } else if (changedValues.preReq1 === "y") {
      newPlan.s_retirement_additional_limit =
        changedValues.s_retirement_additional_limit;
      newPlan.s_retirement_additional_match =
        changedValues.s_retirement_additional_match;
    }

    if (changedValues.preReq3 === "n") {
      newPlan.s_retirement_nonelective_contribution = 0;
    }

    planSetter(newPlan);
  };

  const updatePlanData = spouseView ? updateSpouseData : updateSingleData;

  return (
    <Questionnaire
      questions={questionData}
      values={values}
      onChange={handleChange}
      errors={errors}
      setErrors={setErrors}
    />
  );
};

export default EmployerRetirement;
