import React from "react";
import { EuiModal, EuiModalHeader, EuiModalHeaderTitle, EuiModalBody, EuiModalFooter, EuiIcon, EuiButton } from "@elastic/eui";
import styled from "@emotion/styled";
import { Global, css } from "@emotion/react";
import { StyledEuiButtonEmpty } from "../Global/StyledComponents";

const modalStyles = css`
  .modal-body {
    padding: 0 16px;
    p {
      font-size: 16px;
      font-family: "Inter", sans-serif;
    }
    .euiModalBody__overflow {
      padding-inline: 0;
    }
  }
`;

export const StyledEuiModalHeader = styled(EuiModalHeader)`
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledEuiIcon = styled(EuiIcon)`
  width: 24px;
  height: 24px;
`;

interface Props {
  title: React.ReactNode;
  subTitle?: string;
  isOpen: boolean;
  size?: false | "sm" | "xs" | "md" | "lg" | "xl";
  onClose?: any;
  onBack?: () => void;
  children?: React.ReactNode;
  className?: string;
  titleClassName?: string;
  style?: any;
  hideCloseIcon?: boolean;
  footerButtons?: React.ReactNode;
}

const Modal = ({
  title,
  isOpen,
  size,
  onClose,
  className,
  titleClassName,
  children,
  footerButtons,
  style,
  hideCloseIcon,
}: Props) => {
  return (
    <>
      <Global styles={modalStyles} />
      {isOpen && (
        <EuiModal onClose={onClose} style={{ maxWidth: 600 }}>
          <StyledEuiModalHeader>
            <EuiModalHeaderTitle id="alert-dialog-title">{title}</EuiModalHeaderTitle>
            <StyledEuiIcon
              type="cross"
              onClick={onClose}
              style={{
                cursor: "pointer",
              }}
              aria-label="Close modal"
            />
          </StyledEuiModalHeader>
          <EuiModalBody className="modal-body">
            {children}
          </EuiModalBody>
          {footerButtons && <EuiModalFooter>{footerButtons}</EuiModalFooter>}
        </EuiModal>
      )}
    </>
  );
};

export default Modal;
