import React from "react";
import { useSelector } from "react-redux";

import { Grid, makeStyles } from "@material-ui/core";

import ActionItems from "./Blocks/ActionItems";
import PlanSummaryChart from "./Blocks/PlanSummaryChart";
import IdrSolutionBlock from "./Blocks/IdrSolutionBlock";
import { SPECIAL_REPAYMENTS } from "src/interfaces";
import ScoreSummary from "src/pages/Dashboard/ScoreFactors/Blocks/FitbuxScore";
import LatestPosts from "./Blocks/LatestPosts";
import { getProfile, getSpouseProfile } from "src/store/profileBuild/selector";
import { getIsSubscribed } from "src/store/system/selector";

const useStyles = makeStyles({
  freeFocus: {
    order: -1,
  },
});

export const HomeNoPlan = () => {
  const styles = useStyles();
  const isSubscribed = useSelector(getIsSubscribed);
  const profile = useSelector(getProfile);
  const spouseProfile = useSelector(getSpouseProfile);
  // const isCurrentStudent = useSelector(getIsCurrentStudent);

  const orderStyle = isSubscribed ? undefined : styles.freeFocus;
  const hasIdr =
    SPECIAL_REPAYMENTS.indexOf(profile?.fed_repayment_plan || "") >= 0 ||
    SPECIAL_REPAYMENTS.indexOf(spouseProfile?.fed_repayment_plan || "") >= 0;

  return (
    <>
      <div className="card">
        <PlanSummaryChart />
      </div>
      <div className="card">
        <ActionItems />
      </div>
      <div className="card">
        <LatestPosts />
      </div>
      {hasIdr && (
        <div className="card">
          <IdrSolutionBlock />
        </div>
      )}
    </>
  );
};

export default HomeNoPlan;
