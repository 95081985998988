import React from "react";
import { useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";

import Chart from "src/components/Chart";
import {
  getDashboardScoreProjection,
  getLoadingState,
} from "src/store/planBuild/selector";
import { getHasPlan, getIsSubscribed } from "src/store/system/selector";
import { chartStyles } from "src/theme/base";
import { AdapterLink } from "src/utils";
import { EuiButton, EuiCard, EuiImage } from "@elastic/eui";
import zero_state_plan_summary from "src/assets/svgs/zero-plansummary.svg";
import { StyledSpacer } from "src/components/Global/StyledComponents";
import "src/Cards.css";
import { Link } from "react-router-dom";
import { Global, css } from "@emotion/react";

const planSummaryCard = css`
.fplan-text-mobile {
  display:none;
}
@media screen and (max-width: 991px) {
  .fplan-text-mobile {
    display: block;
    p {
      font-size: 12px !important;
    }
  }
  .fplan-text {
    display: none;
  }
}

`;

const useStyles = makeStyles(chartStyles);

const PlanSummaryChart = () => {
  const styles = useStyles();
  const subscribed = useSelector(getIsSubscribed);
  const hasPlan = useSelector(getHasPlan);
  const scoreProjection = useSelector(getDashboardScoreProjection);
  const loading = useSelector(getLoadingState);
  const mockData = [
    {
      month: 0,
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      month: 12,
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      month: 24,
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      month: 36,
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      month: 48,
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      month: 60,
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      month: 72,
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];

  if (!subscribed || !hasPlan) {
    return (
      <>
        <Global styles={planSummaryCard} />
        <EuiCard
          title=""
          paddingSize="l"
          description=""
          textAlign="center"
          className="zero-state-card"
        >
          <EuiImage
            className=""
            src={zero_state_plan_summary}
            alt="woman on tablet"
            size="original"
          />
          <div className="fplan-text-mobile">
            <StyledSpacer size="10px" />
            <p>
              Build and implement your Financial Plan to start tracking your progress.
            </p>
          </div>
          <StyledSpacer size="16px" />
          <Link to="/plan-list">
            <EuiButton fill color="primary">
              Implement Your Financial Plan
            </EuiButton>
          </Link>
          <div className="fplan-text">
            <StyledSpacer size="14px" />
            <p>
              Build and implement your Financial Plan to start tracking your progress.
            </p>
          </div>
        </EuiCard>
      </>
    );
  }
  return (
    <Chart
      loading={loading.projection}
      title="Projected FitBUX Score"
      headComponent={
        <AdapterLink to="/plan-list" className={styles.chartLink}>
          Explore My Plan
        </AdapterLink>
      }
      data={scoreProjection}
      height="244px"
      keys={["score"]}
      xKey="month"
      keyLabels={{
        score: "Current Projected",
      }}
      width="100%"
    />
  );
};

export default PlanSummaryChart;
