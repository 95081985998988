import React, { useEffect, useState } from "react";
import { cloneDeep } from "lodash";

import Questionnaire from "src/components/Questionnaire";
import {
  QuestionFormProps,
  singleQuestions,
} from "src/interfaces/optimizedPlanBuild.interface";
import { Question, QuestionForm } from "src/interfaces/questionnaire.interface";

const Vacation = ({
  planSetter,
  planData,
  nextClicked,
  goToNext,
  errors,
  setErrors,
  validate,
}: QuestionFormProps) => {
  const [nextClick] = useState(nextClicked);
  const questionData: QuestionForm = singleQuestions.vacation;

  const startingValues = cloneDeep(questionData.emptyValue);
  for (const key in questionData.emptyValue) {
    const savedData = planData[key as keyof typeof planData];
    if (savedData) {
      startingValues[key] = savedData;
    }
  }
  if (planData.vacation_down) {
    startingValues.preReq = "y";
  }
  const [values, setValues] = useState<Question>(startingValues);

  const handleChange = (newValues: any) => {
    const updatedValues = { ...newValues };
    if (updatedValues.preReq === "n") {
      updatedValues.vacation_down = 0;
      updatedValues.vacation_recurring = "n";
    }
    updatePlanData(updatedValues);
    setValues(updatedValues);
  };

  useEffect(() => {
    if (nextClicked !== nextClick) {
      if (!validate || validate(questionData.fields)) {
        goToNext();
      }
    }
  }, [nextClicked]);

  const updatePlanData = (changedValues: any) => {
    const newPlan = {
      ...planData,
      vacation_down: changedValues.vacation_down,
      vacation_recurring: changedValues.vacation_recurring,
      vacation_date: changedValues.vacation_date,
    };
    planSetter(newPlan);
  };

  return (
    <Questionnaire
      questions={questionData}
      values={values}
      onChange={handleChange}
      errors={errors}
      setErrors={setErrors}
    />
  );
};

export default Vacation;
