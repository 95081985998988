import AutoLoan from "./Views/AutoLoan";
import Children from "./Views/Children";
import EmployerRetirement from "./Views/EmployerRetirement";
import HSA from "./Views/HSA";
import EmployerStudentLoans from "./Views/EmployerStudentLoans";
import IncomeIncrease, { IncomeDecrease } from "./Views/IncomeIncreaseDecrease";
import Marriage from "./Views/Marriage";
import WorkStatus from "./Views/WorkStatus";
import InvestOrPayoff from "./Views/InvestOrPayoff";
import Moving from "./Views/Moving";
import Vacation from "./Views/Vacation";
import Introduction from "./Views/Introduction";
import Transition from "./Views/ReviewTransition";
import Recap from "./Views/Recap";

export enum STEP_INDICES {
  Introduction,
  EmployerRetirement,
  HSA,
  EmployerStudentLoans,
  WorkStatus,
  IncomeIncrease,
  IncomeDecrease,
  Marriage,
  Children,
  InvestOrPayoff,
  Vacation,
  AutoLoan,
  Moving,
  Recap,
  Transition,
}

export const progressSteps = {
  [STEP_INDICES.Introduction]: 0,
  [STEP_INDICES.EmployerRetirement]: 1,
  [STEP_INDICES.HSA]: 1,
  [STEP_INDICES.EmployerStudentLoans]: 1,
  [STEP_INDICES.WorkStatus]: 2,
  [STEP_INDICES.IncomeIncrease]: 2,
  [STEP_INDICES.IncomeDecrease]: 2,
  [STEP_INDICES.Marriage]: 3,
  [STEP_INDICES.Children]: 4,
  [STEP_INDICES.InvestOrPayoff]: 5,
  [STEP_INDICES.Vacation]: 6,
  [STEP_INDICES.AutoLoan]: 7,
  [STEP_INDICES.Moving]: 8,
  [STEP_INDICES.Recap]: 9,
  [STEP_INDICES.Transition]: 10,
};

export const steps = [
  Introduction,
  EmployerRetirement,
  HSA,
  EmployerStudentLoans,
  WorkStatus,
  IncomeIncrease,
  IncomeDecrease,
  Marriage,
  Children,
  InvestOrPayoff,
  Vacation,
  AutoLoan,
  Moving,
  Recap,
  Transition,
];
