import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  EuiBasicTable,
  EuiButtonIcon,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiText,
  EuiLink,
  EuiButtonGroup,
  EuiProgress,
  EuiButton
} from "@elastic/eui";
import styled from "@emotion/styled";
import { Global, css } from '@emotion/react';

import CustomDialog from "src/components/Dialogs/CustomDialog";
import ReviewTransactionsDialog from "src/pages/Dashboard/MyProfile/Transactions/Dialogs/ReviewTransactionsDialog";
import CashDialog from "src/components/Dialogs/CashDialog";
import { StyledEuiHorizontalRule, StyledEuiLink, StyledSpacer, StyledEuiButtonEmpty, StyledEuiButton } from "src/components/Global/StyledComponents";
import useWindowSize from "src/hooks/useWindowSize";
import { useFlyout } from "src/components/Global/FlyoutContext";
import magnify_icon from 'src/assets/svgs/magnify.svg';

import { ACCOUNT_TYPES } from "src/interfaces";
import { formatPercent, formatWholeDollars, formatExecutionPercent } from "src/utils";
import { getAccounts, getDebtObligations } from "src/store/account/selector";
import { getExecutionSummary, getExpenseSummary, getIncomeSummary, getRefreshNeeded, getRiskSummary } from "src/store/dashboard/selector";
import { getIncomeAndExpenseSummaries, loadPlanSummary } from "src/store/dashboard/actions";
import { fetchLivePlanProjection } from "src/store/planBuild/actions";
import { getDashboardAllocations, getLiveProjection, getLoadedSavedProjections, getLoadingSavedProjections, getPlans, getSavedProjectionsError, livePlanAllocationTotals, livePlanExpenseTotal, livePlanIncomeTotal, livePlanRiskManagementTotal } from "src/store/planBuild/selector";
import { openPlanForEditing } from "src/store/planBuild/util";

export const TableWrapper = styled.div`
  overflow-x: auto;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-bottom: none;
  @media (max-width: 767px) {
    border: none;
  }
`;

const fpSummary = css`
  .card-header {
    justify-content: flex-start !important;
    align-items: center;
    .card-title {
      h2 {
        font-family: "Poppins", sans-serif;
        font-size: 20px;
        line-height: 32px;
        font-weight: 600;
      }
    }
    .card-link {
      font-size: 16px;
      font-family: "Inter", sans-serif;
      line-hegith: 24px;
      font-weight: 400px;
    }
  }
  .mobile-styles {
    .tabs-summary {
      span {
        font-size: 12px;
      }
    }
    .no-data-title {
      font-size: 14px;
      font-family: "Inter", sans-serif;
      font-weight: bold;
      text-align: left;
    }
    .row-title {
      font-size: 14px;
      font-family: "Inter", sans-serif;
      font-weight: bold;
      text-align: left;
    }
    .row-title-item {
      flex: 0 0 200px !important;
    }
    .row-title-group {
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
    }
    .amounts-review {
      font-size: 14px;
      font-family: "Inter", sans-serif;
      font-weight: 600;
      line-height: 20px;
      text-align: left;
    }
    .planned-text {
      color: #69707D;
    }
    .progress-text {
      font-size: 14px;
      font-family: "Inter", sans-serif;
      line-height: 20px;
      font-weight: 600;
    }
    .child-row-group {
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      gap: 16px;
    }
    .child-item-icon {
      flex: 0 0 20px !important;
      svg {
        width: 20px;
        height: 20px;
        fill: #343741 !important;
      }
    }
    .mobile-header {
      flex-wrap: nowrap !important;
      justify-content: space-between;
      .card-title {
        align-items: flex-start;
        flex: 0 1 auto !important;
        inline-size: auto !important;
        h2 {
          font-size: 18px;
        }
      }
      .card-link {
        flex: 0 1 auto !important;
        font-size: 14px;
        align-items: flex-end;
        inline-size: auto !important;
      }
    }
    .mobile-table {
      padding: 0;
      border-radius: 0;
    }
  }
  .tabs-summary {
    .euiButtonGroupButton {
      background-color: #FBFCFD;
      border: 1px solid #cecece;
    }
    .euiButtonGroupButton-isSelected {
      background-color: #69707D;
    }
    span {
      font-size: 14px;
      font-family: "Inter", sans-serif;
    }
  }
  .table-info {
    font-size: 14px !important;
    line-height: 24px !important;
    text-align: left;
    max-width: 100%;
  }
  .fp-table {
    .euiTableCellContent {
      font-size: 16px;
      line-height: 24px;
      font-family: 'Inter', sans-serif;
    }
    .euiTableRowCell:first-child .cell-overflow div {
      padding-left: 32px;
    }
    .category-name {
      font-weight: 600;
      padding-left: 32px;
    }
    .euiTableHeaderCell:first-child .euiTableCellContent__text {
      padding-left: 32px;
    }
    .euiTableHeaderCell span {
      font-size: 16px;
      line-height: 24px;
    }
    table {
      width: 100%;
      table-layout: auto;
      @media (min-width: 768px) and (max-width: 1400px) {
        table-layout: fixed;
        .euiTableHeaderCell:first-child {
          position: -webkit-sticky;
          position: sticky;
          left: 0;
          background-color: #FFF;
          z-index: 2;
          &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: 5px;
            background: linear-gradient(to right, rgba(206,206,206,1), rgba(255,255,255,0));
          }
        }
        .euiTableRow > .euiTableRowCell:first-child {
          position: -webkit-sticky;
          position: sticky;
          left: 0;
          background-color: #FFF;
          z-index: 2;
        }
        .euiTableRow > .euiTableRowCell:first-child {
          &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: 5px;
            background: linear-gradient(to right, rgba(206,206,206,1), rgba(255,255,255,0));
          }
        }
        th:nth-child(1), td:nth-child(1) {
          width: 260px;
        }
        th:nth-child(2), td:nth-child(2) {
            width: 200px;
        }
        th:nth-child(3), td:nth-child(3) {
            width: 200px;
        }
        th:nth-child(4), td:nth-child(4) {
            width: 200px;
        }
        th:nth-child(5), td:nth-child(5) {
            width: 200px;
        }
        th:nth-child(6), td:nth-child(6) {
            width: 260px;
        }
      }
    }
    .progress-section {
      gap: 10px !important;
      justify-content: center !important;
      align-items: center !important;
      .progress-bar {
        width: 174px;
        height: 8px;
      }
      .progress-text {
        align-items: flex-start;
        .euiText {
          font-size: 12px;
        }
      }
    }
    .drop-down-row {
      .cell-overflow {
        div {
          padding-left: 0px !important;
        }
      }
      .euiFlexGroup {
        gap: 16px;
      }
    }
    .child-row {
      background-color: #F7F8FC;
    }
    .cell-overflow {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

const nowYear = new Date(
  new Date().valueOf() - new Date().getTimezoneOffset() * 60000
).getFullYear();

const messageContent = {
  income: {
    title: "Review/Edit Income",
    body: [
      "To compare your actual total income to the amount budgeted in your plan, visit your Day-to-Day Money page.",
      "If income transactions are missing, make sure to confirm any outstanding transaction and/or add transactions manually.",
    ],
    buttonLabel: "Go to Day-to-Day Money",
    target: "/budget",
  },
  expenses: {
    title: "Review/Edit Expenses",
    body: [
      "To compare your actual expenses to the amount budgeted in your plan, visit your Day-to-Day Money page.",
      "If you are missing expenses, make sure your linked accounts are up-to-date or add an expense manually.",
    ],
    buttonLabel: "Go to Day-to-Day Money",
    target: "/budget",
  },
  premiums: {
    title: "Review/Edit Premium Payments",
    body: [
      "To compare your risk premium expenses to the amount budgeted in your plan, visit your Risk Management page.",
      "If you are missing risk premium expenses, make sure your linked accounts are up-to-date or add a risk premium expense manually.",
    ],
    buttonLabel: "Go to Risk Management",
    target: "/risk",
  },
};

interface StyledEuiLinkNavProps {
  to: string;
  children: React.ReactNode;
  [key: string]: any;
}

const PlanDetails = () => {
  const dispatch = useDispatch();
  const income = useSelector(livePlanIncomeTotal);
  const expenses = useSelector(livePlanExpenseTotal);
  const risks = useSelector(livePlanRiskManagementTotal);
  const executionSummary: any = useSelector(getExecutionSummary);
  const incomeSummary = useSelector(getIncomeSummary);
  const expenseSummary = useSelector(getExpenseSummary);
  const riskSummary = useSelector(getRiskSummary);
  const liveProjection = useSelector(getLiveProjection);
  const plans = useSelector(getPlans);
  const {
    assetsTotal: liveAssetsTotal,
    debtsTotal: liveDebtsTotal,
  } = useSelector(livePlanAllocationTotals);
  const { assets, debts } = useSelector(getDashboardAllocations);
  const debtObligations: any = useSelector(getDebtObligations);
  const accounts = useSelector(getAccounts);
  const [expanded, setExpanded] = useState<Array<number>>([]);
  const [transactionQuery, setTransactionQuery] = useState({});
  const [cashMagnifyingMessage, setCashMagnifyingMessage] = useState(false);
  const [currentTab, setTab] = useState(0);
  const { openCustomFlyout, closeFlyout } = useFlyout();
  const livePlan = plans.find((plan) => plan.implemented);
  const loadedProjections = useSelector(getLoadedSavedProjections);
  const loadingProjections = useSelector(getLoadingSavedProjections);
  const projectionsError = useSelector(getSavedProjectionsError);
  const refreshNeeded = useSelector(getRefreshNeeded);
  const [messageDialogContent, setDialogContent] = useState<any>(null);
  const [cashDialogContent, setCashDialogContent] = useState<any>(null);
  const planStart = liveProjection?.start || `${nowYear}-01`;
  const timeframes = ["current", "month", "annual"];
  const timeframe = timeframes[currentTab];

  const calculateEndDate = (startDate: Date, tab: number) => {
    const endDate = new Date(startDate);
    if (tab === 0) {
      // Last 30 days
      endDate.setDate(startDate.getDate() + 30);
    } else if (tab === 1) {
      // Last month
      endDate.setMonth(startDate.getMonth() - 1);
    } else if (tab === 2) {
      // Last 12 months
      endDate.setFullYear(startDate.getFullYear() - 1);
    }
    return endDate;
  };

  const startDate = new Date(planStart);
  const endDate = calculateEndDate(startDate, currentTab);

  const formattedStartDate = startDate.toLocaleDateString([], {
    month: "short",
    year: "numeric",
  });

  const formattedEndDate = endDate.toLocaleDateString([], {
    month: "short",
    year: "numeric",
  });

  const load = () => {
    dispatch(getIncomeAndExpenseSummaries());
    dispatch(loadPlanSummary());
  };

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    if (!loadingProjections) {
      if (
        (!loadedProjections && !projectionsError) ||
        refreshNeeded.liveProjection
      ) {
        dispatch(fetchLivePlanProjection(false));
      }
    }
  }, [loadedProjections, loadingProjections, projectionsError]);

  const getSummaryRisk = () => {
    const riskKey = timeframe === "month" ? "monthly" : timeframe;
    let timeFrameRisk = 0;
    if (riskKey) {
      const riskItem = riskSummary[riskKey as keyof typeof riskSummary];
      for (const item in riskItem) {
        timeFrameRisk += riskItem[item];
      }
    }
    return timeFrameRisk;
  };

  const getWholeDollars = (num: number) => {
    if (num <= 0) {
      return formatWholeDollars(0);
    } else {
      return formatWholeDollars(num);
    }
  };

  const assetKeyList = Array.from(
    executionSummary.assetKeys as Set<string>
  ).filter((key: string) => key !== "total" && key !== "waterfall");
  const debtKeyList = Array.from(
    executionSummary.debtKeys as Set<string>
  ).filter(
    (key: string) => key !== "total" && key !== "waterfall" && key !== "cash"
  );

  const nonZeroPlannedDebts = [...debtKeyList].filter(
    (key) =>
      !!accounts.find((account) => {
        const accountType = account.variable || account.type || "";
        if (key === "student_loan") {
          return (
            ["fed_loan", "perkins_loan", "priv_loan"].indexOf(accountType) >=
            0 && !!account.balance
          );
        }
        return key === accountType && !!account.balance;
      })
  );

  const openCashDialog = () => {
    setTransactionQuery({});
    setCashMagnifyingMessage(true);
    setCashDialogContent({
      title: 'Review/Edit Cash',
      onClose: () => setCashDialogContent(null),
    });
  };

  const openTransactionsDialog = (category: string, key: string) => {
    if (key === "cash_value") {
      openCashDialog();
      return;
    }
    const query: any = {
      category,
      type: key,
    };
    if (timeframe === "month") {
      query.period = "monthly";
    } else {
      query.period = timeframe;
    }
    setTransactionQuery(query);
    setCashMagnifyingMessage(false);
    openCustomFlyout({
      component: ReviewTransactionsDialog,
      title: 'Review/Edit Transactions',
      query: query,
      cashMessage: false,
      onClose: closeFlyout,
      onAdd: load,
      onRemove: load,
      accounts: accounts,
    });
  };

  const getMagnifier = (
    income: boolean,
    actualOrCash: boolean,
    key: string
  ) => {
    return (
      window.innerWidth <= 767 ? (
        <EuiLink onClick={() => openTransactionsDialog("contribution", key)}>
          Review
        </EuiLink>
      ) : (
        <EuiButtonIcon
          iconType={magnify_icon}
          aria-label="Edit"
          onClick={() => openTransactionsDialog("contribution", key)}
          style={{ marginLeft: 10 }}
        />
      )
    );
  };

  const getPlannedValue = (plannedValue: number) => {
    if (timeframe === "annual") {
      return plannedValue;
    } else {
      return plannedValue / 12;
    }
  };

  const getSummaryRows = React.useMemo(() => {
    let loading = false;
    let rows: any[] = [];
    try {
      const executionIncome = executionSummary.income.total[timeframe].actual;
      rows = [
        {
          header: "Assets",
          columns: [
            formatExecutionPercent(
              executionSummary.asset.total[timeframe].actual,
              executionIncome
            ),
            formatPercent((liveAssetsTotal / income) * 100),
          ],
          children: assetKeyList.map((key: string) => {
            return {
              header: ACCOUNT_TYPES[key as keyof typeof ACCOUNT_TYPES],
              actualDollars: executionSummary.asset[key][timeframe].actual,
              plannedDollars: getPlannedValue(assets[key]),
              actualPercent: formatExecutionPercent(
                executionSummary.asset[key][timeframe].actual,
                executionIncome
              ),
              node: getMagnifier(
                executionIncome,
                executionSummary.asset[key][timeframe].actual ||
                key === "cash_value",
                key
              ),
            };
          }),
        },
        {
          header: "Debts",
          columns: [
            formatExecutionPercent(
              executionSummary.debt.total[timeframe].actual,
              executionIncome
            ),
            formatPercent((liveDebtsTotal / income) * 100),
          ],
          children: debtKeyList.map((key: string) => {
            let value = debts[key] || 0;
            if (key === "student_loan") {
              value =
                Math.max(
                  debts.fed_loan || 0,
                  (debtObligations.fed_loan || 0) * 12
                ) +
                Math.max(
                  debts.priv_loan || 0,
                  (debtObligations.priv_loan || 0) * 12
                ) +
                Math.max(
                  debts.perkins_loan || 0,
                  (debtObligations.perkins_loan || 0) * 12
                );
            } else {
              value = Math.max(debts[key], (debtObligations[key] || 0) * 12);
            }
            if (nonZeroPlannedDebts.indexOf(key) < 0) {
              value = 0;
            }
            return {
              header: ACCOUNT_TYPES[key as keyof typeof ACCOUNT_TYPES],
              actualDollars: executionSummary.debt[key][timeframe].actual,
              plannedDollars: getPlannedValue(value),
              actualPercent: formatExecutionPercent(
                executionSummary.debt[key][timeframe].actual,
                executionIncome
              ),
              node: getMagnifier(
                executionIncome,
                executionSummary.debt[key][timeframe].actual,
                key
              ),
            };
          }),
        },
      ];
      // }
    } catch (summaryError) {
      loading = true;
      rows = [];
    }
    return { loading, rows };
  }, [
    executionSummary,
    timeframe,
    assets,
    debts,
    liveAssetsTotal,
    liveDebtsTotal,
  ]);

  const summary = useMemo(() => {
    const key = timeframe === "month" ? "monthly" : timeframe;
    let income = 0;
    let expense = 0;
    if (key) {
      const incomeItem = incomeSummary[key as keyof typeof incomeSummary];
      for (const item in incomeItem) {
        income += incomeItem[item];
      }
      const expenseItem = expenseSummary[key as keyof typeof expenseSummary];
      for (const item in expenseItem) {
        expense += expenseItem[item];
      }
    }
    return { income, expense };
  }, [timeframe, incomeSummary, expenseSummary]);

  const isExpanded = (i: number) => {
    return expanded.indexOf(i) > -1;
  };

  const handleToggle = (i: number) => {
    const index = expanded.indexOf(i);
    if (index > -1) {
      setExpanded(expanded.filter((j) => j !== i));
      expanded.splice(index, 1);
    } else {
      setExpanded([...expanded, i]);
    }
  };

  const summaryRows = getSummaryRows.rows;

  const StyledEuiLinkNav = ({ to, children, ...rest }: StyledEuiLinkNavProps) => (
    <Link to={to}>
      <EuiLink {...rest} style={{ textDecoration: "underline" }}>
        {children}
      </EuiLink>
    </Link>
  );

  const categoryMapping: { [key: string]: keyof typeof messageContent } = {
    'Total Income': 'income',
    'Day-to-Day Expenses': 'expenses',
    'Premium Payments': 'premiums',
  };

  const renderSpecialEditIcon = (category: keyof typeof messageContent) => (
    width <= 767 ? (
      <EuiLink onClick={() => setDialogContent(messageContent[category])}>
        Review
      </EuiLink>
    ) : (
      <EuiButtonIcon
        iconType={magnify_icon}
        aria-label="Edit"
        onClick={() => setDialogContent(messageContent[category])}
        style={{ marginLeft: 10 }}
      />
    )
  );

  const assetDebtData = ["asset", "debt"].map((row: any, index: number) => {
    const rowName = row === "asset" ? "Asset Contributions" : "Debt Payments";

    const { width } = useWindowSize();

    const arrowIcon = (
      <EuiIcon
        type={isExpanded(index) ? "arrowUp" : "arrowDown"}
        onClick={() => handleToggle(index)}
        style={{ cursor: 'pointer', fill: '#0077CC' }}
      />
    );

    const rowNameText = <EuiText className="row-title">{rowName}</EuiText>;

    const rowHead = width <= 767 ? (
      <>
        {arrowIcon}
        {rowNameText}
      </>
    ) : (
      <EuiFlexGroup alignItems="center">
        <EuiFlexItem grow={false}>
          {arrowIcon}
        </EuiFlexItem>
        <EuiFlexItem>
          {rowNameText}
        </EuiFlexItem>
      </EuiFlexGroup>
    );
    const plannedDollars = getPlannedValue(
      row === "asset" ? liveAssetsTotal : liveDebtsTotal
    );

    const dataRow = {
      rowName: rowHead,
      actual: {
        dollars: getWholeDollars(executionSummary[row].total[timeframe].actual),
        percent: summaryRows[index].columns[0],
      },
      actual_percentage: {
        percent: summaryRows[index].columns[0],
      },
      planned: {
        dollars: plannedDollars,
      },
      planned_percentage: {
        percent: summaryRows[index].columns[1],
      },
      className: 'drop-down-row'
    };

    const childRows = isExpanded(index)
      ? summaryRows[index].children
        .filter((childRow: any) => childRow.plannedDollars || childRow.actualDollars)
        .map((childRow: any) => {
          const plannedPercentage = formatPercent((childRow.plannedDollars / getPlannedValue(income)) * 100);
          const actualDollars = getWholeDollars(childRow.actualDollars);
          return {
            rowName: childRow.header,
            actual: {
              dollars: (
                <>
                  {actualDollars}
                  {childRow.node}
                </>
              ),
              value: actualDollars,
            },
            actual_percentage: {
              percent: childRow.actualPercent,
            },
            planned: {
              dollars: childRow.plannedDollars,
            },
            planned_percentage: {
              percent: plannedPercentage,
            },
            className: 'child-row'
          }
        })
      : [];

    return [dataRow, ...childRows];
  }).flat();

  function calculateProgress(item: any) {
    if (item.noData || !item.actual || !item.planned) {
      return null;
    }

    let actualDollars = 0;

    if (item.actual.value) {
      actualDollars = typeof item.actual.value === 'string' && !isNaN(parseFloat(item.actual.value.replace(/[$,]/g, '')))
        ? parseFloat(item.actual.value.replace(/[$,]/g, ''))
        : 0;
    } else if (item.actual.dollars) {
      actualDollars = typeof item.actual.dollars === 'string' && !isNaN(parseFloat(item.actual.dollars.replace(/[$,]/g, '')))
        ? parseFloat(item.actual.dollars.replace(/[$,]/g, ''))
        : 0;
    }
    const plannedDollars = !isNaN(parseFloat(item.planned.dollars)) ? parseFloat(item.planned.dollars) : 0;
    let progressValue;
    let amountLeft;
    let progressBarColor = 'primary';
    let progressTextColor = 'default';
    let progressText = 'left';
    let progressTextStyle = {};

    if (plannedDollars === 0) {
      progressValue = 0;
      amountLeft = actualDollars;
    } else {
      progressValue = (actualDollars / plannedDollars) * 100;
      amountLeft = Math.round(plannedDollars - actualDollars);
    }

    if (amountLeft < 0) {
      amountLeft = Math.abs(amountLeft);
      progressBarColor = '#D36086';
      progressTextColor = '#D36086';
      progressText = 'over';
      progressTextStyle = { fontWeight: 'bold' };
    }

    if (amountLeft == 0) {
      amountLeft = Math.abs(amountLeft);
      progressBarColor = '#54B399';
    }

    return {
      progressValue,
      amountLeft,
      progressBarColor,
      progressTextColor,
      progressText,
      progressTextStyle
    };
  }

  const columns = [
    {
      field: 'rowName',
      name: 'Category',
      sortable: true,
      width: '260px',
      render: (name: any, item: any) => {
        const classNames = ['Day-to-Day Money', 'Money for Future Self', 'Risk Management'].includes(name)
          ? 'category-name'
          : '';
        return <div className={classNames}>{name}</div>;
      },
    },
    {
      field: 'actual',
      name: 'Actual',
      sortable: true,
      width: '160px',
      render: (actual: any, item: any) => (
        <>
          {!item.noData && (
            <>
              {actual.dollars || 0}
              {categoryMapping[item.rowName] && renderSpecialEditIcon(categoryMapping[item.rowName])}
            </>
          )}
        </>
      ),
    },
    {
      field: 'actual_percentage',
      name: 'Actual % Income',
      sortable: true,
      width: '160px',
      render: (actual: any, item: any) => (
        <>
          {!item.noData && actual && (
            <>
              {actual.percent}
            </>
          )}
        </>
      ),
    },
    {
      field: 'planned',
      name: 'Planned',
      sortable: true,
      width: '160px',
      render: (planned: any, item: any) => (
        <>
          {!item.noData && (
            <>
              {getWholeDollars(planned.dollars || 0)}
            </>
          )}
        </>
      ),
    },
    {
      field: 'planned_percentage',
      name: 'Planned % Income',
      sortable: true,
      width: '160px',
      render: (planned_percentage: any, item: any) => (
        <>
          {!item.noData && planned_percentage && (
            <>
              {planned_percentage.percent}
            </>
          )}
        </>
      ),
    },
    {
      field: 'progress',
      name: 'Progress',
      sortable: true,
      width: '260px',
      render: (progress: any, item: any) => {
        const progressInfo = calculateProgress(item);
        if (!progressInfo) {
          return null;
        }

        return (
          <EuiFlexGroup className="progress-section">
            <EuiFlexItem className="progress-bar">
              <EuiProgress value={progressInfo.progressValue} max={100} size="l" color={progressInfo.progressBarColor} />
            </EuiFlexItem>
            <EuiFlexItem className="progress-text">
              <EuiText color={progressInfo.progressTextColor} style={progressInfo.progressTextStyle}>
                ${progressInfo.amountLeft} {progressInfo.progressText}
              </EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
        );
      },
    },
  ];

  const data = [
    {
      rowName: 'Day-to-Day Money',
      actual: { dollars: 'No Data', percent: 'No Data' },
      planned: { dollars: 'No Data', percent: 'No Data' },
      noData: true,
    },
    {
      rowName: 'Total Income',
      actual: {
        dollars: getWholeDollars(summary.income),
      },
      planned: {
        dollars: getPlannedValue(income),
      },
    },
    {
      rowName: 'Day-to-Day Expenses',
      actual: {
        dollars: getWholeDollars(summary.expense)
      },
      planned: {
        dollars: getPlannedValue(expenses)
      },
      actual_percentage: {
        percent: formatExecutionPercent(summary.expense, summary.income)
      },
      planned_percentage: {
        percent: formatExecutionPercent(expenses, income)
      }
    },
    {
      rowName: 'Money for Future Self',
      actual: { dollars: 'No Data', percent: 'No Data' },
      planned: { dollars: 'No Data', percent: 'No Data' },
      noData: true,
    },
    ...assetDebtData,
    {
      rowName: 'Risk Management',
      actual: { dollars: 'No Data', percent: 'No Data' },
      planned: { dollars: 'No Data', percent: 'No Data' },
      noData: true,
    },
    {
      rowName: 'Premium Payments',
      actual: {
        dollars: getWholeDollars(getSummaryRisk())
      },
      actual_percentage: {
        percent: formatExecutionPercent(
          getSummaryRisk(),
          summary.income
        )
      },
      planned: {
        dollars: getPlannedValue(risks)
      },
      planned_percentage: {
        percent: formatExecutionPercent(risks, income)
      }
    }
  ];

  const { width } = useWindowSize();

  return (
    <>
      <Global styles={fpSummary} />
      <EuiCard
        className={width <= 767 ? 'fp-summary-card mobile-styles' : 'fp-summary-card'}
        title={
          <EuiFlexGroup
            justifyContent="spaceBetween"
            alignItems="center"
            className={width <= 767 ? 'card-header mobile-header' : 'card-header'}
          >
            <EuiFlexItem grow={false} className='card-title'>
              <h2>Financial Plan Summary</h2>
            </EuiFlexItem>
            <EuiFlexItem grow={false} className='card-link'>
              <StyledEuiLink
                onClick={() => openPlanForEditing(dispatch, false, livePlan)}
                color="primary"
              >Edit
              </StyledEuiLink>
            </EuiFlexItem>
          </EuiFlexGroup>
        }
      >
        <StyledSpacer size="16px" />
        <EuiButtonGroup
          className="tabs-summary"
          legend="Legend is required"
          options={[
            { id: '0', label: 'Last 30 Days' },
            { id: '1', label: 'Last Month' },
            { id: '2', label: 'Last 12 Months' },
          ]}
          idSelected={currentTab.toString()}
          onChange={(id) => setTab(parseInt(id))}
        />
        <StyledSpacer size="16px" />
        <EuiText size="m" className="add-text table-info"><p>Date Range: {formattedStartDate} - {formattedEndDate}</p></EuiText>
        <StyledSpacer size={width <= 767 ? "8px" : "16px"} />

        <>
          <TableWrapper>
            {width > 767 ? (
              <EuiBasicTable
                className="fp-table"
                items={data}
                columns={columns}
                rowProps={(item: any) => ({ className: item.className })}
                cellProps={() => ({ className: 'cell-overflow' })}
              />
            ) : (
              data.reduce((acc: any[], item: any, index: number) => {
                if (item.noData) {
                  return acc;
                }

                const previousItem = data[index - 1];
                let title = item.name;
                let rowNameElement = null;
                let rowNameElementMain = null;
                let singleRowNameElement = null;
                let arrowIcon = null;
                let rowNameText = null;
                let withRowHeader = false;
                let withNoRowHeader = false;
                let childRowHeader = false;

                if (typeof item.rowName === 'string') {
                  if (previousItem && previousItem.noData) {
                    withRowHeader = true;
                    title = <><StyledSpacer size="12px" /><EuiText className="no-data-title">{previousItem.rowName}</EuiText></>;

                    rowNameElementMain = <EuiText className="row-title">{item.rowName}</EuiText>;
                  } else {
                    withNoRowHeader = true;
                    singleRowNameElement = <EuiText className="row-title">{item.rowName}</EuiText>;
                  }
                } else {
                  if (React.isValidElement(item.rowName)) {
                    childRowHeader = true;
                    const children = React.Children.toArray(item.rowName.props.children);
                    rowNameElement = children.map((child, index) => {
                      if (React.isValidElement(child) && child.type === EuiIcon) {
                        arrowIcon = child;
                        return null;
                      } else if (React.isValidElement(child) && child.type === EuiText) {
                        rowNameText = child;
                        return child;
                      }
                      return null;
                    }).filter(Boolean);
                    title = previousItem && previousItem.noData ? <><StyledSpacer size="12px" /><EuiText className="no-data-title">{previousItem.rowName}</EuiText></> : '';
                  }
                }

                const progress = calculateProgress(item);

                acc.push(
                  <>
                    <EuiCard key={index} title={title} className="mobile-table">
                      <StyledSpacer size="12px" />
                      <EuiFlexGroup className="row-title-group">
                        <EuiFlexItem className="row-title-item">
                          {withNoRowHeader && singleRowNameElement}
                          {childRowHeader && rowNameElement}
                          {withRowHeader && rowNameElementMain}
                        </EuiFlexItem>
                        {progress && <EuiText className="progress-text">{getWholeDollars(progress.amountLeft)} {progress.progressText}</EuiText>}
                      </EuiFlexGroup>

                      <StyledSpacer size="10px" />

                      {childRowHeader && (
                        <EuiFlexGroup className="child-row-group">
                          <EuiFlexItem>{progress && <EuiProgress value={progress.progressValue} max={100} size="l" color={progress.progressBarColor} />}</EuiFlexItem>
                          <EuiFlexItem className="child-item-icon">{arrowIcon}</EuiFlexItem>
                        </EuiFlexGroup>
                      )}

                      {!childRowHeader && progress && <EuiProgress value={progress.progressValue} max={100} size="l" color={progress.progressBarColor} />}

                      <StyledSpacer size="12px" />

                      <div className="amounts-review">
                        <span className="actual-text">
                          {Array.isArray(item.actual.dollars) ? item.actual.dollars[0] : item.actual.dollars.$$typeof ? item.actual.dollars.props.children[0] : item.actual.dollars}
                        </span>
                        /
                        <span className="planned-text">
                          {getWholeDollars(item.planned.dollars)}
                        </span>
                        &nbsp;&nbsp;
                        {categoryMapping[item.rowName] && renderSpecialEditIcon(categoryMapping[item.rowName])}
                        {Array.isArray(item.actual.dollars) ? item.actual.dollars[1] : item.actual.dollars.$$typeof ? item.actual.dollars.props.children[1] : null}
                      </div>
                      <StyledSpacer size="12px" />
                    </EuiCard>

                    <StyledEuiHorizontalRule />

                  </>
                );

                return acc;
              }, [])
            )}
          </TableWrapper>
        </>

        <StyledSpacer size="16px" />

        <EuiText size="m" className="add-text table-info">
          <p>
            Your actual total income is needed to compute the actual figures. To update your income,&nbsp;
            <StyledEuiLinkNav color="primary" to="/transactions">
              {" "}
              click here{" "}
            </StyledEuiLinkNav>
          </p>
        </EuiText>

        <StyledSpacer size="16px" />

      </EuiCard>
      {cashDialogContent && (
        <CashDialog
          visible={true}
          title={cashDialogContent.title}
          onClose={cashDialogContent.onClose}
        />
      )}

      <CustomDialog
        isOpen={!!messageDialogContent}
        onClose={() => setDialogContent(null)}
        title={messageDialogContent && messageDialogContent.title}
        footerButtons={
          messageDialogContent && messageDialogContent.target ? (
            <>
              <StyledEuiButton
                color="text"
                onClick={() => setDialogContent(null)}
              >
                Cancel
              </StyledEuiButton>
              <EuiButton fill>
                <Link to={messageDialogContent.target} style={{ textDecoration: 'none', color: 'inherit' }}>
                  {messageDialogContent.buttonLabel}
                </Link>
              </EuiButton>
            </>
          ) : (
            <>
              <StyledEuiButtonEmpty onClick={() => setDialogContent(null)}>
                Cancel
              </StyledEuiButtonEmpty>
              <EuiButton fill disabled>
                {messageDialogContent ? messageDialogContent.buttonLabel : 'Go to Accounts Page'}
              </EuiButton>
            </>
          )
        }
      >
        {!!messageDialogContent && (
          <>
            {messageDialogContent.body.map((text: any, index: any) => (
              <p key={index} style={{ marginBottom: index < messageDialogContent.body.length - 1 ? '1em' : '0' }}>
                {text}
              </p>
            ))}
          </>
        )}

      </CustomDialog>


    </>
  );
};

export default PlanDetails;