import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  EuiBasicTable,
  EuiButtonGroup,
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiSpacer,
  EuiTitle,
} from "@elastic/eui";
import { Global, css } from "@emotion/react";
import styled from "@emotion/styled";

import {
  currentPlanExpenseTotal,
  currentPlanIncomeTotal,
  currentPlanRiskManagementTotal,
  getCurrentPlanAllocations,
  getCurrentPlanSurplus,
  getLiabilities,
  isCurrentPlanImplemented,
} from "src/store/planBuild/selector";
import {
  getAccountTypesWithBalances,
  getMyFedLoanPayments,
  getSpouseFedLoanPayments,
} from "src/store/account/selector";
import { ASSET_TYPES, DEBT_TYPES } from "src/interfaces";
import { formatPercent, formatWholeDollars } from "src/utils";
import useWindowSize from "src/hooks/useWindowSize";
import { StyledSpacer } from "src/components/Global/StyledComponents";
import { useFlyout } from "src/components/Global/FlyoutContext";

import { reviewStyles } from "./GoalSummary";

export const TableWrapper = styled.div`
  overflow-x: auto;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ccc !important;
  border-bottom: none;
  @media (min-width: 997px) {
    border: none !important;
  }
`;

const PayCheckAllocation = () => {
  const [view, setView] = useState("monthly");

  const incomeTotal = useSelector(currentPlanIncomeTotal);
  const expenseTotal = useSelector(currentPlanExpenseTotal);
  const riskTotal = useSelector(currentPlanRiskManagementTotal);
  const remainingMoney = useSelector(getCurrentPlanSurplus);
  const { assets, debts: rawDebts } = useSelector(getCurrentPlanAllocations);
  const currentPlanIsImplemented = useSelector(isCurrentPlanImplemented);
  const accountTypesWithBalances = useSelector(getAccountTypesWithBalances);
  const myFedLoanPayments = useSelector(getMyFedLoanPayments);
  const { toggleFlyout } = useFlyout();
  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 567;
  const isSticky = windowSize.width >= 998;
  const spouseFedLoanPayments = useSelector(getSpouseFedLoanPayments);
  const liabilities = useSelector(getLiabilities);
  const myStudentLoanLiability = currentPlanIsImplemented
    ? myFedLoanPayments * 12
    : liabilities.min.solo[0].fed_loan || 0;
  const spouseStudentLoanLiability = currentPlanIsImplemented
    ? spouseFedLoanPayments * 12
    : liabilities.min.solo[1].fed_loan || 0;

  const formatValue = (value: number) => formatWholeDollars(value);

  const debts = !currentPlanIsImplemented
    ? rawDebts
    : rawDebts
      .map((debt: any) => {
        let result = { ...debt };
        if (debt.type === "fed_loan") {
          const annualLiability =
            debt.who === "spouse"
              ? spouseStudentLoanLiability
              : myStudentLoanLiability;
          const annual = Math.max(debt.annual, annualLiability);
          const percent = (annual / incomeTotal) * 100;
          result = {
            ...debt,
            min_annual: annualLiability,
            min_monthly: annualLiability / 12,
            min: (annualLiability / incomeTotal) * 100,
            percent,
          };
        }
        return {
          ...result,
          annual: Math.max(result.annual, result.min_annual),
          monthly: Math.max(result.monthly, result.min_monthly),
          percent: Math.max(result.percent, result.min),
        };
      })
      .filter((debt: any) => accountTypesWithBalances.has(debt.type));

  const hasAllocatedAssets = !!assets.filter((asset: any) => asset.annual)
    .length;
  // const hasAllocatedDebts = !!debts.filter((debt: any) => debt.annual).length;
  const sortedAssets = assets.sort(
    (a: any, b: any) => b.percent - a.percent || a.type.localeCompare(b.type)
  );
  const sortedDebts = debts.sort(
    (a: any, b: any) => b.annual - a.annual || a.type.localeCompare(b.type)
  );

  const columns = [
    {
      field: "category",
      name: "Category",
      truncateText: true,
      isColumnHeader: true,
      width: isMobile ? "200px" : "50%",
      render: (category: any, item: any) => {
        if (item.isHeader) {
          return <span className="header-style-review">{category}</span>;
        }
        return category;
      },
    },
    {
      field: "amount",
      name: "$ Amount",
      isColumnHeader: true,
      width: isMobile ? "100px" : "25%",
      truncateText: true,
      textOnly: true,
      align: "left" as const,
      render: (amount: any, item: any) => {
        if (item.isHeader) return "";
        const value = view === "monthly" ? item.monthly : item.annual;
        return formatValue(value);
      },
    },
    {
      field: "percent",
      name: (
        <>
          % of Your Income
          <EuiButtonIcon
            onClick={() => toggleFlyout("percentage-income")}
            iconType="questionInCircle"
            aria-label="Help"
            className="help-icon"
          />
        </>
      ),
      truncateText: true,
      isColumnHeader: true,
      textOnly: true,
      width: isMobile ? "180px" : "25%",
      align: "left" as const,
      render: (percent: any, item: any) => {
        if (item.isHeader) return "";
        if (typeof item.percent === "number") {
          return formatPercent(item.percent);
        }
        return "";
      },
    },
  ];

  const data = [
    {
      category: "Day-to-Day Money",
      amount: "",
      percent: "",
      isHeader: true,
    },
    {
      category: "Total Income",
      monthly: incomeTotal / 12,
      annual: incomeTotal,
      percent: 100,
    },
    {
      category: "Day-to-Day Expenses",
      monthly: expenseTotal / 12,
      annual: expenseTotal,
      percent: (expenseTotal / incomeTotal) * 100,
    },
    {
      category: "Money for Future Goals",
      amount: "",
      percent: "",
      isHeader: true,
    },
    {
      category: "Asset Contributions",
      monthly: hasAllocatedAssets ? 0 / 12 : 0,
      annual: hasAllocatedAssets ? 0 : 0,
      percent: hasAllocatedAssets ? 0 : 0,
    },
    ...sortedAssets.map((asset: any) => ({
      category: `${ASSET_TYPES[asset.type]} ${asset.who === "spouse" ? "(Spouse)" : ""
        }`,
      monthly: asset.annual / 12,
      annual: asset.annual,
      percent: asset.percent,
    })),
    {
      category: "Debt Payments",
      amount: "",
      percent: "",
      isHeader: true,
    },
    ...sortedDebts.map((debt: any) => ({
      category: `${DEBT_TYPES[debt.type]} ${debt.who === "spouse" ? "(Spouse)" : ""
        }`,
      monthly: debt.annual / 12,
      annual: debt.annual,
      percent: debt.percent,
    })),
    {
      category: "Risk Management",
      amount: "",
      percent: "",
      isHeader: true,
    },
    {
      category: "Insurance Premiums",
      monthly: riskTotal / 12,
      annual: riskTotal,
      percent: (riskTotal / incomeTotal) * 100,
    },
    {
      category: "Available Funds",
      amount: "",
      percent: "",
      isHeader: true,
    },
    {
      category: "Unallocated Funds",
      monthly: remainingMoney / 12,
      annual: remainingMoney,
      percent: (remainingMoney / incomeTotal) * 100,
    },
  ];

  return (
    <>
      <Global
        styles={css`
          ${reviewStyles}
        `}
      />
      <div className="ai-content-title">
        <h1>Paycheck Allocation</h1>
      </div>
      <EuiSpacer size="l" />
      <EuiPanel paddingSize="l">
        <EuiFlexGroup justifyContent="spaceBetween" alignItems="center" className="no-wrap">
          <EuiFlexItem grow={false}>
            <EuiTitle size="s">
              <h3>Summary</h3>
            </EuiTitle>
          </EuiFlexItem>
          <EuiFlexItem grow={false} className="align-r">
            <EuiButtonGroup
              className="tabs-summary"
              legend="View options"
              options={[
                { id: "monthly", label: "Monthly" },
                { id: "annual", label: "Annual" },
              ]}
              idSelected={view}
              onChange={(id) => setView(id)}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
        <StyledSpacer size="32px" />
        <TableWrapper>
          <EuiBasicTable
            responsive={false}
            tableLayout="fixed"
            className={`${isSticky ? 'table-border' : ''} review-table eg-table`}
            columns={columns}
            items={data}
            rowProps={(item) => ({
              className: item.isHeader ? "header-style-review" : "content-row",
            })}
          />
        </TableWrapper>
      </EuiPanel>
      <StyledSpacer size="16px" />
    </>
  );
};

export default PayCheckAllocation;
