import React from "react";
import { useDispatch } from "react-redux";

import { Link } from "react-router-dom";

import { EuiIcon, EuiLink, EuiText, EuiTextColor } from "@elastic/eui";
import styled from "@emotion/styled";

import { savePlan } from "src/store/planBuild/actions";
import { PLAN_BUILD_STEPS } from "src/store/planBuild/constants";
import { colors } from "src/theme";
import { getShortDateString } from "src/utils";

const InfoBox = styled.div`
  background: ${colors.backgroundInfo};
  padding: 16px;
  margin-left: 36px;
  margin-top: 10px;
`;

const SuccessBox = styled.div`
  background: ${colors.backgroundSuccess};
  padding: 16px;
  margin-left: 36px;
  margin-top: 10px;
`;

const WarningBox = styled.div`
  background: ${colors.backgroundWarning};
  padding: 16px;
  margin-left: 36px;
  margin-top: 10px;
`;

const CONTAINERS = {
  info: InfoBox,
  success: SuccessBox,
  warning: WarningBox,
};

const EmergencyFundMessage = () => {
  const dispatch = useDispatch();

  const goToAdditionalQuestions = () => {
    dispatch(savePlan(PLAN_BUILD_STEPS.RECOMMENDATIONS));
  };
  return (
    <EuiText>
      <h4 className="mt-0">
        <EuiTextColor color={colors.info}>
          <EuiIcon type="iInCircle" className="mr-2" />
          You do not have enough saved for your emergency fund
        </EuiTextColor>
      </h4>
      <p>
        You currently do not have enough saved for your emergency fund.
        Therefore, FitBUX automatically prioritizes this as your first goal in
        your financial plan.
      </p>
      <p>
        If you want to change this, you can either a){" "}
        <EuiLink href="#" onClick={goToAdditionalQuestions}>
          reduce the amount of your emergency fund
        </EuiLink>{" "}
        or b){" "}
        <EuiLink>
          <Link to="/plan-summary">exit the plan builder</Link>
        </EuiLink>{" "}
        and add any accounts such as bank accounts onto your profile that you do
        not currently have listed.
      </p>
    </EuiText>
  );
};

const NoFundsAllocatedMessage = () => {
  const dispatch = useDispatch();

  const goToAllocations = () => {
    dispatch(savePlan(PLAN_BUILD_STEPS.ASSETS_AND_DEBTS));
  };
  return (
    <EuiText>
      <h4 className="mt-0">
        <EuiTextColor color={colors.warningText}>
          <EuiIcon type="warning" className="mr-2" />
        </EuiTextColor>
        You do not have enough money allocated to this goal
      </h4>
      <p>
        You moved this goal to number one but you don’t have any money allocated
        to this goal.{" "}
        <EuiLink href="#" onClick={goToAllocations}>
          Edit your checking/savings contribution
        </EuiLink>{" "}
        to increase the amount
      </p>
    </EuiText>
  );
};

const GoalAchievedMessage = () => (
  <EuiText>
    <h4 className="mt-0">
      <EuiTextColor color={colors.success}>
        <EuiIcon type="checkInCircleFilled" className="mr-2" />
        You have enough money to meet this goal
      </EuiTextColor>
    </h4>
    <p>
      We assume you’ll use the available funds to meet this goal. The next goal
      will be set as the highest priority.
    </p>
  </EuiText>
);

const LOAN_TYPE_STRINGS: any = {
  vehicle: "a car loan",
  home: "a mortgage",
  property: "an investment property loan",
};

const FutureDebtMessage = ({ goal }: any) => (
  <EuiText>
    <h4 className="mt-0">
      <EuiTextColor color={colors.info}>
        <EuiIcon type="iInCircle" className="mr-2" />
        You have {LOAN_TYPE_STRINGS[goal.eventType]} with an initial start date
        of {getShortDateString(goal.date)}
      </EuiTextColor>
    </h4>
    <p>Place this where you want to prioritize paying it.</p>
  </EuiText>
);

const MESSAGE_CONTENT = {
  emergencyFund: EmergencyFundMessage,
  noCashAllocation: NoFundsAllocatedMessage,
  savingsAchieved: GoalAchievedMessage,
  futureDebt: FutureDebtMessage,
};

const GoalMessage = ({ level, contentKey, goal }: any) => {
  const Container = CONTAINERS[level as keyof typeof CONTAINERS];
  const MessageContent =
    MESSAGE_CONTENT[contentKey as keyof typeof MESSAGE_CONTENT];
  return (
    <div>
      <Container>
        <MessageContent goal={goal} />
      </Container>
    </div>
  );
};

export default GoalMessage;
