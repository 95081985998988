import { createAction } from "redux-actions";
import { START } from "../common";

// import { Cashflow } from '../../interfaces/cashflow.interface';
// import { Household } from '../../interfaces/household.interface';
// import { Gender, YesNo } from '../../interfaces/common';
import { LifeEvent } from "src/interfaces/lifeEvents.interface";
import {
  Plan,
  Allocations,
  Assumptions,
  Earning,
  Expense,
  StudentLoan,
  PlanAllocations,
} from "src/interfaces/plan.interface";

import { PLAN_BUILD_STEPS } from "./constants";
import { OptimizedPlan } from "src/interfaces/optimizedPlanBuild.interface";

export const UPDATE_CURRENT_PLAN = "UPDATE_CURRENT_PLAN";
export const REPLACE_CURRENT_PLAN = "REPLACE_CURRENT_PLAN";
export const UPDATE_ALLOCATIONS = "UPDATE_ALLOCATIONS";
export const UPDATE_ASSUMPTIONS = "UPDATE_ASSUMPTIONS";
export const SET_CURRENT_PLAN = "SET_CURRENT_PLAN";
export const UPDATE_OPTIMIZED_TO_MANUAL = "UPDATE_OPTIMIZED_TO_MANUAL";
export const SET_OPTIMIZED_PLAN = "SET_OPTIMIZED_PLAN";
export const LOAD_LIVE_PLAN = "LOAD_LIVE_PLAN";
export const DELETE_PLAN = "DELETE_PLAN";
export const SET_MONTHLY_OVERVIEW = "SET_MONTHLY_OVERVIEW";
export const FETCH_PLANS = "FETCH_PLANS";
export const ESTIMATE_TAXES = "ESTIMATE_TAXES";
export const ESTIMATE_STUDENT_TAXES = "ESTIMATE_STUDENT_TAXES";
export const ESTIMATE_LIVE_STUDENT_TAXES = "ESTIMATE_LIVE_STUDENT_TAXES";
export const ESTIMATE_EMERGENCY_FUND = "ESTIMATE_EMERGENCY_FUND";
export const GET_LIABILITIES = "GET_LIABILITIES";
export const ADD_EARNING = "ADD_EARNING";
export const EDIT_EARNING = "EDIT_EARNING";
export const REMOVE_EARNING = "REMOVE_EARNING";
export const ADD_EXPENSE = "ADD_EXPENSE";
export const EDIT_EXPENSE = "EDIT_EXPENSE";
export const REMOVE_EXPENSE = "REMOVE_EXPENSE";
export const ADD_RISK = "ADD_RISK";
export const EDIT_RISK = "EDIT_RISK";
export const REMOVE_RISK = "REMOVE_RISK";
export const ADD_LIFE_EVENT = "ADD_LIFE_EVENT";
export const OPEN_EDIT_LIFE_EVENT = "OPEN_EDIT_LIFE_EVENT";
export const EDIT_LIFE_EVENT = "EDIT_LIFE_EVENT";
export const REMOVE_LIFE_EVENT = "REMOVE_LIFE_EVENT";
export const SET_BUILD_STEP = "SET_BUILD_STEP";
export const SET_FURTHEST_STEP = "SET_FURTHEST_STEP";
export const LOAD_COMPARISON_PLAN = "LOAD_COMPARISON_PLAN";
export const CLEAR_PLAN_COMPARISON = "CLEAR_PLAN_COMPARISON";
export const SAVE_PLAN = "SAVE_PLAN";
export const SAVE_PLAN_AND_CASHFLOWS = "SAVE_PLAN_AND_CASHFLOWS";
export const IMPLEMENT_PLAN = "IMPLEMENT_PLAN";
export const GET_PLAN_PROJECTION = "GET_PLAN_PROJECTION";
export const GET_LIVE_PLAN_PROJECTION = "GET_LIVE_PLAN_PROJECTION";
export const GET_SAVED_PLAN_PROJECTIONS = "GET_SAVED_PLAN_PROJECTIONS";
export const RESET_CURRENT_PLAN = "RESET_CURRENT_PLAN";
export const SET_NEW_PLAN = "SET_NEW_PLAN";
export const SET_REVIEW_SECTION = "SET_REVIEW_SECTION";
export const CLEAROUT_REVIEW_SECTION = "CLEAROUT_REVIEW_SECTION";
export const SET_PLAN_DIRTY = "SET_PLAN_DIRTY";
export const SET_SELECTED_PLAN = 'SET_SELECTED_PLAN';

export interface EditPayload<T> {
  index: number;
  update: Partial<T>;
}

export interface GetLiabilitiesPayload {
  filing_jointly: boolean;
  inschool: boolean;
  studentloan: StudentLoan[];
  incomes: Earning[];
  allocations: PlanAllocations;
}

export interface SetCurrentPlanPayload {
  index?: number;
  planId: number;
  keepId: boolean;
  isImplemented?: boolean;
}

export interface SetOptimizedPlanPayload {
  index?: number;
  planId: number;
  keepId: boolean;
  questionnaire: OptimizedPlan;
  planName: string;
}

export const updateCurrentPlan = createAction<Partial<Plan>>(
  UPDATE_CURRENT_PLAN
);
export const updateAllocations = createAction<Partial<Allocations>>(
  UPDATE_ALLOCATIONS
);
export const updateAssumptions = createAction<Partial<Assumptions>>(
  UPDATE_ASSUMPTIONS
);
export const setCurrentPlan = createAction<SetCurrentPlanPayload>(
  SET_CURRENT_PLAN + START
);
export const setSelectedPlan = (plan: any) => ({
  type: SET_SELECTED_PLAN,
  payload: plan,
})
export const setOptimizedPlan = createAction<SetOptimizedPlanPayload>(
  SET_OPTIMIZED_PLAN + START
);
export const deletePlan = createAction<number>(DELETE_PLAN + START);
export const setMonthlyOverview = createAction<boolean>(SET_MONTHLY_OVERVIEW);
export const fetchPlans = createAction(FETCH_PLANS + START);
export const estimateCurrentPlanTaxes = createAction(ESTIMATE_TAXES + START);
export const estimateCurrentPlanStudentTaxes = createAction(
  ESTIMATE_STUDENT_TAXES + START
);
export const estimateLivePlanStudentTaxes = createAction(
  ESTIMATE_LIVE_STUDENT_TAXES + START
);
export const estimateEmergencyFund = createAction(
  ESTIMATE_EMERGENCY_FUND + START
);
export const getLiabilities = createAction<GetLiabilitiesPayload>(
  GET_LIABILITIES + START
);
export const addLifeEvent = createAction<LifeEvent>(ADD_LIFE_EVENT);
export const openEditLifeEvent = createAction<number>(OPEN_EDIT_LIFE_EVENT);
export const editLifeEvent = createAction<EditPayload<LifeEvent>>(
  EDIT_LIFE_EVENT
);
export const removeLifeEvent = createAction<number>(REMOVE_LIFE_EVENT);
export const addEarning = createAction<Earning>(ADD_EARNING);
export const editEarning = createAction<EditPayload<Earning>>(EDIT_EARNING);
export const removeEarning = createAction<number>(REMOVE_EARNING);
export const addExpense = createAction<Expense>(ADD_EXPENSE);
export const editExpense = createAction<EditPayload<Expense>>(EDIT_EXPENSE);
export const removeExpense = createAction<number>(REMOVE_EXPENSE);
export const addRisk = createAction<Expense>(ADD_RISK);
export const editRisk = createAction<EditPayload<Expense>>(EDIT_RISK);
export const removeRisk = createAction<number>(REMOVE_RISK);
export const setBuildStep = createAction<PLAN_BUILD_STEPS>(SET_BUILD_STEP);
export const setFurthestStep = createAction<PLAN_BUILD_STEPS>(
  SET_FURTHEST_STEP
);
export const loadComparisonPlan = createAction<number>(
  LOAD_COMPARISON_PLAN + START
);
export const clearPlanComparison = createAction(CLEAR_PLAN_COMPARISON);
export const savePlan = createAction<PLAN_BUILD_STEPS | null>(
  SAVE_PLAN + START
);
export const savePlanAndCashflows = createAction<PLAN_BUILD_STEPS | null>(
  SAVE_PLAN_AND_CASHFLOWS
);
export const implementPlan = createAction<number>(IMPLEMENT_PLAN + START);
export const fetchPlanProjection = createAction(GET_PLAN_PROJECTION + START);
export const fetchLivePlanProjection = createAction<boolean>(
  GET_LIVE_PLAN_PROJECTION + START
);
export const fetchSavedPlanProjections = createAction(
  GET_SAVED_PLAN_PROJECTIONS + START
);
export const resetCurrentPlan = createAction(RESET_CURRENT_PLAN);
export const setNewPlan = createAction(SET_NEW_PLAN);
export const setReviewSection = createAction(SET_REVIEW_SECTION);
export const clearoutReviewSection = createAction(CLEAROUT_REVIEW_SECTION);
export const convertOptimizedToManual = createAction(
  UPDATE_OPTIMIZED_TO_MANUAL + START
);
export const setPlanDirty = createAction<boolean>(SET_PLAN_DIRTY);
// export const replaceCurrentPlan = createAction(REPLACE_CURRENT_PLAN);
