import { MONTHS, Months } from "src/constants";

export const getDateString = (date: Date) => {
  const offset = date.getTimezoneOffset();
  date = new Date(date.getTime() - offset * 60 * 1000);
  return date.toISOString().split("T")[0];
};

export const thirtyDaysAgo = () => {
  const date = new Date();
  date.setDate(date.getDate() - 30);
  return date;
};

export const ninetyDaysAgo = () => {
  const date = new Date();
  date.setDate(date.getDate() - 90);
  return date;
};

export const oneYearAgo = () => {
  const date = new Date();
  date.setDate(date.getDate() - 365);
  return date;
};

export const calculateAge = (date: any) => {
  const diff = Date.now() - date.valueOf();
  const days = diff / (1000 * 60 * 60 * 24);
  const years = days / 365.25;
  return Math.floor(years);
};

export const getShortDateString = (rawString: string) => {
  let inputString = rawString;
  if (inputString.length === 7) {
    inputString = `${inputString}-01`;
  }
  return new Date(inputString).toLocaleString("en", {
    month: "short",
    year: "numeric",
  });
};

export const formatDateToMonthYear = (dateString: string) => {
  if (!dateString) return "";

  const dateParts = dateString.split("-");
  if (dateParts.length !== 2) return dateString;

  const [year, month] = dateParts;
  const monthIndex = parseInt(month, 10) - 1;

  if (monthIndex < 0 || monthIndex > 11 || isNaN(monthIndex)) return dateString;

  const monthName = MONTHS[monthIndex];
  return `${monthName}, ${year}`;
};

export const formatDateToMonthYearAbbreviated = (dateString: string) => {
  if (!dateString) return "";

  const dateParts = dateString.split("-");
  if (dateParts.length !== 2) return dateString;

  const [year, month] = dateParts;
  const monthIndex = parseInt(month, 10) - 1;

  if (monthIndex < 0 || monthIndex > 11 || isNaN(monthIndex)) return dateString;

  const monthAbbreviation = Months[monthIndex].abbreviation;
  return `${monthAbbreviation}, ${year}`;
};
