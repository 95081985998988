import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import {
  EuiBadge,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiText,
  EuiTimeline,
  EuiTimelineItem,
} from "@elastic/eui";
import styled from "@emotion/styled";

import { getSelectedPlan } from "src/store/planBuild/selector";
import { StyledSpacer } from "src/components/Global/StyledComponents";
import step_success from "src/assets/svgs/step_success.svg";
import pencil_edit from "src/assets/svgs/pencil_edit.svg";

const ProgressBarFilled = styled.div<{ height: string; color: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: ${(props) => props.color};
  height: ${(props) => props.height};
  transition: height 0.3s ease;
  z-index: 2;
`;

export const reviewSections = [
  {
    label: "Plan Setup",
    steps: [],
  },
  {
    label: "Overview",
    steps: [
      { label: "Goal Summary", target: 0 },
      { label: "Paycheck Allocation", target: 1 },
      { label: "My FitBUX Score", target: 2 },
      { label: "Assets/Investments", target: 3 },
      { label: "Debts", target: 4 },
      { label: "Net Wealth Summary", target: 5 },
    ],
  },
  {
    label: "Implement",
    steps: [],
  },
];

const ReviewSidebar = ({
  handleClick,
  handleEdit,
  reviewStep,
}: {
  handleClick?: any;
  handleEdit?: any;
  reviewStep: number;
}) => {
  const [greenProgressHeight, setGreenProgressHeight] = useState("0px");
  const [blueProgressHeight, setBlueProgressHeight] = useState("0px");
  const timelineContainerRef = useRef<HTMLDivElement>(null);
  const selectedPlan = useSelector(getSelectedPlan);

  // Add state to track visited steps
  const [visitedSteps, setVisitedSteps] = useState(new Set<number>());

  useEffect(() => {
    if (timelineContainerRef.current) {
      const steps = timelineContainerRef.current.querySelectorAll(
        ".timeline-step"
      );
      const stepElements = Array.from(steps);

      const currentStepElement = stepElements.find(
        (el) => el.getAttribute("data-target") === reviewStep.toString()
      );

      if (currentStepElement) {
        const currentStepRect = currentStepElement.getBoundingClientRect();
        const timelineRect = timelineContainerRef.current.getBoundingClientRect();
        const heightToCurrentStep =
          currentStepRect.top + currentStepRect.height / 2 - timelineRect.top;

        const overviewStepElement = stepElements.find(
          (el) => el.getAttribute("data-target") === "0"
        );

        if (overviewStepElement) {
          const overviewStepRect = overviewStepElement.getBoundingClientRect();
          const heightToOverviewStep =
            overviewStepRect.top +
            overviewStepRect.height / 2 -
            timelineRect.top;

          if (reviewStep >= 0) {
            setGreenProgressHeight(`${heightToOverviewStep / 2}px`);
            setBlueProgressHeight(
              `${heightToCurrentStep - heightToOverviewStep / 2}px`
            );
          } else {
            setGreenProgressHeight(`${heightToCurrentStep}px`);
            setBlueProgressHeight("0px");
          }
        } else {
          setGreenProgressHeight(`${heightToCurrentStep}px`);
          setBlueProgressHeight("0px");
        }
      }
    }

    // Update visited steps when reviewStep changes
    setVisitedSteps((prev) => {
      const updatedVisitedSteps = new Set(prev);
      if (prev.has(reviewStep + 1)) {
        updatedVisitedSteps.delete(reviewStep + 1);
      }
      for (let i = 0; i <= reviewStep; i++) {
        updatedVisitedSteps.add(i);
      }
      return updatedVisitedSteps;
    });
  }, [reviewStep]);

  return (
    <div className="side-bar-wrapper">
      <EuiText className="side-bar-header">
        <h2>{selectedPlan ? selectedPlan.name : "AI Financial Plan"}</h2>
        <EuiBadge>
          {selectedPlan && selectedPlan.implemented
            ? "Implemented"
            : "Not Implemented"}
        </EuiBadge>
      </EuiText>
      <StyledSpacer size="32px" />
      <div style={{ position: "relative" }}>
        <div className="progress-bar-container">
          <ProgressBarFilled height={greenProgressHeight} color="#00bfb3" />
          <ProgressBarFilled
            height={blueProgressHeight}
            color="#0073e6"
            style={{ top: greenProgressHeight }}
          />
        </div>
        <div ref={timelineContainerRef}>
          <EuiTimeline className="timeline">
            {reviewSections.map((section, sectionIndex) => (
              <React.Fragment key={section.label}>
                {sectionIndex === 0 ? (
                  <EuiTimelineItem
                    icon={<EuiIcon type={step_success} />}
                    verticalAlign="center"
                    className="section-item"
                  >
                    <EuiFlexGroup alignItems="center" gutterSize="s" className="no-wrap">
                      <EuiFlexItem grow={false}>
                        <EuiText
                          style={{
                            color: sectionIndex < 2 ? "#343741" : undefined,
                          }}
                        >
                          <b>{section.label}</b>
                        </EuiText>
                      </EuiFlexItem>
                      <EuiFlexItem grow={false}>
                        <EuiIcon
                          type={pencil_edit}
                          onClick={handleEdit}
                          className="pencil-icon"
                        />
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiTimelineItem>
                ) : (
                  <>
                    <EuiTimelineItem
                      icon={
                        <div
                          className="step-number"
                          style={{
                            background:
                              sectionIndex === 1 ? "#0077CC" : "#98A2B3",
                          }}
                        >
                          {sectionIndex + 1}
                        </div>
                      }
                      verticalAlign="center"
                      className="section-item"
                    >
                      <EuiText
                        style={{
                          color: sectionIndex < 2 ? "#343741" : undefined,
                        }}
                      >
                        <b>{section.label}</b>
                      </EuiText>
                    </EuiTimelineItem>
                    {section.steps.map((step) => {
                      const isActive = step.target === reviewStep;
                      const isVisited = visitedSteps.has(step.target);
                      return (
                        <EuiTimelineItem
                          key={step.label}
                          className="timeline-step"
                          icon={
                            <EuiIcon
                              type="dot"
                              color={
                                isActive || isVisited ? "#0073e6" : "#98A2B3"
                              }
                            />
                          }
                          iconAriaLabel={`Step ${step.target}`}
                          data-target={step.target}
                          onClick={() => handleClick(step.target)}
                        >
                          <EuiText
                            style={{
                              color: "#343741",
                              fontWeight: isActive ? "bold" : "normal",
                            }}
                          >
                            {step.label}
                          </EuiText>
                        </EuiTimelineItem>
                      );
                    })}
                  </>
                )}
              </React.Fragment>
            ))}
          </EuiTimeline>
        </div>
      </div>
    </div>
  );
};

export default ReviewSidebar;