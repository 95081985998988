import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { InlineWidget } from "react-calendly";
import { useHistory } from "react-router-dom";
import { getCalendlyLinkApi } from "src/apiService";
import { Modal } from "src/components/Dialogs";
import useWindowSize from "src/hooks/useWindowSize";
import { getScore } from "src/store/dashboard/selector";
import { isLoggedInSelector } from "src/store/system/selector";
import { getRouterState } from "src/store/router/selector";
import {
  EuiHeader,
  EuiHeaderSectionItemButton,
  EuiHeaderSectionItem,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
  EuiListGroupItem,
  EuiPopover,
  EuiIcon,
  EuiListGroup,
} from "@elastic/eui";
import Logo from "src/components/Global/FitbuxLogo";
import FitbuxScore from "src/components/Global/FitbuxScore";
import profile_icon from "src/assets/svgs/profile_dash2.svg";
import message_icon from "src/assets/svgs/message_dash.svg";
import phone_icon from "src/assets/svgs/phone_dash.svg";
import styled from "@emotion/styled";
import { StyledEuiHorizontalRule } from "src/components/Global/StyledComponents";
import { Global, css } from "@emotion/react";

const headerStyles = css`
  .icon-group {
    gap: 16px !important;
  }
`;

const StyledHeader = styled(EuiHeader)`
  box-shadow: none;
  border-bottom: none;
  padding: 0 1rem;
  block-size: 60px;
`;

const VerticalBar = styled.div`
  border-left: 1px solid #ccc;
  height: 32px;
  margin: 0 1rem;
`;

const StyledEuiFlexGroup = styled(EuiFlexGroup)`
  img {
    margin: 0 5px;
  }
`;

interface DashboardHeaderProps {
  className?: string;
  navIsOpen: boolean;
  setNavIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const DashboardHeader: React.FC<DashboardHeaderProps> = ({ navIsOpen, setNavIsOpen, className }) => {
  const score = useSelector(getScore);
  const loggedIn = useSelector(isLoggedInSelector);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentScore, setCurrentScore] = useState(score);
  const [dialogUrl, setDialogUrl] = useState("");
  const router = useSelector(getRouterState);
  const { width } = useWindowSize();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const history = useHistory();
  const closeDialog = () => setDialogOpen(false);
  const togglePopover = () => {
    setIsPopoverOpen(!isPopoverOpen);
  };

  useEffect(() => {
    if (router?.location?.hash === "#schedule") {
      openCalendlyDialog();
    }
  }, []);

  const openCalendlyDialog = () => {
    if (dialogUrl) {
      setDialogOpen(true);
    }
    getCalendlyLinkApi()
      .then((result) => {
        let calendlyUrl = result.url + "?";
        if (result.prefill) {
          calendlyUrl += `name=${result.prefill.name}&email=${result.prefill.email}`;
          if (result.prefill.customAnswers) {
            calendlyUrl += `&a1=${result.prefill.customAnswers.a1}`;
          }
        }
        setDialogUrl(calendlyUrl);
        setDialogOpen(true);
      })
      .catch(console.error);
  };

  const button = (
    <EuiHeaderSectionItemButton
      aria-controls="popoverId"
      aria-expanded={isPopoverOpen}
      aria-haspopup="true"
      aria-label="Account menu"
      onClick={togglePopover}
    >
      <img src={profile_icon} alt="Profile" />
    </EuiHeaderSectionItemButton>
  );

  if (loggedIn) {
    return (
      <>
        <Global styles={headerStyles} />
        <StyledHeader className={className}>
          <EuiHeaderSectionItem className={`logo-container ${width > 992 ? 'hidden-logo' : ''}`}>
            <EuiIcon className="hamburger-menu" type="menu" size="m" aria-label="Toggle main navigation" onClick={() => setNavIsOpen(!navIsOpen)} />
            <Logo link="/home" alt="fitbux logo"
            />
          </EuiHeaderSectionItem>
          <EuiHeaderSectionItem>
            <EuiFlexGroup gutterSize="s" alignItems="flexEnd" style={{ flexWrap: 'nowrap' }}>
              <EuiFlexItem grow={false}>
                <FitbuxScore currentScore={currentScore}></FitbuxScore>
              </EuiFlexItem>
              <VerticalBar />
              {width <= 991 && (
                <EuiFlexItem grow={false}>
                  <EuiLink onClick={openCalendlyDialog}>
                    <img src={phone_icon} alt="Call" />
                  </EuiLink>
                </EuiFlexItem>
              )}
              {width >= 992 && (
                <EuiFlexItem grow={false}>
                  <StyledEuiFlexGroup alignItems="flexEnd" className="icon-group">
                    <EuiFlexItem grow={false}>
                      <EuiLink onClick={openCalendlyDialog}>
                        <img src={phone_icon} alt="Call" />
                      </EuiLink>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <EuiLink onClick={() => history.push("/coach")}>
                        <img src={message_icon} alt="Coach" />
                      </EuiLink>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <EuiPopover
                        repositionOnScroll
                        button={button}
                        isOpen={isPopoverOpen}
                        closePopover={togglePopover}
                        anchorPosition="downRight"
                        panelPaddingSize="xs"
                      >
                        <div className="profile-popover">
                          <EuiListGroup>
                            <EuiListGroupItem label="Settings" onClick={() => { togglePopover(); history.push("/settings"); }} />
                            <StyledEuiHorizontalRule />
                            <EuiListGroupItem label="Refer a Friend" onClick={() => { togglePopover(); history.push("/referral"); }} />
                            <StyledEuiHorizontalRule />
                            <EuiListGroupItem label="How FitBUX works" href="https://www.youtube.com/playlist?list=PLYk9ILj711p4xl0yQOm2G5QSMp_ZTBPeG" />
                            <StyledEuiHorizontalRule />
                            <EuiListGroupItem label="Log Out" onClick={() => { togglePopover(); history.push("/logout"); }} />
                          </EuiListGroup>
                        </div>
                      </EuiPopover>
                    </EuiFlexItem>
                  </StyledEuiFlexGroup>
                </EuiFlexItem>
              )}
            </EuiFlexGroup>
          </EuiHeaderSectionItem>
        </StyledHeader >
        <Modal title=" " size="lg" isOpen={dialogOpen} onClose={closeDialog}>
          {/* Override Calendly's default height of 630.
        646+ should remove the iframe's scroll bar.
        850 should show 9 timeslots on desktop */}
          <InlineWidget url={dialogUrl} styles={{ height: 850 }} />
        </Modal>
      </>
    );
  } else
    return (
      <>
        <div />
      </>
    );
};

export default DashboardHeader;
