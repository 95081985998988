import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { sortBy } from "lodash";

import { Box, makeStyles, Typography } from "@material-ui/core";

import {
  fetchAccountTickersApi,
  getHouseholdPerformanceApi,
} from "src/apiService";
import RiskSlider from "src/components/RiskSlider";
import { Account } from "src/interfaces";
import { getInvestmentAccounts } from "src/store/account/selector";
import { getScore } from "src/store/dashboard/selector";
import { getPortfolioPerformance } from "src/store/investments/selector";
import { AdapterLink, scoreToAllocation } from "src/utils";
import { EuiCard, EuiText, EuiButton, EuiImage } from "@elastic/eui";
import { Link } from "react-router-dom";
import zero_state_investment from "src/assets/svgs/allocation_empty_state.svg";
import { StyledSpacer } from "src/components/Global/StyledComponents";
import { Global, css } from "@emotion/react";

const iaStyles = css`
  .css-4ld6o8-euiCard__children {
    margin-block-start: 0 !important;
  }
  .card-title {
    font-size: 20px;
    line-height: 32px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    color: #1A1C21;
    text-align: left;
  }
`;


const useStyles = makeStyles({
  bottomLinks: {
    position: "absolute",
    // display: "flex",
    // justifyContent: "space-between",
    bottom: 10,
    fontSize: 10,
  },
  content: {
    height: 350,
    position: "relative",
  },
});

export const InvestmentAllocationCard = () => {
  const classes = useStyles();
  const score = useSelector(getScore);
  const { standardDeviations } = useSelector(getPortfolioPerformance);
  // const portfolioSettings = useSelector(getPortfolioSettings);
  const [myPerformance, setMyPerformance] = useState<any>(null);
  const [error, setError] = useState(false);
  const fitbuxAllocation = scoreToAllocation(score);
  const accounts: any[] = useSelector(getInvestmentAccounts);
  const [accountInvestments, setAccountInvestments] = useState<any>({});

  const refresh = () => {
    const newInvestments: any = {};
    Promise.all(
      accounts.map((account) =>
        fetchAccountTickersApi(account.id).then((results) => {
          const sorted = sortBy(
            results,
            (item: any) => -(item.quantity * item.close_price)
          );
          newInvestments[account.id] = sorted;
        })
      )
    ).then(() => {
      setAccountInvestments((current: any) => ({
        ...current,
        ...newInvestments,
      }));
    });
  };

  useEffect(refresh, [accounts]);

  const accountBuckets = useMemo(() => {
    const withInvestments: Account[] = [];
    const withoutInvestments: Account[] = [];
    accounts.forEach((account) => {
      if (accountInvestments[account.id]?.length) {
        withInvestments.push(account);
      } else {
        withoutInvestments.push(account);
      }
    });
    return { withInvestments, withoutInvestments };
  }, [accountInvestments]);

  useEffect(() => {
    getHouseholdPerformanceApi()
      .then((result) => setMyPerformance(result.inception))
      .catch(() => setError(true));
  }, []);

  const renderContent = () => {
    if (error || !myPerformance || accountBuckets.withoutInvestments.length) {
      return (
        <>
          <StyledSpacer size="16px" />
          <EuiImage
            className=""
            src={zero_state_investment}
            alt="woman on tablet"
            size="original"
          />
          <StyledSpacer size="24px" />
          <Link to="/tools/investment?return=summary">
            <EuiButton fill color="primary">
              Add/Edit Your Investment Holdings
            </EuiButton>
          </Link>
          <StyledSpacer size="16px" />
          <EuiText>
            Add/edit your investment holdings to view FitBUX's allocation recommendation.
          </EuiText>
        </>
      );
    }

    const fbStdDev = standardDeviations["" + fitbuxAllocation];
    const myStdDev = myPerformance.standard_deviation * 100;
    return (
      <Box className={classes.content}>
        <Box className="pt-20">
          <RiskSlider
            sliderWidth={300}
            fitbuxStdDev={fbStdDev}
            myStdDev={myStdDev}
          />
        </Box>
        <Box className={classes.bottomLinks}>
          <Box>
            <AdapterLink to="/investment-allocations?return=summary">
              Review My Current Portfolio's Allocations and Risk
            </AdapterLink>
          </Box>
          <Box>
            <AdapterLink to="/tools/investment?return=summary">
              See How My Risk And Returns Change When I Adjust My Allocations
            </AdapterLink>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Global styles={iaStyles} />
      <EuiCard
        title={
          <div>
            <h2 className="card-title">Investment Allocation</h2>
          </div>
        }
      >
        {renderContent()}
      </EuiCard>
    </>
  );
};

export default InvestmentAllocationCard;
