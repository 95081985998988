import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { navigation, nestedNavigation, combinedNavigation, secondaryMobileNavigation } from "src/config/navigation";
import { getRouterState } from "src/store/router/selector";
import { setActivePage } from "src/store/dashboard/actions";
import { getActivePage } from "src/store/dashboard/selector";
import { getIsSubscribed } from "src/store/system/selector";
import { EuiPanel, EuiSideNav, EuiIcon, EuiListGroup, EuiListGroupItem, EuiCollapsibleNavGroup, EuiCollapsibleNav } from "@elastic/eui";
import { getCustomIcon } from "../CustomIcon";
import Logo from "src/components/Global/FitbuxLogo";
import useWindowSize from "src/hooks/useWindowSize";
import { StyledEuiHorizontalRule, StyledSpacer } from "../Global/StyledComponents";

interface NavigatorProps {
  className?: string;
  navIsOpen: boolean;
  setNavIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title?: string;
  variant?: string;
  closeMobileOpen?: () => void;
  collapsed?: boolean;
  onCollapse?: () => void;
}

const Navigator: React.FC<NavigatorProps> = ({ className, navIsOpen, setNavIsOpen, ...props }) => {
  const dispatch = useDispatch();
  const isSubscribed = useSelector(getIsSubscribed);
  const router = useSelector(getRouterState);
  const activePage = useSelector(getActivePage);
  const { width } = useWindowSize();
  const [expandedIndex, setExpandedIndex] = useState(0);
  const { title, variant, closeMobileOpen, collapsed, onCollapse, ...other } = props;

  useEffect(() => {
    const nav: any = navigation.find((e) => e.href === router.location.pathname);
    if (nav && nav.id) {
      dispatch(setActivePage(nav.id));
      const newExpandedIndex = nestedNavigation.findIndex(
        (heading) => !!heading.items.find((item) => item.id === nav.id)
      );
      if (newExpandedIndex >= 0) {
        setExpandedIndex(newExpandedIndex);
      } else {
        setExpandedIndex(-1);
      }
      if (closeMobileOpen) {
        closeMobileOpen();
      }
    }
  }, [router, closeMobileOpen]);

  const handleNavigation = (item: any) => {
    dispatch(setActivePage(item.id));
    dispatch(push(item.href));
    setNavIsOpen(false);
  };

  return (
    <>
      {width <= 991 && (
        <EuiCollapsibleNav
          className="popout-nav"
          isOpen={navIsOpen}
          onClose={() => setNavIsOpen(false)}
          style={{ width: 276, overflow: 'auto' }}
        >
          <EuiPanel {...other}>
            <div className="popout-nav-header">
              <Logo link="/" className="logo-container" alt="fitbux logo" />
              <EuiIcon
                type="cross"
                size="l"
                onClick={() => setNavIsOpen(false)}
                className="close-nav"
              />
            </div>
            {combinedNavigation.map(({ title, icon, items, single }, index) => {
              const IconComponent = icon ? getCustomIcon(icon as "Home" | "PlanSummary" | "FinancialPlans" | "MoneyDetails" | "Transactions" | "Accounts" | "ToolsProducts") : null;

              // Single Page Links
              if (!IconComponent) {
                return null;
              }
              if (single) {
                const item = items[0];
                return (
                  <EuiListGroupItem
                    key={item.id}
                    className="single-nav-item"
                    label={item.title}
                    iconType={IconComponent}
                    isActive={activePage === item.id}
                    onClick={() => handleNavigation(item)}
                  />
                );
              }

              // Nested Page Links
              if (!IconComponent) {
                return null;
              }

              return (
                <EuiCollapsibleNavGroup
                  key={title}
                  className="nested-nav-item"
                  title={title}
                  iconType={IconComponent}
                  isCollapsible={true}
                  initialIsOpen={index === expandedIndex}
                >
                  <EuiListGroup>
                    {items.map((item) => (
                      <EuiListGroupItem
                        key={item.id}
                        label={item.title}
                        isActive={activePage === item.id}
                        onClick={() => handleNavigation(item)}
                      />
                    ))}
                  </EuiListGroup>
                </EuiCollapsibleNavGroup>
              );
            })}

            <StyledSpacer size="32px" />
            <StyledEuiHorizontalRule style={{ width: 285, margin: 'auto' }} />
            <StyledSpacer size="32px" />

            {secondaryMobileNavigation.map(({ id, title, icon, href }) => {
              const IconComponent = getCustomIcon(icon as "Coach" | "Refer" | "Settings" | "Works" | "Logout");

              if (!IconComponent) {
                return null;
              }

              return (
                <EuiListGroupItem
                  key={id}
                  className="single-nav-item"
                  label={title}
                  iconType={IconComponent}
                  onClick={() => handleNavigation({ id, href })}
                />
              );
            })}
          </EuiPanel>
        </EuiCollapsibleNav>
      )}
      <EuiPanel {...other} className="side-nav">
        <Logo link="/" className="logo-container" alt="fitbux logo" />
        {combinedNavigation.map(({ title, icon, items, single }, index) => {
          const IconComponent = icon ? getCustomIcon(icon as "Home" | "PlanSummary" | "FinancialPlans" | "MoneyDetails" | "Transactions" | "Accounts" | "ToolsProducts") : null;

          // Single Page Links
          if (!IconComponent) {
            return null;
          }
          if (single) {
            const item = items[0];
            return (
              <EuiListGroupItem
                key={item.id}
                className="single-nav-item"
                label={item.title}
                iconType={IconComponent}
                isActive={activePage === item.id}
                onClick={() => handleNavigation(item)}
              />
            );
          }

          // Nested Page Links
          if (!IconComponent) {
            return null;
          }

          return (
            <EuiCollapsibleNavGroup
              key={title}
              className="nested-nav-item"
              title={title}
              iconType={IconComponent}
              isCollapsible={true}
              initialIsOpen={index === expandedIndex}
            >
              <EuiListGroup>
                {items.map((item) => (
                  <EuiListGroupItem
                    key={item.id}
                    label={item.title}
                    isActive={activePage === item.id}
                    onClick={() => handleNavigation(item)}
                  />
                ))}
              </EuiListGroup>
            </EuiCollapsibleNavGroup>
          );
        })}
      </EuiPanel>
    </>
  );
};

export default Navigator;