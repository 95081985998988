import React from "react";

import { Box, makeStyles, Typography } from "@material-ui/core";
import { EuiButton, EuiImage, EuiText, EuiCard } from "@elastic/eui";
import { Link } from "react-router-dom";
import idr_zero_state from "src/assets/svgs/idr_state.svg";
import { StyledSpacer } from "src/components/Global/StyledComponents";

const useStyles = makeStyles({
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: 40,
    width: "100%",
  },
});

const IdrSolutionBlock = () => {
  const styles = useStyles();

  return (
    <EuiCard
      title={
        <div>
          <h2 className="card-title">IDR Taxes</h2>
        </div>
      }
    >
      <StyledSpacer size="16px" />
      <EuiImage
        className=""
        src={idr_zero_state}
        alt="budget sheet"
        size="original"
      />
      <StyledSpacer size="24px" />
      <Link to="/wealth#debt">
        <EuiButton fill color="primary">
          Add/Edit Your IDR Tax Details
        </EuiButton>
      </Link>
      <StyledSpacer size="16px" />
      <EuiText>
        Add/edit your IDR tax details to view your savings progress.
      </EuiText>

    </EuiCard>
  );
};

export default IdrSolutionBlock;
