import React from "react";
import { useSelector } from "react-redux";

import { Grid, makeStyles } from "@material-ui/core";

import { SPECIAL_REPAYMENTS } from "src/interfaces";
import ScoreSummary from "src/pages/Dashboard/ScoreFactors/Blocks/FitbuxScore";
import { getProfile, getSpouseProfile } from "src/store/profileBuild/selector";
import { getIsSubscribed } from "src/store/system/selector";
import { EuiCard, EuiImage, EuiButton } from "@elastic/eui";
import { StyledSpacer } from "src/components/Global/StyledComponents";
import { Link } from "react-router-dom";
import zero_state_plan_summary from "src/assets/svgs/zero_state_no_plan.svg";
import { Global, css } from '@emotion/react';

const noPlanSummaryStyles = css`
  .css-4ld6o8-euiCard__children {
    margin-block-start: 0 !important;
  }
`;

const useStyles = makeStyles({
  freeFocus: {
    order: -1,
  },
});

export const NoPlanSummary = () => {
  const styles = useStyles();
  const isSubscribed = useSelector(getIsSubscribed);
  const profile = useSelector(getProfile);
  const spouseProfile = useSelector(getSpouseProfile);
  // const isCurrentStudent = useSelector(getIsCurrentStudent);

  const orderStyle = isSubscribed ? undefined : styles.freeFocus;
  const hasIdr =
    SPECIAL_REPAYMENTS.indexOf(profile?.fed_repayment_plan || "") >= 0 ||
    SPECIAL_REPAYMENTS.indexOf(spouseProfile?.fed_repayment_plan || "") >= 0;

  return (
    <>
      <Global styles={noPlanSummaryStyles} />
      <EuiCard
        title=""
        paddingSize="l"
        description=""
        textAlign="center"
        className="zero-state-card"
      >
        <EuiImage
          className=""
          src={zero_state_plan_summary}
          alt="woman on tablet"
          size="original"
        />
        <StyledSpacer size="24px" />
        <Link to="/build-plan">
          <EuiButton fill color="primary">
            Build Your Financial Plan
          </EuiButton>
        </Link>
      </EuiCard>
    </>
  );
};

export default NoPlanSummary;
