const plan5559 = {
    "start": "2024-07",
    "score": [
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500
    ],
    "series": {
        "asset": [
            151781.12,
            152059.19,
            452242.55,
            452426.49,
            452611.02,
            452796.14,
            452981.84,
            453168.13,
            453355.01,
            453542.49,
            453730.55,
            453919.21,
            439108.46,
            439298.31,
            439488.76,
            439679.8,
            439871.44,
            440063.69,
            440256.53,
            440449.98,
            440644.03,
            440838.69,
            441033.96,
            441229.83,
            666941.28,
            667654.39,
            668369.18,
            669130.75,
            669894.09,
            670659.19,
            671426.07,
            672194.72,
            672965.14,
            673737.35,
            674511.34,
            675287.12,
            676064.69,
            676844.06,
            677625.23,
            678408.2,
            679192.98,
            679979.57,
            689850.52,
            708783.74,
            727763.01,
            746788.39,
            765859.93,
            784977.67,
            804288.3,
            823645.43,
            843049.11,
            849834.18,
            856642.95,
            863475.41,
            870395.12,
            877317.37,
            884242.15,
            891169.45,
            898099.26,
            905031.57,
            912432.45,
            919836.2,
            927242.8,
            934734.7,
            942229.45,
            949727.04,
            957293.25,
            964862.49,
            972434.75,
            980010.03,
            987588.3,
            995169.56,
            1002812.16,
            1010457.7,
            1018106.17,
            1129028.42,
            1129952.66,
            1130878.89,
            1131807.13,
            1132737.37,
            1133669.62,
            1134603.88,
            1135540.16,
            1136478.46,
            1137418.78,
            1138361.13,
            1139305.52,
            1140251.94,
            1141200.4,
            1142150.91,
            1145700.31,
            1153336.63,
            1160998.58,
            1168686.17,
            1176399.41,
            1184138.33,
            1192245.15,
            1200378.03,
            1208536.98,
            1216834.59,
            1225234.53,
            1233660.66,
            1242171.71,
            1250687.8,
            1259208.91,
            1267735.03,
            1276266.16,
            1284802.3,
            1293360.45,
            1301923.6,
            1348562.32,
            1349203.26,
            1349846.42,
            1350491.81,
            1351139.43,
            1352930.8,
            1361552.52,
            1370180.38,
            1378814.36,
            1387454.46,
            1396115.97,
            1404783.49,
            1413457.02,
            1422280.26,
            1432204.55,
            1442157.42,
            1452198.03,
            1462267.46,
            1472344.79,
            1482429.58,
            1492521.8,
            1493237.27,
            1503335.69,
            1513441.53,
            1523554.77,
            1533801.41,
            1544140.82,
            1554487.71,
            1564903.39,
            1575326.75,
            1585757.79,
            1596196.51,
            1606642.89,
            1617096.92,
            1627558.62,
            1638027.95,
            1648504.93,
            1659119.3,
            1669831.8,
            1680552.02,
            1691343.55,
            1702143.02,
            1712950.41,
            1723765.71,
            1734588.92,
            1745420.03,
            1756259.04,
            1767105.94,
            1777960.71,
            1788971.55,
            1800092.53,
            1811221.5,
            1822424.43,
            1833635.58,
            1844854.91,
            1856082.44,
            1867318.16,
            1878562.05,
            1889814.11,
            1901074.33,
            1912342.7,
            1923771.86,
            1935314.51,
            1946865.41,
            1958493.02,
            1970129.11,
            1981773.69,
            1993426.74,
            2005088.27,
            2016758.25,
            2028436.68,
            2040123.56,
            2051818.88,
            2063679.84,
            2075657.73,
            2087644.17,
            2099710.12,
            2111784.86,
            2123868.38,
            2135960.66,
            2148061.71,
            2160171.5,
            2172290.05,
            2184417.33,
            2196553.34,
            2208859.99,
            2221287.13,
            2233723.11,
            2246241.52,
            2258769.01,
            2271305.58,
            2283851.23,
            2296405.93,
            2308969.69,
            2321542.5,
            2334124.35,
            2346715.22,
            2359481.91,
            2372372.13,
            2385271.14,
            2398255.24,
            2411248.38,
            2424250.56,
            2437261.77,
            2450281.99,
            2463311.23,
            2476349.47,
            2489396.7,
            2502452.91,
            2515683.57,
            2529037.39,
            2542400.29,
            2555851.35,
            2569311.74,
            2582781.47,
            2596260.51,
            2609748.86,
            2623246.52,
            2636753.46,
            2650269.69,
            2664211.86,
            2678333.73,
            2692582.48,
            2706840.59,
            2721190.04,
            2735549.12,
            2749917.84,
            2764296.18,
            2778684.12,
            2793081.67,
            2807488.81,
            2821905.53,
            2836331.82,
            2850943.23,
            2866773.99,
            2882614.91,
            2898558.71,
            2914512.96,
            2930477.66,
            2946452.79,
            2962438.34,
            2978434.3,
            2994440.66,
            3010457.39,
            3026484.5,
            3042706.28,
            3059106.5,
            3075517.96,
            3092036.76,
            3108567.11,
            3125109.01,
            3141662.44,
            3158227.38,
            3174803.84,
            3191391.79,
            3207991.22,
            3224602.13,
            3240544.64,
            3256658.97,
            3272782.59,
            3289026.86,
            3305280.79,
            3321544.35,
            3337817.53,
            3354100.31,
            3370392.68,
            3386694.62,
            3403006.11,
            3419327.13,
            3435850.55,
            3452550.99,
            3469261.06,
            3486096.06,
            3502941.06,
            3519796.04,
            3536660.97,
            3553535.85,
            3570420.65,
            3587315.36,
            3604219.96,
            3621134.43,
            3638257.42,
            3655562.79,
            3672878.13,
            3690322.83,
            3707777.88,
            3725243.26,
            3742718.95,
            3760204.94,
            3777701.19,
            3795207.7,
            3812724.45,
            3830251.42,
            3847993.22,
            3863673.04,
            3879372.41,
            3895115.93,
            3910879.14,
            3926662.05,
            3942464.68,
            3958287.06,
            3974129.2,
            3989991.13,
            4005872.86,
            4021774.42,
            4037735.44,
            4053970.13,
            4070224.62,
            4086524.4,
            4102844.13,
            4119183.82,
            4135543.51,
            4151923.2,
            4168322.92,
            4184742.69,
            4201182.53,
            4217642.45,
            4234162.15,
            4250968.28,
            4267794.9,
            4284668.42,
            4301562.57,
            4318477.4,
            4335412.91,
            4352369.13,
            4369346.07,
            4386343.77,
            4403362.24,
            4420401.5,
            4437502.42,
            4454915.08,
            4472348.97,
            4489831.42,
            4507335.26,
            4524860.51,
            4542407.19,
            4559975.32,
            4577564.92,
            4595176.02,
            4612808.63,
            4630462.78,
            4648180.58,
            4666219.58,
            4684280.58,
            4702391.86,
            4720525.29,
            4738680.9,
            4756858.7,
            4775058.73,
            4793281,
            4811525.53,
            4829792.35,
            4848081.48,
            4866436.28,
            4885122.05,
            4903830.61,
            4922591.23,
            4941374.79,
            4960181.32,
            4979010.84,
            4997863.38,
            5016738.95,
            5035637.57,
            5054559.28,
            5073504.1,
            5092516.68,
            5111870.3,
            5131247.5,
            5150678.61,
            5170133.49,
            5189612.14,
            5209114.6,
            5228640.9,
            5248191.05,
            5267765.08,
            5287363.01,
            5306984.87,
            5326676.66,
            5346719.84,
            5366787.45,
            5386910.86,
            5407058.88,
            5427231.52,
            5447428.82,
            5467650.79,
            5487897.47,
            5508168.87,
            5528465.02,
            5548785.95,
            5569179.04,
            5589934.18,
            5610714.63,
            5631552.83,
            5652416.52,
            5673305.7,
            5694220.4,
            5715160.66,
            5736126.49,
            5757117.92,
            5778134.97,
            5799177.68,
            5820294.84,
            5841785.06,
            5863301.46,
            5884877.66,
            5906480.23,
            5928109.2,
            5949764.59,
            5971446.44,
            5993154.75,
            6014889.57,
            6036650.92,
            6058438.82,
            6080303.55,
            6102552.67,
            6124828.88,
            6147166.99,
            6169532.39,
            6191925.12,
            6214345.2,
            6236792.67,
            6259267.53,
            6281769.83,
            6304299.6,
            6326856.85,
            6349493.37,
            6372525.93,
            6395586.56,
            6418711.23,
            6441864.16,
            6465045.37,
            6488254.9,
            6511492.76,
            6534758.99,
            6558053.61,
            6581376.66,
            6604728.15,
            6628161.44,
            6652002.79,
            6675873.18,
            6699809.85,
            6723775.76,
            6747770.95,
            6771795.44,
            6791846.47,
            6811922.23,
            6832022.75,
            6852148.06,
            6872298.19,
            6892528.05,
            6913027.62,
            6933552.42,
            6954133.35,
            6974739.68,
            6995371.44,
            7016028.67,
            7036711.37,
            7057419.59,
            7078153.34,
            7098912.65,
            7119697.55,
            7140564.62,
            7161709.51,
            7182880.43,
            7204109.26,
            7225364.3,
            7246645.57,
            7267953.08,
            7289286.88,
            7310646.99,
            7332033.43,
            7353446.23,
            7374885.42,
            7396409.28,
            7418238.97,
            7440095.52,
            7462011.87,
            7483955.27,
            7505925.73,
            7527923.3,
            7549948,
            7571999.85,
            7594078.88,
            7616185.13,
            7638318.61,
            7660539.36,
            7683081.67,
            7705651.71,
            7728283.5,
            7750943.2,
            7773630.86,
            7796346.51,
            7819090.16,
            7841861.85,
            7864661.61,
            7887489.46,
            7910345.44,
            7933291.37,
            7956568.58,
            7979874.42,
            8003244,
            8026642.42,
            8050069.69,
            8073525.86
        ],
        "debt": [
            318526.61,
            315979.67,
            313019.83,
            310046.52,
            307059.67,
            304059.19,
            301018.31,
            297963.13,
            294893.55,
            291809.46,
            288713.03,
            285606.41,
            343747.12,
            336860.56,
            329941.39,
            322982.88,
            308757.73,
            294444.76,
            279979.41,
            265424.02,
            250777.96,
            236040.63,
            221217.62,
            206322.6,
            414730.04,
            398060.35,
            381313.14,
            364524.14,
            347232.8,
            329868.6,
            312405.36,
            294868.47,
            277257.61,
            259572.45,
            241812.66,
            223977.93,
            205925.56,
            187796.97,
            169591.8,
            151309.73,
            132505.78,
            113649.92,
            103788.89,
            102910.92,
            102028.86,
            101142.7,
            100252.41,
            99357.97,
            98459.36,
            97556.57,
            96649.57,
            95738.35,
            94822.88,
            93903.14,
            92979.12,
            92050.8,
            91118.15,
            90181.15,
            89239.79,
            88294.04,
            87712.32,
            87127.91,
            86540.77,
            85950.9,
            85358.3,
            84762.93,
            84164.79,
            83563.88,
            82960.16,
            82353.64,
            81744.3,
            81132.12,
            80473.03,
            79810.87,
            79145.62,
            181408.6,
            173661.54,
            165904.41,
            158064.61,
            150214.18,
            142353.08,
            134481.27,
            126598.71,
            118705.36,
            110683.22,
            102649.77,
            94604.96,
            86432.68,
            78959.98,
            71456.07,
            66460.94,
            65506.76,
            64548.13,
            63585.02,
            62617.42,
            61645.3,
            61010.84,
            60373.43,
            59733.04,
            59089.67,
            58443.29,
            57793.9,
            57141.47,
            56486,
            55827.47,
            55165.87,
            54501.18,
            53833.39,
            53179.5,
            52522.56,
            90183.01,
            81719.69,
            73170.54,
            64615.72,
            55998.1,
            48515.98,
            47837.25,
            47155.36,
            46470.27,
            45781.99,
            45005.8,
            44225.97,
            43442.49,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
        ],
        "hc": [
            5702407,
            5702408,
            5702409,
            5702410,
            5702411,
            5702412,
            5702501,
            5702502,
            5702503,
            5702504,
            5702505,
            5702506,
            5702507,
            5702508,
            5702509,
            5702510,
            5702511,
            5702512,
            5702601,
            5702602,
            5702603,
            5702604,
            5702605,
            5702606,
            5702607,
            5702608,
            5702609,
            5702610,
            5702611,
            5702612,
            5702701,
            5702702,
            5702703,
            5702704,
            5702705,
            5702706,
            5702707,
            5702708,
            5702709,
            5702710,
            5702711,
            5702712,
            5702801,
            5702802,
            5702803,
            5702804,
            5702805,
            5702806,
            5702807,
            5702808,
            5702809,
            5702810,
            5702811,
            5702812,
            5702901,
            5702902,
            5702903,
            5702904,
            5702905,
            5702906,
            5702907,
            5702908,
            5702909,
            5702910,
            5702911,
            5702912,
            5703001,
            5703002,
            5703003,
            5703004,
            5703005,
            5703006,
            5703007,
            5703008,
            5703009,
            5703010,
            5703011,
            5703012,
            5703101,
            5703102,
            5703103,
            5703104,
            5703105,
            5703106,
            5703107,
            5703108,
            5703109,
            5703110,
            5703111,
            5703112,
            5703201,
            5703202,
            5703203,
            5703204,
            5703205,
            5703206,
            5703207,
            5703208,
            5703209,
            5703210,
            5703211,
            5703212,
            5703301,
            5703302,
            5703303,
            5703304,
            5703305,
            5703306,
            5703307,
            5703308,
            5703309,
            5703310,
            5703311,
            5703312,
            5703401,
            5703402,
            5703403,
            5703404,
            5703405,
            5703406,
            5703407,
            5703408,
            5703409,
            5703410,
            5703411,
            5703412,
            5703501,
            5703502,
            5703503,
            5703504,
            5703505,
            5703506,
            5703507,
            5703508,
            5703509,
            5703510,
            5703511,
            5703512,
            5703601,
            5703602,
            5703603,
            5703604,
            5703605,
            5703606,
            5703607,
            5703608,
            5703609,
            5703610,
            5703611,
            5703612,
            5703701,
            5703702,
            5703703,
            5703704,
            5703705,
            5703706,
            5703707,
            5703708,
            5703709,
            5703710,
            5703711,
            5703712,
            5703801,
            5703802,
            5703803,
            5703804,
            5703805,
            5703806,
            5703807,
            5703808,
            5703809,
            5703810,
            5703811,
            5703812,
            5703901,
            5703902,
            5703903,
            5703904,
            5703905,
            5703906,
            5703907,
            5703908,
            5703909,
            5703910,
            5703911,
            5703912,
            5704001,
            5704002,
            5704003,
            5704004,
            5704005,
            5704006,
            5704007,
            5704008,
            5704009,
            5704010,
            5704011,
            5704012,
            5704101,
            5704102,
            5704103,
            5704104,
            5704105,
            5704106,
            5704107,
            5704108,
            5704109,
            5704110,
            5704111,
            5704112,
            5704201,
            5704202,
            5704203,
            5704204,
            5704205,
            5704206,
            5704207,
            5704208,
            5704209,
            5704210,
            5704211,
            5704212,
            5704301,
            5704302,
            5704303,
            5704304,
            5704305,
            5704306,
            5704307,
            5704308,
            5704309,
            5704310,
            5704311,
            5704312,
            5704401,
            5704402,
            5704403,
            5704404,
            5704405,
            5704406,
            5704407,
            5704408,
            5704409,
            5704410,
            5704411,
            5704412,
            5704501,
            5704502,
            5704503,
            5704504,
            5704505,
            5704506,
            5704507,
            5704508,
            5704509,
            5704510,
            5704511,
            5704512,
            5704601,
            5704602,
            5704603,
            5704604,
            5704605,
            5704606,
            5704607,
            5704608,
            5704609,
            5704610,
            5704611,
            5704612,
            5704701,
            5704702,
            5704703,
            5704704,
            5704705,
            5704706,
            5704707,
            5704708,
            5704709,
            5704710,
            5704711,
            5704712,
            5704801,
            5704802,
            5704803,
            5704804,
            5704805,
            5704806,
            5704807,
            5704808,
            5704809,
            5704810,
            5704811,
            5704812,
            5704901,
            5704902,
            5704903,
            5704904,
            5704905,
            5704906,
            5704907,
            5704908,
            5704909,
            5704910,
            5704911,
            5704912,
            5705001,
            5705002,
            5705003,
            5705004,
            5705005,
            5705006,
            5705007,
            5705008,
            5705009,
            5705010,
            5705011,
            5705012,
            5705101,
            5705102,
            5705103,
            5705104,
            5705105,
            5705106,
            5705107,
            5705108,
            5705109,
            5705110,
            5705111,
            5705112,
            5705201,
            5705202,
            5705203,
            5705204,
            5705205,
            5705206,
            5705207,
            5705208,
            5705209,
            5705210,
            5705211,
            5705212,
            5705301,
            5705302,
            5705303,
            5705304,
            5705305,
            5705306,
            5705307,
            5705308,
            5705309,
            5705310,
            5705311,
            5705312,
            5705401,
            5705402,
            5705403,
            5705404,
            5705405,
            5705406,
            5705407,
            5705408,
            5705409,
            5705410,
            5705411,
            5705412,
            5705501,
            5705502,
            5705503,
            5705504,
            5705505,
            5705506,
            5705507,
            5705508,
            5705509,
            5705510,
            5705511,
            5705512,
            5705601,
            5705602,
            5705603,
            5705604,
            5705605,
            5705606,
            5705607,
            5705608,
            5705609,
            5705610,
            5705611,
            5705612,
            5705701,
            5705702,
            5705703,
            5705704,
            5705705,
            5705706,
            5705707,
            5705708,
            5705709,
            5705710,
            5705711,
            5705712,
            5705801,
            5705802,
            5705803,
            5705804,
            5705805,
            5705806,
            5705807,
            5705808,
            5705809,
            5705810,
            5705811,
            5705812,
            5705901,
            5705902,
            5705903,
            5705904,
            5705905,
            5705906,
            5705907,
            5705908,
            5705909,
            5705910,
            5705911,
            5705912,
            5706001,
            5706002,
            5706003,
            5706004,
            5706005,
            5706006,
            5706007,
            5706008,
            5706009,
            5706010,
            5706011,
            5706012,
            5706101,
            5706102,
            5706103,
            5706104,
            5706105,
            5706106,
            5706107,
            5706108,
            5706109,
            5706110,
            5706111,
            5706112,
            5706201,
            5706202,
            5706203,
            5706204,
            5706205,
            5706206,
            5706207,
            5706208,
            5706209,
            5706210,
            5706211,
            5706212,
            5706301,
            5706302,
            5706303,
            5706304,
            5706305,
            5706306,
            5706307,
            5706308,
            5706309,
            5706310,
            5706311,
            5706312,
            5706401,
            5706402,
            5706403,
            5706404,
            5706405,
            5706406,
            5706407,
            5706408,
            5706409,
            5706410,
            5706411,
            5706412,
            5706501,
            5706502,
            5706503,
            5706504,
            5706505,
            5706506,
            5706507,
            5706508,
            5706509,
            5706510,
            5706511,
            5706512,
            5706601,
            5706602,
            5706603,
            5706604,
            5706605,
            5706606,
            5706607,
            5706608,
            5706609,
            5706610,
            5706611,
            5706612,
            5706701,
            5706702,
            5706703,
            5706704
        ]
    },
    "timeline": [
        {
            "goal_id": 1, //emergencyfund 
            "date": "2024-08"
        },
        {
            "goal_id": 5, //save for wedding down payment
            "date": "2024-10"
        },
        {
            "lifeevent_id": 1720575974976, //have wedding
            "date": "2025-07"
        },
        {
            "goal_id": 6, //fiance pays off federal loans
            "date": "2032-05"
        },
        {
            "goal_id": 7, //fiance pays off private loans
            "date": "2028-06"
        },
        {
            "goal_id": 8, //when the home down payment is saved
            "date": "2027-05"
        },
        {
            "lifeevent_id": 1720576005248, //when the home purchase occurs 
            "date": "2027-05"
        },
        {
            "goal_id": 17, //when the future home mortgage is paid off
            "date": "2040-05"
        },
        {
            "goal_id": 10, //when the investment property down payment is saved
            "date": "2028-12"
        },
        {
            "lifeevent_id": 1720576062360, //when the investment property purchase occurs, the earliest should be the user's target date  
            "date": "2030-10"
        },
        {
            "goal_id": 18, //when the future created debt is paid off
            "date": "2045-09"
        },
        {
            "goal_id": 11, //when the vehicle down payment is saved
            "date": "2033-10"
        },
        {
            "lifeevent_id": 1720576129774, //when the vehicle purchase occurs   
            "date": "2033-10"
        },
        {
            "goal_id": 19, //when the future vehicle created debt is paid off
            "date": "2036-01"
        },
        {
            "lifeevent_id": 1720576185262, //enlarge job
            "date": "2025-11"
        },
        {
            "lifeevent_id": 1720576172015, //part-time job
            "date": "2044-11"
        },
        {
            "lifeevent_id": 1720576158974, //reduce job
            "date": "2049-11"
        },
        {
            "lifeevent_id": 1720576097110, //have child + applicant change in income
            "date": "2028-10"
        },
        {
            "lifeevent_id": 1720576114473, //have child + spouse change in income
            "date": "2031-11"
        },
        {
            "goal_id": 9, //when the custom savings goal is saved
            "date": "2027-06"
        },
        {
            "lifeevent_id": 1720576211918, //when the custom purchase goal occurs
            "date": "2026-10"
        },
        {
            "lifeevent_id": 1720576033143, //moveout
            "date": "2024-09"
        },
        {
            "lifeevent_id": 1720576081655, //movein
            "date": "2028-10"
        },
        {
            "lifeevent_id": 1720576081655, //move
            "date": "2033-09"
        },
        {
            "lifeevent_id": 1720576195655, //inheritance
            "date": "2024-09"
        },
        {
            "lifeevent_id": 1720576260472, //save for vacation - recurring
            "date": "2024-10"
        },
        {
            "goal_id": 13, //when the particular debt is paid off
            "date": "2028-01"
        },
        {
            "goal_id": 14, //when the particular debt is paid off
            "date": "2029-02"
        },
        {
            "goal_id": 15, //when the particular debt is paid off
            "date": "2030-03"
        },
        {
            "goal_id": 16, //when the particular debt is paid off
            "date": "2031-04"
        },
        {
            "goal_id": 20, //when the particular debt is paid off
            "date": "2032-05"
        },
        {
            "goal_id": 21, //when the particular debt is paid off
            "date": "2033-06"
        },
        {
            "goal_id": 26, //when the particular debt is paid off
            "date": "2034-07"
        },
        {
            "goal_id": 27, //when the particular debt is paid off
            "date": "2035-11"
        },
        {
            "goal_id": 25, //save for tax bomb goal
            "date": "2032-12"
        },
        {
            "goal": "fedloanforgiveness", //date that the loans are fully forgiven
            "who": "applicant",
            "date": "2042-06"
        },
        {
            "goal_id": 28, //applicant's private loans are paid off
            "date": "2035-10"
        },
        {
            "goal_id": 29, //applicant's perkins loans are paid off
            "date": "2029-04"
        },
        {
            "goal": "child_leaves_nest", //2nd child leaves nest
            "date": "2049-11"
        },
        {
            "goal": "child_leaves_nest", //2nd child leaves nest
            "date": "2049-11"
        }
    ],
    "taxbomb": [
        {
            "who": "applicant",
            "amount": 15646
        }
    ],
    "summary": {
        "now": {
            "date": "2024-07",
            "score": 500,
            "hc": 5702407,
            "assets": {
                "cash": 2598.924462997868,
                "investment": 0,
                "retirement": 0,
                "home": 125257.47953373034,
                "property": 12024.718035238113,
                "vehicle": 11900,
                "other": 0,
                "total": 151781.1220319663
            },
            "debts": {
                "credit_card": 3020,
                "student_loan": 221005.16666666663,
                "home": 49400,
                "property": 24814.583333333336,
                "vehicle": 6184.876666666667,
                "personal": 10828.645833333332,
                "other": 3273.333333333333,
                "total": 318526.60583333333
            }
        },
        "looming": {
            "date": "2025-01",
            "score": 500,
            "hc": 5702501,
            "assets": {
                "cash": 302694.20954406034,
                "investment": 0,
                "retirement": 0,
                "home": 126813.5327306255,
                "property": 12174.099142140049,
                "vehicle": 11300,
                "other": 0,
                "total": 452981.84141682595
            },
            "debts": {
                "credit_card": 1080.2541088456449,
                "student_loan": 214931.90529910312,
                "home": 45768.36842138239,
                "property": 23684.100026300268,
                "vehicle": 3889.5204942290866,
                "personal": 9782.917226665484,
                "other": 1881.2450473667382,
                "total": 301018.3106238927
            }
        },
        "next": {
            "date": "2025-07",
            "score": 500,
            "hc": 5702507,
            "assets": {
                "cash": 287694.20954406034,
                "investment": 0,
                "retirement": 0,
                "home": 128388.91652207343,
                "property": 12325.33598611905,
                "vehicle": 10700,
                "other": 0,
                "total": 439108.46205225284
            },
            "debts": {
                "credit_card": 0,
                "student_loan": 274934.43697759806,
                "home": 42081.920766610114,
                "property": 22522.17002392657,
                "vehicle": 1547.8729344605194,
                "personal": 2228.1841316690643,
                "other": 432.5368803349295,
                "total": 343747.1217145993
            }
        },
        "soon": {
            "date": "2029-07",
            "score": 500,
            "hc": 5702907,
            "assets": {
                "cash": 376525.2060367048,
                "investment": 197514.6996219629,
                "retirement": 22530.318913902283,
                "home": 269777.210842006,
                "property": 13604.86474276233,
                "vehicle": 5900,
                "other": 26580.148480954314,
                "total": 912432.4486382927
            },
            "debts": {
                "credit_card": 0,
                "student_loan": 87712.3231560929,
                "home": 0,
                "property": 0,
                "vehicle": 0,
                "personal": 0,
                "other": 0,
                "total": 87712.3231560929
            }
        },
        "later": {
            "date": "2034-07",
            "score": 500,
            "hc": 5703407,
            "assets": {
                "cash": 357525.2060367048,
                "investment": 431249.02244064095,
                "retirement": 74278.12200668274,
                "home": 305228.1519973693,
                "property": 152802.60003884952,
                "vehicle": 45416.66666666669,
                "other": 29616.19591826404,
                "total": 1396115.965105178
            },
            "debts": {
                "credit_card": 0,
                "student_loan": 45005.7950999076,
                "home": 0,
                "property": 0,
                "vehicle": 0,
                "personal": 0,
                "other": 0,
                "total": 45005.7950999076
            }
        },
        "retirement": {
            "date": "2067-04",
            "score": 500,
            "hc": 5706704,
            "assets": {
                "cash": 348141.0278637547,
                "investment": 5819603.378194391,
                "retirement": 817381.1292973096,
                "home": 685221.8117608172,
                "property": 343034.13284527714,
                "vehicle": 0,
                "other": 60144.377493195694,
                "total": 8073525.857454745
            },
            "debts": {
                "credit_card": 0,
                "student_loan": 0,
                "home": 0,
                "property": 0,
                "vehicle": 0,
                "personal": 0,
                "other": 0,
                "total": 0
            }
        }
    }
};

const plan5585 = {
    "start": "2024-07",
    "score": [
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500
    ],
    "series": {
        "asset": [
            151781.12,
            152059.19,
            452242.55,
            452426.49,
            452611.02,
            452796.14,
            452981.84,
            453168.13,
            453355.01,
            453542.49,
            453730.55,
            453919.21,
            439108.46,
            439298.31,
            439488.76,
            439679.8,
            439871.44,
            440063.69,
            440256.53,
            440449.98,
            440644.03,
            440838.69,
            441033.96,
            441229.83,
            666941.28,
            667654.39,
            668369.18,
            669130.75,
            669894.09,
            670659.19,
            671426.07,
            672194.72,
            672965.14,
            673737.35,
            674511.34,
            675287.12,
            676064.69,
            676844.06,
            677625.23,
            678408.2,
            679192.98,
            679979.57,
            689850.52,
            708783.74,
            727763.01,
            746788.39,
            765859.93,
            784977.67,
            804288.3,
            823645.43,
            843049.11,
            849834.18,
            856642.95,
            863475.41,
            870395.12,
            877317.37,
            884242.15,
            891169.45,
            898099.26,
            905031.57,
            912432.45,
            919836.2,
            927242.8,
            934734.7,
            942229.45,
            949727.04,
            957293.25,
            964862.49,
            972434.75,
            980010.03,
            987588.3,
            995169.56,
            1002812.16,
            1010457.7,
            1018106.17,
            1129028.42,
            1129952.66,
            1130878.89,
            1131807.13,
            1132737.37,
            1133669.62,
            1134603.88,
            1135540.16,
            1136478.46,
            1137418.78,
            1138361.13,
            1139305.52,
            1140251.94,
            1141200.4,
            1142150.91,
            1145700.31,
            1153336.63,
            1160998.58,
            1168686.17,
            1176399.41,
            1184138.33,
            1192245.15,
            1200378.03,
            1208536.98,
            1216834.59,
            1225234.53,
            1233660.66,
            1242171.71,
            1250687.8,
            1259208.91,
            1267735.03,
            1276266.16,
            1284802.3,
            1293360.45,
            1301923.6,
            1348562.32,
            1349203.26,
            1349846.42,
            1350491.81,
            1351139.43,
            1352930.8,
            1361552.52,
            1370180.38,
            1378814.36,
            1387454.46,
            1396115.97,
            1404783.49,
            1413457.02,
            1422280.26,
            1432204.55,
            1442157.42,
            1452198.03,
            1462267.46,
            1472344.79,
            1482429.58,
            1492521.8,
            1493237.27,
            1503335.69,
            1513441.53,
            1523554.77,
            1533801.41,
            1544140.82,
            1554487.71,
            1564903.39,
            1575326.75,
            1585757.79,
            1596196.51,
            1606642.89,
            1617096.92,
            1627558.62,
            1638027.95,
            1648504.93,
            1659119.3,
            1669831.8,
            1680552.02,
            1691343.55,
            1702143.02,
            1712950.41,
            1723765.71,
            1734588.92,
            1745420.03,
            1756259.04,
            1767105.94,
            1777960.71,
            1788971.55,
            1800092.53,
            1811221.5,
            1822424.43,
            1833635.58,
            1844854.91,
            1856082.44,
            1867318.16,
            1878562.05,
            1889814.11,
            1901074.33,
            1912342.7,
            1923771.86,
            1935314.51,
            1946865.41,
            1958493.02,
            1970129.11,
            1981773.69,
            1993426.74,
            2005088.27,
            2016758.25,
            2028436.68,
            2040123.56,
            2051818.88,
            2063679.84,
            2075657.73,
            2087644.17,
            2099710.12,
            2111784.86,
            2123868.38,
            2135960.66,
            2148061.71,
            2160171.5,
            2172290.05,
            2184417.33,
            2196553.34,
            2208859.99,
            2221287.13,
            2233723.11,
            2246241.52,
            2258769.01,
            2271305.58,
            2283851.23,
            2296405.93,
            2308969.69,
            2321542.5,
            2334124.35,
            2346715.22,
            2359481.91,
            2372372.13,
            2385271.14,
            2398255.24,
            2411248.38,
            2424250.56,
            2437261.77,
            2450281.99,
            2463311.23,
            2476349.47,
            2489396.7,
            2502452.91,
            2515683.57,
            2529037.39,
            2542400.29,
            2555851.35,
            2569311.74,
            2582781.47,
            2596260.51,
            2609748.86,
            2623246.52,
            2636753.46,
            2650269.69,
            2664211.86,
            2678333.73,
            2692582.48,
            2706840.59,
            2721190.04,
            2735549.12,
            2749917.84,
            2764296.18,
            2778684.12,
            2793081.67,
            2807488.81,
            2821905.53,
            2836331.82,
            2850943.23,
            2866773.99,
            2882614.91,
            2898558.71,
            2914512.96,
            2930477.66,
            2946452.79,
            2962438.34,
            2978434.3,
            2994440.66,
            3010457.39,
            3026484.5,
            3042706.28,
            3059106.5,
            3075517.96,
            3092036.76,
            3108567.11,
            3125109.01,
            3141662.44,
            3158227.38,
            3174803.84,
            3191391.79,
            3207991.22,
            3224602.13,
            3240544.64,
            3256658.97,
            3272782.59,
            3289026.86,
            3305280.79,
            3321544.35,
            3337817.53,
            3354100.31,
            3370392.68,
            3386694.62,
            3403006.11,
            3419327.13,
            3435850.55,
            3452550.99,
            3469261.06,
            3486096.06,
            3502941.06,
            3519796.04,
            3536660.97,
            3553535.85,
            3570420.65,
            3587315.36,
            3604219.96,
            3621134.43,
            3638257.42,
            3655562.79,
            3672878.13,
            3690322.83,
            3707777.88,
            3725243.26,
            3742718.95,
            3760204.94,
            3777701.19,
            3795207.7,
            3812724.45,
            3830251.42,
            3847993.22,
            3863673.04,
            3879372.41,
            3895115.93,
            3910879.14,
            3926662.05,
            3942464.68,
            3958287.06,
            3974129.2,
            3989991.13,
            4005872.86,
            4021774.42,
            4037735.44,
            4053970.13,
            4070224.62,
            4086524.4,
            4102844.13,
            4119183.82,
            4135543.51,
            4151923.2,
            4168322.92,
            4184742.69,
            4201182.53,
            4217642.45,
            4234162.15,
            4250968.28,
            4267794.9,
            4284668.42,
            4301562.57,
            4318477.4,
            4335412.91,
            4352369.13,
            4369346.07,
            4386343.77,
            4403362.24,
            4420401.5,
            4437502.42,
            4454915.08,
            4472348.97,
            4489831.42,
            4507335.26,
            4524860.51,
            4542407.19,
            4559975.32,
            4577564.92,
            4595176.02,
            4612808.63,
            4630462.78,
            4648180.58,
            4666219.58,
            4684280.58,
            4702391.86,
            4720525.29,
            4738680.9,
            4756858.7,
            4775058.73,
            4793281,
            4811525.53,
            4829792.35,
            4848081.48,
            4866436.28,
            4885122.05,
            4903830.61,
            4922591.23,
            4941374.79,
            4960181.32,
            4979010.84,
            4997863.38,
            5016738.95,
            5035637.57,
            5054559.28,
            5073504.1,
            5092516.68,
            5111870.3,
            5131247.5,
            5150678.61,
            5170133.49,
            5189612.14,
            5209114.6,
            5228640.9,
            5248191.05,
            5267765.08,
            5287363.01,
            5306984.87,
            5326676.66,
            5346719.84,
            5366787.45,
            5386910.86,
            5407058.88,
            5427231.52,
            5447428.82,
            5467650.79,
            5487897.47,
            5508168.87,
            5528465.02,
            5548785.95,
            5569179.04,
            5589934.18,
            5610714.63,
            5631552.83,
            5652416.52,
            5673305.7,
            5694220.4,
            5715160.66,
            5736126.49,
            5757117.92,
            5778134.97,
            5799177.68,
            5820294.84,
            5841785.06,
            5863301.46,
            5884877.66,
            5906480.23,
            5928109.2,
            5949764.59,
            5971446.44,
            5993154.75,
            6014889.57,
            6036650.92,
            6058438.82,
            6080303.55,
            6102552.67,
            6124828.88,
            6147166.99,
            6169532.39,
            6191925.12,
            6214345.2,
            6236792.67,
            6259267.53,
            6281769.83,
            6304299.6,
            6326856.85,
            6349493.37,
            6372525.93,
            6395586.56,
            6418711.23,
            6441864.16,
            6465045.37,
            6488254.9,
            6511492.76,
            6534758.99,
            6558053.61,
            6581376.66,
            6604728.15,
            6628161.44,
            6652002.79,
            6675873.18,
            6699809.85,
            6723775.76,
            6747770.95,
            6771795.44,
            6791846.47,
            6811922.23,
            6832022.75,
            6852148.06,
            6872298.19,
            6892528.05,
            6913027.62,
            6933552.42,
            6954133.35,
            6974739.68,
            6995371.44,
            7016028.67,
            7036711.37,
            7057419.59,
            7078153.34,
            7098912.65,
            7119697.55,
            7140564.62,
            7161709.51,
            7182880.43,
            7204109.26,
            7225364.3,
            7246645.57,
            7267953.08,
            7289286.88,
            7310646.99,
            7332033.43,
            7353446.23,
            7374885.42,
            7396409.28,
            7418238.97,
            7440095.52,
            7462011.87,
            7483955.27,
            7505925.73,
            7527923.3,
            7549948,
            7571999.85,
            7594078.88,
            7616185.13,
            7638318.61,
            7660539.36,
            7683081.67,
            7705651.71,
            7728283.5,
            7750943.2,
            7773630.86,
            7796346.51,
            7819090.16,
            7841861.85,
            7864661.61,
            7887489.46,
            7910345.44,
            7933291.37,
            7956568.58,
            7979874.42,
            8003244,
            8026642.42,
            8050069.69,
            8073525.86
        ],
        "debt": [
            318526.61,
            315979.67,
            313019.83,
            310046.52,
            307059.67,
            304059.19,
            301018.31,
            297963.13,
            294893.55,
            291809.46,
            288713.03,
            285606.41,
            343747.12,
            336860.56,
            329941.39,
            322982.88,
            308757.73,
            294444.76,
            279979.41,
            265424.02,
            250777.96,
            236040.63,
            221217.62,
            206322.6,
            414730.04,
            398060.35,
            381313.14,
            364524.14,
            347232.8,
            329868.6,
            312405.36,
            294868.47,
            277257.61,
            259572.45,
            241812.66,
            223977.93,
            205925.56,
            187796.97,
            169591.8,
            151309.73,
            132505.78,
            113649.92,
            103788.89,
            102910.92,
            102028.86,
            101142.7,
            100252.41,
            99357.97,
            98459.36,
            97556.57,
            96649.57,
            95738.35,
            94822.88,
            93903.14,
            92979.12,
            92050.8,
            91118.15,
            90181.15,
            89239.79,
            88294.04,
            87712.32,
            87127.91,
            86540.77,
            85950.9,
            85358.3,
            84762.93,
            84164.79,
            83563.88,
            82960.16,
            82353.64,
            81744.3,
            81132.12,
            80473.03,
            79810.87,
            79145.62,
            181408.6,
            173661.54,
            165904.41,
            158064.61,
            150214.18,
            142353.08,
            134481.27,
            126598.71,
            118705.36,
            110683.22,
            102649.77,
            94604.96,
            86432.68,
            78959.98,
            71456.07,
            66460.94,
            65506.76,
            64548.13,
            63585.02,
            62617.42,
            61645.3,
            61010.84,
            60373.43,
            59733.04,
            59089.67,
            58443.29,
            57793.9,
            57141.47,
            56486,
            55827.47,
            55165.87,
            54501.18,
            53833.39,
            53179.5,
            52522.56,
            90183.01,
            81719.69,
            73170.54,
            64615.72,
            55998.1,
            48515.98,
            47837.25,
            47155.36,
            46470.27,
            45781.99,
            45005.8,
            44225.97,
            43442.49,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
        ],
        "hc": [
            5702407,
            5702408,
            5702409,
            5702410,
            5702411,
            5702412,
            5702501,
            5702502,
            5702503,
            5702504,
            5702505,
            5702506,
            5702507,
            5702508,
            5702509,
            5702510,
            5702511,
            5702512,
            5702601,
            5702602,
            5702603,
            5702604,
            5702605,
            5702606,
            5702607,
            5702608,
            5702609,
            5702610,
            5702611,
            5702612,
            5702701,
            5702702,
            5702703,
            5702704,
            5702705,
            5702706,
            5702707,
            5702708,
            5702709,
            5702710,
            5702711,
            5702712,
            5702801,
            5702802,
            5702803,
            5702804,
            5702805,
            5702806,
            5702807,
            5702808,
            5702809,
            5702810,
            5702811,
            5702812,
            5702901,
            5702902,
            5702903,
            5702904,
            5702905,
            5702906,
            5702907,
            5702908,
            5702909,
            5702910,
            5702911,
            5702912,
            5703001,
            5703002,
            5703003,
            5703004,
            5703005,
            5703006,
            5703007,
            5703008,
            5703009,
            5703010,
            5703011,
            5703012,
            5703101,
            5703102,
            5703103,
            5703104,
            5703105,
            5703106,
            5703107,
            5703108,
            5703109,
            5703110,
            5703111,
            5703112,
            5703201,
            5703202,
            5703203,
            5703204,
            5703205,
            5703206,
            5703207,
            5703208,
            5703209,
            5703210,
            5703211,
            5703212,
            5703301,
            5703302,
            5703303,
            5703304,
            5703305,
            5703306,
            5703307,
            5703308,
            5703309,
            5703310,
            5703311,
            5703312,
            5703401,
            5703402,
            5703403,
            5703404,
            5703405,
            5703406,
            5703407,
            5703408,
            5703409,
            5703410,
            5703411,
            5703412,
            5703501,
            5703502,
            5703503,
            5703504,
            5703505,
            5703506,
            5703507,
            5703508,
            5703509,
            5703510,
            5703511,
            5703512,
            5703601,
            5703602,
            5703603,
            5703604,
            5703605,
            5703606,
            5703607,
            5703608,
            5703609,
            5703610,
            5703611,
            5703612,
            5703701,
            5703702,
            5703703,
            5703704,
            5703705,
            5703706,
            5703707,
            5703708,
            5703709,
            5703710,
            5703711,
            5703712,
            5703801,
            5703802,
            5703803,
            5703804,
            5703805,
            5703806,
            5703807,
            5703808,
            5703809,
            5703810,
            5703811,
            5703812,
            5703901,
            5703902,
            5703903,
            5703904,
            5703905,
            5703906,
            5703907,
            5703908,
            5703909,
            5703910,
            5703911,
            5703912,
            5704001,
            5704002,
            5704003,
            5704004,
            5704005,
            5704006,
            5704007,
            5704008,
            5704009,
            5704010,
            5704011,
            5704012,
            5704101,
            5704102,
            5704103,
            5704104,
            5704105,
            5704106,
            5704107,
            5704108,
            5704109,
            5704110,
            5704111,
            5704112,
            5704201,
            5704202,
            5704203,
            5704204,
            5704205,
            5704206,
            5704207,
            5704208,
            5704209,
            5704210,
            5704211,
            5704212,
            5704301,
            5704302,
            5704303,
            5704304,
            5704305,
            5704306,
            5704307,
            5704308,
            5704309,
            5704310,
            5704311,
            5704312,
            5704401,
            5704402,
            5704403,
            5704404,
            5704405,
            5704406,
            5704407,
            5704408,
            5704409,
            5704410,
            5704411,
            5704412,
            5704501,
            5704502,
            5704503,
            5704504,
            5704505,
            5704506,
            5704507,
            5704508,
            5704509,
            5704510,
            5704511,
            5704512,
            5704601,
            5704602,
            5704603,
            5704604,
            5704605,
            5704606,
            5704607,
            5704608,
            5704609,
            5704610,
            5704611,
            5704612,
            5704701,
            5704702,
            5704703,
            5704704,
            5704705,
            5704706,
            5704707,
            5704708,
            5704709,
            5704710,
            5704711,
            5704712,
            5704801,
            5704802,
            5704803,
            5704804,
            5704805,
            5704806,
            5704807,
            5704808,
            5704809,
            5704810,
            5704811,
            5704812,
            5704901,
            5704902,
            5704903,
            5704904,
            5704905,
            5704906,
            5704907,
            5704908,
            5704909,
            5704910,
            5704911,
            5704912,
            5705001,
            5705002,
            5705003,
            5705004,
            5705005,
            5705006,
            5705007,
            5705008,
            5705009,
            5705010,
            5705011,
            5705012,
            5705101,
            5705102,
            5705103,
            5705104,
            5705105,
            5705106,
            5705107,
            5705108,
            5705109,
            5705110,
            5705111,
            5705112,
            5705201,
            5705202,
            5705203,
            5705204,
            5705205,
            5705206,
            5705207,
            5705208,
            5705209,
            5705210,
            5705211,
            5705212,
            5705301,
            5705302,
            5705303,
            5705304,
            5705305,
            5705306,
            5705307,
            5705308,
            5705309,
            5705310,
            5705311,
            5705312,
            5705401,
            5705402,
            5705403,
            5705404,
            5705405,
            5705406,
            5705407,
            5705408,
            5705409,
            5705410,
            5705411,
            5705412,
            5705501,
            5705502,
            5705503,
            5705504,
            5705505,
            5705506,
            5705507,
            5705508,
            5705509,
            5705510,
            5705511,
            5705512,
            5705601,
            5705602,
            5705603,
            5705604,
            5705605,
            5705606,
            5705607,
            5705608,
            5705609,
            5705610,
            5705611,
            5705612,
            5705701,
            5705702,
            5705703,
            5705704,
            5705705,
            5705706,
            5705707,
            5705708,
            5705709,
            5705710,
            5705711,
            5705712,
            5705801,
            5705802,
            5705803,
            5705804,
            5705805,
            5705806,
            5705807,
            5705808,
            5705809,
            5705810,
            5705811,
            5705812,
            5705901,
            5705902,
            5705903,
            5705904,
            5705905,
            5705906,
            5705907,
            5705908,
            5705909,
            5705910,
            5705911,
            5705912,
            5706001,
            5706002,
            5706003,
            5706004,
            5706005,
            5706006,
            5706007,
            5706008,
            5706009,
            5706010,
            5706011,
            5706012,
            5706101,
            5706102,
            5706103,
            5706104,
            5706105,
            5706106,
            5706107,
            5706108,
            5706109,
            5706110,
            5706111,
            5706112,
            5706201,
            5706202,
            5706203,
            5706204,
            5706205,
            5706206,
            5706207,
            5706208,
            5706209,
            5706210,
            5706211,
            5706212,
            5706301,
            5706302,
            5706303,
            5706304,
            5706305,
            5706306,
            5706307,
            5706308,
            5706309,
            5706310,
            5706311,
            5706312,
            5706401,
            5706402,
            5706403,
            5706404,
            5706405,
            5706406,
            5706407,
            5706408,
            5706409,
            5706410,
            5706411,
            5706412,
            5706501,
            5706502,
            5706503,
            5706504,
            5706505,
            5706506,
            5706507,
            5706508,
            5706509,
            5706510,
            5706511,
            5706512,
            5706601,
            5706602,
            5706603,
            5706604,
            5706605,
            5706606,
            5706607,
            5706608,
            5706609,
            5706610,
            5706611,
            5706612,
            5706701,
            5706702,
            5706703,
            5706704
        ]
    },
    "timeline": [
        {
            "goal_id": 3, //emergencyfund 
            "date": "2024-08"
        },
        {
            "lifeevent_id": 1720803139289, //enlarge job
            "date": "2034-12"
        },
        {
            "lifeevent_id": 1720800083602, //part-time job
            "date": "2031-11"
        },
        {
            "lifeevent_id": 1720800052291, //reduce job
            "date": "2029-10"
        },
        {
            "lifeevent_id": 1720803149666, //enlarge job
            "date": "2035-05"
        },
        {
            "lifeevent_id": 1720800096107, //part-time job
            "date": "2032-12"
        },
        {
            "lifeevent_id": 1720800068818, //reduce job
            "date": "2030-11"
        },
        {
            "lifeevent_id": 1720800001899, //have child w/ no change in income
            "date": "2025-07"
        },
        {
            "lifeevent_id": 1720800019107, //have child + spouse change in income
            "date": "2026-08"
        },
        {
            "lifeevent_id": 1720576260472, //when down payment for vacation is saved
            "date": "2024-10"
        },
        {
            "lifeevent_id": 9,  //vacation - when event occurs
            "date": "2024-12"
        },
        {
            "goal_id": 5, //when the particular debt is paid off
            "date": "2032-12"
        },
        {
            "goal_id": 6, //when the particular debt is paid off
            "date": "2035-02"
        },
        {
            "goal": "fedloanforgiveness", //date that the loans are fully forgiven for PSLF
            "who": "spouse",
            "date": "2038-06"
        },
        {
            "goal_id": 4, //applicant pays off federal loans
            "date": "2032-05"
        },
        {
            "goal_id": 7, //applicant's private loans are paid off
            "date": "2035-10"
        },
        {
            "goal_id": 8, //applicant's perkins loans are paid off
            "date": "2029-04"
        },
        {
            "goal_id": 11, //spouse's private loans are paid off
            "date": "2032-05"
        },
        {
            "goal_id": 12, //spouse's perkins loans are paid off
            "date": "2028-04"
        },
        {
            "goal": "child_leaves_nest",
            "date": "2038-05"
        },
        {
            "goal": "child_leaves_nest",
            "date": "2045-09"
        }
    ],
    "taxbomb": [
        {
            "who": "applicant",
            "amount": 15646
        }
    ],
    "summary": {
        "now": {
            "date": "2024-07",
            "score": 500,
            "hc": 5702407,
            "assets": {
                "cash": 2598.924462997868,
                "investment": 0,
                "retirement": 0,
                "home": 125257.47953373034,
                "property": 12024.718035238113,
                "vehicle": 11900,
                "other": 0,
                "total": 151781.1220319663
            },
            "debts": {
                "credit_card": 3020,
                "student_loan": 221005.16666666663,
                "home": 49400,
                "property": 24814.583333333336,
                "vehicle": 6184.876666666667,
                "personal": 10828.645833333332,
                "other": 3273.333333333333,
                "total": 318526.60583333333
            }
        },
        "looming": {
            "date": "2025-01",
            "score": 500,
            "hc": 5702501,
            "assets": {
                "cash": 302694.20954406034,
                "investment": 0,
                "retirement": 0,
                "home": 126813.5327306255,
                "property": 12174.099142140049,
                "vehicle": 11300,
                "other": 0,
                "total": 452981.84141682595
            },
            "debts": {
                "credit_card": 1080.2541088456449,
                "student_loan": 214931.90529910312,
                "home": 45768.36842138239,
                "property": 23684.100026300268,
                "vehicle": 3889.5204942290866,
                "personal": 9782.917226665484,
                "other": 1881.2450473667382,
                "total": 301018.3106238927
            }
        },
        "next": {
            "date": "2025-07",
            "score": 500,
            "hc": 5702507,
            "assets": {
                "cash": 287694.20954406034,
                "investment": 0,
                "retirement": 0,
                "home": 128388.91652207343,
                "property": 12325.33598611905,
                "vehicle": 10700,
                "other": 0,
                "total": 439108.46205225284
            },
            "debts": {
                "credit_card": 0,
                "student_loan": 274934.43697759806,
                "home": 42081.920766610114,
                "property": 22522.17002392657,
                "vehicle": 1547.8729344605194,
                "personal": 2228.1841316690643,
                "other": 432.5368803349295,
                "total": 343747.1217145993
            }
        },
        "soon": {
            "date": "2029-07",
            "score": 500,
            "hc": 5702907,
            "assets": {
                "cash": 376525.2060367048,
                "investment": 197514.6996219629,
                "retirement": 22530.318913902283,
                "home": 269777.210842006,
                "property": 13604.86474276233,
                "vehicle": 5900,
                "other": 26580.148480954314,
                "total": 912432.4486382927
            },
            "debts": {
                "credit_card": 0,
                "student_loan": 87712.3231560929,
                "home": 0,
                "property": 0,
                "vehicle": 0,
                "personal": 0,
                "other": 0,
                "total": 87712.3231560929
            }
        },
        "later": {
            "date": "2034-07",
            "score": 500,
            "hc": 5703407,
            "assets": {
                "cash": 357525.2060367048,
                "investment": 431249.02244064095,
                "retirement": 74278.12200668274,
                "home": 305228.1519973693,
                "property": 152802.60003884952,
                "vehicle": 45416.66666666669,
                "other": 29616.19591826404,
                "total": 1396115.965105178
            },
            "debts": {
                "credit_card": 0,
                "student_loan": 45005.7950999076,
                "home": 0,
                "property": 0,
                "vehicle": 0,
                "personal": 0,
                "other": 0,
                "total": 45005.7950999076
            }
        },
        "retirement": {
            "date": "2067-04",
            "score": 500,
            "hc": 5706704,
            "assets": {
                "cash": 348141.0278637547,
                "investment": 5819603.378194391,
                "retirement": 817381.1292973096,
                "home": 685221.8117608172,
                "property": 343034.13284527714,
                "vehicle": 0,
                "other": 60144.377493195694,
                "total": 8073525.857454745
            },
            "debts": {
                "credit_card": 0,
                "student_loan": 0,
                "home": 0,
                "property": 0,
                "vehicle": 0,
                "personal": 0,
                "other": 0,
                "total": 0
            }
        }
    }
}

const plan5593 = {
    "start": "2024-07",
    "score": [
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500,
        500
    ],
    "series": {
        "asset": [
            151781.12,
            152059.19,
            452242.55,
            452426.49,
            452611.02,
            452796.14,
            452981.84,
            453168.13,
            453355.01,
            453542.49,
            453730.55,
            453919.21,
            439108.46,
            439298.31,
            439488.76,
            439679.8,
            439871.44,
            440063.69,
            440256.53,
            440449.98,
            440644.03,
            440838.69,
            441033.96,
            441229.83,
            666941.28,
            667654.39,
            668369.18,
            669130.75,
            669894.09,
            670659.19,
            671426.07,
            672194.72,
            672965.14,
            673737.35,
            674511.34,
            675287.12,
            676064.69,
            676844.06,
            677625.23,
            678408.2,
            679192.98,
            679979.57,
            689850.52,
            708783.74,
            727763.01,
            746788.39,
            765859.93,
            784977.67,
            804288.3,
            823645.43,
            843049.11,
            849834.18,
            856642.95,
            863475.41,
            870395.12,
            877317.37,
            884242.15,
            891169.45,
            898099.26,
            905031.57,
            912432.45,
            919836.2,
            927242.8,
            934734.7,
            942229.45,
            949727.04,
            957293.25,
            964862.49,
            972434.75,
            980010.03,
            987588.3,
            995169.56,
            1002812.16,
            1010457.7,
            1018106.17,
            1129028.42,
            1129952.66,
            1130878.89,
            1131807.13,
            1132737.37,
            1133669.62,
            1134603.88,
            1135540.16,
            1136478.46,
            1137418.78,
            1138361.13,
            1139305.52,
            1140251.94,
            1141200.4,
            1142150.91,
            1145700.31,
            1153336.63,
            1160998.58,
            1168686.17,
            1176399.41,
            1184138.33,
            1192245.15,
            1200378.03,
            1208536.98,
            1216834.59,
            1225234.53,
            1233660.66,
            1242171.71,
            1250687.8,
            1259208.91,
            1267735.03,
            1276266.16,
            1284802.3,
            1293360.45,
            1301923.6,
            1348562.32,
            1349203.26,
            1349846.42,
            1350491.81,
            1351139.43,
            1352930.8,
            1361552.52,
            1370180.38,
            1378814.36,
            1387454.46,
            1396115.97,
            1404783.49,
            1413457.02,
            1422280.26,
            1432204.55,
            1442157.42,
            1452198.03,
            1462267.46,
            1472344.79,
            1482429.58,
            1492521.8,
            1493237.27,
            1503335.69,
            1513441.53,
            1523554.77,
            1533801.41,
            1544140.82,
            1554487.71,
            1564903.39,
            1575326.75,
            1585757.79,
            1596196.51,
            1606642.89,
            1617096.92,
            1627558.62,
            1638027.95,
            1648504.93,
            1659119.3,
            1669831.8,
            1680552.02,
            1691343.55,
            1702143.02,
            1712950.41,
            1723765.71,
            1734588.92,
            1745420.03,
            1756259.04,
            1767105.94,
            1777960.71,
            1788971.55,
            1800092.53,
            1811221.5,
            1822424.43,
            1833635.58,
            1844854.91,
            1856082.44,
            1867318.16,
            1878562.05,
            1889814.11,
            1901074.33,
            1912342.7,
            1923771.86,
            1935314.51,
            1946865.41,
            1958493.02,
            1970129.11,
            1981773.69,
            1993426.74,
            2005088.27,
            2016758.25,
            2028436.68,
            2040123.56,
            2051818.88,
            2063679.84,
            2075657.73,
            2087644.17,
            2099710.12,
            2111784.86,
            2123868.38,
            2135960.66,
            2148061.71,
            2160171.5,
            2172290.05,
            2184417.33,
            2196553.34,
            2208859.99,
            2221287.13,
            2233723.11,
            2246241.52,
            2258769.01,
            2271305.58,
            2283851.23,
            2296405.93,
            2308969.69,
            2321542.5,
            2334124.35,
            2346715.22,
            2359481.91,
            2372372.13,
            2385271.14,
            2398255.24,
            2411248.38,
            2424250.56,
            2437261.77,
            2450281.99,
            2463311.23,
            2476349.47,
            2489396.7,
            2502452.91,
            2515683.57,
            2529037.39,
            2542400.29,
            2555851.35,
            2569311.74,
            2582781.47,
            2596260.51,
            2609748.86,
            2623246.52,
            2636753.46,
            2650269.69,
            2664211.86,
            2678333.73,
            2692582.48,
            2706840.59,
            2721190.04,
            2735549.12,
            2749917.84,
            2764296.18,
            2778684.12,
            2793081.67,
            2807488.81,
            2821905.53,
            2836331.82,
            2850943.23,
            2866773.99,
            2882614.91,
            2898558.71,
            2914512.96,
            2930477.66,
            2946452.79,
            2962438.34,
            2978434.3,
            2994440.66,
            3010457.39,
            3026484.5,
            3042706.28,
            3059106.5,
            3075517.96,
            3092036.76,
            3108567.11,
            3125109.01,
            3141662.44,
            3158227.38,
            3174803.84,
            3191391.79,
            3207991.22,
            3224602.13,
            3240544.64,
            3256658.97,
            3272782.59,
            3289026.86,
            3305280.79,
            3321544.35,
            3337817.53,
            3354100.31,
            3370392.68,
            3386694.62,
            3403006.11,
            3419327.13,
            3435850.55,
            3452550.99,
            3469261.06,
            3486096.06,
            3502941.06,
            3519796.04,
            3536660.97,
            3553535.85,
            3570420.65,
            3587315.36,
            3604219.96,
            3621134.43,
            3638257.42,
            3655562.79,
            3672878.13,
            3690322.83,
            3707777.88,
            3725243.26,
            3742718.95,
            3760204.94,
            3777701.19,
            3795207.7,
            3812724.45,
            3830251.42,
            3847993.22,
            3863673.04,
            3879372.41,
            3895115.93,
            3910879.14,
            3926662.05,
            3942464.68,
            3958287.06,
            3974129.2,
            3989991.13,
            4005872.86,
            4021774.42,
            4037735.44,
            4053970.13,
            4070224.62,
            4086524.4,
            4102844.13,
            4119183.82,
            4135543.51,
            4151923.2,
            4168322.92,
            4184742.69,
            4201182.53,
            4217642.45,
            4234162.15,
            4250968.28,
            4267794.9,
            4284668.42,
            4301562.57,
            4318477.4,
            4335412.91,
            4352369.13,
            4369346.07,
            4386343.77,
            4403362.24,
            4420401.5,
            4437502.42,
            4454915.08,
            4472348.97,
            4489831.42,
            4507335.26,
            4524860.51,
            4542407.19,
            4559975.32,
            4577564.92,
            4595176.02,
            4612808.63,
            4630462.78,
            4648180.58,
            4666219.58,
            4684280.58,
            4702391.86,
            4720525.29,
            4738680.9,
            4756858.7,
            4775058.73,
            4793281,
            4811525.53,
            4829792.35,
            4848081.48,
            4866436.28,
            4885122.05,
            4903830.61,
            4922591.23,
            4941374.79,
            4960181.32,
            4979010.84,
            4997863.38,
            5016738.95,
            5035637.57,
            5054559.28,
            5073504.1,
            5092516.68,
            5111870.3,
            5131247.5,
            5150678.61,
            5170133.49,
            5189612.14,
            5209114.6,
            5228640.9,
            5248191.05,
            5267765.08,
            5287363.01,
            5306984.87,
            5326676.66,
            5346719.84,
            5366787.45,
            5386910.86,
            5407058.88,
            5427231.52,
            5447428.82,
            5467650.79,
            5487897.47,
            5508168.87,
            5528465.02,
            5548785.95,
            5569179.04,
            5589934.18,
            5610714.63,
            5631552.83,
            5652416.52,
            5673305.7,
            5694220.4,
            5715160.66,
            5736126.49,
            5757117.92,
            5778134.97,
            5799177.68,
            5820294.84,
            5841785.06,
            5863301.46,
            5884877.66,
            5906480.23,
            5928109.2,
            5949764.59,
            5971446.44,
            5993154.75,
            6014889.57,
            6036650.92,
            6058438.82,
            6080303.55,
            6102552.67,
            6124828.88,
            6147166.99,
            6169532.39,
            6191925.12,
            6214345.2,
            6236792.67,
            6259267.53,
            6281769.83,
            6304299.6,
            6326856.85,
            6349493.37,
            6372525.93,
            6395586.56,
            6418711.23,
            6441864.16,
            6465045.37,
            6488254.9,
            6511492.76,
            6534758.99,
            6558053.61,
            6581376.66,
            6604728.15,
            6628161.44,
            6652002.79,
            6675873.18,
            6699809.85,
            6723775.76,
            6747770.95,
            6771795.44,
            6791846.47,
            6811922.23,
            6832022.75,
            6852148.06,
            6872298.19,
            6892528.05,
            6913027.62,
            6933552.42,
            6954133.35,
            6974739.68,
            6995371.44,
            7016028.67,
            7036711.37,
            7057419.59,
            7078153.34,
            7098912.65,
            7119697.55,
            7140564.62,
            7161709.51,
            7182880.43,
            7204109.26,
            7225364.3,
            7246645.57,
            7267953.08,
            7289286.88,
            7310646.99,
            7332033.43,
            7353446.23,
            7374885.42,
            7396409.28,
            7418238.97,
            7440095.52,
            7462011.87,
            7483955.27,
            7505925.73,
            7527923.3,
            7549948,
            7571999.85,
            7594078.88,
            7616185.13,
            7638318.61,
            7660539.36,
            7683081.67,
            7705651.71,
            7728283.5,
            7750943.2,
            7773630.86,
            7796346.51,
            7819090.16,
            7841861.85,
            7864661.61,
            7887489.46,
            7910345.44,
            7933291.37,
            7956568.58,
            7979874.42,
            8003244,
            8026642.42,
            8050069.69,
            8073525.86
        ],
        "debt": [
            318526.61,
            315979.67,
            313019.83,
            310046.52,
            307059.67,
            304059.19,
            301018.31,
            297963.13,
            294893.55,
            291809.46,
            288713.03,
            285606.41,
            343747.12,
            336860.56,
            329941.39,
            322982.88,
            308757.73,
            294444.76,
            279979.41,
            265424.02,
            250777.96,
            236040.63,
            221217.62,
            206322.6,
            414730.04,
            398060.35,
            381313.14,
            364524.14,
            347232.8,
            329868.6,
            312405.36,
            294868.47,
            277257.61,
            259572.45,
            241812.66,
            223977.93,
            205925.56,
            187796.97,
            169591.8,
            151309.73,
            132505.78,
            113649.92,
            103788.89,
            102910.92,
            102028.86,
            101142.7,
            100252.41,
            99357.97,
            98459.36,
            97556.57,
            96649.57,
            95738.35,
            94822.88,
            93903.14,
            92979.12,
            92050.8,
            91118.15,
            90181.15,
            89239.79,
            88294.04,
            87712.32,
            87127.91,
            86540.77,
            85950.9,
            85358.3,
            84762.93,
            84164.79,
            83563.88,
            82960.16,
            82353.64,
            81744.3,
            81132.12,
            80473.03,
            79810.87,
            79145.62,
            181408.6,
            173661.54,
            165904.41,
            158064.61,
            150214.18,
            142353.08,
            134481.27,
            126598.71,
            118705.36,
            110683.22,
            102649.77,
            94604.96,
            86432.68,
            78959.98,
            71456.07,
            66460.94,
            65506.76,
            64548.13,
            63585.02,
            62617.42,
            61645.3,
            61010.84,
            60373.43,
            59733.04,
            59089.67,
            58443.29,
            57793.9,
            57141.47,
            56486,
            55827.47,
            55165.87,
            54501.18,
            53833.39,
            53179.5,
            52522.56,
            90183.01,
            81719.69,
            73170.54,
            64615.72,
            55998.1,
            48515.98,
            47837.25,
            47155.36,
            46470.27,
            45781.99,
            45005.8,
            44225.97,
            43442.49,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
        ],
        "hc": [
            5702407,
            5702408,
            5702409,
            5702410,
            5702411,
            5702412,
            5702501,
            5702502,
            5702503,
            5702504,
            5702505,
            5702506,
            5702507,
            5702508,
            5702509,
            5702510,
            5702511,
            5702512,
            5702601,
            5702602,
            5702603,
            5702604,
            5702605,
            5702606,
            5702607,
            5702608,
            5702609,
            5702610,
            5702611,
            5702612,
            5702701,
            5702702,
            5702703,
            5702704,
            5702705,
            5702706,
            5702707,
            5702708,
            5702709,
            5702710,
            5702711,
            5702712,
            5702801,
            5702802,
            5702803,
            5702804,
            5702805,
            5702806,
            5702807,
            5702808,
            5702809,
            5702810,
            5702811,
            5702812,
            5702901,
            5702902,
            5702903,
            5702904,
            5702905,
            5702906,
            5702907,
            5702908,
            5702909,
            5702910,
            5702911,
            5702912,
            5703001,
            5703002,
            5703003,
            5703004,
            5703005,
            5703006,
            5703007,
            5703008,
            5703009,
            5703010,
            5703011,
            5703012,
            5703101,
            5703102,
            5703103,
            5703104,
            5703105,
            5703106,
            5703107,
            5703108,
            5703109,
            5703110,
            5703111,
            5703112,
            5703201,
            5703202,
            5703203,
            5703204,
            5703205,
            5703206,
            5703207,
            5703208,
            5703209,
            5703210,
            5703211,
            5703212,
            5703301,
            5703302,
            5703303,
            5703304,
            5703305,
            5703306,
            5703307,
            5703308,
            5703309,
            5703310,
            5703311,
            5703312,
            5703401,
            5703402,
            5703403,
            5703404,
            5703405,
            5703406,
            5703407,
            5703408,
            5703409,
            5703410,
            5703411,
            5703412,
            5703501,
            5703502,
            5703503,
            5703504,
            5703505,
            5703506,
            5703507,
            5703508,
            5703509,
            5703510,
            5703511,
            5703512,
            5703601,
            5703602,
            5703603,
            5703604,
            5703605,
            5703606,
            5703607,
            5703608,
            5703609,
            5703610,
            5703611,
            5703612,
            5703701,
            5703702,
            5703703,
            5703704,
            5703705,
            5703706,
            5703707,
            5703708,
            5703709,
            5703710,
            5703711,
            5703712,
            5703801,
            5703802,
            5703803,
            5703804,
            5703805,
            5703806,
            5703807,
            5703808,
            5703809,
            5703810,
            5703811,
            5703812,
            5703901,
            5703902,
            5703903,
            5703904,
            5703905,
            5703906,
            5703907,
            5703908,
            5703909,
            5703910,
            5703911,
            5703912,
            5704001,
            5704002,
            5704003,
            5704004,
            5704005,
            5704006,
            5704007,
            5704008,
            5704009,
            5704010,
            5704011,
            5704012,
            5704101,
            5704102,
            5704103,
            5704104,
            5704105,
            5704106,
            5704107,
            5704108,
            5704109,
            5704110,
            5704111,
            5704112,
            5704201,
            5704202,
            5704203,
            5704204,
            5704205,
            5704206,
            5704207,
            5704208,
            5704209,
            5704210,
            5704211,
            5704212,
            5704301,
            5704302,
            5704303,
            5704304,
            5704305,
            5704306,
            5704307,
            5704308,
            5704309,
            5704310,
            5704311,
            5704312,
            5704401,
            5704402,
            5704403,
            5704404,
            5704405,
            5704406,
            5704407,
            5704408,
            5704409,
            5704410,
            5704411,
            5704412,
            5704501,
            5704502,
            5704503,
            5704504,
            5704505,
            5704506,
            5704507,
            5704508,
            5704509,
            5704510,
            5704511,
            5704512,
            5704601,
            5704602,
            5704603,
            5704604,
            5704605,
            5704606,
            5704607,
            5704608,
            5704609,
            5704610,
            5704611,
            5704612,
            5704701,
            5704702,
            5704703,
            5704704,
            5704705,
            5704706,
            5704707,
            5704708,
            5704709,
            5704710,
            5704711,
            5704712,
            5704801,
            5704802,
            5704803,
            5704804,
            5704805,
            5704806,
            5704807,
            5704808,
            5704809,
            5704810,
            5704811,
            5704812,
            5704901,
            5704902,
            5704903,
            5704904,
            5704905,
            5704906,
            5704907,
            5704908,
            5704909,
            5704910,
            5704911,
            5704912,
            5705001,
            5705002,
            5705003,
            5705004,
            5705005,
            5705006,
            5705007,
            5705008,
            5705009,
            5705010,
            5705011,
            5705012,
            5705101,
            5705102,
            5705103,
            5705104,
            5705105,
            5705106,
            5705107,
            5705108,
            5705109,
            5705110,
            5705111,
            5705112,
            5705201,
            5705202,
            5705203,
            5705204,
            5705205,
            5705206,
            5705207,
            5705208,
            5705209,
            5705210,
            5705211,
            5705212,
            5705301,
            5705302,
            5705303,
            5705304,
            5705305,
            5705306,
            5705307,
            5705308,
            5705309,
            5705310,
            5705311,
            5705312,
            5705401,
            5705402,
            5705403,
            5705404,
            5705405,
            5705406,
            5705407,
            5705408,
            5705409,
            5705410,
            5705411,
            5705412,
            5705501,
            5705502,
            5705503,
            5705504,
            5705505,
            5705506,
            5705507,
            5705508,
            5705509,
            5705510,
            5705511,
            5705512,
            5705601,
            5705602,
            5705603,
            5705604,
            5705605,
            5705606,
            5705607,
            5705608,
            5705609,
            5705610,
            5705611,
            5705612,
            5705701,
            5705702,
            5705703,
            5705704,
            5705705,
            5705706,
            5705707,
            5705708,
            5705709,
            5705710,
            5705711,
            5705712,
            5705801,
            5705802,
            5705803,
            5705804,
            5705805,
            5705806,
            5705807,
            5705808,
            5705809,
            5705810,
            5705811,
            5705812,
            5705901,
            5705902,
            5705903,
            5705904,
            5705905,
            5705906,
            5705907,
            5705908,
            5705909,
            5705910,
            5705911,
            5705912,
            5706001,
            5706002,
            5706003,
            5706004,
            5706005,
            5706006,
            5706007,
            5706008,
            5706009,
            5706010,
            5706011,
            5706012,
            5706101,
            5706102,
            5706103,
            5706104,
            5706105,
            5706106,
            5706107,
            5706108,
            5706109,
            5706110,
            5706111,
            5706112,
            5706201,
            5706202,
            5706203,
            5706204,
            5706205,
            5706206,
            5706207,
            5706208,
            5706209,
            5706210,
            5706211,
            5706212,
            5706301,
            5706302,
            5706303,
            5706304,
            5706305,
            5706306,
            5706307,
            5706308,
            5706309,
            5706310,
            5706311,
            5706312,
            5706401,
            5706402,
            5706403,
            5706404,
            5706405,
            5706406,
            5706407,
            5706408,
            5706409,
            5706410,
            5706411,
            5706412,
            5706501,
            5706502,
            5706503,
            5706504,
            5706505,
            5706506,
            5706507,
            5706508,
            5706509,
            5706510,
            5706511,
            5706512,
            5706601,
            5706602,
            5706603,
            5706604,
            5706605,
            5706606,
            5706607,
            5706608,
            5706609,
            5706610,
            5706611,
            5706612,
            5706701,
            5706702,
            5706703,
            5706704
        ]
    },
    "timeline": [
        {
            "goal_id": 3, //emergencyfund 
            "date": "2024-08"
        },
        {
            "lifeevent_id": 1720803139289, //enlarge job
            "date": "2034-12"
        },
        {
            "lifeevent_id": 1720800083602, //part-time job
            "date": "2031-11"
        },
        {
            "lifeevent_id": 1720800052291, //reduce job
            "date": "2029-10"
        },
        {
            "lifeevent_id": 1720803149666, //enlarge job
            "date": "2035-05"
        },
        {
            "lifeevent_id": 1720800096107, //part-time job
            "date": "2032-12"
        },
        {
            "lifeevent_id": 1720800068818, //reduce job
            "date": "2030-11"
        },
        {
            "lifeevent_id": 1720800001899, //have child w/ no change in income
            "date": "2025-07"
        },
        {
            "lifeevent_id": 1720800019107, //have child + spouse change in income
            "date": "2026-08"
        },
        {
            "lifeevent_id": 1720576260472, //when down payment for vacation is saved
            "date": "2024-10"
        },
        {
            "lifeevent_id": 9,  //vacation - when event occurs
            "date": "2024-12"
        },
        {
            "goal_id": 5, //when the particular debt is paid off
            "date": "2032-12"
        },
        {
            "goal_id": 6, //when the particular debt is paid off
            "date": "2035-02"
        },
        {
            "goal": "fedloanpayoff", //ancillary event - spouse originally expected to do forgiveness but ends up paying off loans
            "who": "spouse",
            "date": "2029-06"
        },
        {
            "goal_id": 4, //applicant pays off federal loans
            "date": "2032-05"
        },
        {
            "goal_id": 7, //applicant's private loans are paid off
            "date": "2035-10"
        },
        {
            "goal_id": 8, //applicant's perkins loans are paid off
            "date": "2029-04"
        },
        {
            "goal_id": 11, //spouse's private loans are paid off
            "date": "2032-05"
        },
        {
            "goal_id": 12, //spouse's perkins loans are paid off
            "date": "2028-04"
        },
        {
            "goal": "child_leaves_nest",
            "date": "2038-05"
        },
        {
            "goal": "child_leaves_nest",
            "date": "2045-09"
        }
    ],
    "taxbomb": [
        {
            "who": "applicant",
            "amount": 15646
        }
    ],
    "summary": {
        "now": {
            "date": "2024-07",
            "score": 500,
            "hc": 5702407,
            "assets": {
                "cash": 2598.924462997868,
                "investment": 0,
                "retirement": 0,
                "home": 125257.47953373034,
                "property": 12024.718035238113,
                "vehicle": 11900,
                "other": 0,
                "total": 151781.1220319663
            },
            "debts": {
                "credit_card": 3020,
                "student_loan": 221005.16666666663,
                "home": 49400,
                "property": 24814.583333333336,
                "vehicle": 6184.876666666667,
                "personal": 10828.645833333332,
                "other": 3273.333333333333,
                "total": 318526.60583333333
            }
        },
        "looming": {
            "date": "2025-01",
            "score": 500,
            "hc": 5702501,
            "assets": {
                "cash": 302694.20954406034,
                "investment": 0,
                "retirement": 0,
                "home": 126813.5327306255,
                "property": 12174.099142140049,
                "vehicle": 11300,
                "other": 0,
                "total": 452981.84141682595
            },
            "debts": {
                "credit_card": 1080.2541088456449,
                "student_loan": 214931.90529910312,
                "home": 45768.36842138239,
                "property": 23684.100026300268,
                "vehicle": 3889.5204942290866,
                "personal": 9782.917226665484,
                "other": 1881.2450473667382,
                "total": 301018.3106238927
            }
        },
        "next": {
            "date": "2025-07",
            "score": 500,
            "hc": 5702507,
            "assets": {
                "cash": 287694.20954406034,
                "investment": 0,
                "retirement": 0,
                "home": 128388.91652207343,
                "property": 12325.33598611905,
                "vehicle": 10700,
                "other": 0,
                "total": 439108.46205225284
            },
            "debts": {
                "credit_card": 0,
                "student_loan": 274934.43697759806,
                "home": 42081.920766610114,
                "property": 22522.17002392657,
                "vehicle": 1547.8729344605194,
                "personal": 2228.1841316690643,
                "other": 432.5368803349295,
                "total": 343747.1217145993
            }
        },
        "soon": {
            "date": "2029-07",
            "score": 500,
            "hc": 5702907,
            "assets": {
                "cash": 376525.2060367048,
                "investment": 197514.6996219629,
                "retirement": 22530.318913902283,
                "home": 269777.210842006,
                "property": 13604.86474276233,
                "vehicle": 5900,
                "other": 26580.148480954314,
                "total": 912432.4486382927
            },
            "debts": {
                "credit_card": 0,
                "student_loan": 87712.3231560929,
                "home": 0,
                "property": 0,
                "vehicle": 0,
                "personal": 0,
                "other": 0,
                "total": 87712.3231560929
            }
        },
        "later": {
            "date": "2034-07",
            "score": 500,
            "hc": 5703407,
            "assets": {
                "cash": 357525.2060367048,
                "investment": 431249.02244064095,
                "retirement": 74278.12200668274,
                "home": 305228.1519973693,
                "property": 152802.60003884952,
                "vehicle": 45416.66666666669,
                "other": 29616.19591826404,
                "total": 1396115.965105178
            },
            "debts": {
                "credit_card": 0,
                "student_loan": 45005.7950999076,
                "home": 0,
                "property": 0,
                "vehicle": 0,
                "personal": 0,
                "other": 0,
                "total": 45005.7950999076
            }
        },
        "retirement": {
            "date": "2067-04",
            "score": 500,
            "hc": 5706704,
            "assets": {
                "cash": 348141.0278637547,
                "investment": 5819603.378194391,
                "retirement": 817381.1292973096,
                "home": 685221.8117608172,
                "property": 343034.13284527714,
                "vehicle": 0,
                "other": 60144.377493195694,
                "total": 8073525.857454745
            },
            "debts": {
                "credit_card": 0,
                "student_loan": 0,
                "home": 0,
                "property": 0,
                "vehicle": 0,
                "personal": 0,
                "other": 0,
                "total": 0
            }
        }
    }
}

export default { plan5559, plan5585, plan5593, plan5678: {} };
