import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import styled from "@emotion/styled";
import { Global, css } from "@emotion/react";
import useWindowSize from "src/hooks/useWindowSize";
import { EuiTitle } from "@elastic/eui";
import { MONTHS } from "src/constants";
import themeColors from "src/theme/colors";
import colors from "src/theme/colors";
import { StyledSpacer } from "../Global/StyledComponents";

const now = new Date();
const nowMonth = now.getMonth() + 1;
const nowYear = now.getFullYear();

const monthYearFormatter = (monthIndex: number) => {
  const targetMonth = monthIndex + nowMonth;
  const monthOffset = targetMonth % 12;
  const yearOffset = Math.floor(targetMonth / 12);
  return `${MONTHS[monthOffset]} ${nowYear + yearOffset}`;
};

const yearFormatter = (monthIndex: number) => {
  const yearOffset = Math.floor((monthIndex + nowMonth) / 12);
  return "" + (nowYear + yearOffset);
};

const TooltipContainer = styled.div`
  background-color: #fff;
  padding: 10px 20px;
  text-align: left;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  .chart-title {
    color: #343741;
    font-size: 14px;
    font-weight: 600;
    font-family: "Inter";
    line-height: 24px;
  }
  ,
  & > .chart-items {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
      font-size: 12px;
      line-height: 18px;
      display: flex;
      span.circle {
        display: block;
        width: 12px;
        height: 12px;
        border-radius: 12px;
        top: 5px;
        margin-right: 10px;
        margin-bottom: 16px;
      }
      & > strong: {
        margin-left: 10px;
      }
    }
  }
`;

export const Chart = ({
  actions,
  colors,
  dollars,
  data,
  keys,
  xKey,
  xMargin,
  xYearOnly,
  height,
  keyLabels,
  yMargin,
}: any) => {
  const defaultColors = [
    themeColors.brandingBlue1,
    themeColors.brandingBlue2,
    themeColors.warn,
  ];
  const displayColors = colors || defaultColors;

  const windowSize = useWindowSize();

  const getMaxTicks = (width: number) => {
    if (width < 576) return 4;
    if (width < 1160) return 8;
    return 10;
  };

  const CustomTooltip = ({ active, payload, label, keyLabels }: any) => {
    if (active && payload && payload.length) {
      return (
        <TooltipContainer>
          <EuiTitle className="chart-title">
            <h3>
              {xYearOnly ? yearFormatter(label) : monthYearFormatter(label)}
            </h3>
          </EuiTitle>
          <StyledSpacer size="16px" />
          <ul className="chart-items">
            {payload.map((e: any) => (
              <li key={e.dataKey}>
                <span
                  className="circle"
                  style={{ backgroundColor: e.stroke }}
                ></span>
                {`${(keyLabels || {})[e.dataKey] || [e.dataKey]} `}
                <span className="ml-2">
                  <strong>{` ${kFormatter(e.value)}`}</strong>
                </span>
              </li>
            ))}
          </ul>
        </TooltipContainer>
      );
    }

    return null;
  };

  const kFormatter = (value: number) => {
    if (isNaN(value)) {
      return "";
    }
    let result = "" + value;
    if (!value) {
      result = "0";
    } else if (Math.abs(value) > 999999) {
      result = (Math.abs(value / 1e6)).toFixed(3) + "M";
    } else if (Math.abs(value) > 999) {
      result = (Math.abs(value / 1e3)).toFixed(3) + "k"; 
    } else {
      result = Math.abs(value).toFixed(3);
    }
    if (dollars) {
      result = "$" + result;
    }
    if (value < 0) {
      result = "-" + result;
    }
    return result;
  };

  const calculateInterval = (dataLength: number, maxTicks: number) => {
    return Math.ceil(dataLength / maxTicks);
  };

  const xAxisInterval = calculateInterval(data.length, getMaxTicks(windowSize.width));

  const CustomYAxisTick = ({ x, y, payload }: { x: number; y: number; payload: { value: number } }) => {
    const formattedValue = kFormatter(payload.value);
    const numericPart = formattedValue.split('.')[0];
    const suffix = formattedValue.replace(/[^a-zA-Z]/g, ''); 
    const wholeNumberValue = numericPart + suffix; 
    return payload.value === 0 ? (
      <g />
    ) : (
      <text x={x} y={y} textAnchor="end" dy={20}>
        {wholeNumberValue} 
      </text>
    );
  };

  return (
    <>
      <Global
        styles={css`
          *:focus:not(:focus-visible) {
            outline: none !important;
          }
          *:focus {
            outline: none !important;
            outline-offset: 0 !important;
          }
          outline-style: none;
        `}
      />
      <div
        className={height ? "" : "h-60"}
        style={height ? { height } : undefined}
      >
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={400}
            data={data}
            margin={{
              top: 20,
              right: xMargin ? xMargin / 2 : 0,
              left: 60,
              bottom: 40,
            }}
          >
            <XAxis
              axisLine={false}
              dataKey={xKey}
              tickMargin={10}
              height={0.1}
              interval={xAxisInterval}
              tick={(props) => {
                const { x, y, payload } = props;
                return (
                  <g transform={`translate(${x},${y})`}>
                    <text
                      x={32}
                      y={0}
                      dy={16}
                      textAnchor="end"
                    >
                      {yearFormatter(payload.value)}
                    </text>
                  </g>
                );
              }}
              tickLine={false}
            />
            <YAxis
              axisLine={false}
              tickMargin={10}
              tickLine={false}
              scale="linear"
              domain={['auto', 'auto']}
              width={1}
              tick={CustomYAxisTick}
              tickCount={4}
            />
            <Tooltip content={<CustomTooltip keyLabels={keyLabels} />} wrapperStyle={{ outline: 'none' }} />
            {!!keys &&
              keys.map((key: string, index: number) => (
                <Line
                  key={key}
                  type="monotone"
                  dataKey={key}
                  stroke={displayColors[index]}
                  dot={false}
                  strokeWidth={4}
                />
              ))}
          </LineChart>
        </ResponsiveContainer>
      </div>
      {actions || null}
    </>
  );
};

export default Chart;