import axiosClient from "../axiosClient";
import makeAuthenticatedRequest from "../authenticatedRequest";
import {
  Earning,
  Plan,
  PlanAllocations,
} from "../../interfaces/plan.interface";
import NodeClient from "../nodeClient";
import { OptimizedPlan } from "src/interfaces/optimizedPlanBuild.interface";

export const listPlansApi = () =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get("plan/plan.php");
    return data;
  });

export const getPlanApi = (id = 0) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get(`plan/plan.php?id=${id}`);
    return data;
  });

export const setOptimizedPlanApi = (optimizedPlan: OptimizedPlan) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await NodeClient.post("plan/optimize", optimizedPlan);
    return data;
  });

export const addPlanApi = (plan: Plan) =>
  makeAuthenticatedRequest(async () => {
    const response = await axiosClient.post("plan/plan.php", plan);
    return response;
  });

export const updatePlanApi = (id: number, plan: Partial<Plan>) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.put(`plan/plan.php?id=${id}`, plan);
    return data;
  });

export const deletePlanApi = (id: number) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.delete(`plan/plan.php?id=${id}`);
    return data;
  });

export const getPlanProjectionApi = (planId: number, minimum?: boolean) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get(
      `plan/data.php?plan=${planId}${minimum ? "&mode=minimum" : ""}`
    );
    return data;
  });

export const getLivePlanProjectionApi = () =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get("plan/data.php?plan=live");
    return data;
  });

export const getV2PlanProjectionApi = (planId: number) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get(`/v2/plan/${planId}/projection`);
    return data;
  });

export const implementPlanApi = (id: number) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.put("plan/implement.php", { id });
    return data;
  });

export const estimateTaxesApi = (
  incomes: Earning[],
  firstAllocation: PlanAllocations,
  inschool: boolean
) =>
  makeAuthenticatedRequest(async () => {
    const education = inschool ? { education: [] } : {};
    const { data } = await axiosClient.post("plan/taxes.php", {
      incomes,
      allocations: [firstAllocation],
      ...education,
    });
    return data;
  });

export const getLiabilitiesApi = () =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get("plan/liabilities.php");
    return data;
  });

export const getStudentLoanLiabilitiesApi = (input: any) =>
  makeAuthenticatedRequest(async () => {
    const education = input.inschool ? { education: [] } : {};
    delete input.inschool;
    const requestBody = {
      ...education,
      ...input,
      allocations: [input.allocations],
    };
    const { data } = await axiosClient.put("plan/liabilities.php", requestBody);
    return data;
  });

export const calculateEmergencyExpensesApi = (plan: any) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.post("plan/emergencyfund.php", plan);
    return data;
  });

export const cacheRemainingFundsApi = (planId: number, remaining: number) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.put(`plan/education.php?id=${planId}`, {
      remaining,
    });
    return data;
  });
