import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Divider, makeStyles, Typography } from "@material-ui/core";

import { savePlanAndCashflows, savePlan } from "src/store/planBuild/actions";
import { PLAN_BUILD_STEPS } from "src/store/planBuild/constants";
import { getCurrentStep, getFurthestStep } from "src/store/planBuild/selector";
import { getIsCurrentStudent } from "src/store/system/selector";
// import { PlanViewComponent } from "src/interfaces/viewComponent.interface";
// import { Plan } from "src/interfaces/plan.interface";
import { planBuilderStyles } from "src/theme";
import Card from "src/components/Card";

const useStyles = makeStyles(planBuilderStyles);
const steps = [
  {
    label: "1. Life Events/Goals",
    subSteps: [PLAN_BUILD_STEPS.GRADUATED_LIFE_EVENTS_OVERVIEW],
  },
  {
    label: "2. Day-To-Day Money",
    subSteps: [PLAN_BUILD_STEPS.INCOME_AND_EXPENSES],
  },
  {
    label: "3. Money For Future Goals",
    subSteps: [PLAN_BUILD_STEPS.ASSETS_AND_DEBTS],
  },
  {
    label: "4. Risk Management",
    subSteps: [PLAN_BUILD_STEPS.RISK_MANAGEMENT],
  },
  {
    label: "5. Additional Questions",
    subSteps: [PLAN_BUILD_STEPS.SAVINGS],
  },
  {
    label: "6. Goals",
    subSteps: [PLAN_BUILD_STEPS.GOALS],
  },
  {
    label: "7. Simulate & Review My Plan",
    subSteps: [PLAN_BUILD_STEPS.RECOMMENDATIONS],
  },
];
const currentStudentSteps = [
  {
    label: "1. Education Funding & Costs",
    subSteps: [PLAN_BUILD_STEPS.EDUCATION_FUNDING],
  },
  {
    label: "2. Expenses & Insurance",
    subSteps: [PLAN_BUILD_STEPS.EXPENSES_AND_INSURANCE],
  },
  {
    label: "3. Debt & Assets",
    subSteps: [PLAN_BUILD_STEPS.ASSETS_AND_DEBTS],
  },
  {
    label: "4. Future Questions",
    subSteps: [PLAN_BUILD_STEPS.FUTURE_QUESTIONS],
  },
  {
    label: "5. Life Events",
    subSteps: [PLAN_BUILD_STEPS.LIFE_EVENTS_OVERVIEW],
  },
];

const LeftSidebar = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const currentStep = useSelector(getCurrentStep);
  const furthestStep = useSelector(getFurthestStep);
  const isCurrentStudent = useSelector(getIsCurrentStudent);
  const displaySteps: any[] = isCurrentStudent ? currentStudentSteps : steps;
  const currentStepIndex = displaySteps.findIndex((step) => {
    const index = step.subSteps.findIndex(
      (stepName: PLAN_BUILD_STEPS) => stepName === currentStep
    );
    return index > -1;
  });
  const furthestStepIndex = displaySteps.findIndex((step) => {
    const index = step.subSteps.findIndex(
      (stepName: PLAN_BUILD_STEPS) => stepName === furthestStep
    );
    return index > -1;
  });
  return (
    <Card
      className={styles.sidebar}
      title={"Plan Setup"}
      noPadding
      titleTag="h6"
    >
      <Divider />
      <Box className={styles.leftCenter}>
        {displaySteps.map((step: any, index) => {
          let className = styles.step;
          let onItemClick: any = () => {
            const nextStep = step.subSteps[0] as PLAN_BUILD_STEPS;
            const action = isCurrentStudent ? savePlan : savePlanAndCashflows;
            dispatch(action(nextStep));
          };
          if (currentStepIndex === index) {
            className = `${styles.step} ${styles.activeStep}`;
            onItemClick = undefined;
          } else if (furthestStepIndex >= index) {
            className = `${styles.step} ${styles.completedStep}`;
          }
          return (
            <Typography
              key={step.label}
              variant="body1"
              className={className}
              onClick={onItemClick}
            >
              {step.label}
            </Typography>
          );
        })}
      </Box>
      {/* <Divider />
      <Box className={styles.leftBottom}>
        <Typography variant="body2">{planBuilderText[currentStep]}</Typography>
        <LearnMoreLink learnMoreKey={`planBuilder${currentStepIndex}`} />
      </Box> */}
    </Card>
  );
};

export default LeftSidebar;
