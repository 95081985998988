import React from "react";
import { Transaction } from "src/interfaces";
import { formatDollarsAndCents } from "src/utils";
import { Global, css } from "@emotion/react";
import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiText, EuiButtonIcon } from "@elastic/eui";
import { StyledEuiButtonIcon, StyledEuiHorizontalRule, StyledSpacer } from "src/components/Global/StyledComponents";
import edit_icon from 'src/assets/svgs/edit_pencil.svg';
import check_icon from 'src/assets/svgs/check_icon.svg';
import fill_check from 'src/assets/svgs/fill_check.svg';

const confirmlistStyles = css`
  .transaction-list-group {
    display: flex;
    padding: 0;
    justify-content: space-between !important;
    .euiFlexItem {
      flex: 0 1 auto;
    }
    .transaction-label-group-transfer {
      display: flex;
      padding: 0;
      justify-content: space-between !important;
      color: #343741;
      gap: 16px;
      align-items: center;
      .euiAvatar, img {
        width: 40px;
        height: 40px;
      }
    }
    .transaction-label-group {
      color: #343741;
      gap: 16px;
      align-items: flex-start;
      justify-content: space-between;
    }
    .transaction-main-text {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      font-family: "Inter", sans-serif;
    }
    .transaction-secondary-text {
      font-size: 14px;
      line-height: 24px;
      font-family: "Inter", sans-serif;
    }
    .confirm-button {
      font-size: 14px !important;
      line-height: 21px;
      max-width: 71px;
      min-width: 71px;
      padding: 8px;
    }
  }
`;

interface IncomeTransactionListProps {
  items: Transaction[];
  onSelect?: (item: Transaction) => void;
  openConfirmDialog: (item: Transaction, description: string) => void;
  selectedIds?: Set<number>;
  variableHeight?: boolean;
}

const IncomeTransactionList = ({
  items,
  onSelect,
  openConfirmDialog,
  selectedIds,
}: IncomeTransactionListProps) => {
  return (
    <>
      <Global styles={confirmlistStyles} />
      {items.map((item, index) => (
        <React.Fragment key={item.id}>
          {!!onSelect && !!selectedIds && (
            <EuiFlexGroup className="transaction-list-group">
              <EuiFlexItem>
                <EuiFlexGroup className="transaction-label-group-transfer">
                  <EuiFlexItem>
                    <StyledEuiButtonIcon
                      className="euiAvatar"
                      iconType={selectedIds.has(item.id) ? fill_check : check_icon}
                      onClick={() => onSelect(item)}
                    />
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <span className="transaction-main-text">{item.description.replace(/\(.*?\)/g, '').length > 20 ? item.description.replace(/\(.*?\)/g, '').substring(0, 20) + '...' : item.description.replace(/\(.*?\)/g, '')}</span><span className="transaction-secondary-text">{new Date(item.date).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}</span>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiFlexGroup className="transaction-amount-group">
                  <EuiFlexItem className="transaction-secondary-text">
                    <EuiText style={{ textAlign: 'right' }}>
                      <span style={{ fontWeight: '600' }}>{item.amount < 0 ? "+" : "-"}{formatDollarsAndCents(Math.abs(item.amount))}</span>
                    </EuiText>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiButtonIcon
                      className="euiButtonIcon"
                      iconType={edit_icon}
                      onClick={() => openConfirmDialog(item, item.description)}
                    />
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
            </EuiFlexGroup>
          )}
          {!onSelect && (
            <div className="transaction-list-group">
              <EuiFlexGroup className="transaction-label-group">
                <EuiFlexItem className="transaction-main-text">
                  <span>{item.description.replace(/\(.*?\)/g, '').length > 20 ? item.description.replace(/\(.*?\)/g, '').substring(0, 20) + '...' : item.description.replace(/\(.*?\)/g, '')}</span>
                </EuiFlexItem>
                <EuiFlexItem className="transaction-secondary-text">
                  <EuiText style={{ textAlign: 'right' }}>
                    <span style={{ fontWeight: '600' }}>{item.amount < 0 ? "+" : "-"}{formatDollarsAndCents(Math.abs(item.amount))}</span><br />
                    <span>{new Date(item.date).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}</span>
                  </EuiText>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiButton
                    className="confirm-button"
                    color="primary"
                    fill
                    onClick={() => {
                      openConfirmDialog(item, item.description);
                      console.log(item.description);
                    }}
                  >
                    Confirm
                  </EuiButton>
                </EuiFlexItem>
              </EuiFlexGroup>
            </div>
          )}
          {index !== items.length - 1 && (
            <>
              <StyledSpacer size="16px" />
              <StyledEuiHorizontalRule />
              <StyledSpacer size="16px" />
            </>
          )}
        </React.Fragment >
      ))}
    </>
  );
};

export default IncomeTransactionList;