import React from "react";
import { useSelector } from "react-redux";
import {
  Box,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Card from "src/components/Card";
import { GOALS, SPECIAL_REPAYMENTS } from "src/interfaces";
import {
  getLiveLifeEventProjections,
  getLiveProjection,
} from "src/store/planBuild/selector";
import {
  getProfile,
  getSpouseInSchool,
  getSpouseProfile,
  getUserInSchool,
} from "src/store/profileBuild/selector";
import { getIsCurrentStudent, getIsMarried } from "src/store/system/selector";
import { AdapterLink, formatWholeDollars, reformatDateString } from "src/utils";
import { getAccounts } from "src/store/account/selector";
import { colors } from "src/theme";
import ToolTipIcon from "src/components/ToolTipIcon";
import { Global, css } from "@emotion/react";
import { EuiCard, EuiFlexGroup, EuiFlexItem, EuiIcon, EuiLink, EuiText } from "@elastic/eui";
import { StyledEuiLink, StyledSpacer } from "src/components/Global/StyledComponents";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import useWindowSize from "src/hooks/useWindowSize";

const EGStyles = css`
  .card {
    max-height: 100% !important;
    .css-4ld6o8-euiCard__children {
        margin: 0;
    }
    .euiPanel {
      padding: 20px 16px 16px;
      min-height: 322px;
      max-height: 100% !important;
    }
    .eg-card {
      .eg-short-goal {
        align-items: center;
        justify-content: flex-start;
        flex-wrap: nowrap !important;
        .eg-wheel {
          flex: 0 1 auto !important;
          inline-size: auto !important;
        }
        .eg-info-text {
          flex: 1 1 auto !important;
          inline-size: auto !important;
          text-align: left;
        }
        .eg-short-label {
          font-size: 14px;
          font-family: 'Inter', sans-serif;
          font-weight: 600;
        }
        .eg-short-text {
          font-size: 16px;
          line-height: 24px;
          font-family: 'Inter', sans-serif;
          font-weight: 600;
          color: #000;
          .eg-target-amount {
            font-weight: 500;
            color: #69707D;
          }
        }
        .eg-notice-text {
          font-size: 16px;
          line-height: 24px;
          font-family: 'Inter', sans-serif;
          color: #006BB8;
        }
      }
      .eg-long-goal {
        align-items: center;
        justify-content: flex-start;
        flex-wrap: nowrap !important;
        .eg-wheel {
          flex: 0 1 auto !important;
          inline-size: auto !important;
        }
        .eg-info-text {
          flex: 1 1 auto !important;
          inline-size: auto !important;
          text-align: left;
        }
        .eg-short-label {
          font-size: 14px;
          font-family: 'Inter', sans-serif;
          font-weight: 600;
        }
        .eg-short-text {
          font-size: 16px;
          line-height: 24px;
          font-family: 'Inter', sans-serif;
          font-weight: 600;
          color: #000;
          .eg-target-amount {
            font-weight: 500;
            color: #69707D;
          }
        }
        .eg-notice-text {
          font-size: 16px;
          line-height: 24px;
          font-family: 'Inter', sans-serif;
          color: #8A6A0A;
        }
      }
      .progress-chart {
        flex: 0 1 auto !important;
        inline-size: auto !important;
        .CircularProgressbar-text {
          dominant-baseline: middle;
          text-anchor: middle;
          font-weight: 600;
        }
      }
      .eg-card-header {
        flex-wrap: nowrap !important;
        .eg-card-title {
          display: flex;
          justifycontent: center;
          alignitems: center;
          flex-direction: row;
          flex: 1 1 auto !important;
            inline-size: auto !important;
          h2 {
            font-size: 20px;
            line-height: 32px;
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
          }
          .eg-card-link {
            font-size: 16px;
            font-family: 'Inter', sans-serif;
            line-height: 24px;
            text-decoration: underline;
          }
        }
        .eg-view-more {
          flex: 0 1 auto !important;
          inline-size: auto !important;
          .eg-more-link {
            font-size: 16px;
            font-family: 'Inter', sans-serif;
            line-height: 24px;
            color: #69707D;
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles({
  chartContainer: {
    margin: "0 auto",
    maxWidth: 900,
  },
  title: {
    fontSize: 14,
    color: colors.brandingBlue1,
    fontWeight: 600,
  },
  tableContainer: {
    maxHeight: 350,
  },
  table: {
    width: "100%",
    borderBottom: `1px solid ${colors.blueGray6}`,
  },
  headerCell: {
    background: "#fff",
    fontSize: 10,
    color: colors.blueGray1,
    fontWeight: 500,
    borderBottom: `1px solid ${colors.blueGray6}`,
    padding: "7px 0",
    textAlign: "center",
  },
  noteCell: {
    fontSize: 10,
    color: colors.blueGray1,
    fontWeight: 500,
    padding: "10px 0 0px 0",
  },
  firstCol: {
    width: 170,
  },
  row: {
    cursor: "pointer",
  },
  cell: {
    fontSize: 10,
    borderBottom: "none",
    padding: "10px 0",
    position: "relative",
    whiteSpace: "nowrap",
    textAlign: "center",
  },
  unbold: {
    fontWeight: 500,
  },
  odd: {
    color: colors.brandingBlue1,
  },
  even: {
    color: colors.brandingBlue2,
  },
  leftBorder: {
    borderLeft: `1px solid ${colors.blueGray6}`,
    textAlign: "center",
  },
  topBorder: {
    borderTop: `1px solid ${colors.blueGray6} !important`,
  },
  subCell: {
    fontSize: 10,
    fontWeight: 500,
    borderBottom: "none",
    borderTop: "none",
    padding: "7px 0",
    color: colors.blueGray2,
  },
  idr: {
    width: "180px",
  },
});

const LifeEventsGoals = () => {
  const styles = useStyles();
  const livePlanEvents: any[] = useSelector(getLiveLifeEventProjections);
  const livePlanProjection = useSelector(getLiveProjection);
  const targets = livePlanProjection?.targets;
  const isCurrentStudent = useSelector(getIsCurrentStudent);
  const userInSchool = useSelector(getUserInSchool);
  const spouseInSchool = useSelector(getSpouseInSchool);
  const isMarried = useSelector(getIsMarried);
  const accounts = useSelector(getAccounts);
  const { width } = useWindowSize();
  const myProfile = useSelector(getProfile);
  const spouseProfile = useSelector(getSpouseProfile);
  const applicantIdr =
    SPECIAL_REPAYMENTS.indexOf(myProfile.fed_repayment_plan || "") >= 0;
  const spouseIdr =
    SPECIAL_REPAYMENTS.indexOf(spouseProfile.fed_repayment_plan || "") >= 0;

  const getHeaderRow = () => {
    const headers = [<Box> Date Acquired </Box>];
    if (!isCurrentStudent) {
      headers.push(
        <Box>
          {" "}
          Current
          <ToolTipIcon
            placement="top-start"
            title="Reflects balances from cash and/or taxable brokerage accounts to achieve savings goals."
          />
        </Box>,
        <Box> Goal </Box>
      );
    }
    return headers;
  };

  const formatLifeEventDollars = (value: number) => {
    if (value) {
      return formatWholeDollars(value);
    } else {
      return "--";
    }
  };

  const StudentLoanTypes: any = {
    priv_loan: 1,
    perkins_loan: 1,
  };

  const SpecialStudentLoanTypes: any = {
    fed_loan: 1,
  };

  const HomeLoanTypes: any = {
    home_loan: 1,
    property_loan: 1,
  };

  const getInitialTotals = () => {
    const results = {
      cash_value: 0,
      other_investments_value: 0,
      auto_loan: 0,
      student_loan: 0,
      student_loan_spouse: 0,
      student_loan_fed: 0,
      student_loan_fed_spouse: 0,
      home: 0,
      creditCard: 0,
      allocateFunds: function (targetAmount: number, type: any) {
        let remaining = targetAmount; //5
        let result = 0;

        let consumed = Math.min(this.cash_value, remaining);
        result += consumed;
        this.cash_value -= consumed;
        remaining -= consumed;

        if (type !== "emergencyfund") {
          consumed = Math.min(this.other_investments_value, remaining);
          result += consumed;
          this.other_investments_value -= consumed;
          remaining -= consumed;
        }
        return result;
      },
    };
    accounts.map((account) => {
      if (account.variable && account.balance) {
        if (account.variable === "cash_value") {
          results.cash_value += account.balance;
        } else if (account.variable === "other_investments_value") {
          results.other_investments_value += account.balance;
        } else if (account.variable === "auto_loan") {
          results.auto_loan += account.balance;
        } else if (StudentLoanTypes[account.variable]) {
          if (account.who === "applicant") {
            results.student_loan += account.balance;
          } else {
            results.student_loan_spouse += account.balance;
          }
        } else if (SpecialStudentLoanTypes[account.variable]) {
          if (account.who === "applicant") {
            results.student_loan_fed += account.balance;
          } else {
            results.student_loan_fed_spouse += account.balance;
          }
        } else if (HomeLoanTypes[account.variable]) {
          results.home += account.balance;
        } else if (account.variable === "credit_card") {
          results.creditCard += account.balance;
        }
      }
    });
    return results;
  };

  const getRows = () => {
    const currentBalances = getInitialTotals();
    const savingTargets: any = {};
    targets?.savings.map((target: any) => {
      savingTargets[target.type] = target.amount;
    });
    const today = new Date();

    const filteredPlanEvents = livePlanEvents.filter((event) => {
      const eventDate = new Date(event.plan);
      if (
        !isCurrentStudent &&
        (event.type === "emergencyfund" || eventDate < today)
      ) {
        return false;
      }
      if (event.type === "graduate" || event.type === "repayment") {
        if (
          (event.who === "spouse" && !spouseInSchool) ||
          (event.who !== "spouse" && !userInSchool)
        ) {
          return false;
        }
      }
      return true;
    });

    if (!isCurrentStudent) {
      filteredPlanEvents.unshift({ type: "emergencyfund", plan: "", who: "" });
    }

    // duplicate filter
    const duplicateGoalFilters: any = {
      mortgage: 0,
      auto_loan: 0,
      credit_card: 0,
    };

    const rows = filteredPlanEvents.map((event) => {
      const typeLabel = GOALS[event.type as keyof typeof GOALS] || "Goal";
      const date = event.plan ? reformatDateString(event.plan) : "";
      let current = 0;
      let goal = 0;
      // stop and aggregate the student loans based on idr and owner
      if (!isCurrentStudent) {
        // get the current based on if this is spouses or not
        if (event.type === "student_loan") {
          if (event.who === "applicant") {
            current = currentBalances.student_loan;
            if (!applicantIdr) {
              current += currentBalances.student_loan_fed;
            }
          } else {
            current = currentBalances.student_loan_spouse;
            if (!spouseIdr) {
              current += currentBalances.student_loan_fed_spouse;
            }
          }
          targets?.debts.forEach((targetDebt: any) => {
            if (
              targetDebt.type === "student_loan" &&
              targetDebt.who === event.who
            ) {
              goal = targetDebt.amount;
            }
          });

          // stop and aggregate the target debts
        } else if (
          event.type === "auto_loan" &&
          duplicateGoalFilters[event.type] === 0
        ) {
          current = currentBalances.auto_loan;
          targets?.debts.forEach((item: any) => {
            if (item.type === "auto_loan") {
              goal = item.amount;
            }
          });
          duplicateGoalFilters[event.type]++;
          // stop and aggregate the mortgage
        } else if (
          event.type === "mortgage" &&
          duplicateGoalFilters[event.type] === 0
        ) {
          targets?.debts.forEach((item: any) => {
            if (item.type === "mortgage") {
              goal = item.amount;
            }
          });
          current = currentBalances.home;
          duplicateGoalFilters[event.type]++;
        } else if (
          event.type === "credit_card" &&
          duplicateGoalFilters[event.type] === 0
        ) {
          targets?.debts.forEach((item: any) => {
            if (item.type === "credit_card") {
              goal = item.amount;
            }
          });
          current = currentBalances.creditCard;
        }
      }

      return {
        type: event.type,
        header: typeLabel,
        who: event.who,
        date,
        currentValue: current,
        goal,
      };
    });

    const consumedSavings: any = {};
    rows.forEach((entry) => {
      const entryType = entry.type.replace("savings-", "");
      for (const item in savingTargets) {
        if (entryType === item && !consumedSavings[entryType]) {
          entry.currentValue = currentBalances.allocateFunds(
            savingTargets[item],
            entry.type
          );
          entry.goal = savingTargets[item];
          consumedSavings[entryType] = true;
          break;
        }
      }
    });

    return rows;
  };

  return (
    <>
      <Global styles={EGStyles} />
      <EuiCard
        className='eg-card'
        title={
          <EuiFlexGroup
            justifyContent="spaceBetween"
            alignItems="center"
            className='eg-card-header'
          >
            <EuiFlexItem grow={false} className='eg-card-title'>
              {width >= 1264 && <h2>Life Events/Goal Ranking</h2>}
              {(width < 992 || width <= 1263) && <h2>Life Events/Goals</h2>}
              &nbsp;&nbsp;
              {(width >= 767 && width < 992) || width >= 1264 && <EuiLink className="eg-card-link">Goal Ranking</EuiLink>}
            </EuiFlexItem>
            <EuiFlexItem grow={false} className="eg-view-more">
              <EuiLink
                className="eg-more-link"
              >View more
              </EuiLink>
            </EuiFlexItem>
          </EuiFlexGroup>
        }
      >
        <StyledSpacer size="24px" />
        <EuiFlexGroup className="eg-short-goal">
          <EuiFlexItem className="progress-chart">
            <div style={{ width: '60px', height: '60px' }}>
              <CircularProgressbar
                value={44}
                text={'44%'}
                styles={buildStyles({
                  textSize: '25px',
                  pathColor: 'rgba(96, 146, 192, 1)',
                  textColor: '#000',
                  trailColor: '#D3DAE6',
                })}
              />
            </div>
          </EuiFlexItem>
          <EuiFlexItem className="eg-info-text">
            <EuiText className="eg-short-label">SHORT-TERM GOAL</EuiText>
            <EuiText className="eg-short-text">Pay off student loan on Dec 2032<br />
              <span>$34,000</span>/<span className="eg-target-amount">$78,000</span>
            </EuiText>
            <EuiText className="eg-notice-text">
              <EuiIcon type="faceHappy" size="m" />&nbsp;&nbsp;
              <span>Good job, keep it up!</span>
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
        <StyledSpacer size="24px" />
        <EuiFlexGroup className="eg-long-goal">
          <EuiFlexItem className="progress-chart">
            <div style={{ width: '60px', height: '60px' }}>
              <CircularProgressbar
                value={66}
                text={'66%'}
                styles={buildStyles({
                  textSize: '25px',
                  pathColor: 'rgba(96, 146, 192, 1)',
                  textColor: '#000',
                  trailColor: '#D3DAE6',
                })}
              />
            </div>
          </EuiFlexItem>
          <EuiFlexItem className="eg-info-text">
            <EuiText className="eg-short-label">LONG-TERM GOAL</EuiText>
            <EuiText className="eg-short-text">Assign 30% of income to Money for Future Self<br />
              <span>$17,000</span>/<span className="eg-target-amount">$25,499</span>
            </EuiText>
            <EuiText className="eg-notice-text">
              <EuiIcon type="warning" size="m" />&nbsp;&nbsp;
              <span>You should be increasing your allocation</span>
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>

      </EuiCard>
      {/* <Card className="h-full" title="Life Events/Goal Progress">
        <TableContainer className={styles.tableContainer}>
          <Table stickyHeader className={styles.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  className={`${styles.headerCell} ${styles.firstCol}`}
                ></TableCell>
                {getHeaderRow().map((col, index) => (
                  <TableCell key={index} className={styles.headerCell}>
                    {col}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {getRows().map((row, rIndex) => {
                return (
                  <>
                    <TableRow key={rIndex} className={styles.row}>
                      <TableCell
                        component="th"
                        scope="row"
                        className={styles.cell}
                      >
                        <Box className="flex items-center relative">
                          <span className="ml-4 text-left">
                            {row.header}
                            {isMarried &&
                              (row.type === "student_loan" ||
                                row.type === "idr") &&
                              row.who !== "applicant" &&
                              ` (${row.who})`}
                          </span>
                        </Box>
                      </TableCell>
                      {row.type !== "idr" && (
                        <>
                          <TableCell className={styles.cell}>
                            {row.date}
                          </TableCell>
                          {!isCurrentStudent && (
                            <>
                              <TableCell className={styles.cell}>
                                {formatLifeEventDollars(row.currentValue)}
                              </TableCell>
                              <TableCell className={styles.cell}>
                                {formatLifeEventDollars(row.goal)}
                              </TableCell>
                            </>
                          )}
                        </>
                      )}
                      {row.type === "idr" && (
                        <>
                          <TableCell className={styles.cell}>
                            {row.date}
                          </TableCell>

                          {!isCurrentStudent && (
                            <div className={`${styles.cell} ${styles.idr}`}>
                              <AdapterLink to="/tax-bomb">
                                Click for details
                              </AdapterLink>
                            </div>
                          )}
                        </>
                      )}
                    </TableRow>
                  </>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Card> */}
    </>
  );
};

export default LifeEventsGoals;
