// This is a combination of 2 different sets of steps
// The left nav bar relies on the order to
// properly display it's menu items
export enum PLAN_BUILD_STEPS {
  NAME = 1,
  REUSE_SELECTIONS,
  EDUCATION_FUNDING,
  EXPENSES_AND_INSURANCE,
  GRADUATED_LIFE_EVENTS_OVERVIEW,
  INCOME_AND_EXPENSES,
  ASSETS_AND_DEBTS,
  STUDENT_ASSETS_AND_DEBTS,
  RISK_MANAGEMENT,
  FUTURE_QUESTIONS,
  LIFE_EVENTS_OVERVIEW,
  SAVINGS,
  GOALS,
  RECOMMENDATIONS,
  REVIEW,
  GRADUATED_REVIEW,
  IMPLEMENT,
  COMPARE,
}

export enum COMPARE_PLAN_STEPS {
  SCORE = 1,
  GOAL_SUMMARY,
  ASSETS_AND_INVESTMENTS,
  DEBTS,
  NET_WEALTH,
  PAYCHECK_ALLOCATION,
}

export const WHO_TYPES: any = {
  mine: "Mine",
  spouse: "Spouse",
};

export enum ReviewSections {
  HOME,
  SCORE,
  GOALSUMMARY,
  ASSETSINVESTMENTS,
  DEBTS,
  NETWEALTH,
  PAYCHECKALLOCATION,
}
