import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { EuiBadge } from "@elastic/eui";

import { getAccounts, getAssetBalanceTotals } from "src/store/account/selector";
import {
  getCurrentPlan,
  getEmergencyMonthlyExpenses,
  getFormattedGoalData,
  getPlanProjection,
} from "src/store/planBuild/selector";
import { spouseSelector, userSelector } from "src/store/system/selector";
import useWindowSize from "src/hooks/useWindowSize";
import { LIFE_EVENTS } from "src/interfaces";
import { colors } from "src/theme";

const GROUP_LABELS: any = {
  house: "Home",
  vehicle: "Vehicle",
  property: "Investment Property",
};

export enum DATA_TYPES {
  GOALS,
  ASSETS,
  DEBTS,
}

const useGoalSummaryData = (dataType: DATA_TYPES) => {
  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 567;
  const plan = useSelector(getCurrentPlan);
  const projection = useSelector(getPlanProjection);
  const timeline = projection?.timeline || [];
  const planEvents: any[] = plan.lifeevents;
  const planGoals = useSelector(getFormattedGoalData);
  const monthlyExpenses = useSelector(getEmergencyMonthlyExpenses);
  const assetBalanceTotals = useSelector(getAssetBalanceTotals);
  const user = useSelector(userSelector);
  const spouse = useSelector(spouseSelector);
  const accounts = useSelector(getAccounts);
  const emergencyFundTarget = monthlyExpenses * plan.emergencymonths;
  const spouseFirstName = spouse?.first_name || "";
  const myFirstName = user?.first_name || "";

  const populateMarriageGroup = (
    items: any[],
    skipIndices: Set<number>,
    isShortTermGoal?: boolean,
    goalIsMet?: boolean
  ) => {
    if (dataType === DATA_TYPES.DEBTS) {
      return;
    }
    const lifeEvent = planEvents.find(
      (event) => event.eventtype === "marriage"
    );
    const goal = planGoals.find((g) => g.lifeevent_id === lifeEvent?.id);
    const saveItemIndex = timeline.findIndex(
      (item: any) => item.goal_id === goal?.id
    );
    const getMarriedItemIndex = timeline.find(
      (item: any) => item.lifeevent_id === lifeEvent?.id
    );
    skipIndices.add(saveItemIndex);
    skipIndices.add(getMarriedItemIndex);
    const saveItem = timeline[saveItemIndex];
    const getMarriedItem = timeline[getMarriedItemIndex];
    if (!getMarriedItem) {
      return;
    }
    if (!saveItem) {
      items.push({
        label: "Get married",
        date: getMarriedItem.date,
        amount: 0,
      });
      return;
    }
    items.push({
      label: <strong>Wedding</strong>,
      date: "",
      amount: 0,
    });
    items.push({
      label: (
        <div className="ml-3">
          Save for wedding
          {dataType === DATA_TYPES.GOALS && isShortTermGoal && (
            <EuiBadge className={`${isMobile ? 'ml-0' : 'ml-4'}`} color={colors.backgroundInfo}>SHORT-TERM</EuiBadge>
          )}
          {dataType === DATA_TYPES.GOALS && goalIsMet && (
            <EuiBadge className={`${isMobile ? 'ml-0' : 'ml-4'}`} color={colors.backgroundSuccess}>GOAL MET</EuiBadge>
          )}
        </div>
      ),
      date: saveItem.date,
      amount: lifeEvent.down,
    });
    items.push({
      label: <div className="ml-3">Get married</div>,
      date: getMarriedItem.date,
      amount: 0,
    });
  };

  const populatePurchaseGroup = (
    key: string,
    items: any[],
    skipIndices: Set<number>,
    isShortTermGoal?: boolean,
    goalIsMet?: boolean
  ) => {
    const lifeEvent = planEvents.find((event) => event.eventtype === key);
    const downpaymentGoal = planGoals.find(
      (goal) => goal.lifeevent_id === lifeEvent?.id && goal.goaltype === "down"
    );
    const payoffGoal = planGoals.find(
      (goal) =>
        goal.lifeevent_id === lifeEvent?.id && goal.goaltype === "payoff"
    );
    const downpaymentItemIndex = downpaymentGoal
      ? timeline.findIndex((item: any) => item.goal_id === downpaymentGoal?.id)
      : -1;
    const purchaseItemIndex = timeline.findIndex(
      (item: any) => item.lifeevent_id === lifeEvent?.id
    );
    const payoffItemIndex = payoffGoal
      ? timeline.findIndex((item: any) => item.goal_id === payoffGoal?.id)
      : -1;
    const downpaymentItem = timeline[downpaymentItemIndex];
    const purchaseItem = timeline[purchaseItemIndex];
    const payoffItem = timeline[payoffItemIndex];
    const validItems = [downpaymentItem, purchaseItem, payoffItem].filter(
      (item) => !!item
    );

    skipIndices.add(downpaymentItemIndex);
    skipIndices.add(purchaseItemIndex);
    skipIndices.add(payoffItemIndex);
    let indent = false;
    if (validItems.length > 1) {
      indent = true;
      items.push({
        label: <strong>{GROUP_LABELS[key]}</strong>,
        date: "",
        amount: 0,
      });
    }
    if (downpaymentItem) {
      items.push({
        label: (
          <div className={indent ? "ml-3" : ""}>
            {downpaymentGoal.title}
            {dataType === DATA_TYPES.GOALS && isShortTermGoal && (
              <EuiBadge className={`${isMobile ? 'ml-0' : 'ml-4'}`} color={colors.backgroundInfo}>SHORT-TERM</EuiBadge>
            )}
            {dataType === DATA_TYPES.GOALS && goalIsMet && (
              <EuiBadge className={`${isMobile ? 'ml-0' : 'ml-4'}`} color={colors.backgroundSuccess}>GOAL MET</EuiBadge>
            )}
          </div>
        ),
        date: downpaymentItem.date,
        amount: lifeEvent?.down || 0,
      });
    }
    if (purchaseItem) {
      items.push({
        label: (
          <div className={indent ? "ml-3" : ""}>
            {LIFE_EVENTS[key].typeLabel}
          </div>
        ),
        date: purchaseItem.date,
        amount: 0,
      });
    }
    if (dataType !== DATA_TYPES.ASSETS && payoffItem) {
      items.push({
        label: <div className={indent ? "ml-3" : ""}>{payoffGoal.title}</div>,
        date: payoffItem.date,
        amount: lifeEvent.cost - lifeEvent.down,
      });
    }
  };

  const formattedData = useMemo(() => {
    const items = [];
    const shortTermGoal: any = {};
    const skipIndices = new Set<number>([]);
    let runningCashTotal =
      (assetBalanceTotals.cash_value || 0) - emergencyFundTarget;
    for (let i = 0; i < timeline.length; i++) {
      let isShortTermGoal = false;
      let goalIsMet = false;
      if (skipIndices.has(i)) {
        continue;
      }
      const item = timeline[i];
      if (item.goal_id === 1) {
        let label: React.ReactNode = "Save for emergency fund";
        if (runningCashTotal > 0) {
          label = (
            <div>
              Save for emergency fund
              <EuiBadge className={`${isMobile ? 'ml-0' : 'ml-4'}`} color={colors.backgroundSuccess}>GOAL MET</EuiBadge>
            </div>
          );
        }
        if (dataType === DATA_TYPES.GOALS) {
          items.push({
            label,
            date: item.date,
            amount: emergencyFundTarget,
          });
        } else if (dataType === DATA_TYPES.ASSETS) {
          items.push({
            label: "Save for emergency fund",
            date: item.date,
            amount: emergencyFundTarget,
          });
        }
        continue;
      }
      const goal = planGoals.find((g) => g.id === item.goal_id);
      if (goal) {
        const lifeEvent = planEvents.find(
          (event) => event.id === goal.lifeevent_id
        );
        const badges: React.ReactNode[] = [];
        if (goal.savingsGoal && runningCashTotal > 0) {
          runningCashTotal -= goal.savingsGoal;
          if (runningCashTotal < 0) {
            shortTermGoal.goal = goal;
            shortTermGoal.event = lifeEvent;
            shortTermGoal.account = accounts.find(
              (a) => a.id === goal.accountid
            );
            isShortTermGoal = true;
            badges.push(<EuiBadge className={`${isMobile ? 'ml-0' : 'ml-4'}`} color={colors.backgroundInfo}>SHORT-TERM</EuiBadge>);
          } else {
            goalIsMet = true;
            badges.push(<EuiBadge className={`${isMobile ? 'ml-0' : 'ml-4'}`} color={colors.backgroundSuccess}>GOAL MET</EuiBadge>);
          }
        }
        if (goal.recurring === "annually") {
          badges.push(
            <EuiBadge className={`${isMobile ? 'ml-0' : 'ml-4'}`} iconType="refresh" color={colors.backgroundSubdued}>
              ANNUAL
            </EuiBadge>
          );
        }
        if (goal.goaltype === "down" && dataType !== DATA_TYPES.DEBTS) {
          if (
            ["marriage", "house", "property", "vehicle"].includes(
              lifeEvent?.eventtype
            )
          ) {
            switch (lifeEvent.eventtype) {
              case "marriage":
                populateMarriageGroup(
                  items,
                  skipIndices,
                  isShortTermGoal,
                  goalIsMet
                );
                break;
              case "house":
              case "property":
              case "vehicle":
                populatePurchaseGroup(
                  lifeEvent.eventtype,
                  items,
                  skipIndices,
                  isShortTermGoal,
                  goalIsMet
                );
                break;
              default:
                break;
            }
            continue;
          } else {
            if (
              dataType === DATA_TYPES.ASSETS &&
              lifeEvent?.eventtype === "vacation"
            ) {
              continue;
            }
            items.push({
              label: (
                <div>
                  {goal.title}
                  {dataType === DATA_TYPES.GOALS && <>{badges}</>}
                </div>
              ),
              date: item.date,
              amount: goal.savingsGoal || 0,
            });
          }
        } else {
          if (
            (dataType === DATA_TYPES.ASSETS && goal.goaltype !== "taxbomb") ||
            (dataType === DATA_TYPES.DEBTS &&
              ["down", "taxbomb"].includes(goal.goaltype))
          ) {
            continue;
          }
          items.push({
            label: (
              <div>
                {goal.title}
                {dataType === DATA_TYPES.GOALS && <>{badges}</>}
              </div>
            ),
            date: item.date,
            amount: goal.balance || 0,
          });
        }
      } else {
        const lifeEvent = planEvents.find(
          (event) => event.id === item.lifeevent_id
        );
        if (lifeEvent) {
          if (
            ["house", "property", "vehicle"].includes(lifeEvent?.eventtype) &&
            dataType !== DATA_TYPES.ASSETS
          ) {
            populatePurchaseGroup(
              lifeEvent.eventtype,
              items,
              skipIndices,
              false,
              false
            );
            continue;
          }
          if (
            dataType === DATA_TYPES.DEBTS &&
            !["house", "property", "vehicle"].includes(lifeEvent?.eventtype)
          ) {
            continue;
          }
          let label: React.ReactNode = (
            <div>
              {LIFE_EVENTS[lifeEvent.eventtype]?.typeLabel ||
                lifeEvent.name ||
                ""}
            </div>
          );
          let amount = 0;
          if (lifeEvent.eventtype === "vacation") {
            label = "Go on vacation";
            amount = lifeEvent.down;
            if (lifeEvent.recurring === "annually") {
              label = (
                <div>
                  Go on vacation{" "}
                  <EuiBadge className={`${isMobile ? 'ml-0' : 'ml-4'}`} iconType="refresh" color={colors.backgroundSubdued}>
                    ANNUAL
                  </EuiBadge>
                </div>
              );
            }
          }
          if (
            !(
              dataType !== DATA_TYPES.GOALS &&
              lifeEvent.eventtype === "vacation"
            )
          ) {
            items.push({
              label,
              date: item.date,
              amount,
            });
          }
        } else {
          switch (item.goal) {
            case "fedloanforgiveness":
              if (dataType !== DATA_TYPES.ASSETS) {
                items.push({
                  label: `${
                    item.who === "spouse" ? spouseFirstName : myFirstName
                  }'s Federal student loans are forgiven`,
                  date: item.date,
                  amount: 0,
                });
              }
              break;
            case "child_leaves_nest":
              if (dataType !== DATA_TYPES.DEBTS) {
                items.push({
                  label: "Child leaves nest",
                  date: item.date,
                  amount: 0,
                });
              }
              break;
            default:
              items.push({
                label: JSON.stringify(item),
                date: item.date,
                amount: 0,
              });
          }
        }
      }
    }
    return { items, shortTermGoal };
  }, [timeline, planGoals, planEvents, dataType]);

  return formattedData;
};

export default useGoalSummaryData;
