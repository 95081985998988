import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";

import { headerStyles } from "src/theme";

import DashboardLayout from "src/layouts/DashboardLayout";
import HomeNoPlan from "./HomeNoPlan";
import HomePlan from "./HomePlan";
import { getHasPlan } from "src/store/system/selector";

const HomeContainer = () => {
  const classes = useStyles();
  const hasPlan = useSelector(getHasPlan);

  return (
    <DashboardLayout pageTitle="Plans">
      {hasPlan ? <HomePlan /> : <HomeNoPlan />}
    </DashboardLayout>
  );
};

const useStyles = makeStyles(headerStyles);

export default HomeContainer;
