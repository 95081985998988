import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Redirect } from "react-router";
import { Switch } from "react-router-dom";

import LogOut from "src/pages/Authentication/LogOut";
import Popup from "src/components/Popup";
import ProfileBuilderLayout from "src/layouts/ProfileBuilderLayout";

import Solutions from "src/pages/Dashboard/deprecated/Solutions";
import BuildPlan from "src/pages/Dashboard/Plan/BuildPlan";
import ComparePlans from "src/pages/Dashboard/Plan/ComparePlans";
import PlanList from "src/pages/Dashboard/Plan/PlanList";
import PlanSummary from "src/pages/Dashboard/Plan/PlanSummary";
import Wealth from "src/pages/Dashboard/MoneyDetail/NetWealth";
import Budget from "src/pages/Dashboard/MoneyDetail/DayToDay";
import RiskManagement from "src/pages/Dashboard/MoneyDetail/RiskManagement";
import Settings from "src/pages/Dashboard/Settings";
import ScoreFactors from "src/pages/Dashboard/ScoreFactors";
import Accounts from "src/pages/Dashboard/MyProfile/Accounts";
import Transactions from "src/pages/Dashboard/MyProfile/Transactions";
import HomeAffordability from "src/pages/Dashboard/ToolsAndProducts/HomeBuying/HomeAffordability";
import Tools from "src/pages/Dashboard/Tools";
import Coach from "src/pages/Dashboard/Coach";
import Investments from "src/pages/Dashboard/ToolsAndProducts/Investments";
import StudentLoans from "src/pages/Dashboard/ToolsAndProducts/StudentLoans";
import CreditPersonalLoans from "src/pages/Dashboard/ToolsAndProducts/CreditPersonalLoans";
import AutoLoanRefinance from "src/pages/Dashboard/ToolsAndProducts/CreditPersonalLoans/AutoLoanRefinance";
import CreditCardConsolidation from "src/pages/Dashboard/ToolsAndProducts/CreditPersonalLoans/CreditCardConsolidation";
import OtherFinancialItems from "src/pages/Dashboard/ToolsAndProducts/OtherFinancialItems";
import HomeBuying from "src/pages/Dashboard/ToolsAndProducts/HomeBuying";
import Insurance from "src/pages/Dashboard/ToolsAndProducts/Insurance";
import Referral from "src/pages/Referral";
import CdReturns from "src/pages/Dashboard/ToolsAndProducts/Investments/CdReturns";
import InvestmentProjections from "src/pages/Dashboard/ToolsAndProducts/Investments/InvestmentProjections";
import TaxBomb from "src/pages/Dashboard/ToolsAndProducts/StudentLoans/TaxBomb";
import PaymentsApplied from "src/pages/Dashboard/ToolsAndProducts/StudentLoans/PaymentsApplied";
import PayMyLoans from "src/pages/Dashboard/ToolsAndProducts/StudentLoans/PayMyLoans";
import MortgageRates from "src/pages/Dashboard/ToolsAndProducts/HomeBuying/MortgageRates";
import HelocRates from "src/pages/Dashboard/ToolsAndProducts/HomeBuying/HelocRates";
import RefinanceRates from "src/pages/Dashboard/ToolsAndProducts/HomeBuying/RefinanceRates";
import HelocRepayment from "src/pages/Dashboard/ToolsAndProducts/HomeBuying/HelocRepayment";
import LifeInsuranceRecommendation from "src/pages/Dashboard/ToolsAndProducts/Insurance/LifeInsuranceRecommendation";
import DisabilityInsuranceRecommendation from "src/pages/Dashboard/ToolsAndProducts/Insurance/DisabilityInsuranceRecommendation";

import CreateBettermentAccount from "src/pages/Investments/BettermentAccount/CreateAccount";
import EditBettermentAccount from "src/pages/Investments/BettermentAccount/EditAccount";
import InvestmentAllocations from "src/pages/Investments/AccountAllocations";
import HowToInvest from "src/pages/Investments/HowToInvest";
import HowToAdjust from "src/pages/Investments/HowToAdjust";
// import PlanComparisonTool from "src/pages/PlanComparisonTool";
import PlanBuilder from "src/pages/PlanBuilder";
import ProfileBuilder from "src/pages/ProfileBuilder";
import ProfileBuilderContinue from "src/pages/ProfileBuilder/Continue";

import PointsCalculator from "src/pages/PointsCalculator";
import MortgagePayment from "src/pages/MortgagePayment";
import PropertyTaxEstimate from "src/pages/PropertyTaxEstimate";
import CostOfLivingTool from "src/pages/CostOfLiving";
import EmergencyFundAmount from "src/pages/EmergencyFundAmount";
import RentVsBuy from "src/pages/RentVsBuy";

import { getScore } from "src/store/dashboard/actions";
import { getRefreshNeeded } from "src/store/dashboard/selector";
import {
  getProfileLoaded,
  getProfileProgress,
} from "src/store/profileBuild/selector";
import PlanSelect from "src/components/PlanTypeSelect";
import OptimizedPlan from "src/pages/OptimizedPlan";
import HomePlan from "src/pages/Dashboard/Home/HomeSummary";

const PrivateRouter = () => {
  const dispatch = useDispatch();
  const refreshNeeded = useSelector(getRefreshNeeded);
  useEffect(() => {
    if (refreshNeeded.score) {
      dispatch(getScore());
    }
  }, [refreshNeeded.score]);
  const profileLoaded = useSelector(getProfileLoaded);
  const profileProgress = useSelector(getProfileProgress);
  return (
    <Switch>
      <Route path="/profile-builder" exact>
        <ProfileBuilder />
      </Route>
      <Route path="/profile-builder-continue" exact>
        <ProfileBuilderLayout>
          <ProfileBuilderContinue />
        </ProfileBuilderLayout>
      </Route>
      <Route path="/investment-allocations" exact>
        <InvestmentAllocations />
      </Route>
      <Route path="/how-to-invest" exact>
        <HowToInvest />
      </Route>
      <Route path="/how-to-adjust" exact>
        <HowToAdjust />
      </Route>
      <Route path="/tax-bomb" exact>
        <TaxBomb />
      </Route>
      <Route path="/pay-my-loans" exact>
        <PayMyLoans />
      </Route>
      <Route path="/spinwheel-payments" exact>
        <PaymentsApplied />
      </Route>
      <Route path="/auto-refi" exact>
        <AutoLoanRefinance />
      </Route>
      <Route path="/betterment/create" exact>
        <CreateBettermentAccount />
      </Route>
      <Route path="/betterment/edit" exact>
        <EditBettermentAccount />
      </Route>
      <Route exact path="/logout">
        <Popup />
        <LogOut />
      </Route>
      {profileLoaded.progress && profileProgress < 8 && profileProgress > 0 && (
        <Redirect to="/profile-builder-continue" />
      )}
      {profileLoaded.progress && profileProgress < 8 && (
        <Redirect to="/profile-builder" />
      )}
      <Route path="/cost-of-living" exact>
        <CostOfLivingTool />
      </Route>
      <Route path="/emergency-fund-amount">
        <EmergencyFundAmount />
      </Route>
      <Route path="/build-plan" exact>
        <BuildPlan />
      </Route>
      <Route path="/compare-plans" exact>
        <ComparePlans />
      </Route>
      <Route path="/home" exact>
        <HomePlan />
      </Route>
      <Route path="/plan-summary" exact>
        <PlanSummary />
      </Route>
      <Route path="/plan-list" exact>
        <PlanList />
      </Route>
      <Route path="/property-tax-estimate" exact>
        <PropertyTaxEstimate />
      </Route>
      <Route path="/wealth" exact>
        <Wealth />
      </Route>
      <Route path="/accounts" exact>
        <Accounts />
      </Route>
      <Route path="/budget" exact>
        <Budget />
      </Route>
      <Route path="/settings" exact>
        <Settings />
      </Route>
      <Route path="/rent-vs-buy" exact>
        <RentVsBuy />
      </Route>
      <Route path="/risk" exact>
        <RiskManagement />
      </Route>
      <Route path="/scorefactors" exact>
        <ScoreFactors />
      </Route>
      <Route path="/transactions" exact>
        <Transactions />
      </Route>
      <Route path="/studentloans" exact>
        <StudentLoans />
      </Route>
      <Route path="/investments" exact>
        <Investments />
      </Route>
      <Route path="/cd-returns" exact>
        <CdReturns />
      </Route>
      <Route path="/investment-projections" exact>
        <InvestmentProjections />
      </Route>
      <Route path="/studentloans" exact>
        <StudentLoans />
      </Route>
      <Route path="/homebuying" exact>
        <HomeBuying />
      </Route>
      <Route path="/insurance" exact>
        <Insurance />
      </Route>
      <Route path="/credit" exact>
        <CreditPersonalLoans />
      </Route>
      <Route path="/otheritems" exact>
        <OtherFinancialItems />
      </Route>
      <Route path="/homeaffordability" exact>
        <HomeAffordability />
      </Route>
      <Route path="/mortgage-payment" exact>
        <MortgagePayment />
      </Route>
      <Route path="/mortgage-rates" exact>
        <MortgageRates />
      </Route>
      <Route path="/heloc-rates" exact>
        <HelocRates />
      </Route>
      <Route path="/refi-rates" exact>
        <RefinanceRates />
      </Route>
      <Route path="/heloc-payment" exact>
        <HelocRepayment />
      </Route>
      <Route path="/life-insurance" exact>
        <LifeInsuranceRecommendation />
      </Route>
      <Route path="/disability-insurance" exact>
        <DisabilityInsuranceRecommendation />
      </Route>
      <Route path="/credit-card-consolidation" exact>
        <CreditCardConsolidation />
      </Route>
      <Route path="/coach" exact>
        <Coach />
      </Route>
      <Route path="/plan-select" exact>
        <PlanSelect />
      </Route>
      <Route path="/plan-builder" exact>
        <PlanBuilder />
      </Route>
      <Route path="/plan-builder-optimized" exact>
        <OptimizedPlan />
      </Route>
      <Route path="/solutions" exact>
        <Solutions />
      </Route>
      {/*<Route path="/plan-comparison" exact>
        <PlanBuilderLayout
          fixedStep={1}
          steps={["Build Plan", "Review & Compare", "Implement"]}
          pageTitle="Simulate &amp; Review"
        >
          <PlanComparisonTool />
        </PlanBuilderLayout>
      </Route>*/}
      <Route path="/points-calculator" exact>
        <PointsCalculator />
      </Route>
      <Route path="/tools">
        <Tools />
      </Route>
      <Route path="/referral" exact>
        <Referral />
      </Route>
      <Route component={() => <Redirect to="/home" />} />
    </Switch>
  );
};

export default PrivateRouter;
