import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import {
  EuiText,
  EuiTimeline,
  EuiTimelineItem,
  EuiIcon,
  EuiBadge,
} from "@elastic/eui";
import styled from "@emotion/styled";
import { StyledSpacer } from "src/components/Global/StyledComponents";
import { STEP_INDICES } from "./common";
import { getSelectedPlan } from "src/store/planBuild/selector";

const ProgressBarFilled = styled.div<{ height: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #0073e6;
  height: ${(props) => props.height};
  transition: height 0.3s ease;
  z-index: 2;
`;

interface Step {
  label: string;
  target: number;
  relevantSteps?: number[];
}

interface Section {
  label: string;
  steps: Step[];
}

export const sections: Section[] = [
  {
    label: "Plan Setup",
    steps: [
      {
        label: "Employer Retirement & Student Loan Plans",
        target: STEP_INDICES.EmployerRetirement,
        relevantSteps: [STEP_INDICES.HSA, STEP_INDICES.EmployerStudentLoans],
      },
      {
        label: "Work Status & Income",
        target: STEP_INDICES.WorkStatus,
        relevantSteps: [
          STEP_INDICES.IncomeIncrease,
          STEP_INDICES.IncomeDecrease,
        ],
      },
      {
        label: "Marriage",
        target: STEP_INDICES.Marriage,
      },
      {
        label: "Children",
        target: STEP_INDICES.Children,
      },
      {
        label: "Invest or Pay Off Debt",
        target: STEP_INDICES.InvestOrPayoff,
      },
      {
        label: "Vacation",
        target: STEP_INDICES.Vacation,
      },
      {
        label: "Purchase a Vehicle",
        target: STEP_INDICES.AutoLoan,
      },
      {
        label: "Moving",
        target: STEP_INDICES.Moving,
      },
      {
        label: "Recap",
        target: STEP_INDICES.Recap,
      },
    ],
  },
  {
    label: "Overview",
    steps: [],
  },
  {
    label: "Implement",
    steps: [],
  },
];

interface LeftSidebarProps {
  stepIndex: number;
  handleClick: (index: number) => void;
}

const LeftSidebar = ({ stepIndex, handleClick }: LeftSidebarProps) => {
  const [progressHeight, setProgressHeight] = useState("0px");
  const timelineContainerRef = useRef<HTMLDivElement>(null);
  const selectedPlan = useSelector(getSelectedPlan);

  // Add state to track visited steps
  const [visitedSteps, setVisitedSteps] = useState(() => {
    const initialVisited = new Set<number>();
    for (let i = 0; i <= stepIndex; i++) {
      initialVisited.add(i);
    }
    return initialVisited;
  });

  useEffect(() => {
    if (timelineContainerRef.current) {
      const steps = timelineContainerRef.current.querySelectorAll(".timeline-step");
      const stepElements = Array.from(steps);

      // Get all relevant steps indices for the current step index
      const allRelevantSteps = new Set<number>();
      const addRelevantSteps = (index: number) => {
        const currentStep = sections
          .flatMap((section) => section.steps)
          .find((step) => step.target === index);
        if (currentStep && currentStep.relevantSteps) {
          currentStep.relevantSteps.forEach((relevantStep) => {
            allRelevantSteps.add(relevantStep);
          });
        }
      };

      // Add relevant steps for all steps up to and including the current step index
      for (let i = 0; i <= stepIndex; i++) {
        addRelevantSteps(i);
      }

      // Calculate the maximum height for the progress bar
      let maxHeight = 0;
      stepElements.forEach((el) => {
        const targetIndex = parseInt(el.getAttribute("data-target") || "0", 10);
        if (targetIndex <= stepIndex || allRelevantSteps.has(targetIndex)) {
          const stepRect = el.getBoundingClientRect();
          const timelineRect = timelineContainerRef.current?.getBoundingClientRect();
          const heightToStep = stepRect.top + stepRect.height / 2 - (timelineRect?.top || 0);
          if (heightToStep > maxHeight) {
            maxHeight = heightToStep;
          }
        }
      });

      setProgressHeight(`${maxHeight}px`);
    }

    // Update visited steps when stepIndex changes
    setVisitedSteps((prev) => {
      const updatedVisitedSteps = new Set(prev);
      if (prev.has(stepIndex + 1)) {
        updatedVisitedSteps.delete(stepIndex + 1);
      }
      for (let i = 1; i <= stepIndex; i++) {
        updatedVisitedSteps.add(i);
        // Add relevant steps to visited steps
        const currentStep = sections
          .flatMap((section) => section.steps)
          .find((step) => step.target === i);
        if (currentStep && currentStep.relevantSteps) {
          currentStep.relevantSteps.forEach((relevantStep) => {
            updatedVisitedSteps.add(relevantStep);
          });
        }
      }
      return updatedVisitedSteps;
    });
  }, [stepIndex]);

  return (
    <div className="side-bar-wrapper">
      <EuiText className="side-bar-header">
        <h2>{selectedPlan ? selectedPlan.name : "AI Financial Plan"}</h2>
        <EuiBadge>
          {selectedPlan && selectedPlan.implemented
            ? "Implemented"
            : "Not Implemented"}
        </EuiBadge>
      </EuiText>
      <StyledSpacer size="32px" />
      <div style={{ position: "relative" }}>
        <div className="progress-bar-container">
          <ProgressBarFilled height={progressHeight} />
        </div>
        <div ref={timelineContainerRef}>
          <EuiTimeline className="timeline">
            {sections.map((section, sectionIndex) => {
              const isSectionActive = section.steps.some(
                (step) => step.target <= stepIndex
              );
              return (
                <React.Fragment key={section.label}>
                  <EuiTimelineItem
                    icon={
                      <div
                        className="step-number"
                        style={{
                          background:
                            sectionIndex === 0 ? "#0077CC" : "#98A2B3",
                        }}
                      >
                        {sectionIndex + 1}
                      </div>
                    }
                    verticalAlign="center"
                    className="section-item"
                  >
                    <EuiText
                      style={{ color: sectionIndex < 1 ? "#343741" : undefined }}
                      className={isSectionActive ? "active-section-label" : ""}
                    >
                      <b>{section.label}</b>
                    </EuiText>
                  </EuiTimelineItem>
                  {section.steps.map((step) => {
                    const isActive = step.target === stepIndex || (step.relevantSteps && step.relevantSteps.includes(stepIndex));
                    const isVisited = visitedSteps.has(step.target);
                    return (
                      <EuiTimelineItem
                        key={step.label}
                        className={`timeline-step ${
                          isVisited ? "visited-step" : ""
                        }`}
                        icon={
                          <EuiIcon
                            type="dot"
                            color={isActive || isVisited ? "#0073e6" : "#98A2B3"}
                            className={isVisited ? "visited-step-icon" : ""}
                          />
                        }
                        iconAriaLabel={`Step ${step.target}`}
                        data-target={step.target}
                        onClick={() => isVisited && handleClick(step.target)}
                      >
                        <EuiText
                          className={isActive ? "visited-step" : ""}
                          style={{
                            color: isActive || isVisited ? "#343741" : "#69707d",
                            fontWeight: isActive ? "bold" : "normal",
                          }}
                        >
                          {step.label}
                        </EuiText>
                      </EuiTimelineItem>
                    );
                  })}
                </React.Fragment>
              );
            })}
          </EuiTimeline>
        </div>
      </div>
    </div>
  );
};

export default LeftSidebar;