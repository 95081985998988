import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  EuiButtonEmpty,
  EuiFormControlLayout,
  EuiFormRow,
  EuiRadioGroup,
  EuiSuperSelect,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButtonIcon,
} from "@elastic/eui";

import { MONTHS, YEARS_FROM_NOW } from "src/constants";
import { YES_NO_OPTIONS } from "src/constants/formOptions";
import {
  StyledDualSelect,
  StyledSpacer,
  StyledEuiButton,
  StyledEuiHorizontalRule,
} from "src/components/Global/StyledComponents";
import { QuestionFormProps } from "src/interfaces/optimizedPlanBuild.interface";
import { getIsMarried, spouseSelector } from "src/store/system/selector";
import { DollarTextField } from "src/utils";
import { useFlyout } from "src/components/Global/FlyoutContext";

const Children = ({
  planSetter,
  planData,
  nextClicked,
  goToNext,
  errors,
  setErrors,
}: QuestionFormProps) => {
  const isMarried = useSelector(getIsMarried);
  const spouse = useSelector(spouseSelector);
  const [nextClick] = useState(nextClicked);
  const { toggleFlyout } = useFlyout();

  const startingValues = {
    children_dates: [...planData.children_dates],
    child_income_drop: [...planData.child_income_drop],
    s_child_income_drop: [...planData.s_child_income_drop],
  };
  const [expected, setExpected] = useState(!!planData.children_dates.length);
  const [values, setValues] = useState<any>(startingValues);
  const [hasIncomeDrop, setHasIncomeDrop] = useState(
    planData.children_dates.map(
      (date, index) =>
        planData.child_income_drop[index] !== null ||
        planData.s_child_income_drop[index] !== null
    )
  );
  const [userOrSpouseSelected, setUserOrSpouseSelected] = useState(
    planData.children_dates.map((date, index) =>
      planData.s_child_income_drop[index] !== null ? "s" : "u"
    )
  );
  const currentDate = new Date();
  const spouseFirstName = spouse?.first_name;

  const handleDateChange = (value: string, index: number) => {
    const newDates = values.children_dates.slice();
    newDates[index] = value;
    planSetter({ ...planData, children_dates: newDates });
    setValues((current: any) => ({ ...current, children_dates: newDates }));
    setErrors((current) => {
      const newErrors = new Set(current);
      newErrors.delete(`children_dates_${index}`);
      return newErrors;
    });
  };

  const validate = (checkValues: any) => {
    let isValid = true;
    const newErrors = new Set(errors);
    for (let i = 0; i < checkValues.children_dates.length; i++) {
      if (!checkValues.children_dates[i]) {
        newErrors.add(`children_dates_${i}`);
        isValid = false;
      }
      if (hasIncomeDrop[i]) {
        if (isMarried) {
          if (!userOrSpouseSelected[i]) {
            newErrors.add(`user_or_spouse_${i}`);
            isValid = false;
          } else {
            newErrors.delete(`user_or_spouse_${i}`);
          }
        } else {
          const key =
            !isMarried || userOrSpouseSelected[i] === "u"
              ? "child_income_drop"
              : "s_child_income_drop";
          if (!checkValues[key][i]) {
            newErrors.add(`${key}_${i}`);
            isValid = false;
          } else {
            newErrors.delete(`${key}_${i}`);
          }
        }
      } else {
        newErrors.delete("user_or_spouse_${i}");
        newErrors.delete("child_income_drop_${i}");
        newErrors.delete("s_child_income_drop_${i}");
      }
    }
    setErrors(() => newErrors);
    return isValid;
  };

  useEffect(() => {
    if (nextClick !== nextClicked && validate(values)) {
      goToNext();
    }
  }, [nextClicked]);

  const deleteLastValue = () => {
    const newValues = {
      children_dates: values.children_dates.slice(0, -1),
      child_income_drop: values.child_income_drop.slice(0, -1),
      s_child_income_drop: values.s_child_income_drop.slice(0, -1),
    };
    setHasIncomeDrop((current: any) => current.slice(0, -1));
    setUserOrSpouseSelected((current: any) => current.slice(0, -1));
    setValues(newValues);
    validate(newValues);
    planSetter({ ...planData, ...newValues });
  };

  const addValue = () => {
    const newValues = {
      children_dates: [...values.children_dates, ""],
      child_income_drop: [...values.child_income_drop, null],
      s_child_income_drop: [...values.s_child_income_drop, null],
    };
    setValues(newValues);
    setHasIncomeDrop((current: any) => [...current, "n"]);
    setUserOrSpouseSelected((current: any) => [...current, undefined]);
    planSetter({ ...planData, ...newValues });
  };

  const youOrYourSpouse = isMarried ? "you our your spouse" : "you";

  return (
    <>
      <EuiFlexGroup className="ai-flex-content">
        <EuiFlexItem>
          <div className="ai-content-title">
            <EuiFlexGroup style={{ gap: 0 }}>
              <EuiFlexItem grow={false}>
                <h1>Children</h1>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButtonIcon
                  onClick={() => toggleFlyout("children-ai")}
                  iconType="questionInCircle"
                  aria-label="Help"
                  className="help-icon"
                />
              </EuiFlexItem>
            </EuiFlexGroup>
          </div>
        </EuiFlexItem>
      </EuiFlexGroup>
      <StyledSpacer size="32px" />
      <EuiFormRow
        label="Are you planning on adding children to your family in the future?"
        className="full-width-ai"
      >
        <EuiRadioGroup
          idSelected={expected ? "y" : "n"}
          name="expected"
          options={YES_NO_OPTIONS}
          onChange={(id: any) => {
            setExpected(id === "y");
            if (id === "y") {
              const month = currentDate.getMonth() + 1;
              const monthString = month < 10 ? "0" + month : "" + month;
              const dateString = `${currentDate.getFullYear()}-${monthString}`;
              const updatedValues = {
                children_dates: [dateString],
                child_income_drop: [null],
                s_child_income_drop: [null],
              };
              setValues(updatedValues);
              setHasIncomeDrop([false]);
              setUserOrSpouseSelected(["u"]);
              planSetter({ ...planData, ...updatedValues });
            } else {
              const updatedValues = {
                children_dates: [],
                child_income_drop: [],
                s_child_income_drop: [],
              };
              setErrors(new Set([]));
              setValues(updatedValues);
              setHasIncomeDrop([]);
              setUserOrSpouseSelected([]);
              planSetter({ ...planData, ...updatedValues });
            }
          }}
        />
      </EuiFormRow>
      <StyledSpacer size="32px" />
      <>
        {values.children_dates.map((value: string, index: number) => {
          const yearString = value.slice(0, 4);
          let monthString = value.slice(5);
          if (monthString[0] === "0") {
            monthString = monthString.slice(1);
          }
          return (
            <div key={index}>
              <EuiFormRow
                label="When do you plan on having a child?"
                isInvalid={errors.has(`children_dates_${index}`)}
                error="This field is required."
              >
                <StyledDualSelect>
                  <EuiFormControlLayout>
                    <EuiSuperSelect
                      options={MONTHS.map((month, index) => ({
                        value: (index + 1).toString(),
                        inputDisplay: month,
                      }))}
                      valueOfSelected={monthString}
                      onChange={(value) => {
                        const newMonthString =
                          value.length < 2 ? "0" + value : value;
                        const newDate = `${yearString}-${newMonthString}`;
                        handleDateChange(newDate, index);
                      }}
                    />
                  </EuiFormControlLayout>
                  <EuiFormControlLayout>
                    <EuiSuperSelect
                      options={YEARS_FROM_NOW.slice(0, 9).map((year) => ({
                        value: year.toString(),
                        inputDisplay: year.toString(),
                      }))}
                      valueOfSelected={yearString}
                      onChange={(value) => {
                        const newDate = `${value}-${monthString}`;
                        handleDateChange(newDate, index);
                      }}
                    />
                  </EuiFormControlLayout>
                </StyledDualSelect>
              </EuiFormRow>
              <StyledSpacer size="32px" />
              <EuiFormRow
                label={`Do ${youOrYourSpouse} plan on reducing your work hours?`}
              >
                <EuiRadioGroup
                  idSelected={hasIncomeDrop[index] ? "y" : "n"}
                  name={`reduce_${index}`}
                  options={YES_NO_OPTIONS}
                  onChange={(id: string) => {
                    setHasIncomeDrop((current: any) => {
                      const newValues = [...current];
                      newValues[index] = id;
                      return newValues;
                    });
                    if (id === "n") {
                      setValues((current: any) => {
                        const newUserValues = [...current.child_income_drop];
                        const newSpouseValues = [
                          ...current.s_child_income_drop,
                        ];
                        newUserValues[index] = null;
                        newSpouseValues[index] = null;
                        const newValues = {
                          ...current,
                          child_income_drop: newUserValues,
                          s_child_income_drop: newSpouseValues,
                        };
                        planSetter({ ...planData, ...newValues });
                        return newValues;
                      });
                    }
                  }}
                />
              </EuiFormRow>
              <StyledSpacer size="32px" />
              {isMarried && (
                <EuiFormRow
                  label="Who is going to reduce their work hours?"
                  isInvalid={errors.has(`user_or_spouse_${index}`)}
                  error="This field is required."
                >
                  <EuiRadioGroup
                    idSelected={userOrSpouseSelected[index]}
                    name={`userOrSpouse_${index}`}
                    options={[
                      { id: "u", label: "Me" },
                      { id: "s", label: spouseFirstName },
                    ]}
                    onChange={(id: string) => {
                      setUserOrSpouseSelected((current: any) => {
                        const newValues = [...current];
                        newValues[index] = id;
                        return newValues;
                      });
                      setErrors((current) => {
                        const newErrors = new Set(current);
                        newErrors.delete(`user_or_spouse_${index}`);
                        return newErrors;
                      });
                      setValues((current: any) => {
                        const newUserValues = [...current.child_income_drop];
                        const newSpouseValues = [
                          ...current.s_child_income_drop,
                        ];
                        newUserValues[index] =
                          id === "u"
                            ? current.s_child_income_drop[index]
                            : null;
                        newSpouseValues[index] =
                          id === "s" ? current.child_income_drop[index] : null;
                        const newValues = {
                          ...current,
                          child_income_drop: newUserValues,
                          s_child_income_drop: newSpouseValues,
                        };
                        planSetter({ ...planData, ...newValues });
                        return newValues;
                      });
                    }}
                  />
                </EuiFormRow>
              )}
              <EuiFormRow
                label={`What will be ${
                  userOrSpouseSelected[index] === "u"
                    ? "your"
                    : userOrSpouseSelected[index] === "s"
                    ? spouseFirstName + "'s"
                    : "your"
                } new total annual income?`}
                isInvalid={errors.has(
                  userOrSpouseSelected[index] === "s"
                    ? `s_child_income_drop_${index}`
                    : `child_income_drop_${index}`
                )}
                error="This field is required."
              >
                <DollarTextField
                  eui
                  name={`dollars_${index}`}
                  value={
                    (userOrSpouseSelected[index] === "u"
                      ? values.child_income_drop[index]
                      : values.s_child_income_drop[index]) || undefined
                  }
                  placeholder="0"
                  onChange={(e: any) => {
                    const value = e.target.value;
                    setErrors((current) => {
                      const newErrors = new Set(current);
                      newErrors.delete(
                        userOrSpouseSelected[index] === "s"
                          ? `s_child_income_drop_${index}`
                          : `child_income_drop_${index}`
                      );
                      return newErrors;
                    });
                    setValues((current: any) => {
                      const newValues = { ...current };
                      if (userOrSpouseSelected[index] === "u") {
                        const newUserValues = [...current.child_income_drop];
                        newUserValues[index] = value;
                        newValues.child_income_drop = newUserValues;
                      } else {
                        const newSpouseValues = [
                          ...current.s_child_income_drop,
                        ];
                        newSpouseValues[index] = value;
                        newValues.s_child_income_drop = newSpouseValues;
                      }
                      planSetter({ ...planData, ...newValues });
                      return newValues;
                    });
                  }}
                />
              </EuiFormRow>
              {index === values.children_dates.length - 1 && index > 0 && (
                <div>
                  <EuiButtonEmpty color="primary" onClick={deleteLastValue}>
                    Delete
                  </EuiButtonEmpty>
                </div>
              )}
              <StyledSpacer size="24px" />
              <StyledEuiHorizontalRule maxWidth="428px" style={{ margin: 0 }} />
              <StyledSpacer size="24px" />
            </div>
          );
        })}
        {expected && (
          <StyledEuiButton color="text" iconType="plus" onClick={addValue}>
            Add Child
          </StyledEuiButton>
        )}
      </>
    </>
  );
};

export default Children;
