import React, { useState } from "react";

import DashboardHeader from "../components/Headers/DashboardHeader";
import Navigator from "../components/Navigator";
import { EuiProvider, EuiPageBody, EuiCallOut, EuiLink, EuiIcon } from "@elastic/eui";
import "src/Dashboard.css";
import { FlyoutProvider } from "src/components/Global/FlyoutContext";
import Flyout from "src/components/Global/Flyout";
import { AppConfig } from "../config";


const DashboardLayout = (props: any) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [navIsOpen, setNavIsOpen] = useState(false);
  const toggleMobileOpen = () => setMobileOpen((current) => !current);
  const closeMobileOpen = () => setMobileOpen(false);
  const toggleCollapsed = () => setCollapsed((current) => !current);
  const { children, pageTitle } = props;

  return (
    <EuiProvider>
      <FlyoutProvider>
        <Flyout />
        <EuiPageBody className="dashboard">
          <Navigator
            onCollapse={toggleCollapsed}
            closeMobileOpen={closeMobileOpen}
            variant="permanent"
            title={AppConfig.title}
            navIsOpen={navIsOpen}
            setNavIsOpen={setNavIsOpen}
          />
          <div className="main-container">
            <section className="nav-header">
              <DashboardHeader
                className="main-nav"
                navIsOpen={navIsOpen}
                setNavIsOpen={setNavIsOpen}
              />
            </section>
            <EuiCallOut
              className="nav-callout"
              iconType="cheer"
              title={
                <p nav-callout>Register for our next webinar: <EuiLink href="#">click here</EuiLink>
                </p>
              }
            >
              <EuiIcon type="cross" />
            </EuiCallOut>
            <section className="main-content">
              {children}
            </section>
          </div>
        </EuiPageBody>
      </FlyoutProvider>
    </EuiProvider>
  );
};

export default DashboardLayout;
