import React from "react";
import { useSelector } from "react-redux";
import { sortBy } from "lodash";
import {
  EuiForm,
  EuiFormRow,
  EuiComboBox,
  EuiFieldText,
  EuiRadioGroup,
  EuiText,
  EuiSuperSelect,
} from "@elastic/eui";

import {
  Account,
  ACCOUNT_TYPES,
  STUDENT_TOP_LEVEL_CATEGORIES,
  TOP_LEVEL_CATEGORIES,
  TRANSACTION_ACCOUNT_TYPES,
  TRANSACTION_CATEGORIES,
  TYPE_LIST_ORDER,
} from "src/interfaces";
import { getAccounts } from "src/store/account/selector";
import { getIsCurrentStudent } from "src/store/system/selector";
import { formatDollarsAndCents } from "src/utils";
import { DollarTextField } from "src/utils";
import { Global, css } from "@emotion/react";
import { StyledSpacer } from "src/components/Global/StyledComponents";

const transactionDetailStyles = css`
  .static-text {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
  }
  .secondary-text {
    font-weight: 400;
  }
`;

const TransactionDetails = ({ formValues, handleChange, transaction }: any) => {
  const isCurrentStudent = useSelector(getIsCurrentStudent);
  const accounts: Account[] = useSelector(getAccounts);
  const topLevelCategories = isCurrentStudent
    ? STUDENT_TOP_LEVEL_CATEGORIES
    : TOP_LEVEL_CATEGORIES;

  const selectedAccount = accounts.find(
    (account) => account.id === transaction.account
  );
  const availableTypes: any = {};
  const renderTypes: any[] = [];
  const availableCategories: any[] = topLevelCategories.filter(
    (category, index) => {
      if (!selectedAccount) {
        return false;
      }
      let allowed = false;
      const subcategories = TRANSACTION_CATEGORIES.slice(
        category.start,
        category.end
      );
      subcategories.forEach((subcategory) => {
        let subcategoryItems: [string, string][] = [];
        Object.entries(subcategory.types).forEach(
          ([typeId, label]: [string, string | undefined]) => {
            const validAccountTypes =
              TRANSACTION_ACCOUNT_TYPES[
              typeId as keyof typeof TRANSACTION_ACCOUNT_TYPES
              ];
            const accountType =
              selectedAccount?.variable || selectedAccount?.type || "";
            if (
              validAccountTypes &&
              validAccountTypes.indexOf(accountType) >= 0
            ) {
              allowed = true;
              if (
                formValues.category === index &&
                typeId !== "6001" &&
                typeId !== "6002"
              ) {
                availableTypes[typeId] = label || "Type";
                subcategoryItems.push([typeId, label || "Type"]);
              }
            }
          }
        );
        if (subcategoryItems.length) {
          subcategoryItems = sortBy(
            subcategoryItems,
            (item: [string, string]) =>
              TYPE_LIST_ORDER[item[0] as keyof typeof TYPE_LIST_ORDER]
          );
          const renderSubcategoryItems = subcategoryItems.map(
            ([typeId, label]: [string, string]) => ({
              label: label,
              value: typeId,
            })
          );
          renderTypes.push({
            label: subcategory.label,
            options: renderSubcategoryItems,
          });
        }
      });
      return allowed;
    }
  );

  let accountName = selectedAccount?.name || "";
  if (!accountName) {
    const accountType =
      selectedAccount?.variable || selectedAccount?.type || "";
    if (accountType) {
      accountName += ` ${ACCOUNT_TYPES[accountType]}`;
    }
  }

  let displayType = "";
  if (!formValues.type) {
    displayType = transaction.type;
  } else if (availableTypes?.[formValues.type]) {
    displayType = formValues.type;
  }
  if (!availableTypes[displayType]) {
    displayType = "";
  }

  const radioOptions = [
    {
      id: 'y',
      label: 'Yes',
    },
    {
      id: 'n',
      label: 'No',
    },
  ];

  return (
    <>
      <Global styles={transactionDetailStyles} />
      <EuiForm>
        <EuiText>
          <p className="static-text">
            Date: {new Date(transaction.date).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}
          </p>
        </EuiText>

        <StyledSpacer size="16px" />

        <EuiFormRow label="Category">
          <EuiSuperSelect
            options={topLevelCategories.map((item, index) => ({
              value: index.toString(),
              inputDisplay: item.label,
              dropdownDisplay: item.label,
            }))}
            valueOfSelected={formValues.category === undefined ? "" : formValues.category.toString()}
            onChange={(value) => handleChange({ target: { name: "category", value: parseInt(value) } })}
          />
        </EuiFormRow>

        <StyledSpacer size="32px" />

        <EuiFormRow label="Type">
          <EuiComboBox
            isClearable={false}
            singleSelection={{ asPlainText: true }}
            options={renderTypes}
            selectedOptions={
              displayType ? [{ label: availableTypes[displayType], value: displayType }] : []
            }
            onChange={(selected) => {
              const value = selected.length ? selected[0].value : "";
              handleChange({ target: { name: "type", value } });
            }}
            isDisabled={formValues.category === undefined}
          />
        </EuiFormRow>

        <StyledSpacer size="32px" />

        <EuiText>
          <p className="static-text">Account:<br /> <span className="secondary-text">{accountName}</span></p>
        </EuiText>

        <StyledSpacer size="32px" />

        {transaction.manual === "manual" ? (
          <EuiFormRow label="Amount">
            <DollarTextField
              name="amount"
              value={formValues.amount}
              onChange={handleChange}
            />
          </EuiFormRow>
        ) : (
          <EuiText>
            <p className="static-text">Amount:<br /> <span className="secondary-text">{formatDollarsAndCents(Math.abs(transaction.amount))}</span></p>
          </EuiText>
        )}

        <StyledSpacer size="32px" />

        <EuiFormRow label="Description">
          <EuiFieldText
            name="description"
            value={formValues.description || ""}
            onChange={handleChange}
          />
        </EuiFormRow>

        <StyledSpacer size="32px" />

        <EuiFormRow label="Ignore Transaction">
          <EuiRadioGroup
            options={radioOptions}
            idSelected={formValues.ignore === "y" ? "y" : "n"}
            onChange={(id) => handleChange({ target: { name: "ignore", value: id } })}
          />
        </EuiFormRow>
      </EuiForm>
    </>
  );
};

export default TransactionDetails;